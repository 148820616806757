const RightArrow = () => (
    <svg
        stroke="currentColor"
        fill="currentColor"
        strokeWidth="0"
        viewBox="0 0 512 512"
        height="1em"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
    </svg>
)

const FollowUS = () => (
    <svg
        stroke="currentColor"
        fill="currentColor"
        strokeWidth="0"
        viewBox="0 0 448 512"
        height="1em"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M446.7 98.6l-67.6 318.8c-5.1 22.5-18.4 28.1-37.3 17.5l-103-75.9-49.7 47.8c-5.5 5.5-10.1 10.1-20.7 10.1l7.4-104.9 190.9-172.5c8.3-7.4-1.8-11.5-12.9-4.1L117.8 284 16.2 252.2c-22.1-6.9-22.5-22.1 4.6-32.7L418.2 66.4c18.4-6.9 34.5 4.1 28.5 32.2z"></path>
    </svg>
)

const Signout = () => (
    <svg
        stroke="currentColor"
        fill="none"
        strokeWidth="2"
        viewBox="0 0 24 24"
        strokeLinecap="round"
        strokeLinejoin="round"
        height="1em"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
        <polyline points="16 17 21 12 16 7"></polyline>
        <line x1="21" y1="12" x2="9" y2="12"></line>
    </svg>
)

const HistorySvg = () => (
    <svg
        stroke="currentColor"
        fill="currentColor"
        strokeWidth="0"
        viewBox="0 0 24 24"
        height="1em"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path fill="none" d="M0 0h24v24H0z"></path>
        <path d="M15.5 11.5c0 2-2.5 3.5-2.5 5h-2c0-1.5-2.5-3-2.5-5C8.5 9.57 10.07 8 12 8s3.5 1.57 3.5 3.5zm-2.5 6h-2V19h2v-1.5zm9-5.5c0-2.76-1.12-5.26-2.93-7.07l-1.06 1.06A8.481 8.481 0 0120.5 12c0 2.34-.95 4.47-2.49 6.01l1.06 1.06A9.969 9.969 0 0022 12zM3.5 12c0-2.34.95-4.47 2.49-6.01L4.93 4.93A9.969 9.969 0 002 12c0 2.76 1.12 5.26 2.93 7.07l1.06-1.06A8.481 8.481 0 013.5 12zm14 0c0 1.52-.62 2.89-1.61 3.89l1.06 1.06A6.976 6.976 0 0019 12c0-1.93-.78-3.68-2.05-4.95l-1.06 1.06c.99 1 1.61 2.37 1.61 3.89zM7.05 16.95l1.06-1.06c-1-1-1.61-2.37-1.61-3.89s.62-2.89 1.61-3.89L7.05 7.05A6.976 6.976 0 005 12c0 1.93.78 3.68 2.05 4.95z"></path>
    </svg>
)

const Transaction = () => (
    <svg
        stroke="currentColor"
        fill="none"
        strokeWidth="2"
        viewBox="0 0 24 24"
        strokeLinecap="round"
        strokeLinejoin="round"
        height="1em"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
        <path d="M9 7m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0"></path>
        <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"></path>
        <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
        <path d="M21 21v-2a4 4 0 0 0 -3 -3.85"></path>
    </svg>
)

const Rupee = () => (
    <svg
        stroke="currentColor"
        fill="currentColor"
        strokeWidth="0"
        viewBox="0 0 320 512"
        height="1em"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M308 96c6.627 0 12-5.373 12-12V44c0-6.627-5.373-12-12-12H12C5.373 32 0 37.373 0 44v44.748c0 6.627 5.373 12 12 12h85.28c27.308 0 48.261 9.958 60.97 27.252H12c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h158.757c-6.217 36.086-32.961 58.632-74.757 58.632H12c-6.627 0-12 5.373-12 12v53.012c0 3.349 1.4 6.546 3.861 8.818l165.052 152.356a12.001 12.001 0 0 0 8.139 3.182h82.562c10.924 0 16.166-13.408 8.139-20.818L116.871 319.906c76.499-2.34 131.144-53.395 138.318-127.906H308c6.627 0 12-5.373 12-12v-40c0-6.627-5.373-12-12-12h-58.69c-3.486-11.541-8.28-22.246-14.252-32H308z"></path>
    </svg>
)

const ReferBanner = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="236" height="236" viewBox="0 0 236 236" fill="none">
                        <path d="M118.472 231.865C177.769 231.865 225.838 208.772 225.838 180.285C225.838 151.798 177.769 128.705 118.472 128.705C59.1755 128.705 11.1062 151.798 11.1062 180.285C11.1062 208.772 59.1755 231.865 118.472 231.865Z" fill="#F0F0F0" />
                        <path d="M94.99 192.685C120.698 192.685 141.539 180.652 141.539 165.809C141.539 150.966 120.698 138.933 94.99 138.933C69.2819 138.933 48.4414 150.966 48.4414 165.809C48.4414 180.652 69.2819 192.685 94.99 192.685Z" fill="#E0E0E0" />
                        <path d="M70.2855 217.63C93.353 217.63 112.053 206.833 112.053 193.515C112.053 180.197 93.353 169.401 70.2855 169.401C47.2181 169.401 28.5182 180.197 28.5182 193.515C28.5182 206.833 47.2181 217.63 70.2855 217.63Z" fill="#E0E0E0" />
                        <path d="M169.873 205.924C195.581 205.924 216.421 193.892 216.421 179.048C216.421 164.205 195.581 152.173 169.873 152.173C144.165 152.173 123.324 164.205 123.324 179.048C123.324 193.892 144.165 205.924 169.873 205.924Z" fill="#E0E0E0" />
                        <path d="M199.33 56.0547C199.354 58.2779 199.274 60.4207 196.064 60.9211C199.458 60.8739 199.198 63.012 199.368 65.693C199.462 63.012 199.698 61.091 202.342 60.973C199.599 60.5104 199.656 59.2407 199.33 56.0547Z" fill="#455A64" />
                        <path d="M21.7734 53.7278C21.7734 55.4128 21.7309 57.0318 19.2954 57.4094C21.8678 57.3763 21.6554 58.9953 21.797 61.0249C21.8678 58.9953 22.0424 57.5415 24.0484 57.4471C21.9763 57.112 22.033 56.1397 21.7734 53.7278Z" fill="#455A64" />
                        <path d="M69.1905 12.5694C69.2188 15.1842 69.1244 17.7 65.3484 18.29C69.3368 18.2381 69.0347 20.7538 69.2329 23.9021C69.3368 20.7538 69.6153 18.493 72.7257 18.3419C69.5067 17.8086 69.5917 16.317 69.1905 12.5694Z" fill="#455A64" />
                        <path d="M149.671 108.385C149.671 109.693 149.638 110.953 147.745 111.246C149.742 111.217 149.591 112.478 149.69 114.054C149.742 112.478 149.884 111.35 151.436 111.274C149.827 111.005 149.869 110.259 149.671 108.385Z" fill="#455A64" />
                        <path d="M187.993 72.9665C188.031 76.3602 187.908 79.6311 183.004 80.3911C188.196 80.325 187.795 83.5912 188.045 87.6835C188.186 83.5912 188.545 80.6554 192.585 80.4619C188.404 79.768 188.517 77.8328 187.993 72.9665Z" fill="#F10086" />
                        <path d="M141.17 49.3382C141.204 52.1702 141.1 54.9314 136.979 55.5733C141.331 55.5166 141.005 58.259 141.227 61.7093C141.34 58.2731 141.642 55.8093 145.036 55.6441C141.52 55.0494 141.6 53.4257 141.17 49.3382Z" fill="#F10086" />
                        <path d="M178.104 14.75C178.104 16.0102 178.076 17.2186 176.259 17.497C178.175 17.497 178.034 18.6818 178.123 20.1922C178.175 18.6818 178.307 17.5962 179.804 17.5254C178.26 17.2705 178.298 16.553 178.104 14.75Z" fill="#F10086" />
                        <path d="M35.6266 117.674C35.6266 118.963 35.5982 120.2 33.7386 120.506C35.7021 120.483 35.551 121.719 35.6266 123.272C35.6785 121.719 35.8154 120.605 37.3446 120.535C35.787 120.251 35.8248 119.52 35.6266 117.674Z" fill="#F10086" />
                        <path d="M33.899 61.7282C33.9321 65.1549 33.8094 68.4542 28.8581 69.2235C34.0926 69.1574 33.6961 72.452 33.951 76.5867C34.0926 72.452 35.013 69.351 39.091 69.1574C34.8714 68.44 34.4277 66.6417 33.899 61.7282Z" fill="#F10086" />
                        <path d="M207.468 70.0731C207.468 71.8101 207.118 73.5565 204.607 73.9435C207.26 73.9105 207.364 75.5106 207.491 77.6062C207.562 75.5106 208.138 73.8963 210.205 73.7972C208.067 73.4432 207.732 72.5653 207.468 70.0731Z" fill="#EBEBEB" />
                        <path d="M215.133 126.123C215.133 127.983 214.76 129.847 212.079 130.263C214.911 130.225 215.024 131.938 215.166 134.175C215.241 131.938 215.85 130.211 218.064 130.107C215.775 129.729 215.421 128.785 215.133 126.123Z" fill="#EBEBEB" />
                        <path d="M14.5282 133.972C14.5282 135.832 14.1553 137.697 11.4696 138.112C14.3016 138.074 14.4149 139.788 14.5565 142.025C14.632 139.788 15.2456 138.06 17.4546 137.956C15.1701 137.579 14.8114 136.635 14.5282 133.972Z" fill="#EBEBEB" />
                        <path d="M186.379 18.2381C186.402 20.6547 186.317 22.9581 182.824 23.5245C186.516 23.4726 186.237 25.7995 186.416 28.7165C186.516 25.8042 186.77 23.7133 189.645 23.5764C186.671 23.0808 186.752 21.712 186.379 18.2381Z" fill="#E0E0E0" />
                        <path d="M39.0438 53.3596C39.0438 50.943 38.9825 48.7859 35.4897 48.2431C39.1807 48.295 38.8975 45.7982 39.0816 42.8859C39.176 45.784 39.648 48.295 42.48 48.4319C39.5253 48.9228 39.4167 49.8951 39.0438 53.3596Z" fill="#E0E0E0" />
                        <path d="M24.695 157.044C24.6427 156.157 24.3413 155.303 23.8252 154.58C23.3092 153.857 22.5997 153.294 21.7781 152.956C19.5455 151.951 16.9212 152.012 16.723 152.239C16.5247 152.465 17.4734 154.887 19.9231 156.374C20.616 156.839 21.4055 157.141 22.232 157.257C23.0586 157.373 23.9007 157.3 24.695 157.044Z" fill="#455A64" />
                        <path d="M27.376 162.5C26.9251 161.735 26.2673 161.112 25.4783 160.704C24.6892 160.295 23.8009 160.118 22.9156 160.192C20.4706 160.32 18.1673 161.575 18.0918 161.858C18.0162 162.141 19.9798 163.874 22.8118 164.081C23.6427 164.182 24.4855 164.091 25.2763 163.817C26.0671 163.543 26.7851 163.093 27.376 162.5Z" fill="#455A64" />
                        <path d="M32.0394 162.127C32.7149 161.551 33.2141 160.795 33.4796 159.948C33.7451 159.101 33.7662 158.195 33.5403 157.336C32.9928 154.948 31.6098 152.985 31.3172 152.961C31.0246 152.937 29.4622 155.373 29.5472 157.95C29.6369 160.759 31.5013 161.665 32.0394 162.127Z" fill="#455A64" />
                        <path d="M40.1814 181.305C40.12 179.1 39.3506 176.939 38.5435 174.909C37.7837 173.002 36.8681 171.162 35.8059 169.406C33.5949 165.744 30.906 162.393 27.8102 159.442C25.8467 157.554 23.6802 155.666 21.2022 154.519C21.1503 154.495 21.0606 154.608 21.1078 154.646C23.1506 156.084 25.0477 157.719 26.7718 159.527C28.3848 161.071 29.8805 162.734 31.2464 164.501C33.5583 167.518 35.4907 170.807 37.0001 174.295C38.0149 176.604 38.6285 179.015 39.3601 181.427C39.3763 181.536 39.4352 181.634 39.5237 181.7C39.6123 181.765 39.7232 181.793 39.8321 181.777C39.941 181.76 40.039 181.701 40.1045 181.613C40.17 181.524 40.1976 181.414 40.1814 181.305Z" fill="#37474F" />
                        <path d="M32.096 164.114C32.3136 163.027 32.4102 161.919 32.3839 160.81C32.4023 159.743 32.2165 158.683 31.8364 157.686C31.7798 157.549 31.7184 157.766 31.7278 157.86C31.8197 158.801 31.8575 159.747 31.8411 160.692C31.8125 161.702 31.6425 162.703 31.3361 163.666C31.2989 163.798 31.3029 163.939 31.3476 164.069C31.3923 164.198 31.4755 164.312 31.5862 164.393C31.6373 164.418 31.6938 164.431 31.7508 164.43C31.8078 164.429 31.8637 164.414 31.9138 164.387C31.9639 164.359 32.0066 164.32 32.0383 164.273C32.07 164.225 32.0898 164.171 32.096 164.114Z" fill="#37474F" />
                        <path d="M31.7703 163.855C30.4912 162.963 29.2404 162.58 27.4751 162.127C26.7884 161.955 26.0893 161.837 25.3842 161.773C24.5462 161.685 23.6997 161.723 22.8731 161.887C22.8448 161.887 22.6749 162.014 22.7362 162.019C23.5577 162.041 24.3771 162.11 25.1906 162.226C25.9315 162.319 26.6628 162.477 27.376 162.698C28.8123 163.112 30.1977 163.685 31.506 164.407C31.7798 164.563 31.9922 164.011 31.7703 163.855Z" fill="#37474F" />
                        <path d="M32.9126 169.693C32.6307 168.851 32.1166 168.104 31.4294 167.541C30.7422 166.977 29.9098 166.619 29.028 166.507C26.6114 166.12 24.0956 166.871 23.9634 167.13C23.8313 167.39 25.3794 169.49 28.1312 170.293C28.9214 170.563 29.7626 170.649 30.591 170.545C31.4195 170.441 32.2134 170.15 32.9126 169.693Z" fill="#455A64" />
                        <path d="M36.9198 171.935C35.8531 170.798 34.7109 170.161 33.0825 169.354C32.4466 169.041 31.7868 168.779 31.1095 168.57C30.3089 168.309 29.4736 168.169 28.6315 168.155C28.5985 168.155 28.4097 168.24 28.471 168.254C29.2703 168.45 30.0581 168.689 30.831 168.971C31.538 169.227 32.2217 169.543 32.8748 169.915C34.1911 170.62 35.4244 171.471 36.5517 172.45C36.783 172.634 37.1039 172.134 36.9198 171.935Z" fill="#37474F" />
                        <path d="M41.031 143.795C40.651 142.897 40.5314 141.911 40.6858 140.948C40.8402 139.986 41.2624 139.086 41.9042 138.353C43.6128 136.281 46.2277 135.049 46.525 135.186C46.8224 135.322 47.0773 138.154 45.397 140.816C44.9425 141.611 44.3133 142.292 43.5569 142.808C42.8005 143.324 41.9368 143.662 41.031 143.795Z" fill="#455A64" />
                        <path d="M41.064 150.469C41.1317 149.496 41.474 148.562 42.0514 147.776C42.6287 146.989 43.4173 146.383 44.3255 146.027C46.7894 144.961 49.6686 145.083 49.881 145.315C50.0934 145.546 49.0172 148.213 46.3032 149.808C45.5373 150.309 44.6676 150.628 43.7598 150.743C42.8521 150.857 41.9302 150.764 41.064 150.469Z" fill="#455A64" />
                        <path d="M36.2874 152.376C35.3408 152.14 34.4805 151.64 33.8063 150.935C33.1321 150.23 32.6716 149.348 32.4783 148.392C31.8506 145.777 32.2565 143.176 32.5302 143.007C32.804 142.837 35.5274 144.479 36.7027 147.052C37.9818 149.855 36.5894 151.658 36.2874 152.376Z" fill="#455A64" />
                        <path d="M37.6278 175.202C36.613 173.002 36.3157 170.482 36.1174 168.122C35.931 165.877 35.931 163.621 36.1174 161.377C36.5058 156.701 37.5153 152.098 39.1194 147.689C40.1436 144.899 41.3425 141.963 43.221 139.632C43.2588 139.58 43.4051 139.632 43.3721 139.707C42.0682 142.115 41.0027 144.644 40.1908 147.259C39.3627 149.569 38.7079 151.937 38.232 154.344C37.4285 158.436 37.132 162.612 37.3494 166.776C37.4768 169.542 38.0479 172.223 38.4916 174.947C38.4898 175.047 38.4561 175.145 38.3952 175.224C38.3344 175.304 38.2496 175.363 38.1532 175.391C38.0569 175.42 37.954 175.417 37.8595 175.383C37.765 175.348 37.6838 175.285 37.6278 175.202Z" fill="#37474F" />
                        <path d="M37.203 154.344C36.4602 153.379 35.8269 152.334 35.315 151.229C34.7784 150.187 34.4437 149.053 34.3286 147.887C34.3286 147.727 34.4843 147.911 34.5174 148.01C34.8888 148.978 35.3159 149.923 35.7965 150.842C36.3149 151.82 36.9679 152.721 37.7364 153.518C37.8381 153.628 37.9036 153.767 37.9238 153.916C37.9441 154.064 37.9182 154.215 37.8497 154.349C37.8148 154.403 37.7668 154.448 37.71 154.478C37.6532 154.509 37.5896 154.525 37.5251 154.525C37.4605 154.524 37.3971 154.507 37.3408 154.476C37.2845 154.444 37.2371 154.399 37.203 154.344Z" fill="#37474F" />
                        <path d="M37.3918 153.943C38.2178 152.442 39.2798 151.455 40.7761 150.167C41.3689 149.662 42.0001 149.205 42.6641 148.798C43.4426 148.301 44.2926 147.925 45.1846 147.684C45.2223 147.684 45.4442 147.712 45.3828 147.745C44.5877 148.174 43.8172 148.647 43.0747 149.161C42.3922 149.617 41.751 150.132 41.1584 150.7C39.9512 151.793 38.8671 153.015 37.9252 154.344C37.7317 154.646 37.2502 154.202 37.3918 153.943Z" fill="#37474F" />
                        <path d="M39.1194 160.239C38.9833 159.274 39.1234 158.29 39.5236 157.401C39.9237 156.512 40.5674 155.755 41.3802 155.217C43.5703 153.655 46.407 153.169 46.6666 153.362C46.9262 153.556 46.4259 156.374 44.1084 158.502C43.4624 159.151 42.6778 159.644 41.8136 159.945C40.9494 160.245 40.0282 160.346 39.1194 160.239Z" fill="#455A64" />
                        <path d="M36.2685 164.398C36.7405 162.76 37.5712 161.566 38.7842 159.989C39.2566 159.371 39.7774 158.792 40.3418 158.257C40.9984 157.607 41.7515 157.063 42.5744 156.643C42.6122 156.643 42.8387 156.614 42.7821 156.643C42.0941 157.223 41.4386 157.841 40.8186 158.493C40.2452 159.076 39.724 159.707 39.261 160.381C38.3103 161.715 37.5109 163.151 36.8774 164.662C36.7452 165.016 36.1835 164.681 36.2685 164.398Z" fill="#37474F" />
                        <path d="M219.881 107.913L221.132 108.475L219.971 111.024C219.829 111.329 219.649 111.615 219.433 111.873C219.357 111.963 219.277 112.048 219.192 112.133C218.755 112.553 218.244 112.891 217.686 113.129C217.479 113.221 217.266 113.302 217.049 113.37C216.816 113.448 216.578 113.513 216.336 113.563C216.012 113.636 215.685 113.693 215.355 113.733C214.996 113.776 214.628 113.804 214.25 113.813C213.967 113.813 213.679 113.813 213.386 113.813C209.101 113.658 204.05 111.571 200.642 108.555C200.411 108.352 200.17 108.14 199.972 107.928C199.661 107.625 199.368 107.309 199.094 106.984C198.821 106.658 198.665 106.46 198.476 106.2C198.287 105.94 198.164 105.728 198.004 105.525C197.844 105.322 197.749 105.086 197.641 104.869C197.333 104.276 197.118 103.639 197.003 102.981C197.003 102.849 196.966 102.721 196.956 102.599C196.906 102.076 196.959 101.548 197.112 101.046C197.155 100.895 197.21 100.748 197.277 100.607L198.377 98.1996L199.514 98.7141C199.651 98.6527 199.793 98.6008 199.939 98.5489C200.538 98.3444 201.156 98.2051 201.785 98.1335C202.19 98.0849 202.599 98.0581 203.007 98.0533C203.556 98.0451 204.104 98.0672 204.65 98.1193C208.707 98.5953 212.555 100.181 215.77 102.702C216.229 103.064 216.669 103.45 217.087 103.859C217.431 104.189 217.752 104.534 218.055 104.883C218.486 105.385 218.873 105.924 219.211 106.493C219.482 106.942 219.706 107.418 219.881 107.913Z" fill="#E0E0E0" />
                        <path opacity="0.15" d="M202.823 110.217L205.183 105.025L206.986 106.087L204.626 111.279C204.003 110.951 203.402 110.597 202.823 110.217Z" fill="white" />
                        <path opacity="0.05" d="M219.952 111.024C219.811 111.329 219.63 111.615 219.414 111.873C219.338 111.963 219.258 112.048 219.173 112.133C218.736 112.553 218.226 112.891 217.668 113.129L217.441 113.223L219.858 107.899L221.108 108.461L219.952 111.024Z" fill="black" />
                        <path opacity="0.15" d="M214.76 105.766L215.841 105.978L212.277 113.752C211.923 113.714 211.56 113.662 211.201 113.601L214.76 105.766Z" fill="white" />
                        <path opacity="0.15" d="M208.459 112.902L211.517 106.2L213.349 107.201L210.517 113.459C209.822 113.31 209.135 113.124 208.459 112.902Z" fill="white" />
                        <path opacity="0.15" d="M198.391 98.2137L199.529 98.7282C199.665 98.6669 199.807 98.615 199.953 98.563C200.552 98.3585 201.171 98.2193 201.799 98.1477C202.205 98.099 202.613 98.0722 203.021 98.0674C203.57 98.0593 204.118 98.0813 204.664 98.1335C205.193 98.1854 205.731 98.2657 206.269 98.3648L201.36 109.159C201.11 108.961 200.888 108.758 200.633 108.551C200.378 108.343 200.161 108.135 199.963 107.923C199.651 107.621 199.359 107.304 199.085 106.979C198.811 106.653 198.655 106.455 198.467 106.195C198.278 105.936 198.155 105.723 197.995 105.52C197.834 105.317 197.74 105.081 197.631 104.864C197.323 104.271 197.108 103.634 196.994 102.976C196.994 102.844 196.956 102.717 196.947 102.594C196.896 102.071 196.949 101.543 197.102 101.041C197.146 100.89 197.201 100.744 197.268 100.602L198.391 98.2137Z" fill="white" />
                        <path opacity="0.15" d="M197.018 102.995C197.018 102.863 196.98 102.736 196.97 102.613C196.92 102.09 196.973 101.562 197.126 101.06C197.169 100.909 197.224 100.763 197.291 100.621L198.391 98.2138L199.529 98.7283L199.85 99.0445L197.409 104.402C197.225 103.95 197.093 103.478 197.018 102.995Z" fill="white" />
                        <path d="M219.31 102.174C219.008 101.82 218.687 101.48 218.342 101.145C217.923 100.739 217.484 100.355 217.026 99.9932C213.811 97.4704 209.963 95.8829 205.905 95.4054C205.359 95.3532 204.811 95.3311 204.263 95.3393C203.843 95.3393 203.437 95.377 203.04 95.4242C202.412 95.4944 201.793 95.632 201.195 95.8349C200.615 96.0296 200.071 96.3182 199.585 96.6892C198.956 97.1632 198.494 97.8241 198.264 98.5772C198.111 99.078 198.058 99.6041 198.108 100.125C198.108 100.253 198.136 100.38 198.155 100.508C198.269 101.166 198.484 101.803 198.792 102.396C198.901 102.613 199.024 102.83 199.16 103.052C199.297 103.274 199.444 103.5 199.604 103.722C199.765 103.944 200.001 104.246 200.222 104.51C200.444 104.775 200.789 105.143 201.1 105.454C201.317 105.667 201.539 105.879 201.775 106.082C205.188 109.098 210.238 111.184 214.519 111.34C214.812 111.34 215.1 111.34 215.383 111.34C215.761 111.34 216.129 111.302 216.488 111.26C216.817 111.22 217.145 111.163 217.469 111.09C217.719 111.033 217.941 110.972 218.182 110.896C218.399 110.827 218.612 110.745 218.819 110.651C219.377 110.415 219.887 110.079 220.325 109.66C220.41 109.575 220.49 109.485 220.566 109.395C220.894 109.012 221.137 108.563 221.278 108.079C221.497 107.284 221.489 106.443 221.255 105.652C221.074 105.005 220.807 104.384 220.462 103.807C220.123 103.232 219.738 102.686 219.31 102.174Z" fill="#F0F0F0" />
                        <path opacity="0.15" d="M202.488 106.672C202.238 106.469 202.016 106.266 201.761 106.058C201.506 105.851 201.289 105.643 201.086 105.431C200.775 105.124 200.482 104.812 200.208 104.487C199.934 104.161 199.779 103.963 199.59 103.698C199.401 103.434 199.278 103.25 199.146 103.028C199.014 102.806 198.887 102.589 198.778 102.372C198.47 101.779 198.255 101.142 198.141 100.484C198.141 100.357 198.103 100.229 198.094 100.102C198.044 99.5805 198.096 99.0545 198.249 98.5536C198.48 97.8005 198.942 97.1396 199.571 96.6656C200.057 96.2946 200.601 96.006 201.181 95.8113C201.779 95.6084 202.398 95.4708 203.026 95.4006C203.38 95.3582 203.739 95.3346 204.107 95.3251L202.488 106.672Z" fill="white" />
                        <path opacity="0.15" d="M198.561 101.872C198.373 101.428 198.237 100.964 198.155 100.489C198.155 100.361 198.117 100.234 198.108 100.106C198.058 99.5852 198.111 99.0592 198.264 98.5583C198.452 97.9495 198.797 97.401 199.264 96.9677L198.561 101.872Z" fill="white" />
                        <path opacity="0.15" d="M205.759 108.782C205.141 108.451 204.536 108.093 203.961 107.715L205.726 95.3912H205.905C206.472 95.4478 207.052 95.5375 207.638 95.6508L205.759 108.782Z" fill="white" />
                        <path opacity="0.15" d="M211.635 110.92C210.96 110.778 210.276 110.594 209.596 110.382L211.541 96.8166C212.216 97.0904 212.877 97.3972 213.528 97.7276L211.635 110.92Z" fill="white" />
                        <path opacity="0.15" d="M213.42 111.222C213.061 111.184 212.697 111.132 212.334 111.071L214.189 98.1052C214.538 98.2987 214.878 98.497 215.213 98.7094L213.42 111.222Z" fill="white" />
                        <path opacity="0.3" d="M219.74 102.712C219.853 102.863 219.957 103.009 220.061 103.156L220.16 103.302L220.259 103.448L220.466 103.779C220.812 104.356 221.078 104.976 221.259 105.624C221.494 106.415 221.502 107.255 221.283 108.05C221.142 108.535 220.899 108.984 220.57 109.367C220.495 109.457 220.415 109.546 220.33 109.631C219.892 110.05 219.382 110.386 218.824 110.623L218.593 110.722L219.74 102.712Z" fill="#E0E0E0" />
                        <path d="M216.856 102.424C214.184 99.3041 209.077 97.1187 205.211 97.3783C205.046 97.3783 204.881 97.4019 204.739 97.4208C200.827 97.8928 199.911 100.942 202.696 104.194C205.48 107.446 210.913 109.683 214.826 109.192C214.987 109.174 215.144 109.15 215.298 109.122C218.791 108.475 219.527 105.558 216.856 102.424Z" fill="#E0E0E0" />
                        <path d="M216.539 102.481C216.454 102.383 216.365 102.284 216.27 102.183C213.66 99.3513 208.983 97.4208 205.414 97.6615H205.32L204.952 97.6993C204.295 97.7662 203.655 97.9517 203.064 98.2468C202.507 98.5018 202.051 98.9345 201.766 99.477C201.482 100.019 201.385 100.641 201.492 101.244C201.66 102.137 202.044 102.974 202.611 103.684C202.729 103.845 202.856 104.005 202.998 104.156C203.139 104.307 203.257 104.454 203.394 104.6C203.693 104.908 204.008 105.199 204.338 105.473C206.757 107.462 209.716 108.682 212.834 108.975C213.235 109.001 213.637 109.001 214.038 108.975C214.222 108.975 214.406 108.952 214.585 108.928C214.765 108.905 214.883 108.886 215.029 108.857L215.147 108.834C215.965 108.7 216.723 108.324 217.323 107.753C218.163 106.894 218.319 105.652 217.715 104.27C217.412 103.619 217.016 103.016 216.539 102.481Z" fill="#E0E0E0" />
                        <path opacity="0.13" d="M216.539 102.481C216.454 102.383 216.365 102.284 216.27 102.183C213.66 99.3513 208.983 97.4208 205.414 97.6615H205.32L204.952 97.6993C204.295 97.7662 203.655 97.9517 203.064 98.2468C202.507 98.5018 202.051 98.9345 201.766 99.477C201.482 100.019 201.385 100.641 201.492 101.244C201.66 102.137 202.044 102.974 202.611 103.684C202.729 103.845 202.856 104.005 202.998 104.156C203.139 104.307 203.257 104.454 203.394 104.6C203.693 104.908 204.008 105.199 204.338 105.473C206.757 107.462 209.716 108.682 212.834 108.975C213.235 109.001 213.637 109.001 214.038 108.975C214.222 108.975 214.406 108.952 214.585 108.928C214.765 108.905 214.883 108.886 215.029 108.857L215.147 108.834C215.965 108.7 216.723 108.324 217.323 107.753C218.163 106.894 218.319 105.652 217.715 104.27C217.412 103.619 217.016 103.016 216.539 102.481Z" fill="black" />
                        <path d="M215.841 103.132C215.935 103.231 216.025 103.33 216.11 103.434C216.589 103.976 216.985 104.585 217.285 105.242C217.618 105.936 217.721 106.718 217.578 107.474C217.501 107.582 217.416 107.683 217.323 107.776C216.723 108.347 215.964 108.724 215.147 108.857L215.029 108.881C214.883 108.909 214.736 108.933 214.585 108.952C214.434 108.971 214.222 108.99 214.038 108.999C213.637 109.025 213.235 109.025 212.834 108.999C209.716 108.705 206.757 107.486 204.338 105.497C204.008 105.223 203.693 104.931 203.394 104.624C203.257 104.477 203.125 104.331 202.998 104.18C202.87 104.029 202.729 103.859 202.611 103.708C202.044 102.998 201.66 102.16 201.492 101.268C201.434 100.897 201.445 100.52 201.525 100.154C201.816 99.7545 202.198 99.4307 202.639 99.2097C203.23 98.9144 203.87 98.7274 204.527 98.6574L204.895 98.6244H204.994C208.558 98.3742 213.231 100.314 215.841 103.132Z" fill="#E0E0E0" />
                        <path d="M220.33 109.631C217.743 111.902 213.627 111.401 210.465 110.575C205.745 109.188 201.114 106.441 198.83 102.334C198.167 101.194 197.963 99.8438 198.259 98.5583C197.913 99.8487 198.082 101.223 198.731 102.391C200.524 105.804 204.041 108.248 207.765 109.825C211.588 111.293 216.974 112.544 220.33 109.631Z" fill="#F5F5F5" />
                        <path d="M204.135 103.302C205.155 104.406 206.495 104.718 207.826 104.855L207.383 105.029L206.788 106.337C207.018 106.509 207.26 106.667 207.51 106.809C207.944 107.052 208.405 107.242 208.884 107.375C209.674 107.587 210.492 107.676 211.31 107.64L212.206 108.612L214.123 108.371L214.718 107.068L214.491 106.818L214.444 106.766C214.512 106.676 214.569 106.578 214.614 106.474C214.628 106.438 214.641 106.402 214.651 106.365C214.703 106.195 215.076 105.473 215.208 105.166C215.223 105.131 215.236 105.095 215.246 105.058C215.369 104.675 215.213 104.208 214.713 103.67C214.202 103.129 213.562 102.728 212.853 102.504L212.674 102.448L212.485 102.396C212.431 102.379 212.375 102.365 212.32 102.353H212.249L212.122 102.325C211.764 102.253 211.404 102.201 211.041 102.169L210.569 102.136L211.154 101.905L211.749 100.597C210.499 99.7495 209.009 99.3305 207.501 99.4032L206.604 98.4592L204.716 98.733L204.121 100.04L204.4 100.342C204.339 100.411 204.29 100.489 204.253 100.574C204.173 100.781 203.781 101.636 203.663 101.872C203.432 102.245 203.607 102.731 204.135 103.302Z" fill="#E0E0E0" />
                        <path opacity="0.1" d="M215.18 105.256C215.029 105.591 214.708 106.238 214.651 106.394L214.614 106.502C214.568 106.606 214.511 106.704 214.444 106.795L213.816 106.077C214.094 106.064 214.364 105.982 214.602 105.839C214.84 105.696 215.039 105.495 215.18 105.256Z" fill="black" />
                        <path opacity="0.2" d="M207.147 101.551C206.878 101.258 206.939 100.975 207.732 100.857C208.092 100.791 208.462 100.799 208.819 100.88C209.176 100.961 209.513 101.114 209.809 101.329L211.72 100.569L211.121 101.872L210.545 102.103H210.512L209.78 102.07H209.502C208.991 102.076 208.481 102.036 207.977 101.952C207.77 101.918 207.572 101.844 207.392 101.735C207.302 101.685 207.22 101.623 207.147 101.551Z" fill="black" />
                        <path opacity="0.13" d="M204.343 100.3L204.064 99.9979L204.659 98.6952L205.551 99.6628C205.094 99.7514 204.674 99.9731 204.343 100.3Z" fill="black" />
                        <path opacity="0.1" d="M212.801 107.314L214.718 107.073L214.128 108.381L212.206 108.621L211.314 107.649C210.495 107.687 209.675 107.596 208.884 107.38C208.404 107.25 207.943 107.062 207.51 106.818C207.259 106.678 207.017 106.52 206.788 106.346L207.383 105.039C207.611 105.212 207.851 105.369 208.1 105.511C208.535 105.753 208.998 105.942 209.478 106.073C210.268 106.291 211.087 106.381 211.904 106.342L212.801 107.314Z" fill="black" />
                        <path opacity="0.13" d="M211.314 107.649C210.649 107.735 209.975 107.709 209.318 107.574C208.671 107.445 208.056 107.189 207.51 106.818C208.105 107.08 208.725 107.279 209.36 107.413C209.672 107.481 209.987 107.533 210.304 107.569C210.649 107.616 210.984 107.616 211.314 107.649Z" fill="black" />
                        <path opacity="0.13" d="M203.602 101.853C203.701 101.617 204.112 100.763 204.192 100.555C204.041 100.951 204.192 101.433 204.73 102.004C205.915 103.283 207.515 103.528 209.054 103.599C210.47 103.661 211.598 103.543 212.173 104.161C212.532 104.548 212.268 104.784 211.645 104.869C211.249 104.924 210.846 104.897 210.462 104.79C210.077 104.683 209.718 104.497 209.408 104.246L207.826 104.864C206.491 104.746 205.155 104.392 204.14 103.311C203.602 102.731 203.432 102.249 203.602 101.853Z" fill="black" />
                        <path d="M209.214 102.655L209.809 101.348L211.72 100.583L211.126 101.891L209.214 102.655Z" fill="#E0E0E0" />
                        <path opacity="0.1" d="M209.214 102.655L209.809 101.348L211.72 100.583L211.126 101.891L209.214 102.655Z" fill="black" />
                        <path d="M204.659 98.6952L205.556 99.6628L204.343 100.305L204.064 100.003L204.659 98.6952Z" fill="#E0E0E0" />
                        <path opacity="0.2" d="M204.659 98.6952L205.556 99.6628L204.343 100.305L204.064 100.003L204.659 98.6952Z" fill="black" />
                        <path d="M214.491 106.828L213.816 106.077C214.111 106.068 214.398 105.979 214.647 105.82C214.897 105.662 215.098 105.439 215.232 105.176C215.247 105.14 215.259 105.104 215.27 105.067C215.392 104.685 215.237 104.218 214.736 103.68C214.226 103.139 213.586 102.738 212.877 102.514L212.697 102.457L212.509 102.405C212.454 102.388 212.399 102.374 212.343 102.363H212.273L212.145 102.334C211.788 102.262 211.427 102.21 211.064 102.179C210.89 102.179 210.715 102.15 210.54 102.146L209.809 102.112H209.53C209.02 102.117 208.51 102.079 208.006 101.999C207.799 101.961 207.601 101.886 207.42 101.777C207.335 101.723 207.257 101.658 207.189 101.584C206.92 101.291 206.981 101.008 207.774 100.895C208.134 100.826 208.505 100.833 208.862 100.914C209.219 100.995 209.556 101.149 209.851 101.367L211.763 100.602C210.514 99.7542 209.023 99.3352 207.515 99.4079L206.618 98.4639L204.73 98.7377L205.627 99.7053C204.9 99.8988 204.428 100.177 204.267 100.578C204.116 100.975 204.267 101.456 204.806 102.032C205.986 103.307 207.59 103.552 209.129 103.623C210.545 103.684 211.673 103.566 212.249 104.185C212.603 104.572 212.343 104.808 211.72 104.893C211.325 104.947 210.923 104.92 210.538 104.814C210.153 104.708 209.794 104.524 209.483 104.274L207.458 105.062C207.687 105.237 207.928 105.395 208.18 105.534C208.614 105.777 209.075 105.967 209.554 106.101C210.344 106.315 211.162 106.404 211.98 106.365L212.877 107.338L214.793 107.102L214.491 106.828Z" fill="#E0E0E0" />
                        <path d="M214.491 106.828L213.816 106.077C214.111 106.068 214.398 105.979 214.647 105.82C214.897 105.662 215.098 105.439 215.232 105.176C215.247 105.14 215.259 105.104 215.27 105.067C215.392 104.685 215.237 104.218 214.736 103.68C214.226 103.139 213.586 102.738 212.877 102.514L212.697 102.457L212.509 102.405C212.454 102.388 212.399 102.374 212.343 102.363H212.273L212.145 102.334C211.788 102.262 211.427 102.21 211.064 102.179C210.89 102.179 210.715 102.15 210.54 102.146L209.809 102.112H209.53C209.02 102.117 208.51 102.079 208.006 101.999C207.799 101.961 207.601 101.886 207.42 101.777C207.335 101.723 207.257 101.658 207.189 101.584C206.92 101.291 206.981 101.008 207.774 100.895C208.134 100.826 208.505 100.833 208.862 100.914C209.219 100.995 209.556 101.149 209.851 101.367L211.763 100.602C210.514 99.7542 209.023 99.3352 207.515 99.4079L206.618 98.4639L204.73 98.7377L205.627 99.7053C204.9 99.8988 204.428 100.177 204.267 100.578C204.116 100.975 204.267 101.456 204.806 102.032C205.986 103.307 207.59 103.552 209.129 103.623C210.545 103.684 211.673 103.566 212.249 104.185C212.603 104.572 212.343 104.808 211.72 104.893C211.325 104.947 210.923 104.92 210.538 104.814C210.153 104.708 209.794 104.524 209.483 104.274L207.458 105.062C207.687 105.237 207.928 105.395 208.18 105.534C208.614 105.777 209.075 105.967 209.554 106.101C210.344 106.315 211.162 106.404 211.98 106.365L212.877 107.338L214.793 107.102L214.491 106.828Z" fill="#F0F0F0" />
                        <path d="M204.73 101.999C205.187 102.462 205.738 102.822 206.344 103.056C206.967 103.294 207.62 103.44 208.284 103.491C208.954 103.562 209.625 103.562 210.3 103.59C210.638 103.598 210.976 103.633 211.31 103.694C211.64 103.758 211.941 103.923 212.173 104.166C211.931 103.942 211.627 103.797 211.3 103.75C210.969 103.7 210.635 103.673 210.3 103.67C209.625 103.67 208.95 103.67 208.275 103.595C207.601 103.533 206.939 103.374 206.311 103.123C206.007 102.996 205.718 102.838 205.447 102.651C205.189 102.456 204.948 102.238 204.73 101.999Z" fill="white" />
                        <path d="M212.801 107.314L212.206 108.621L211.31 107.649L211.904 106.342L212.801 107.314Z" fill="#E0E0E0" />
                        <path opacity="0.13" d="M212.801 107.314L212.206 108.621L211.31 107.649L211.904 106.342L212.801 107.314Z" fill="black" />
                        <path d="M214.718 107.073L212.872 107.172L211.904 106.342L212.801 107.314L214.718 107.073Z" fill="#FAFAFA" />
                        <path d="M207.463 99.389L206.514 98.563L204.659 98.6952L206.566 98.4261L207.463 99.389Z" fill="#FAFAFA" />
                        <path opacity="0.15" d="M211.72 100.583C211.508 100.438 211.288 100.305 211.06 100.187L210.96 100.885L211.72 100.583Z" fill="white" />
                        <path opacity="0.15" d="M204.772 102.037C205.268 102.556 205.882 102.948 206.561 103.179L207.151 99.0539L206.566 98.4214L205.263 98.6055L205.174 99.2474L205.556 99.6628C205.395 99.7021 205.238 99.7525 205.084 99.8138L204.772 102.037Z" fill="white" />
                        <path opacity="0.15" d="M214.529 103.486C214.227 103.207 213.887 102.971 213.519 102.787L212.872 107.304L214 107.163L214.109 106.412L213.802 106.077C213.925 106.069 214.047 106.049 214.165 106.016L214.529 103.486Z" fill="white" />
                        <path opacity="0.15" d="M212.655 102.448L212.466 102.396C212.412 102.379 212.357 102.365 212.301 102.353H212.23L212.103 102.325C211.746 102.253 211.385 102.201 211.022 102.169H210.781L210.569 103.661C211.267 103.698 211.824 103.802 212.173 104.18C212.523 104.557 212.268 104.803 211.645 104.888C211.229 104.946 210.805 104.912 210.403 104.789L210.196 106.261C210.76 106.357 211.333 106.393 211.904 106.37L212.244 106.738L212.844 102.533L212.674 102.481L212.655 102.448Z" fill="white" />
                        <path d="M154.476 30.8688L155.751 31.3691L154.712 33.984C154.594 34.2929 154.435 34.5849 154.24 34.8525C154.168 34.948 154.091 35.0394 154.009 35.1262C153.595 35.5679 153.102 35.9281 152.555 36.1882C152.351 36.2917 152.142 36.3831 151.927 36.462C151.699 36.5495 151.465 36.6251 151.229 36.6886C150.909 36.7779 150.584 36.8504 150.256 36.9057C149.898 36.9623 149.53 37.0095 149.157 37.0378C148.874 37.0378 148.586 37.0709 148.293 37.0709C144.012 37.1181 138.853 35.2773 135.322 32.4264C135.077 32.2329 134.85 32.0346 134.619 31.827C134.289 31.5443 133.974 31.245 133.675 30.9302C133.444 30.68 133.203 30.4298 133.024 30.1797C132.844 29.9295 132.693 29.7454 132.552 29.5283C132.41 29.3112 132.273 29.0988 132.151 28.8864C131.819 28.3123 131.576 27.6918 131.428 27.0456C131.405 26.9182 131.381 26.7907 131.362 26.668C131.29 26.1498 131.319 25.6224 131.447 25.1151C131.485 24.9546 131.534 24.7969 131.594 24.6431L132.575 22.1887L133.736 22.6607C133.873 22.5946 134.01 22.5333 134.152 22.4766C134.74 22.2467 135.351 22.079 135.974 21.9763C136.377 21.9087 136.783 21.863 137.192 21.8394C137.739 21.8063 138.287 21.8063 138.834 21.8394C142.911 22.1202 146.831 23.5217 150.162 25.8892C150.634 26.2385 151.106 26.6019 151.531 26.9795C151.89 27.2958 152.229 27.6262 152.546 27.9613C152.998 28.4414 153.409 28.9593 153.773 29.5094C154.05 29.9392 154.285 30.3944 154.476 30.8688Z" fill="#E0E0E0" />
                        <path opacity="0.15" d="M137.56 33.984L139.665 28.6976L141.515 29.6699L139.41 34.9516C138.771 34.6495 138.154 34.327 137.56 33.984Z" fill="white" />
                        <path opacity="0.05" d="M154.712 33.984C154.594 34.2929 154.435 34.5849 154.24 34.8525C154.168 34.948 154.091 35.0394 154.009 35.1262C153.595 35.5679 153.102 35.9281 152.555 36.1882L152.329 36.2968L154.495 30.8688L155.769 31.3691L154.712 33.984Z" fill="black" />
                        <path opacity="0.15" d="M149.242 28.9666L150.327 29.1318L147.16 37.0284C146.801 37.0284 146.443 36.9718 146.075 36.9293L149.242 28.9666Z" fill="white" />
                        <path opacity="0.15" d="M143.313 36.3818L146.046 29.5331L147.934 30.4487L145.376 36.816C144.681 36.7066 143.993 36.5617 143.313 36.3818Z" fill="white" />
                        <path opacity="0.15" d="M132.571 22.184L133.732 22.656C133.869 22.5899 134.006 22.5286 134.147 22.4719C134.735 22.2419 135.346 22.0742 135.969 21.9716C136.372 21.904 136.779 21.8583 137.187 21.8347C137.734 21.8016 138.282 21.8016 138.829 21.8347C139.37 21.8592 139.909 21.9112 140.444 21.9905L136.049 32.9928C135.79 32.804 135.54 32.6152 135.299 32.4217C135.058 32.2282 134.827 32.0299 134.596 31.8222C134.266 31.5396 133.951 31.2403 133.652 30.9254C133.42 30.6753 133.18 30.4251 133 30.175C132.821 29.9248 132.67 29.7407 132.528 29.5236C132.387 29.3065 132.25 29.0941 132.127 28.8817C131.796 28.3076 131.552 27.6871 131.405 27.0409C131.381 26.9135 131.358 26.786 131.339 26.6633C131.266 26.145 131.295 25.6177 131.424 25.1104C131.461 24.9499 131.51 24.7922 131.57 24.6384L132.571 22.184Z" fill="white" />
                        <path opacity="0.15" d="M131.424 27.0314C131.4 26.904 131.376 26.7766 131.358 26.6538C131.285 26.1356 131.314 25.6082 131.443 25.101C131.48 24.9404 131.529 24.7827 131.589 24.629L132.571 22.1746L133.732 22.6466H133.755L134.086 22.9439L131.905 28.4097C131.691 27.9707 131.53 27.5081 131.424 27.0314Z" fill="white" />
                        <path d="M153.636 25.167C153.32 24.8319 152.98 24.5015 152.626 24.1853C152.196 23.8077 151.739 23.4442 151.257 23.0997C147.927 20.7305 144.007 19.3274 139.929 19.0452C139.382 19.0145 138.834 19.0145 138.287 19.0452C137.871 19.0735 137.465 19.1207 137.069 19.1868C136.447 19.2803 135.836 19.4385 135.247 19.6588C134.677 19.8836 134.149 20.2027 133.685 20.6028C133.083 21.1013 132.651 21.7742 132.448 22.5286C132.323 23.0365 132.296 23.5634 132.368 24.0814C132.368 24.2089 132.41 24.3363 132.434 24.4638C132.582 25.1097 132.825 25.7301 133.156 26.3046C133.279 26.517 133.411 26.7294 133.552 26.9465C133.694 27.1636 133.859 27.376 134.024 27.5931C134.19 27.8102 134.44 28.0982 134.676 28.3483C134.912 28.5985 135.27 28.9572 135.596 29.2451C135.823 29.4481 136.068 29.6463 136.295 29.8398C139.844 32.6718 144.984 34.5362 149.27 34.489C149.563 34.489 149.851 34.489 150.134 34.4513C150.507 34.423 150.875 34.3805 151.234 34.3191C151.561 34.2653 151.886 34.1944 152.206 34.1067C152.442 34.0433 152.676 33.9676 152.904 33.8802C153.126 33.7952 153.334 33.7055 153.532 33.6064C154.083 33.3536 154.582 33.0016 155.005 32.568C155.085 32.4783 155.161 32.3886 155.236 32.2942C155.543 31.8939 155.763 31.4342 155.883 30.9443C156.065 30.1404 156.018 29.3012 155.746 28.523C155.531 27.8861 155.235 27.28 154.863 26.7199C154.501 26.1671 154.09 25.6474 153.636 25.167Z" fill="#F0F0F0" />
                        <path opacity="0.15" d="M137.074 30.4346C136.814 30.2458 136.559 30.057 136.314 29.8587C136.068 29.6605 135.842 29.467 135.615 29.264C135.289 28.9761 134.983 28.674 134.695 28.3672C134.407 28.0604 134.223 27.8622 134.043 27.612C133.864 27.3618 133.708 27.1778 133.571 26.9654C133.434 26.753 133.298 26.5358 133.175 26.3234C132.844 25.749 132.601 25.1286 132.453 24.4826C132.429 24.3552 132.405 24.2278 132.387 24.1003C132.315 23.5823 132.342 23.0554 132.467 22.5474C132.67 21.7931 133.102 21.1202 133.703 20.6217C134.168 20.2216 134.696 19.9025 135.266 19.6777C135.855 19.4574 136.466 19.2992 137.088 19.2057C137.445 19.1438 137.806 19.1013 138.169 19.0782L137.074 30.4346Z" fill="white" />
                        <path opacity="0.15" d="M132.915 25.842C132.706 25.4086 132.547 24.9525 132.443 24.4826C132.42 24.3552 132.396 24.2278 132.377 24.1003C132.306 23.5823 132.333 23.0554 132.457 22.5474C132.626 21.931 132.952 21.3685 133.401 20.9143L132.915 25.842Z" fill="white" />
                        <path opacity="0.15" d="M140.42 32.3981C139.788 32.096 139.169 31.7703 138.579 31.4163L139.759 19.0358H139.939C140.51 19.0641 141.09 19.1254 141.68 19.2104L140.42 32.3981Z" fill="white" />
                        <path opacity="0.15" d="M146.391 34.2767C145.696 34.1645 145.008 34.0164 144.328 33.833L145.631 20.2063C146.315 20.447 146.995 20.7208 147.656 21.0229L146.391 34.2767Z" fill="white" />
                        <path opacity="0.15" d="M148.208 34.4796C147.845 34.4796 147.481 34.423 147.113 34.3758L148.359 21.3486C148.713 21.5232 149.067 21.7073 149.407 21.9008L148.208 34.4796Z" fill="white" />
                        <path opacity="0.3" d="M154.094 25.6862C154.213 25.8278 154.327 25.9694 154.434 26.111L154.538 26.2526L154.646 26.399C154.722 26.5028 154.797 26.6114 154.863 26.7152C155.24 27.2745 155.541 27.8807 155.76 28.5182C156.032 29.2965 156.08 30.1357 155.897 30.9396C155.777 31.4295 155.557 31.8892 155.25 32.2895C155.175 32.3839 155.099 32.4736 155.019 32.5633C154.604 33.0035 154.111 33.3635 153.565 33.6253L153.339 33.7291L154.094 25.6862Z" fill="#E0E0E0" />
                        <path d="M151.2 25.5494C148.368 22.5616 143.176 20.6217 139.339 21.0606C139.179 21.0606 139.013 21.1031 138.867 21.1314C134.983 21.8017 134.213 24.8697 137.144 27.9849C140.075 31.1001 145.603 33.0778 149.487 32.4028C149.646 32.3794 149.804 32.3479 149.959 32.3084C153.4 31.4824 154.014 28.5371 151.2 25.5494Z" fill="#E0E0E0" />
                        <path d="M150.884 25.606C150.795 25.5069 150.7 25.4125 150.606 25.3181C147.863 22.6277 143.11 20.9096 139.556 21.3155H139.457C139.334 21.3155 139.212 21.3486 139.094 21.3722C138.441 21.4715 137.811 21.6874 137.234 22.0094C136.692 22.2909 136.259 22.7445 136.002 23.299C135.746 23.8534 135.681 24.4773 135.818 25.0726C136.032 25.9473 136.455 26.7571 137.05 27.4326C137.173 27.5884 137.31 27.7442 137.456 27.9046C137.602 28.0651 137.73 28.1878 137.876 28.3247C138.188 28.6188 138.516 28.8961 138.858 29.1554C141.368 31.0287 144.381 32.1071 147.509 32.2518C147.91 32.2624 148.31 32.2467 148.708 32.2046C148.892 32.2046 149.076 32.1621 149.251 32.129C149.426 32.096 149.548 32.0724 149.69 32.0394L149.808 32.011C150.618 31.8374 151.357 31.4266 151.932 30.831C152.73 29.9295 152.824 28.6882 152.154 27.3335C151.819 26.6976 151.391 26.1153 150.884 25.606Z" fill="#E0E0E0" />
                        <path opacity="0.13" d="M150.884 25.606C150.795 25.5069 150.7 25.4125 150.606 25.3181C147.863 22.6277 143.11 20.9096 139.556 21.3155H139.457C139.334 21.3155 139.212 21.3486 139.094 21.3722C138.441 21.4715 137.811 21.6874 137.234 22.0094C136.692 22.2909 136.259 22.7445 136.002 23.299C135.746 23.8534 135.681 24.4773 135.818 25.0726C136.032 25.9473 136.455 26.7571 137.05 27.4326C137.173 27.5884 137.31 27.7442 137.456 27.9046C137.602 28.0651 137.73 28.1878 137.876 28.3247C138.188 28.6188 138.516 28.8961 138.858 29.1554C141.368 31.0287 144.381 32.1071 147.509 32.2518C147.91 32.2624 148.31 32.2467 148.708 32.2046C148.892 32.2046 149.076 32.1621 149.251 32.129C149.426 32.096 149.548 32.0724 149.69 32.0394L149.808 32.011C150.618 31.8374 151.357 31.4266 151.932 30.831C152.73 29.9295 152.824 28.6882 152.154 27.3335C151.819 26.6976 151.391 26.1153 150.884 25.606Z" fill="black" />
                        <path d="M150.223 26.2904L150.502 26.5736C151.004 27.0908 151.427 27.6793 151.757 28.32C152.125 28.9959 152.265 29.7719 152.159 30.5337C152.087 30.6452 152.006 30.751 151.918 30.8499C151.343 31.4455 150.603 31.8563 149.794 32.0299L149.676 32.0582C149.534 32.0913 149.388 32.1243 149.237 32.1479C149.086 32.1715 148.878 32.2046 148.694 32.2234C148.296 32.2655 147.895 32.2813 147.495 32.2706C144.367 32.126 141.353 31.0476 138.844 29.1743C138.502 28.915 138.174 28.6377 137.862 28.3436C137.715 28.2067 137.579 28.0651 137.442 27.9235C137.305 27.7819 137.158 27.612 137.036 27.4515C136.44 26.776 136.018 25.9662 135.804 25.0915C135.736 24.7234 135.736 24.3458 135.804 23.9776C136.079 23.5723 136.444 23.2362 136.871 22.9958C137.446 22.6738 138.074 22.458 138.726 22.3586L139.094 22.3067H139.188C142.723 21.8772 147.481 23.6 150.223 26.2904Z" fill="#E0E0E0" />
                        <path d="M155.005 32.568C152.527 34.9563 148.397 34.6495 145.201 33.984C140.401 32.8182 135.676 30.293 133.203 26.3046C132.478 25.1886 132.21 23.8369 132.453 22.5286C132.177 23.8362 132.419 25.1999 133.128 26.3329C135.077 29.6369 138.702 31.9355 142.492 33.3279C146.358 34.6306 151.79 35.6266 155.005 32.568Z" fill="#F5F5F5" />
                        <path d="M138.546 27.0078C139.613 28.0604 140.963 28.3294 142.322 28.3814L141.883 28.5749L141.355 29.9106C141.591 30.0761 141.838 30.2243 142.096 30.3543C142.545 30.5784 143.021 30.7465 143.512 30.8546C144.309 31.0307 145.129 31.0832 145.942 31.0104L146.886 31.9544L148.774 31.6287L149.308 30.293L149.072 30.057L149.02 30.005C149.085 29.9133 149.137 29.8133 149.176 29.7077C149.189 29.6722 149.2 29.6359 149.209 29.5991C149.256 29.4292 149.591 28.6882 149.709 28.3766C149.709 28.3389 149.733 28.3011 149.742 28.2681C149.846 27.8763 149.667 27.4185 149.143 26.904C148.606 26.3901 147.949 26.0199 147.231 25.8278L147.052 25.7806L146.858 25.7382L146.693 25.7051H146.622H146.49C146.13 25.6499 145.768 25.6153 145.404 25.6013H144.932L145.503 25.3417L146.037 24.0106C144.751 23.2179 143.24 22.8696 141.737 23.0194L140.793 22.0754L138.905 22.4342L138.296 23.7133L138.593 24.0059C138.537 24.0779 138.491 24.1573 138.456 24.2419C138.381 24.4543 138.013 25.3275 137.928 25.5635C137.824 25.9836 137.989 26.4603 138.546 27.0078Z" fill="#E0E0E0" />
                        <path opacity="0.1" d="M149.657 28.4191C149.525 28.759 149.228 29.4198 149.185 29.5802C149.176 29.6171 149.165 29.6533 149.152 29.6888C149.112 29.7954 149.06 29.8968 148.996 29.9909L148.321 29.3206C148.602 29.2965 148.872 29.201 149.106 29.0432C149.34 28.8855 149.53 28.6707 149.657 28.4191Z" fill="black" />
                        <path opacity="0.2" d="M141.468 25.1151C141.185 24.8366 141.232 24.5534 142.02 24.3977C142.377 24.3163 142.747 24.3091 143.106 24.3765C143.466 24.4439 143.808 24.5844 144.111 24.7894L145.999 23.9398L145.466 25.2709L144.899 25.5305H144.852C144.602 25.5305 144.356 25.5305 144.125 25.5305H143.847C143.337 25.559 142.825 25.5448 142.317 25.488C142.108 25.4631 141.906 25.3974 141.723 25.2945C141.629 25.2481 141.543 25.1876 141.468 25.1151Z" fill="black" />
                        <path opacity="0.13" d="M138.608 24.0012L138.315 23.7133L138.853 22.3822L139.797 23.3262C139.343 23.431 138.93 23.6655 138.608 24.0012Z" fill="black" />
                        <path opacity="0.1" d="M147.382 30.6045L149.27 30.2741L148.756 31.624L146.868 31.9544L145.924 31.0104C145.109 31.0858 144.287 31.0332 143.488 30.8547C142.719 30.684 141.99 30.3632 141.345 29.9106L141.874 28.5749C142.517 29.0306 143.246 29.3517 144.017 29.5189C144.816 29.6947 145.637 29.7472 146.452 29.6746L147.382 30.6045Z" fill="black" />
                        <path opacity="0.13" d="M145.914 31.0104C145.253 31.1213 144.578 31.1213 143.918 31.0104C143.265 30.9146 142.638 30.6865 142.077 30.3402C142.686 30.5763 143.319 30.7472 143.965 30.8499C144.286 30.9018 144.611 30.9349 144.937 30.9585C145.263 30.9821 145.579 31.0057 145.914 31.0104Z" fill="black" />
                        <path opacity="0.13" d="M137.942 25.5824C138.027 25.3417 138.414 24.4685 138.471 24.2561C138.334 24.6573 138.513 25.134 139.07 25.6721C140.311 26.8898 141.926 27.0598 143.464 27.0598C144.88 27.0598 145.999 26.8804 146.603 27.4704C146.976 27.8386 146.726 28.0887 146.108 28.2067C145.715 28.278 145.312 28.2687 144.924 28.1795C144.535 28.0902 144.169 27.9229 143.847 27.6875L142.299 28.3814C140.958 28.3247 139.608 28.051 138.523 27.0031C137.989 26.4603 137.824 25.9883 137.942 25.5824Z" fill="black" />
                        <path d="M143.582 26.1205L144.111 24.7894L145.985 23.9398L145.452 25.2709L143.582 26.1205Z" fill="#E0E0E0" />
                        <path opacity="0.1" d="M143.582 26.1205L144.111 24.7894L145.985 23.9398L145.452 25.2709L143.582 26.1205Z" fill="black" />
                        <path d="M138.848 22.3822L139.792 23.3074L138.612 24.0059L138.315 23.7133L138.848 22.3822Z" fill="#E0E0E0" />
                        <path opacity="0.2" d="M138.848 22.3822L139.792 23.3074L138.612 24.0059L138.315 23.7133L138.848 22.3822Z" fill="black" />
                        <path d="M149.048 30.0381L148.321 29.3206C148.612 29.2975 148.891 29.1981 149.13 29.0323C149.37 28.8665 149.561 28.6403 149.685 28.3766C149.685 28.3389 149.709 28.3011 149.718 28.2681C149.822 27.8763 149.643 27.4185 149.119 26.904C148.582 26.3901 147.925 26.02 147.207 25.8278L147.028 25.7806L146.834 25.7382L146.669 25.7051H146.598H146.466C146.107 25.6499 145.744 25.6153 145.381 25.6013C145.206 25.6013 145.031 25.6013 144.857 25.6013C144.607 25.6013 144.361 25.6013 144.125 25.6013H143.847C143.337 25.6296 142.825 25.6154 142.317 25.5588C142.11 25.5339 141.909 25.4682 141.727 25.3653C141.64 25.3147 141.559 25.2528 141.487 25.1812C141.204 24.9027 141.251 24.6148 142.039 24.4685C142.395 24.3853 142.765 24.3767 143.125 24.4433C143.485 24.5099 143.827 24.6503 144.13 24.8555L146.018 24.0059C144.732 23.2132 143.221 22.8649 141.718 23.0147L140.774 22.0707L138.886 22.4294L139.83 23.3734C139.113 23.6 138.655 23.9162 138.513 24.3174C138.376 24.7186 138.56 25.1954 139.117 25.7334C140.354 26.9512 141.949 27.1211 143.507 27.1211C144.923 27.1211 146.042 26.9418 146.646 27.5318C147.019 27.9046 146.768 28.1501 146.15 28.2681C145.758 28.3405 145.355 28.3318 144.966 28.2425C144.577 28.1532 144.211 27.9852 143.889 27.7489L141.902 28.6315C142.139 28.8073 142.388 28.9651 142.648 29.1035C143.097 29.3285 143.573 29.4966 144.064 29.6038C144.863 29.7796 145.684 29.8322 146.499 29.7596L147.443 30.7036L149.331 30.3732L149.048 30.0381Z" fill="#E0E0E0" />
                        <path d="M149.048 30.0381L148.321 29.3206C148.612 29.2975 148.891 29.1981 149.13 29.0323C149.37 28.8665 149.561 28.6403 149.685 28.3766C149.685 28.3389 149.709 28.3011 149.718 28.2681C149.822 27.8763 149.643 27.4185 149.119 26.904C148.582 26.3901 147.925 26.02 147.207 25.8278L147.028 25.7806L146.834 25.7382L146.669 25.7051H146.598H146.466C146.107 25.6499 145.744 25.6153 145.381 25.6013C145.206 25.6013 145.031 25.6013 144.857 25.6013C144.607 25.6013 144.361 25.6013 144.125 25.6013H143.847C143.337 25.6296 142.825 25.6154 142.317 25.5588C142.11 25.5339 141.909 25.4682 141.727 25.3653C141.64 25.3147 141.559 25.2528 141.487 25.1812C141.204 24.9027 141.251 24.6148 142.039 24.4685C142.395 24.3853 142.765 24.3767 143.125 24.4433C143.485 24.5099 143.827 24.6503 144.13 24.8555L146.018 24.0059C144.732 23.2132 143.221 22.8649 141.718 23.0147L140.774 22.0707L138.886 22.4294L139.83 23.3734C139.113 23.6 138.655 23.9162 138.513 24.3174C138.376 24.7186 138.56 25.1954 139.117 25.7334C140.354 26.9512 141.949 27.1211 143.507 27.1211C144.923 27.1211 146.042 26.9418 146.646 27.5318C147.019 27.9046 146.768 28.1501 146.15 28.2681C145.758 28.3405 145.355 28.3318 144.966 28.2425C144.577 28.1532 144.211 27.9852 143.889 27.7489L141.902 28.6315C142.139 28.8073 142.388 28.9651 142.648 29.1035C143.097 29.3285 143.573 29.4966 144.064 29.6038C144.863 29.7796 145.684 29.8322 146.499 29.7596L147.443 30.7036L149.331 30.3732L149.048 30.0381Z" fill="#F0F0F0" />
                        <path d="M139.08 25.6768C139.557 26.1167 140.121 26.4512 140.736 26.6586C141.369 26.8668 142.029 26.9813 142.695 26.9984C143.365 27.0409 144.036 26.9984 144.71 26.9984C145.05 26.9912 145.389 27.0101 145.725 27.055C146.054 27.1029 146.361 27.2475 146.608 27.4704C146.355 27.2601 146.044 27.1317 145.716 27.1022C145.382 27.0636 145.046 27.0509 144.71 27.0645C144.04 27.0645 143.365 27.1211 142.686 27.0645C142.012 27.0414 141.346 26.9189 140.708 26.701C140.397 26.5873 140.1 26.4401 139.821 26.2621C139.553 26.094 139.305 25.8978 139.08 25.6768Z" fill="white" />
                        <path d="M147.382 30.6045L146.849 31.9355L145.909 31.0104L146.443 29.6794L147.382 30.6045Z" fill="#E0E0E0" />
                        <path opacity="0.13" d="M147.382 30.6045L146.849 31.9355L145.909 31.0104L146.443 29.6794L147.382 30.6045Z" fill="black" />
                        <path d="M149.284 30.2741L147.448 30.4582L146.443 29.6794L147.382 30.6045L149.284 30.2741Z" fill="#FAFAFA" />
                        <path d="M141.685 22.9487L140.694 22.1651L138.848 22.3822L140.741 22.0235L141.685 22.9487Z" fill="#FAFAFA" />
                        <path opacity="0.15" d="M145.985 23.9398C145.769 23.8016 145.543 23.6785 145.31 23.5717L145.244 24.275L145.985 23.9398Z" fill="white" />
                        <path opacity="0.15" d="M139.122 25.7146C139.641 26.2072 140.271 26.5681 140.958 26.7671L141.355 22.623L140.741 22.0235L139.452 22.269L139.391 22.9156L139.792 23.3074C139.631 23.3538 139.474 23.4106 139.32 23.4773L139.122 25.7146Z" fill="white" />
                        <path opacity="0.15" d="M148.925 26.7058C148.613 26.4379 148.263 26.2169 147.887 26.0497L147.453 30.5903L148.571 30.3968L148.647 29.6416L148.321 29.3206C148.443 29.3149 148.563 29.2959 148.68 29.264L148.925 26.7058Z" fill="white" />
                        <path opacity="0.15" d="M147.009 25.7523L146.816 25.7098L146.65 25.6768H146.58H146.447C146.088 25.6216 145.725 25.587 145.362 25.573H145.121L144.98 27.0739C145.683 27.0739 146.24 27.1542 146.608 27.5129C146.976 27.8716 146.731 28.1312 146.112 28.2492C145.7 28.324 145.277 28.3096 144.871 28.2067L144.729 29.6841C145.298 29.7535 145.872 29.7646 146.443 29.7171L146.797 30.0664L147.198 25.842L147.028 25.7948L147.009 25.7523Z" fill="white" />
                        <path d="M168.414 99.4551L169.387 100.173L167.914 102.155C167.739 102.394 167.531 102.607 167.296 102.787C167.215 102.854 167.13 102.916 167.041 102.972C166.586 103.253 166.084 103.45 165.559 103.552C165.362 103.593 165.164 103.623 164.964 103.642C164.748 103.667 164.53 103.679 164.313 103.68C164.02 103.683 163.726 103.67 163.435 103.642C163.118 103.613 162.798 103.571 162.467 103.51C162.222 103.462 161.995 103.41 161.726 103.344C158.059 102.424 154.084 99.6959 151.696 96.4674C151.532 96.2503 151.375 96.0284 151.224 95.8019C151.012 95.4809 150.818 95.1599 150.644 94.8295C150.469 94.4991 150.37 94.3009 150.252 94.0413C150.134 93.7817 150.068 93.5693 149.992 93.3805C149.917 93.1917 149.851 92.951 149.794 92.7433C149.641 92.1792 149.572 91.5954 149.591 91.0111C149.591 90.8931 149.591 90.7798 149.619 90.6712C149.672 90.2121 149.815 89.7678 150.039 89.3638C150.107 89.2422 150.182 89.1254 150.266 89.0145L151.654 87.1265L152.55 87.792L152.942 87.7259C153.498 87.6665 154.058 87.6665 154.613 87.7259C154.971 87.7561 155.327 87.8065 155.68 87.877C156.159 87.97 156.631 88.0914 157.096 88.2404C160.509 89.3975 163.537 91.4732 165.847 94.2395C166.174 94.6357 166.482 95.0484 166.767 95.4762C167.003 95.8255 167.215 96.1795 167.409 96.5382C167.691 97.0495 167.926 97.585 168.112 98.1383C168.256 98.5661 168.357 99.0073 168.414 99.4551Z" fill="#E0E0E0" />
                        <path opacity="0.15" d="M153.277 98.3082L156.256 94.2773L157.615 95.5234L154.632 99.559C154.16 99.1578 153.707 98.7424 153.277 98.3082Z" fill="white" />
                        <path opacity="0.05" d="M167.914 102.155C167.739 102.394 167.531 102.607 167.296 102.787C167.215 102.854 167.13 102.916 167.041 102.972C166.586 103.253 166.084 103.45 165.559 103.552L165.346 103.59L168.41 99.4457L169.382 100.163L167.914 102.155Z" fill="black" />
                        <path opacity="0.15" d="M164.369 96.6609L165.261 97.0432L160.782 103.075C160.485 102.976 160.183 102.863 159.881 102.745L164.369 96.6609Z" fill="white" />
                        <path opacity="0.15" d="M157.648 101.659L161.518 96.4249L162.934 97.6285L159.328 102.5C158.754 102.248 158.193 101.968 157.648 101.659Z" fill="white" />
                        <path opacity="0.15" d="M151.658 87.1359L152.55 87.792L152.942 87.7259C153.498 87.6664 154.058 87.6664 154.613 87.7259C154.971 87.7561 155.327 87.8065 155.68 87.877C156.159 87.97 156.631 88.0914 157.096 88.2404C157.539 88.3773 157.988 88.5472 158.436 88.736L152.22 97.1376C152.041 96.9252 151.866 96.7034 151.706 96.4815C151.545 96.2597 151.385 96.0426 151.234 95.816C151.021 95.495 150.828 95.1741 150.653 94.8437C150.478 94.5133 150.379 94.315 150.261 94.0554C150.143 93.7958 150.077 93.5834 150.002 93.3946C149.926 93.2058 149.86 92.9651 149.803 92.7574C149.65 92.1933 149.582 91.6095 149.6 91.0252C149.6 90.9072 149.6 90.7939 149.629 90.6854C149.682 90.2262 149.824 89.7819 150.049 89.3779C150.116 89.2563 150.192 89.1396 150.275 89.0286L151.658 87.1359Z" fill="white" />
                        <path opacity="0.15" d="M149.596 91.0111C149.596 90.8931 149.596 90.7798 149.624 90.6712C149.677 90.2121 149.82 89.7678 150.044 89.3638C150.111 89.2422 150.187 89.1255 150.271 89.0145L151.658 87.1265L152.55 87.792L152.768 88.1224L149.681 92.2996C149.6 91.8751 149.572 91.4424 149.596 91.0111Z" fill="white" />
                        <path d="M168.976 94.4C168.782 94.0413 168.57 93.6873 168.334 93.338C168.049 92.9103 167.742 92.4975 167.414 92.1014C165.102 89.3365 162.075 87.2611 158.663 86.1022C158.198 85.9547 157.725 85.835 157.247 85.7435C156.894 85.6738 156.538 85.6218 156.18 85.5878C155.625 85.526 155.064 85.526 154.509 85.5878C153.974 85.6465 153.452 85.7949 152.966 86.0267C152.341 86.3173 151.823 86.7959 151.484 87.3955C151.261 87.8001 151.118 88.2441 151.064 88.703C151.064 88.81 151.054 88.9232 151.035 89.0428C151.012 89.6273 151.08 90.2118 151.238 90.7751C151.29 90.9827 151.356 91.1951 151.432 91.4123C151.507 91.6294 151.597 91.8465 151.696 92.0731C151.795 92.2996 151.942 92.597 152.083 92.8613C152.225 93.1256 152.456 93.5127 152.668 93.8336C152.815 94.0602 152.97 94.282 153.14 94.4991C155.529 97.7323 159.503 100.456 163.17 101.381C163.421 101.442 163.671 101.494 163.916 101.541C164.242 101.603 164.563 101.645 164.884 101.678C165.205 101.711 165.483 101.716 165.762 101.711C165.978 101.711 166.194 101.698 166.408 101.674C166.61 101.654 166.81 101.624 167.008 101.584C167.537 101.485 168.045 101.29 168.504 101.008C168.594 100.951 168.679 100.89 168.764 100.824C169.117 100.554 169.408 100.211 169.618 99.8186C169.952 99.1732 170.099 98.4474 170.043 97.7229C170.006 97.1311 169.89 96.547 169.698 95.9859C169.503 95.4377 169.261 94.9072 168.976 94.4Z" fill="#F0F0F0" />
                        <path opacity="0.15" d="M153.655 95.1788C153.476 94.9617 153.301 94.7446 153.136 94.518C152.97 94.2914 152.819 94.079 152.664 93.8525C152.451 93.5315 152.258 93.2106 152.078 92.8802C151.899 92.5498 151.805 92.3515 151.691 92.0919C151.578 91.8323 151.503 91.6483 151.427 91.4311C151.352 91.214 151.285 91.0016 151.234 90.7939C151.076 90.2307 151.007 89.6462 151.031 89.0617C151.031 88.9453 151.04 88.832 151.059 88.7219C151.113 88.263 151.256 87.819 151.479 87.4144C151.818 86.8148 152.336 86.3362 152.961 86.0456C153.448 85.8145 153.969 85.6661 154.504 85.6067C155.06 85.5449 155.62 85.5449 156.175 85.6067C156.487 85.635 156.803 85.6822 157.119 85.7388L153.655 95.1788Z" fill="white" />
                        <path opacity="0.15" d="M151.134 90.3266C151.056 89.9099 151.024 89.4855 151.04 89.0617C151.04 88.9453 151.049 88.832 151.068 88.7219C151.123 88.263 151.265 87.819 151.488 87.4144C151.764 86.9244 152.163 86.5145 152.645 86.225L151.134 90.3266Z" fill="white" />
                        <path opacity="0.15" d="M156.072 97.6096C155.6 97.2084 155.128 96.793 154.717 96.3588L158.493 86.0551L158.644 86.1023C159.116 86.2533 159.588 86.4374 160.088 86.6403L156.072 97.6096Z" fill="white" />
                        <path opacity="0.15" d="M160.744 100.536C160.177 100.284 159.622 100.004 159.083 99.6959L163.255 88.3679C163.784 88.7266 164.303 89.1136 164.799 89.5243L160.744 100.536Z" fill="white" />
                        <path opacity="0.15" d="M162.231 101.126C161.929 101.027 161.622 100.914 161.32 100.791L165.304 89.9349C165.568 90.1662 165.828 90.4069 166.078 90.6476L162.231 101.126Z" fill="white" />
                        <path opacity="0.3" d="M169.245 94.9475C169.316 95.0938 169.382 95.2402 169.439 95.3865C169.439 95.4337 169.481 95.4809 169.5 95.5281L169.557 95.6791C169.599 95.7877 169.637 95.8962 169.675 96.0001C169.866 96.5611 169.982 97.1453 170.019 97.7371C170.075 98.4615 169.928 99.1873 169.594 99.8327C169.385 100.225 169.093 100.568 168.74 100.838C168.655 100.904 168.57 100.966 168.48 101.022C168.027 101.303 167.527 101.5 167.003 101.603L166.786 101.641L169.245 94.9475Z" fill="#E0E0E0" />
                        <path d="M166.805 94.1829C165.077 91.0016 161.075 88.179 157.69 87.6882L157.266 87.6362C153.796 87.3389 152.451 89.7838 154.254 93.102C156.057 96.4202 160.329 99.3466 163.798 99.6439C163.94 99.6439 164.077 99.6439 164.209 99.6439C167.366 99.743 168.537 97.3642 166.805 94.1829Z" fill="#E0E0E0" />
                        <path d="M166.526 94.1593C166.474 94.0555 166.413 93.9516 166.352 93.8525C164.615 90.9402 160.943 88.4056 157.818 87.9525H157.733C157.624 87.9525 157.516 87.9242 157.407 87.9147C156.828 87.8542 156.243 87.8973 155.68 88.0422C155.156 88.1627 154.687 88.453 154.345 88.8679C154.004 89.2828 153.809 89.799 153.792 90.3361C153.771 91.136 153.948 91.9286 154.306 92.6442C154.382 92.8046 154.462 92.9698 154.552 93.1162C154.641 93.2625 154.722 93.4182 154.816 93.5882C155.014 93.9154 155.232 94.2306 155.467 94.5322C157.187 96.6968 159.517 98.2951 162.156 99.12C162.495 99.2169 162.84 99.2926 163.189 99.3466C163.35 99.3749 163.51 99.3938 163.661 99.4079C163.812 99.4221 163.93 99.4079 164.058 99.4079H164.162C164.891 99.4418 165.614 99.2578 166.238 98.8793C167.121 98.2893 167.484 97.2509 167.215 95.9435C167.076 95.3177 166.844 94.7164 166.526 94.1593Z" fill="#E0E0E0" />
                        <path opacity="0.13" d="M166.526 94.1593C166.474 94.0555 166.413 93.9516 166.352 93.8525C164.615 90.9402 160.943 88.4056 157.818 87.9525H157.733C157.624 87.9525 157.516 87.9242 157.407 87.9147C156.828 87.8542 156.243 87.8973 155.68 88.0422C155.156 88.1627 154.687 88.453 154.345 88.8679C154.004 89.2828 153.809 89.799 153.792 90.3361C153.771 91.136 153.948 91.9286 154.306 92.6442C154.382 92.8046 154.462 92.9698 154.552 93.1162C154.641 93.2625 154.722 93.4182 154.816 93.5882C155.014 93.9154 155.232 94.2306 155.467 94.5322C157.187 96.6968 159.517 98.2951 162.156 99.12C162.495 99.2169 162.84 99.2926 163.189 99.3466C163.35 99.3749 163.51 99.3938 163.661 99.4079C163.812 99.4221 163.93 99.4079 164.058 99.4079H164.162C164.891 99.4418 165.614 99.2578 166.238 98.8793C167.121 98.2893 167.484 97.2509 167.215 95.9435C167.076 95.3177 166.844 94.7164 166.526 94.1593Z" fill="black" />
                        <path d="M165.809 94.5935C165.87 94.6927 165.927 94.7965 165.984 94.9003C166.294 95.4561 166.523 96.0538 166.663 96.6751C166.824 97.334 166.769 98.0271 166.507 98.6527C166.421 98.7328 166.328 98.8054 166.229 98.8699C165.605 99.2484 164.882 99.4324 164.152 99.3985H164.048C163.921 99.3985 163.784 99.3985 163.652 99.3985C163.52 99.3985 163.331 99.3655 163.18 99.3371C162.831 99.2832 162.486 99.2075 162.146 99.1106C159.508 98.2857 157.178 96.6874 155.458 94.5227C155.222 94.2212 155.005 93.906 154.807 93.5787C154.712 93.4277 154.627 93.2767 154.542 93.1067C154.457 92.9368 154.372 92.7763 154.297 92.6347C153.939 91.9192 153.762 91.1266 153.782 90.3267C153.803 90.0008 153.882 89.6813 154.014 89.3827C154.34 89.0979 154.728 88.8927 155.146 88.7832C155.71 88.6382 156.295 88.595 156.874 88.6558L157.2 88.6935H157.285C160.4 89.1467 164.072 91.6813 165.809 94.5935Z" fill="#E0E0E0" />
                        <path d="M168.504 101.008C165.856 102.49 162.401 101.301 159.824 100.012C155.987 97.94 152.522 94.7257 151.304 90.7656C150.938 89.6572 151.013 88.4505 151.512 87.3955C150.978 88.4465 150.877 89.6644 151.229 90.7892C152.144 94.0649 154.731 96.8214 157.653 98.8604C160.64 100.848 165.054 102.896 168.504 101.008Z" fill="#F5F5F5" />
                        <path d="M155.68 92.5875C156.349 93.6089 157.387 94.3313 158.578 94.603L158.162 94.6691L157.407 95.6886C157.573 95.8825 157.751 96.0654 157.941 96.2361C158.272 96.5248 158.637 96.7736 159.026 96.9771C159.666 97.3072 160.355 97.535 161.065 97.6521L161.66 98.648L163.359 98.7943L164.114 97.7748L163.963 97.5199L163.93 97.4633C164.007 97.4002 164.075 97.3272 164.133 97.2462L164.185 97.1612C164.261 97.0243 164.714 96.4674 164.884 96.2172C164.903 96.19 164.92 96.1616 164.936 96.1322C165.115 95.8254 165.068 95.3912 164.733 94.8342C164.392 94.274 163.914 93.8095 163.345 93.4843L163.199 93.4041L163.048 93.3238L162.911 93.2578H162.854C162.82 93.2381 162.783 93.2223 162.746 93.2106C162.45 93.0816 162.148 92.9697 161.839 92.8754C161.707 92.833 161.57 92.7952 161.433 92.7622L161.981 92.6678L162.731 91.6482C161.811 90.6856 160.601 90.0507 159.286 89.8405L158.686 88.8398L156.992 88.7218L156.237 89.7366L156.426 90.0529C156.361 90.1009 156.304 90.1582 156.256 90.2228C156.147 90.388 155.633 91.0488 155.51 91.2329C155.288 91.568 155.326 91.9928 155.68 92.5875Z" fill="#E0E0E0" />
                        <path opacity="0.1" d="M164.851 96.288C164.657 96.5523 164.256 97.0479 164.185 97.1754L164.133 97.2603C164.073 97.3408 164.005 97.4151 163.93 97.4822L163.506 96.76C163.751 96.8021 164.004 96.7809 164.239 96.6983C164.475 96.6157 164.685 96.4745 164.851 96.288Z" fill="black" />
                        <path opacity="0.2" d="M158.592 91.6247C158.413 91.3273 158.521 91.0913 159.224 91.1527C159.548 91.1629 159.865 91.2394 160.157 91.3774C160.45 91.5155 160.711 91.7121 160.924 91.9551L162.713 91.653L161.962 92.6678L161.419 92.7622H161.377L160.754 92.6017L160.518 92.5451C160.075 92.4537 159.641 92.3258 159.22 92.1627C159.045 92.0945 158.885 91.9936 158.748 91.8654C158.684 91.7938 158.631 91.7126 158.592 91.6247Z" fill="black" />
                        <path opacity="0.13" d="M156.411 90.034L156.223 89.7225L156.978 88.7077L157.572 89.7036C157.161 89.6969 156.757 89.8119 156.411 90.034Z" fill="black" />
                        <path opacity="0.1" d="M162.415 97.6379L164.114 97.7843L163.359 98.8038L161.66 98.6575L161.07 97.6568C160.358 97.5365 159.669 97.3073 159.026 96.9771C158.636 96.7747 158.271 96.5259 157.941 96.2361C157.75 96.0666 157.572 95.8836 157.407 95.6886L158.162 94.6691C158.324 94.8663 158.503 95.0495 158.696 95.2166C159.025 95.5068 159.388 95.7556 159.777 95.9576C160.419 96.2885 161.108 96.5178 161.82 96.6373L162.415 97.6379Z" fill="black" />
                        <path opacity="0.13" d="M161.07 97.6568C160.48 97.6068 159.903 97.4601 159.361 97.2226C158.829 96.9908 158.347 96.6555 157.945 96.2361C158.41 96.5714 158.908 96.8577 159.432 97.0904C159.696 97.2084 159.966 97.3075 160.239 97.4019C160.513 97.4963 160.787 97.5766 161.07 97.6568Z" fill="black" />
                        <path opacity="0.13" d="M155.486 91.2329C155.609 91.0488 156.123 90.388 156.232 90.2228C156.029 90.5343 156.076 90.978 156.426 91.5727C157.214 92.8896 158.55 93.3994 159.866 93.7439C161.089 94.0602 162.071 94.1593 162.448 94.7965C162.689 95.1977 162.42 95.3534 161.868 95.3157C161.517 95.2898 161.174 95.1927 160.862 95.0304C160.55 94.8681 160.274 94.644 160.05 94.3717L158.573 94.6124C157.44 94.2631 156.355 93.7298 155.68 92.597C155.326 91.9928 155.288 91.568 155.486 91.2329Z" fill="black" />
                        <path d="M160.178 92.9604L160.928 91.9409L162.717 91.6341L161.967 92.6536L160.178 92.9604Z" fill="#E0E0E0" />
                        <path opacity="0.1" d="M160.178 92.9604L160.928 91.9409L162.717 91.6341L161.967 92.6536L160.178 92.9604Z" fill="black" />
                        <path d="M156.978 88.7077L157.572 89.7036L156.411 90.034L156.223 89.7225L156.978 88.7077Z" fill="#E0E0E0" />
                        <path opacity="0.2" d="M156.978 88.7077L157.572 89.7036L156.411 90.034L156.223 89.7225L156.978 88.7077Z" fill="black" />
                        <path d="M163.963 97.5294L163.491 96.7553C163.747 96.7998 164.009 96.7752 164.251 96.6839C164.494 96.5925 164.707 96.4379 164.87 96.2361C164.889 96.2089 164.906 96.1805 164.922 96.1511C165.101 95.8443 165.054 95.4101 164.719 94.8531C164.378 94.2929 163.9 93.8284 163.331 93.5032L163.185 93.423L163.034 93.3427L162.897 93.2767H162.84C162.806 93.257 162.769 93.2412 162.731 93.2295C162.436 93.1005 162.133 92.9886 161.825 92.8943L161.382 92.7669L160.758 92.6064L160.522 92.5498C160.08 92.4591 159.646 92.3312 159.224 92.1675C159.05 92.0992 158.89 91.9983 158.752 91.8701C158.69 91.8073 158.634 91.7376 158.587 91.6624C158.408 91.3603 158.516 91.1243 159.22 91.1904C159.543 91.2007 159.86 91.2772 160.153 91.4152C160.445 91.5532 160.706 91.7498 160.919 91.9928L162.708 91.686C161.788 90.7233 160.577 90.0884 159.262 89.8783L158.663 88.8776L156.968 88.7596L157.563 89.7555C156.902 89.7933 156.44 89.9585 156.232 90.2606C156.029 90.5721 156.076 91.0205 156.43 91.6105C157.209 92.9274 158.55 93.4371 159.866 93.7817C161.089 94.0979 162.071 94.1971 162.448 94.839C162.684 95.2355 162.42 95.3912 161.863 95.3535C161.513 95.3264 161.172 95.2288 160.86 95.0666C160.549 94.9044 160.273 94.6808 160.05 94.4095L158.162 94.7163C158.326 94.9124 158.504 95.0955 158.696 95.2638C159.025 95.555 159.388 95.8054 159.777 96.0095C160.42 96.3369 161.109 96.5645 161.82 96.6845L162.415 97.6851L164.114 97.8315L163.963 97.5294Z" fill="#E0E0E0" />
                        <path d="M163.963 97.5294L163.491 96.7553C163.747 96.7998 164.009 96.7752 164.251 96.6839C164.494 96.5925 164.707 96.4379 164.87 96.2361C164.889 96.2089 164.906 96.1805 164.922 96.1511C165.101 95.8443 165.054 95.4101 164.719 94.8531C164.378 94.2929 163.9 93.8284 163.331 93.5032L163.185 93.423L163.034 93.3427L162.897 93.2767H162.84C162.806 93.257 162.769 93.2412 162.731 93.2295C162.436 93.1005 162.133 92.9886 161.825 92.8943L161.382 92.7669L160.758 92.6064L160.522 92.5498C160.08 92.4591 159.646 92.3312 159.224 92.1675C159.05 92.0992 158.89 91.9983 158.752 91.8701C158.69 91.8073 158.634 91.7376 158.587 91.6624C158.408 91.3603 158.516 91.1243 159.22 91.1904C159.543 91.2007 159.86 91.2772 160.153 91.4152C160.445 91.5532 160.706 91.7498 160.919 91.9928L162.708 91.686C161.788 90.7233 160.577 90.0884 159.262 89.8783L158.663 88.8776L156.968 88.7596L157.563 89.7555C156.902 89.7933 156.44 89.9585 156.232 90.2606C156.029 90.5721 156.076 91.0205 156.43 91.6105C157.209 92.9274 158.55 93.4371 159.866 93.7817C161.089 94.0979 162.071 94.1971 162.448 94.839C162.684 95.2355 162.42 95.3912 161.863 95.3535C161.513 95.3264 161.172 95.2288 160.86 95.0666C160.549 94.9044 160.273 94.6808 160.05 94.4095L158.162 94.7163C158.326 94.9124 158.504 95.0955 158.696 95.2638C159.025 95.555 159.388 95.8054 159.777 96.0095C160.42 96.3369 161.109 96.5645 161.82 96.6845L162.415 97.6851L164.114 97.8315L163.963 97.5294Z" fill="#F0F0F0" />
                        <path d="M156.435 91.568C156.743 92.0522 157.15 92.4657 157.629 92.7811C158.123 93.0985 158.661 93.3433 159.224 93.5079C159.786 93.6967 160.367 93.8195 160.943 93.9799C161.234 94.0492 161.52 94.1423 161.797 94.2584C162.069 94.368 162.299 94.5615 162.453 94.8107C162.287 94.5724 162.051 94.3912 161.778 94.2915C161.501 94.1873 161.217 94.1022 160.928 94.0366C160.353 93.9044 159.767 93.7817 159.196 93.5976C158.627 93.4274 158.086 93.1729 157.591 92.8424C157.351 92.6764 157.13 92.4849 156.931 92.2713C156.74 92.0561 156.573 91.8202 156.435 91.568Z" fill="white" />
                        <path d="M162.415 97.6379L161.66 98.6575L161.07 97.6568L161.82 96.6373L162.415 97.6379Z" fill="#E0E0E0" />
                        <path opacity="0.13" d="M162.415 97.6379L161.66 98.6575L161.07 97.6568L161.82 96.6373L162.415 97.6379Z" fill="black" />
                        <path d="M164.114 97.7843L162.505 97.5294L161.82 96.6373L162.415 97.6379L164.114 97.7843Z" fill="#FAFAFA" />
                        <path d="M159.272 89.8263L158.601 88.9342L156.978 88.7077L158.672 88.8257L159.272 89.8263Z" fill="#FAFAFA" />
                        <path opacity="0.15" d="M162.717 91.6341C162.569 91.4676 162.412 91.31 162.245 91.1621L162.028 91.7474L162.717 91.6341Z" fill="white" />
                        <path opacity="0.15" d="M156.463 91.6105C156.794 92.1497 157.25 92.6001 157.794 92.9227L159.064 89.4771L158.672 88.8257L157.516 88.7455L157.318 89.2788L157.572 89.7036C157.43 89.7113 157.288 89.7271 157.148 89.7508L156.463 91.6105Z" fill="white" />
                        <path opacity="0.15" d="M164.61 94.6549C164.4 94.3593 164.151 94.0941 163.869 93.8667L162.477 97.6426L163.477 97.7323L163.708 97.0999L163.506 96.7553C163.614 96.7664 163.723 96.7664 163.831 96.7553L164.61 94.6549Z" fill="white" />
                        <path opacity="0.15" d="M163.185 93.4135L163.034 93.3333L162.897 93.2672H162.84C162.806 93.2476 162.769 93.2317 162.731 93.22C162.436 93.091 162.133 92.9791 161.825 92.8849L161.622 92.8282L161.15 94.0791C161.66 94.1486 162.122 94.4145 162.439 94.8201C162.675 95.2166 162.41 95.3723 161.854 95.3346C161.485 95.3069 161.128 95.1989 160.806 95.0183L160.334 96.2502C160.803 96.4362 161.29 96.5738 161.787 96.6609L162.014 97.0385L163.303 93.5221L163.166 93.4466L163.185 93.4135Z" fill="white" />
                        <path d="M218.328 44.3208L219.272 45.0099L217.856 46.9262C217.685 47.1556 217.486 47.3619 217.262 47.5398C217.181 47.6012 217.096 47.6578 217.011 47.7145C216.577 47.9838 216.097 48.1725 215.595 48.2714C215.404 48.3142 215.21 48.3442 215.015 48.3611C214.817 48.3847 214.609 48.3942 214.392 48.3989C214.175 48.4036 213.84 48.3989 213.542 48.3658C213.245 48.3328 212.924 48.2903 212.598 48.2337C212.362 48.1912 212.126 48.1393 211.881 48.0779C208.346 47.1858 204.508 44.5568 202.205 41.4369C202.044 41.2245 201.893 41.0121 201.756 40.795C201.549 40.4882 201.36 40.1719 201.19 39.851C201.02 39.53 200.926 39.3365 200.817 39.0863C200.709 38.8362 200.638 38.6568 200.562 38.4491C200.487 38.2414 200.425 38.0338 200.373 37.8355C200.226 37.2898 200.16 36.725 200.18 36.1599C200.18 36.0529 200.189 35.9444 200.208 35.8342C200.258 35.3918 200.394 34.9635 200.609 34.574C200.676 34.456 200.751 34.3427 200.827 34.2342L202.167 32.4264L203.026 33.0542L203.408 32.9928C203.943 32.9355 204.483 32.9355 205.018 32.9928C205.365 33.0237 205.71 33.0725 206.052 33.1391C206.509 33.2306 206.961 33.3473 207.406 33.4884C210.695 34.6053 213.613 36.6057 215.841 39.2704C216.162 39.6622 216.459 40.0634 216.728 40.4646C216.997 40.8658 217.162 41.149 217.351 41.4935C217.622 41.9865 217.848 42.5031 218.026 43.037C218.169 43.4539 218.27 43.8839 218.328 44.3208Z" fill="#E0E0E0" />
                        <path opacity="0.15" d="M203.72 43.2116L206.594 39.327L207.911 40.5259L205.027 44.4199C204.574 44.0376 204.135 43.6317 203.72 43.2116Z" fill="white" />
                        <path opacity="0.05" d="M217.847 46.9262C217.676 47.1556 217.476 47.3619 217.252 47.5398C217.172 47.6012 217.087 47.6578 217.002 47.7145C216.567 47.9838 216.088 48.1725 215.586 48.2714L215.374 48.3092L218.338 44.3114L219.282 45.0005L217.847 46.9262Z" fill="black" />
                        <path opacity="0.15" d="M214.425 41.6257L215.284 41.9938L210.984 47.8136C210.696 47.7192 210.403 47.6106 210.116 47.4926L214.425 41.6257Z" fill="white" />
                        <path opacity="0.15" d="M207.935 46.4495L211.673 41.3991L213.018 42.5555L209.535 47.2755C208.997 47.0206 208.464 46.728 207.935 46.4495Z" fill="white" />
                        <path opacity="0.15" d="M202.158 32.4358L203.017 33.0636L203.399 33.0022C203.934 32.9449 204.473 32.9449 205.008 33.0022C205.355 33.0331 205.7 33.082 206.042 33.1486C206.5 33.2401 206.952 33.3567 207.397 33.4978C207.826 33.63 208.261 33.7952 208.69 33.9698L202.691 42.0741C202.516 41.8617 202.351 41.654 202.195 41.4369C202.04 41.2198 201.884 41.0121 201.747 40.795C201.539 40.4882 201.35 40.1719 201.181 39.851C201.011 39.53 200.916 39.3365 200.808 39.0863C200.699 38.8362 200.628 38.6568 200.553 38.4491C200.477 38.2414 200.416 38.0338 200.364 37.8355C200.216 37.2898 200.151 36.725 200.17 36.1599C200.17 36.0529 200.18 35.9444 200.199 35.8342C200.248 35.3918 200.385 34.9636 200.6 34.574C200.666 34.456 200.742 34.3427 200.817 34.2342L202.158 32.4358Z" fill="white" />
                        <path opacity="0.15" d="M200.17 36.1694C200.17 36.0624 200.18 35.9538 200.199 35.8437C200.248 35.4013 200.385 34.973 200.6 34.5834C200.666 34.4654 200.742 34.3522 200.817 34.2436L202.158 32.4358L203.017 33.0636L203.229 33.3798L200.246 37.4107C200.174 37.0012 200.148 36.5847 200.17 36.1694Z" fill="white" />
                        <path d="M218.866 39.445C218.682 39.1005 218.475 38.7559 218.248 38.4161C218.022 38.0762 217.677 37.6137 217.356 37.2219C215.126 34.554 212.205 32.5519 208.912 31.4352C208.467 31.2929 208.015 31.1763 207.557 31.0859C207.215 31.0193 206.87 30.9705 206.524 30.9396C205.989 30.8825 205.449 30.8825 204.914 30.9396C204.399 30.9985 203.896 31.142 203.427 31.3644C202.829 31.647 202.334 32.109 202.011 32.686C201.798 33.0768 201.66 33.5043 201.605 33.9462C201.605 34.0548 201.605 34.1634 201.605 34.2766C201.583 34.8402 201.649 35.4039 201.799 35.9475C201.851 36.1505 201.917 36.3534 201.988 36.5611C202.058 36.7688 202.148 36.9812 202.243 37.1983C202.337 37.4154 202.479 37.7034 202.615 37.963C202.752 38.2226 202.974 38.5907 203.177 38.907C203.319 39.1241 203.47 39.3365 203.626 39.5489C205.934 42.6641 209.762 45.2978 213.306 46.1852C213.547 46.2466 213.778 46.2985 214.024 46.3457C214.338 46.4023 214.653 46.4448 214.968 46.4731C215.25 46.5002 215.534 46.5112 215.817 46.5062C216.034 46.5062 216.242 46.5062 216.44 46.4731C216.639 46.4401 216.832 46.4212 217.016 46.3834C217.519 46.2854 217.999 46.095 218.432 45.8218C218.517 45.7651 218.602 45.7085 218.682 45.6471C219.011 45.3841 219.283 45.0564 219.48 44.6842C219.8 44.0614 219.942 43.3624 219.891 42.6641C219.855 42.093 219.742 41.5294 219.556 40.9885C219.371 40.4551 219.14 39.9386 218.866 39.445Z" fill="#F0F0F0" />
                        <path opacity="0.15" d="M204.083 40.1955C203.913 39.9878 203.744 39.7754 203.583 39.5583C203.423 39.3412 203.276 39.1335 203.135 38.9164C202.932 38.6049 202.743 38.2934 202.573 37.9724C202.403 37.6514 202.309 37.4579 202.2 37.2078C202.092 36.9576 202.021 36.7782 201.945 36.5706C201.87 36.3629 201.808 36.1599 201.756 35.957C201.606 35.4133 201.541 34.8497 201.563 34.2861C201.563 34.1728 201.563 34.0642 201.563 33.9557C201.618 33.5138 201.755 33.0862 201.969 32.6954C202.292 32.1184 202.787 31.6565 203.385 31.3738C203.854 31.1515 204.356 31.0079 204.872 30.949C205.407 30.8919 205.946 30.8919 206.481 30.949C206.788 30.9756 207.094 31.0181 207.397 31.0765L204.083 40.1955Z" fill="white" />
                        <path opacity="0.15" d="M201.653 35.5086C201.575 35.1075 201.545 34.6988 201.563 34.2908C201.563 34.1775 201.563 34.069 201.563 33.9604C201.618 33.5185 201.755 33.0909 201.969 32.7002C202.235 32.2264 202.621 31.8309 203.087 31.5532L201.653 35.5086Z" fill="white" />
                        <path opacity="0.15" d="M206.42 42.5366C205.948 42.1543 205.523 41.7484 205.112 41.333L208.766 31.388L208.912 31.4352C209.384 31.5815 209.856 31.7562 210.328 31.9544L206.42 42.5366Z" fill="white" />
                        <path opacity="0.15" d="M210.927 45.3781C210.38 45.1368 209.845 44.8658 209.327 44.5662L213.344 33.6253C213.858 33.9698 214.354 34.3427 214.836 34.7345L210.927 45.3781Z" fill="white" />
                        <path opacity="0.15" d="M212.362 45.9303C212.07 45.8359 211.777 45.7274 211.484 45.6141L215.331 35.1498C215.586 35.3717 215.831 35.6218 216.072 35.8342L212.362 45.9303Z" fill="white" />
                        <path opacity="0.3" d="M219.131 39.969C219.202 40.1153 219.263 40.2522 219.32 40.3938L219.376 40.5306L219.433 40.677L219.546 40.9885C219.733 41.5294 219.845 42.093 219.881 42.6641C219.933 43.3624 219.791 44.0614 219.471 44.6842C219.266 45.0625 218.985 45.3937 218.645 45.6566C218.564 45.7179 218.479 45.7746 218.394 45.8312C217.961 46.1044 217.481 46.2949 216.978 46.3929L216.771 46.4306L219.131 39.969Z" fill="#E0E0E0" />
                        <path d="M216.775 39.2326C215.109 36.1646 211.248 33.4365 207.982 32.9645C207.845 32.9645 207.704 32.9267 207.567 32.9173C204.22 32.6294 202.922 34.9894 204.664 38.1895C206.406 41.3897 210.512 44.217 213.873 44.5049H214.274C217.318 44.5993 218.446 42.3006 216.775 39.2326Z" fill="#E0E0E0" />
                        <path d="M216.506 39.209C216.453 39.1115 216.396 39.0124 216.336 38.9117C214.666 36.0797 211.144 33.6583 208.105 33.2194H208.02L207.708 33.1816C207.15 33.1241 206.586 33.1657 206.042 33.3043C205.533 33.4187 205.077 33.6985 204.744 34.0998C204.411 34.5012 204.22 35.0015 204.201 35.5227C204.18 36.2979 204.35 37.0663 204.697 37.76C204.768 37.911 204.843 38.0715 204.933 38.232C205.023 38.3925 205.098 38.5246 205.188 38.6662C205.379 38.9772 205.589 39.2767 205.816 39.563C207.476 41.65 209.723 43.1921 212.268 43.9904C212.596 44.0843 212.931 44.1568 213.268 44.2075C213.424 44.2358 213.58 44.25 213.74 44.2642C213.901 44.2783 214 44.2642 214.123 44.2878H214.227C214.93 44.3199 215.626 44.1425 216.228 43.778C217.082 43.2069 217.432 42.2015 217.172 40.946C217.037 40.3373 216.813 39.7519 216.506 39.209Z" fill="#E0E0E0" />
                        <path opacity="0.13" d="M216.506 39.209C216.453 39.1115 216.396 39.0124 216.336 38.9117C214.666 36.0797 211.144 33.6583 208.105 33.2194H208.02L207.708 33.1816C207.15 33.1241 206.586 33.1657 206.042 33.3043C205.533 33.4187 205.077 33.6985 204.744 34.0998C204.411 34.5012 204.22 35.0015 204.201 35.5227C204.18 36.2979 204.35 37.0663 204.697 37.76C204.768 37.911 204.843 38.0715 204.933 38.232C205.023 38.3925 205.098 38.5246 205.188 38.6662C205.379 38.9772 205.589 39.2767 205.816 39.563C207.476 41.65 209.723 43.1921 212.268 43.9904C212.596 44.0843 212.931 44.1568 213.268 44.2075C213.424 44.2358 213.58 44.25 213.74 44.2642C213.901 44.2783 214 44.2642 214.123 44.2878H214.227C214.93 44.3199 215.626 44.1425 216.228 43.778C217.082 43.2069 217.432 42.2015 217.172 40.946C217.037 40.3373 216.813 39.7519 216.506 39.209Z" fill="black" />
                        <path d="M215.813 39.648C215.872 39.7487 215.929 39.8478 215.982 39.9454C216.287 40.4742 216.511 41.0453 216.648 41.6398C216.799 42.2693 216.746 42.9303 216.497 43.5278C216.413 43.606 216.323 43.6771 216.228 43.7402C215.626 44.1048 214.929 44.2822 214.227 44.25H214.123C214 44.25 213.873 44.25 213.74 44.2264C213.608 44.2028 213.434 44.1981 213.268 44.1698C212.931 44.119 212.596 44.0465 212.268 43.9526C209.723 43.1543 207.476 41.6123 205.816 39.5253C205.589 39.2389 205.379 38.9395 205.188 38.6285C205.098 38.4869 205.013 38.3406 204.933 38.1942C204.853 38.0479 204.768 37.8733 204.697 37.7222C204.352 37.0313 204.182 36.2664 204.201 35.4944C204.216 35.1762 204.291 34.8638 204.423 34.574C204.737 34.2982 205.11 34.0994 205.514 33.9934C206.057 33.8552 206.621 33.8136 207.18 33.8707L207.496 33.9085H207.576C210.592 34.371 214.137 36.816 215.813 39.648Z" fill="#E0E0E0" />
                        <path d="M218.394 45.8312C215.836 47.2472 212.504 46.1191 210.016 44.8872C206.311 42.8718 202.96 39.7707 201.794 35.9475C201.441 34.8765 201.512 33.711 201.992 32.6907C201.48 33.706 201.383 34.8811 201.723 35.9664C202.611 39.1288 205.103 41.7862 207.925 43.7591C210.824 45.6613 215.086 47.672 218.394 45.8312Z" fill="#F5F5F5" />
                        <path d="M206.042 37.6939C206.687 38.6808 207.688 39.3807 208.836 39.648L208.435 39.7141L207.708 40.6958C207.866 40.8846 208.038 41.0613 208.223 41.2245C208.541 41.5037 208.891 41.7445 209.266 41.9419C209.887 42.2572 210.552 42.4768 211.239 42.5933L211.815 43.5373L213.453 43.6789L214.179 42.6971L214.033 42.447L214 42.395C214.075 42.3345 214.14 42.2629 214.194 42.1826C214.214 42.1581 214.232 42.1312 214.246 42.1024C214.321 41.9702 214.755 41.4322 214.92 41.2009C214.941 41.1764 214.958 41.1494 214.972 41.1206C215.142 40.8186 215.095 40.4032 214.774 39.8651C214.444 39.3254 213.983 38.8774 213.434 38.5624L213.292 38.4869L213.146 38.4066L213.014 38.3453H212.957L212.853 38.2981C212.569 38.1738 212.277 38.0666 211.98 37.9771L211.588 37.8638L212.117 37.7742L212.844 36.7924C211.953 35.8619 210.783 35.2486 209.511 35.046L208.94 34.102L207.302 33.9887L206.566 34.928L206.745 35.2301C206.682 35.2782 206.627 35.3355 206.58 35.4C206.476 35.5605 205.981 36.1977 205.858 36.377C205.65 36.6886 205.698 37.1228 206.042 37.6939Z" fill="#E0E0E0" />
                        <path opacity="0.1" d="M214.887 41.2622C214.703 41.5124 214.316 41.9938 214.246 42.1166L214.198 42.1968C214.142 42.2762 214.075 42.3477 214 42.4092L213.589 41.7154C213.826 41.7563 214.07 41.7362 214.297 41.6569C214.524 41.5776 214.727 41.4418 214.887 41.2622Z" fill="black" />
                        <path opacity="0.2" d="M208.855 36.7641C208.681 36.4762 208.784 36.2496 209.464 36.2921C209.776 36.3011 210.084 36.3746 210.366 36.5078C210.649 36.6409 210.901 36.831 211.107 37.0662L212.834 36.7735L212.103 37.76L211.583 37.8544H211.541L210.942 37.6986L210.71 37.642C210.285 37.5548 209.868 37.4317 209.464 37.2738C209.297 37.2095 209.144 37.1115 209.016 36.9859C208.951 36.9212 208.896 36.8463 208.855 36.7641Z" fill="black" />
                        <path opacity="0.13" d="M206.736 35.2301L206.557 34.928L207.284 33.9462L207.855 34.8902C207.456 34.887 207.066 35.0056 206.736 35.2301Z" fill="black" />
                        <path opacity="0.1" d="M212.542 42.565L214.179 42.7113L213.453 43.693L211.81 43.5514L211.239 42.6074C210.552 42.495 209.888 42.2735 209.271 41.9514C208.893 41.7585 208.541 41.519 208.223 41.2386C208.038 41.0754 207.866 40.8987 207.708 40.71L208.435 39.7282C208.595 39.9158 208.766 40.0924 208.95 40.2569C209.267 40.536 209.617 40.7754 209.993 40.9696C210.613 41.2891 211.278 41.5104 211.966 41.6257L212.542 42.565Z" fill="black" />
                        <path opacity="0.13" d="M211.239 42.5838C210.67 42.538 210.113 42.3959 209.592 42.1638C209.077 41.9438 208.611 41.6228 208.223 41.2198C208.665 41.5427 209.14 41.8195 209.639 42.0458C209.889 42.159 210.149 42.2534 210.413 42.3478C210.677 42.4422 210.984 42.5083 211.239 42.5838Z" fill="black" />
                        <path opacity="0.13" d="M205.849 36.3865C205.971 36.2071 206.467 35.5699 206.571 35.4094C206.373 35.7115 206.42 36.141 206.76 36.7122C207.52 37.9866 208.808 38.4774 210.064 38.8078C211.244 39.1146 212.192 39.2138 212.56 39.8274C212.787 40.2144 212.527 40.3654 211.994 40.3277C211.656 40.3011 211.326 40.2063 211.025 40.049C210.724 39.8916 210.458 39.6749 210.243 39.412L208.827 39.6433C207.68 39.3762 206.679 38.6783 206.033 37.6939C205.698 37.1181 205.655 36.6886 205.849 36.3865Z" fill="black" />
                        <path d="M210.38 38.0526L211.107 37.0709L212.834 36.7735L212.107 37.7553L210.38 38.0526Z" fill="#E0E0E0" />
                        <path opacity="0.1" d="M210.38 38.0526L211.107 37.0709L212.834 36.7735L212.107 37.7553L210.38 38.0526Z" fill="black" />
                        <path d="M207.293 33.9462L207.864 34.9138L206.745 35.2301L206.566 34.928L207.293 33.9462Z" fill="#E0E0E0" />
                        <path opacity="0.2" d="M207.293 33.9462L207.864 34.9138L206.745 35.2301L206.566 34.928L207.293 33.9462Z" fill="black" />
                        <path d="M214.033 42.48L213.589 41.7342C213.836 41.7756 214.089 41.7511 214.322 41.6633C214.556 41.5754 214.762 41.4273 214.92 41.2339C214.941 41.2094 214.958 41.1825 214.972 41.1537C215.142 40.8516 215.095 40.4362 214.774 39.8982C214.444 39.3584 213.983 38.9105 213.434 38.5954L213.292 38.5199L213.146 38.4397L213.014 38.3783H212.957L212.853 38.3311C212.569 38.2068 212.277 38.0996 211.98 38.0102L211.555 37.8874L210.951 37.7317L210.72 37.675C210.293 37.5871 209.875 37.4639 209.469 37.3069C209.305 37.241 209.154 37.145 209.025 37.0237C208.964 36.9627 208.91 36.8945 208.865 36.8207C208.69 36.5281 208.794 36.3015 209.474 36.3487C209.786 36.3578 210.093 36.4312 210.376 36.5644C210.658 36.6976 210.91 36.8877 211.116 37.1228L212.844 36.8254C211.953 35.895 210.783 35.2816 209.511 35.079L208.94 34.135L207.302 34.0218L207.878 34.9658C207.642 34.9453 207.404 34.9769 207.181 35.0585C206.959 35.14 206.757 35.2695 206.59 35.4378C206.391 35.7398 206.439 36.1694 206.783 36.7452C207.534 38.0149 208.827 38.5058 210.087 38.8362C211.267 39.143 212.216 39.2421 212.584 39.8557C212.811 40.2427 212.551 40.389 212.018 40.356C211.679 40.3289 211.35 40.2339 211.049 40.0766C210.748 39.9192 210.482 39.7028 210.267 39.4403L208.44 39.7377C208.598 39.9264 208.77 40.1031 208.954 40.2663C209.273 40.5455 209.623 40.7863 209.998 40.9838C210.619 41.2991 211.284 41.5187 211.97 41.6351L212.546 42.5791L214.184 42.7207L214.033 42.48Z" fill="#E0E0E0" />
                        <path d="M214.033 42.48L213.589 41.7342C213.836 41.7756 214.089 41.7511 214.322 41.6633C214.556 41.5754 214.762 41.4273 214.92 41.2339C214.941 41.2094 214.958 41.1825 214.972 41.1537C215.142 40.8516 215.095 40.4362 214.774 39.8982C214.444 39.3584 213.983 38.9105 213.434 38.5954L213.292 38.5199L213.146 38.4397L213.014 38.3783H212.957L212.853 38.3311C212.569 38.2068 212.277 38.0996 211.98 38.0102L211.555 37.8874L210.951 37.7317L210.72 37.675C210.293 37.5871 209.875 37.4639 209.469 37.3069C209.305 37.241 209.154 37.145 209.025 37.0237C208.964 36.9627 208.91 36.8945 208.865 36.8207C208.69 36.5281 208.794 36.3015 209.474 36.3487C209.786 36.3578 210.093 36.4312 210.376 36.5644C210.658 36.6976 210.91 36.8877 211.116 37.1228L212.844 36.8254C211.953 35.895 210.783 35.2816 209.511 35.079L208.94 34.135L207.302 34.0218L207.878 34.9658C207.642 34.9453 207.404 34.9769 207.181 35.0585C206.959 35.14 206.757 35.2695 206.59 35.4378C206.391 35.7398 206.439 36.1694 206.783 36.7452C207.534 38.0149 208.827 38.5058 210.087 38.8362C211.267 39.143 212.216 39.2421 212.584 39.8557C212.811 40.2427 212.551 40.389 212.018 40.356C211.679 40.3289 211.35 40.2339 211.049 40.0766C210.748 39.9192 210.482 39.7028 210.267 39.4403L208.44 39.7377C208.598 39.9264 208.77 40.1031 208.954 40.2663C209.273 40.5455 209.623 40.7863 209.998 40.9838C210.619 41.2991 211.284 41.5187 211.97 41.6351L212.546 42.5791L214.184 42.7207L214.033 42.48Z" fill="#F0F0F0" />
                        <path d="M206.769 36.7122C207.064 37.1795 207.457 37.5773 207.921 37.878C208.399 38.1837 208.916 38.4219 209.459 38.586C210.002 38.7654 210.564 38.8834 211.121 39.025C211.401 39.0916 211.676 39.18 211.942 39.2893C212.203 39.3993 212.425 39.5851 212.579 39.8226C212.419 39.5933 212.192 39.42 211.928 39.327C211.663 39.2233 211.39 39.1412 211.111 39.0816C210.554 38.9494 209.988 38.8314 209.441 38.6568C208.889 38.4916 208.366 38.245 207.888 37.9252C207.655 37.7657 207.441 37.5805 207.25 37.373C207.067 37.1708 206.905 36.9492 206.769 36.7122Z" fill="white" />
                        <path d="M212.542 42.5697L211.81 43.5514L211.239 42.5838L211.966 41.6021L212.542 42.5697Z" fill="#E0E0E0" />
                        <path opacity="0.13" d="M212.542 42.5697L211.81 43.5514L211.239 42.5838L211.966 41.6021L212.542 42.5697Z" fill="black" />
                        <path d="M214.179 42.7113L212.627 42.4611L211.966 41.6021L212.542 42.5697L214.179 42.7113Z" fill="#FAFAFA" />
                        <path d="M209.507 35.0271L208.86 34.1681L207.293 33.9462L208.926 34.0642L209.507 35.0271Z" fill="#FAFAFA" />
                        <path opacity="0.15" d="M212.834 36.7735C212.686 36.6077 212.528 36.4501 212.362 36.3015L212.15 36.8679L212.834 36.7735Z" fill="white" />
                        <path opacity="0.15" d="M206.793 36.7546C207.115 37.2729 207.557 37.7066 208.081 38.0196L209.304 34.6873L208.931 34.0595L207.812 33.984L207.623 34.5032L207.869 34.9138C207.73 34.9198 207.591 34.9339 207.453 34.9563L206.793 36.7546Z" fill="white" />
                        <path opacity="0.15" d="M214.656 39.6905C214.455 39.4028 214.213 39.1453 213.939 38.9258L212.598 42.5744L213.566 42.6546L213.788 42.0505L213.589 41.7154C213.695 41.7262 213.801 41.7262 213.906 41.7154L214.656 39.6905Z" fill="white" />
                        <path opacity="0.15" d="M213.283 38.4916L213.136 38.4114L213.004 38.35H212.948L212.844 38.3028C212.559 38.1785 212.268 38.0713 211.97 37.9818L211.777 37.9252L211.333 39.1335C211.825 39.2016 212.271 39.457 212.579 39.8462C212.806 40.2333 212.546 40.3796 212.013 40.3466C211.656 40.3191 211.31 40.2145 210.998 40.0398L210.559 41.2292C211.017 41.4089 211.491 41.5417 211.975 41.6257L212.192 41.9891L213.438 38.5954L213.306 38.5246L213.283 38.4916Z" fill="white" />
                        <path d="M165.512 62.7288L164.596 63.399L165.979 65.287C166.145 65.5115 166.34 65.7131 166.559 65.8865L166.8 66.0564C167.227 66.3214 167.699 66.5054 168.192 66.5992C168.372 66.637 168.561 66.6653 168.754 66.6889C168.948 66.7125 169.151 66.7219 169.363 66.7219C169.575 66.7219 169.901 66.7219 170.189 66.6889C170.477 66.6558 170.793 66.6181 171.1 66.5614C171.331 66.519 171.572 66.467 171.799 66.4104C175.244 65.5419 178.978 62.979 181.239 59.944C181.394 59.7363 181.541 59.5286 181.678 59.3162C181.876 59.0189 182.06 58.7121 182.225 58.4053C182.39 58.0985 182.48 57.905 182.588 57.6595C182.697 57.4141 182.763 57.2442 182.839 57.0412C182.914 56.8382 182.971 56.6353 183.018 56.4418C183.162 55.9115 183.226 55.3626 183.207 54.8134C183.207 54.7048 183.207 54.5962 183.178 54.4924C183.129 54.0618 182.996 53.645 182.787 53.2652C182.721 53.1519 182.65 53.0434 182.574 52.9348L181.248 51.1507L180.413 51.7642L180.044 51.7029C179.522 51.6457 178.995 51.6457 178.473 51.7029C178.135 51.7322 177.8 51.7795 177.467 51.8445C177.021 51.9328 176.579 52.0463 176.146 52.1843C172.937 53.281 170.092 55.2407 167.923 57.8483C167.612 58.2259 167.319 58.6177 167.055 59.0094C166.791 59.4012 166.635 59.675 166.451 60.0101C166.191 60.4913 165.971 60.9933 165.795 61.511C165.661 61.9067 165.566 62.3146 165.512 62.7288Z" fill="#E0E0E0" />
                        <path opacity="0.15" d="M179.738 61.6479L176.939 57.8719L175.66 59.0425L178.463 62.8185C178.888 62.4503 179.36 62.0585 179.738 61.6479Z" fill="white" />
                        <path opacity="0.05" d="M165.979 65.2634C166.145 65.488 166.34 65.6895 166.559 65.8629L166.8 66.0328C167.227 66.2978 167.699 66.4818 168.192 66.5756L168.395 66.6134L165.512 62.7194L164.596 63.3896L165.979 65.2634Z" fill="black" />
                        <path opacity="0.15" d="M169.311 60.0998L168.476 60.4585L172.681 66.1225C172.964 66.0328 173.248 65.929 173.531 65.8157L169.311 60.0998Z" fill="white" />
                        <path opacity="0.15" d="M175.631 64.7962L171.992 59.8779L170.685 61.0107L174.074 65.5891C174.607 65.3541 175.127 65.0894 175.631 64.7962Z" fill="white" />
                        <path opacity="0.15" d="M181.248 51.1507L180.413 51.7642L180.044 51.7029C179.522 51.6457 178.995 51.6457 178.473 51.7029C178.135 51.7322 177.8 51.7795 177.467 51.8445C177.021 51.9328 176.579 52.0463 176.146 52.1843C175.73 52.3165 175.306 52.4722 174.885 52.6563L180.734 60.5576C180.899 60.3546 181.064 60.147 181.206 59.9393C181.347 59.7316 181.508 59.5239 181.644 59.3115C181.843 59.0142 182.027 58.7074 182.192 58.4006C182.357 58.0938 182.447 57.9002 182.555 57.6548C182.664 57.4094 182.73 57.2394 182.806 57.0365C182.881 56.8335 182.938 56.6306 182.985 56.437C183.129 55.9068 183.193 55.3578 183.174 54.8086C183.174 54.7001 183.174 54.5915 183.145 54.4877C183.096 54.0571 182.962 53.6403 182.754 53.2605C182.688 53.1472 182.617 53.0386 182.541 52.9301L181.248 51.1507Z" fill="white" />
                        <path opacity="0.15" d="M183.197 54.7898C183.197 54.6812 183.197 54.5726 183.169 54.4688C183.119 54.0382 182.986 53.6214 182.777 53.2416C182.711 53.1283 182.64 53.0198 182.565 52.9112L181.248 51.1507L180.413 51.7642L180.21 52.0758L183.112 55.9981C183.188 55.6001 183.217 55.1945 183.197 54.7898Z" fill="white" />
                        <path d="M164.327 59.472C164.144 59.9972 164.034 60.5452 164.001 61.1004C163.945 61.7823 164.082 62.4661 164.398 63.0734C164.596 63.4423 164.871 63.7643 165.205 64.0174C165.28 64.0787 165.36 64.1354 165.445 64.192C165.879 64.4605 166.359 64.6446 166.861 64.7348C167.047 64.7737 167.234 64.8021 167.423 64.8198C167.617 64.8434 167.82 64.8528 168.032 64.8575C168.244 64.8622 168.57 64.8575 168.858 64.8245C169.146 64.7914 169.457 64.7537 169.769 64.697C169.996 64.6546 170.241 64.6026 170.468 64.546C173.913 63.6775 177.647 61.1146 179.908 58.0796C180.063 57.8719 180.205 57.6642 180.346 57.4518C180.545 57.1498 180.729 56.8477 180.894 56.5409C181.059 56.2341 181.149 56.0406 181.257 55.7951C181.366 55.5497 181.432 55.3798 181.503 55.1721C181.574 54.9644 181.635 54.7709 181.687 54.5774C181.835 54.0477 181.9 53.4985 181.88 52.949C181.88 52.8404 181.88 52.7318 181.852 52.628C181.802 52.1974 181.669 51.7806 181.46 51.4008C181.136 50.8325 180.641 50.3814 180.044 50.1122C179.586 49.8949 179.095 49.7561 178.591 49.7016C178.07 49.6442 177.544 49.6442 177.024 49.7016C176.686 49.7325 176.351 49.7814 176.018 49.8479C175.572 49.9368 175.131 50.0487 174.697 50.183C171.488 51.2797 168.643 53.2394 166.474 55.847C166.163 56.2294 165.87 56.6164 165.606 57.0082C165.342 57.3999 165.186 57.6737 165.002 58.0088C164.736 58.4769 164.511 58.9663 164.327 59.472Z" fill="#F0F0F0" />
                        <path opacity="0.15" d="M179.384 58.7074C179.554 58.5044 179.714 58.2967 179.856 58.089C179.997 57.8814 180.153 57.6737 180.295 57.4613C180.493 57.1592 180.677 56.8571 180.842 56.5503C181.007 56.2435 181.097 56.05 181.206 55.8046C181.314 55.5591 181.38 55.3892 181.451 55.1815C181.522 54.9738 181.583 54.7803 181.635 54.5868C181.783 54.0572 181.848 53.5079 181.829 52.9584C181.829 52.8498 181.829 52.7413 181.8 52.6374C181.75 52.2069 181.617 51.7901 181.408 51.4102C181.084 50.8419 180.589 50.3908 179.992 50.1217C179.534 49.9043 179.043 49.7655 178.539 49.711C178.018 49.6537 177.492 49.6537 176.972 49.711C176.679 49.7394 176.382 49.7818 176.08 49.8385L179.384 58.7074Z" fill="white" />
                        <path opacity="0.15" d="M181.748 54.1478C181.827 53.7565 181.859 53.3572 181.843 52.9584C181.843 52.8498 181.843 52.7413 181.814 52.6374C181.765 52.2069 181.631 51.7901 181.423 51.4102C181.162 50.9491 180.787 50.5636 180.332 50.2916L181.748 54.1478Z" fill="white" />
                        <path opacity="0.15" d="M177.113 60.9918C177.557 60.6142 177.982 60.2225 178.383 59.8166L174.824 50.1311L174.678 50.1736C174.229 50.3152 173.776 50.4898 173.323 50.6834L177.113 60.9918Z" fill="white" />
                        <path opacity="0.15" d="M172.719 63.7578C173.252 63.5208 173.772 63.2562 174.277 62.9648L170.359 52.307C169.859 52.6469 169.372 53.0056 168.905 53.3926L172.719 63.7578Z" fill="white" />
                        <path opacity="0.15" d="M171.336 64.2959C171.619 64.2015 171.907 64.0976 172.19 63.9843L168.433 53.808C168.183 54.0251 167.961 54.247 167.706 54.4735L171.336 64.2959Z" fill="white" />
                        <path opacity="0.3" d="M164.728 58.4902C164.662 58.6256 164.601 58.7624 164.544 58.9009L164.487 59.0378C164.487 59.085 164.454 59.1322 164.435 59.1747C164.416 59.2171 164.36 59.3823 164.327 59.4815C164.144 60.0066 164.034 60.5546 164.001 61.1099C163.945 61.7917 164.082 62.4756 164.398 63.0828C164.596 63.4518 164.871 63.7737 165.205 64.0268C165.28 64.0882 165.36 64.1448 165.445 64.2015C165.879 64.4699 166.359 64.6541 166.861 64.7443L167.064 64.782L164.728 58.4902Z" fill="#E0E0E0" />
                        <path d="M167.022 57.7728C168.646 54.7803 172.407 52.1088 175.589 51.6651L175.99 51.6179C179.251 51.3394 180.516 53.6381 178.822 56.7533C177.127 59.8685 173.111 62.625 169.854 62.8893H169.467C166.493 62.9978 165.394 60.7606 167.022 57.7728Z" fill="#E0E0E0" />
                        <path d="M167.282 57.7492C167.333 57.6501 167.39 57.5557 167.447 57.4613C169.08 54.7237 172.53 52.3401 175.471 51.9153H175.546L175.853 51.8775C176.397 51.8225 176.947 51.864 177.477 52.0002C177.973 52.1084 178.42 52.3794 178.745 52.7702C179.07 53.161 179.255 53.6492 179.27 54.1573C179.292 54.9071 179.13 55.651 178.798 56.3238C178.728 56.4748 178.652 56.6306 178.567 56.7958C178.482 56.961 178.407 57.079 178.317 57.2206C178.132 57.5236 177.928 57.8152 177.708 58.0938C176.088 60.1245 173.899 61.6251 171.421 62.4031C171.102 62.4952 170.777 62.5677 170.449 62.6202C170.298 62.6438 170.147 62.6627 169.977 62.6722C169.807 62.6816 169.726 62.6722 169.604 62.6722H169.505C168.819 62.7043 168.14 62.53 167.555 62.1718C166.725 61.6196 166.385 60.6426 166.635 59.4154C166.766 58.8309 166.984 58.2693 167.282 57.7492Z" fill="#E0E0E0" />
                        <path opacity="0.13" d="M167.282 57.7492C167.333 57.6501 167.39 57.5557 167.447 57.4613C169.08 54.7237 172.53 52.3401 175.471 51.9153H175.546L175.853 51.8775C176.397 51.8225 176.947 51.864 177.477 52.0002C177.973 52.1084 178.42 52.3794 178.745 52.7702C179.07 53.161 179.255 53.6492 179.27 54.1573C179.292 54.9071 179.13 55.651 178.798 56.3238C178.728 56.4748 178.652 56.6306 178.567 56.7958C178.482 56.961 178.407 57.079 178.317 57.2206C178.132 57.5236 177.928 57.8152 177.708 58.0938C176.088 60.1245 173.899 61.6251 171.421 62.4031C171.102 62.4952 170.777 62.5677 170.449 62.6202C170.298 62.6438 170.147 62.6627 169.977 62.6722C169.807 62.6816 169.726 62.6722 169.604 62.6722H169.505C168.819 62.7043 168.14 62.53 167.555 62.1718C166.725 61.6196 166.385 60.6426 166.635 59.4154C166.766 58.8309 166.984 58.2693 167.282 57.7492Z" fill="black" />
                        <path d="M167.961 58.1551C167.9 58.2542 167.848 58.3486 167.796 58.443C167.503 58.9659 167.288 59.5288 167.159 60.1139C167.006 60.733 167.056 61.3848 167.3 61.9736C167.382 62.0489 167.471 62.1168 167.565 62.1766C168.15 62.5347 168.829 62.709 169.514 62.6769H169.613C169.736 62.6769 169.859 62.6769 169.986 62.6769C170.114 62.6769 170.288 62.6486 170.458 62.625C170.786 62.5724 171.111 62.4999 171.43 62.4078C173.907 61.6149 176.09 60.0993 177.699 58.056C177.919 57.7775 178.122 57.4859 178.307 57.1828C178.397 57.0412 178.477 56.8996 178.558 56.758C178.638 56.6164 178.718 56.4465 178.789 56.286C179.121 55.6132 179.283 54.8694 179.261 54.1195C179.245 53.8096 179.172 53.5054 179.044 53.2227C178.738 52.9545 178.375 52.7606 177.982 52.6563C177.452 52.5221 176.902 52.4822 176.358 52.5383L176.051 52.5714H175.971C173.045 53.0386 169.59 55.4175 167.961 58.1551Z" fill="#E0E0E0" />
                        <path d="M165.445 64.192C167.938 65.5844 171.185 64.4657 173.606 63.248C177.212 61.3034 180.469 58.2778 181.63 54.5585C181.979 53.518 181.911 52.3831 181.442 51.3914C181.938 52.381 182.033 53.5242 181.706 54.5821C180.842 57.6595 178.402 60.2461 175.669 62.1671C172.818 64.0362 168.669 65.9809 165.445 64.192Z" fill="#F5F5F5" />
                        <path opacity="0.13" d="M170.807 58.4666L171.388 59.3304L168.976 59.9298L168.391 59.0661L170.807 58.4666Z" fill="black" />
                        <path opacity="0.13" d="M170.458 57.9144C170.727 58.3864 171.61 58.5327 172.346 58.6318C172.53 58.6318 172.695 58.6696 172.842 58.6932C172.931 58.6209 173.006 58.5329 173.064 58.4336C173.333 57.9616 172.842 57.8955 171.761 57.7681C171.396 57.7286 171.035 57.6639 170.68 57.5746C170.788 57.3197 170.737 57.0223 169.859 57.0554L170.458 57.9144Z" fill="black" />
                        <path opacity="0.2" d="M176.084 52.6705L176.67 53.5342L176.122 54.5018L175.537 53.6381L176.084 52.6705Z" fill="black" />
                        <path opacity="0.13" d="M172.563 55.696C172.713 55.4942 172.909 55.3312 173.135 55.2203C173.361 55.1095 173.61 55.0542 173.861 55.0588C174.805 54.988 174.89 55.4364 174.772 55.781L174.876 55.932C174.733 55.9148 174.589 55.9148 174.446 55.932C174.05 55.9446 173.668 56.0799 173.351 56.319C172.752 56.2246 172.318 56.1161 172.563 55.696Z" fill="black" />
                        <path opacity="0.13" d="M177.071 54.752C177.138 54.85 177.234 54.9255 177.345 54.9691C177.345 55.0258 177.321 55.0824 177.302 55.1438L177.887 56.0028C177.944 55.8539 177.967 55.6938 177.952 55.5351C177.937 55.3763 177.886 55.223 177.802 55.0871L177.217 54.2281C177.217 54.2281 176.901 54.4735 177.071 54.752Z" fill="black" />
                        <path d="M171.775 57.7681C172.856 57.8955 173.347 57.9569 173.078 58.4336C173.02 58.5329 172.945 58.6209 172.856 58.6932C173.583 58.7923 173.89 58.9009 173.663 59.2974C173.51 59.4987 173.31 59.6597 173.08 59.7665C172.851 59.8734 172.599 59.923 172.346 59.911C171.553 59.9723 171.26 59.741 171.402 59.3304L168.976 59.944C168.976 59.944 168.976 59.9865 168.976 60.0101C168.859 59.9915 168.739 59.9915 168.622 60.0101L169.094 60.7086L169.141 60.7747C169.295 60.962 169.5 61.0999 169.731 61.1712C170.049 61.2739 170.379 61.3296 170.713 61.3364L169.59 61.4261L170.175 62.2851L170.935 62.2285L171.964 62.1482L172.511 61.2042C174.126 60.9258 175.442 60.1989 176.089 59.0566C176.382 58.5374 176.405 58.1787 176.254 57.9191C176.243 57.8969 176.229 57.8763 176.212 57.8578L175.678 57.112C175.601 57.2036 175.554 57.3178 175.546 57.4377C175.303 57.3636 175.053 57.3099 174.8 57.2772C173.79 57.1309 172.799 57.1781 173.148 56.5598C173.203 56.4662 173.271 56.3818 173.351 56.3096C172.733 56.2388 172.318 56.1302 172.563 55.696C172.713 55.4942 172.909 55.3312 173.135 55.2203C173.361 55.1095 173.61 55.0541 173.861 55.0588C174.805 54.988 174.89 55.4364 174.772 55.781L174.876 55.932L175.348 56.6447L177.878 56.0217L177.293 55.1626C177.333 55.0309 177.355 54.8944 177.359 54.7567C176.964 54.5932 176.54 54.5114 176.113 54.516L176.334 54.1242L174.885 53.6853L174.338 54.6529C172.705 54.9455 171.265 55.6913 170.609 56.8571C170.492 57.0481 170.412 57.2595 170.373 57.4802C170.826 57.6366 171.297 57.7334 171.775 57.7681Z" fill="#E0E0E0" />
                        <path opacity="0.1" d="M171.775 57.7681C172.856 57.8955 173.347 57.9569 173.078 58.4336C173.02 58.5329 172.945 58.6209 172.856 58.6932C173.583 58.7923 173.89 58.9009 173.663 59.2974C173.51 59.4987 173.31 59.6597 173.08 59.7665C172.851 59.8734 172.599 59.923 172.346 59.911C171.553 59.9723 171.26 59.741 171.402 59.3304L168.976 59.944C168.976 59.944 168.976 59.9865 168.976 60.0101C168.859 59.9915 168.739 59.9915 168.622 60.0101L169.094 60.7086L169.141 60.7747C169.295 60.962 169.5 61.0999 169.731 61.1712C170.049 61.2739 170.379 61.3296 170.713 61.3364L169.59 61.4261L170.175 62.2851L170.935 62.2285L171.964 62.1482L172.511 61.2042C174.126 60.9258 175.442 60.1989 176.089 59.0566C176.382 58.5374 176.405 58.1787 176.254 57.9191C176.243 57.8969 176.229 57.8763 176.212 57.8578L175.678 57.112C175.601 57.2036 175.554 57.3178 175.546 57.4377C175.303 57.3636 175.053 57.3099 174.8 57.2772C173.79 57.1309 172.799 57.1781 173.148 56.5598C173.203 56.4662 173.271 56.3818 173.351 56.3096C172.733 56.2388 172.318 56.1302 172.563 55.696C172.713 55.4942 172.909 55.3312 173.135 55.2203C173.361 55.1095 173.61 55.0541 173.861 55.0588C174.805 54.988 174.89 55.4364 174.772 55.781L174.876 55.932L175.348 56.6447L177.878 56.0217L177.293 55.1626C177.333 55.0309 177.355 54.8944 177.359 54.7567C176.964 54.5932 176.54 54.5114 176.113 54.516L176.334 54.1242L174.885 53.6853L174.338 54.6529C172.705 54.9455 171.265 55.6913 170.609 56.8571C170.492 57.0481 170.412 57.2595 170.373 57.4802C170.826 57.6366 171.297 57.7334 171.775 57.7681Z" fill="black" />
                        <g opacity="0.2">
                            <path opacity="0.2" d="M172.483 61.1665C173.399 60.9902 174.268 60.6218 175.032 60.0856C175.405 59.8204 175.725 59.4874 175.976 59.1038C176.103 58.9217 176.202 58.7211 176.268 58.5091C176.344 58.3049 176.335 58.0793 176.245 57.8814C176.301 57.9764 176.337 58.082 176.351 58.1916C176.365 58.3012 176.356 58.4125 176.325 58.5186C176.267 58.7386 176.176 58.9486 176.056 59.1416C175.812 59.5431 175.491 59.8927 175.112 60.1706C174.73 60.4499 174.311 60.674 173.866 60.8361C173.419 60.9978 172.955 61.1086 172.483 61.1665Z" fill="black" />
                        </g>
                        <path d="M170.392 57.466C170.841 57.6265 171.309 57.728 171.784 57.7681C172.865 57.8955 173.356 57.9569 173.087 58.4336C173.03 58.5329 172.954 58.6209 172.865 58.6932C172.542 58.9151 172.162 59.0381 171.77 59.0472C170.982 59.1086 170.689 58.8773 170.826 58.4666L168.41 59.0661C168.344 59.2916 168.364 59.5337 168.467 59.7449C168.57 59.9562 168.748 60.1214 168.967 60.2083C169.334 60.3641 169.729 60.4443 170.128 60.4443L169.58 61.4119L170.704 61.3222L171.369 61.2703L171.917 60.3263C173.531 60.0431 174.848 59.3162 175.494 58.1787C175.62 57.9733 175.705 57.7458 175.744 57.5085C175.834 56.7816 175.088 56.5645 174.234 56.4182C173.937 56.3757 173.635 56.3474 173.37 56.3143C172.752 56.2435 172.337 56.135 172.582 55.7007C172.732 55.4989 172.928 55.3359 173.154 55.2251C173.38 55.1142 173.629 55.0589 173.88 55.0635C174.824 54.9927 174.909 55.4411 174.791 55.7857L177.321 55.1674C177.361 55.0356 177.383 54.8991 177.387 54.7614C177.387 54.2517 177.052 53.9543 176.547 53.8174C176.224 53.7257 175.891 53.6781 175.556 53.6758L176.103 52.7318L174.333 52.864L173.781 53.8363C172.148 54.129 170.708 54.8747 170.052 56.0406C169.557 56.8146 169.84 57.2347 170.392 57.466Z" fill="#E0E0E0" />
                        <path d="M170.392 57.466C170.841 57.6265 171.309 57.728 171.784 57.7681C172.865 57.8955 173.356 57.9569 173.087 58.4336C173.03 58.5329 172.954 58.6209 172.865 58.6932C172.542 58.9151 172.162 59.0381 171.77 59.0472C170.982 59.1086 170.689 58.8773 170.826 58.4666L168.41 59.0661C168.344 59.2916 168.364 59.5337 168.467 59.7449C168.57 59.9562 168.748 60.1214 168.967 60.2083C169.334 60.3641 169.729 60.4443 170.128 60.4443L169.58 61.4119L170.704 61.3222L171.369 61.2703L171.917 60.3263C173.531 60.0431 174.848 59.3162 175.494 58.1787C175.62 57.9733 175.705 57.7458 175.744 57.5085C175.834 56.7816 175.088 56.5645 174.234 56.4182C173.937 56.3757 173.635 56.3474 173.37 56.3143C172.752 56.2435 172.337 56.135 172.582 55.7007C172.732 55.4989 172.928 55.3359 173.154 55.2251C173.38 55.1142 173.629 55.0589 173.88 55.0635C174.824 54.9927 174.909 55.4411 174.791 55.7857L177.321 55.1674C177.361 55.0356 177.383 54.8991 177.387 54.7614C177.387 54.2517 177.052 53.9543 176.547 53.8174C176.224 53.7257 175.891 53.6781 175.556 53.6758L176.103 52.7318L174.333 52.864L173.781 53.8363C172.148 54.129 170.708 54.8747 170.052 56.0406C169.557 56.8146 169.84 57.2347 170.392 57.466Z" fill="#F0F0F0" />
                        <path opacity="0.15" d="M174.947 55.7243L176.5 55.342L175.962 53.6711C175.821 53.6527 175.679 53.6417 175.537 53.6381L175.801 53.1661L175.65 52.6941L174.314 52.7932L174.116 53.1378L174.947 55.7243Z" fill="white" />
                        <path opacity="0.15" d="M170.392 57.466C170.841 57.6265 171.309 57.7281 171.784 57.7681L172.134 57.8106L171.161 54.7945C170.703 55.0989 170.32 55.5041 170.043 55.9792C169.967 56.1092 169.905 56.2469 169.859 56.3899L170.165 57.3339C170.237 57.3836 170.313 57.4278 170.392 57.466Z" fill="white" />
                        <path opacity="0.15" d="M172.474 58.9009C172.244 58.9843 172.004 59.0336 171.761 59.0472C170.973 59.1086 170.68 58.8773 170.817 58.4666L170.548 58.5327L171.407 61.1948L171.907 60.3074C172.226 60.2482 172.541 60.171 172.851 60.0762L172.474 58.9009Z" fill="white" />
                        <path opacity="0.15" d="M169.571 60.3877C169.748 60.42 169.928 60.4389 170.109 60.4443L169.778 61.0296L169.892 61.3836L170.685 61.3222H170.822L169.972 58.6838L169.089 58.9009L169.571 60.3877Z" fill="white" />
                        <path opacity="0.15" d="M175.258 56.6825L175.631 57.8389C175.675 57.7262 175.707 57.6091 175.726 57.4896C175.749 57.3222 175.716 57.1516 175.631 57.0053C175.547 56.859 175.415 56.7453 175.258 56.6825Z" fill="white" />
                        <path d="M171.898 60.3074L172.483 61.1665L171.935 62.1341L171.35 61.2703L171.898 60.3074Z" fill="#E0E0E0" />
                        <path opacity="0.13" d="M171.898 60.3074L172.483 61.1665L171.935 62.1341L171.35 61.2703L171.898 60.3074Z" fill="black" />
                        <path d="M173.762 53.775L173.573 53.8174L173.356 53.8694L173.078 53.9402L172.752 54.0393L172.577 54.1006L172.398 54.1667L172.209 54.2422L172.02 54.3225C171.898 54.3838 171.77 54.4405 171.648 54.5113C171.525 54.5821 171.407 54.6482 171.289 54.719C171.171 54.7898 171.062 54.8747 170.963 54.955C170.864 55.0352 170.765 55.1202 170.675 55.1957L170.552 55.3184L170.491 55.375L170.439 55.4317C170.373 55.5119 170.307 55.5827 170.26 55.6488C170.213 55.7149 170.165 55.7715 170.132 55.8234L170.033 55.9792L170.128 55.8187C170.165 55.7565 170.206 55.6966 170.25 55.6394C170.302 55.562 170.361 55.4894 170.425 55.4222L170.472 55.3609L170.534 55.2995L170.652 55.1768C170.742 55.089 170.838 55.0071 170.94 54.9314C171.039 54.8464 171.152 54.7709 171.265 54.6906C171.378 54.6104 171.501 54.5396 171.629 54.4782C171.756 54.4169 171.879 54.3508 172.006 54.2894L172.195 54.2092L172.384 54.1337L172.568 54.0676L172.747 54.0157C172.861 53.9732 172.974 53.9496 173.073 53.9213C173.172 53.893 173.271 53.8741 173.356 53.8552L173.573 53.8127L173.762 53.775Z" fill="white" />
                        <path d="M171.35 61.2703L169.561 61.4119L170.109 60.4443L169.745 61.2986L171.35 61.2703Z" fill="#FAFAFA" />
                        <path d="M175.537 53.6381L175.938 52.7696L174.314 52.8026L176.084 52.6705L175.537 53.6381Z" fill="#FAFAFA" />
                        <path d="M20.3196 32.3698L19.031 33.3138L20.9804 35.9428C21.2143 36.2577 21.4888 36.5402 21.797 36.783C21.9055 36.8679 22.0235 36.9482 22.1415 37.0237C22.7425 37.3966 23.4062 37.6572 24.1003 37.793C24.362 37.8483 24.6268 37.8877 24.8933 37.911C25.1785 37.9432 25.4653 37.9605 25.7523 37.963C26.1399 37.9677 26.5275 37.9519 26.9134 37.9158C27.3335 37.878 27.763 37.8166 28.1973 37.7364C28.523 37.675 28.8486 37.6042 29.1838 37.5193C34.0406 36.3015 39.3034 32.686 42.48 28.4097C42.6971 28.117 42.9001 27.8244 43.0936 27.527C43.3768 27.1022 43.6317 26.6727 43.8677 26.2385C44.0565 25.8845 44.2311 25.5352 44.3822 25.1906C44.5332 24.8461 44.6276 24.6006 44.7314 24.3174C44.8353 24.0342 44.9155 23.751 44.9863 23.4726C45.1909 22.7259 45.2801 21.9523 45.2506 21.1786C45.2506 21.0229 45.2506 20.8718 45.2129 20.7066C45.1428 20.0989 44.9552 19.5106 44.6606 18.9744C44.5722 18.8096 44.4712 18.6518 44.3586 18.5024L42.5178 16.048L41.3378 16.9118C41.1678 16.8787 40.9932 16.8504 40.8138 16.8315C40.0798 16.7504 39.339 16.7504 38.6049 16.8315C38.1297 16.8744 37.657 16.9421 37.1889 17.0345C36.5509 17.1549 35.9205 17.3125 35.3009 17.5065C30.7926 19.0405 26.7924 21.7835 23.7369 25.4361C23.3013 25.9605 22.8932 26.5072 22.5144 27.0739C22.2029 27.5459 21.9197 28.0179 21.6601 28.4899C21.287 29.1672 20.9712 29.8745 20.7161 30.6045C20.5281 31.179 20.3953 31.7701 20.3196 32.3698Z" fill="#E0E0E0" />
                        <path opacity="0.15" d="M40.3749 30.8452L36.4242 25.488L34.6212 27.14L38.5766 32.483C39.2059 31.967 39.8053 31.421 40.3749 30.8452Z" fill="white" />
                        <path opacity="0.05" d="M20.9804 35.9428C21.2143 36.2577 21.4888 36.5402 21.797 36.783C21.9055 36.8679 22.0235 36.9482 22.1415 37.0237C22.7425 37.3966 23.4062 37.6573 24.1003 37.7931L24.3882 37.845L20.3243 32.3509L19.0358 33.2949L20.9804 35.9428Z" fill="black" />
                        <path opacity="0.15" d="M25.6768 28.6646L24.4968 29.1696L30.4298 37.1606C30.8263 37.0284 31.2228 36.8821 31.624 36.7216L25.6768 28.6646Z" fill="white" />
                        <path opacity="0.15" d="M34.5882 35.2867L29.4575 28.353L27.612 29.9437L32.3886 36.4006C33.1297 36.0702 33.866 35.6974 34.5882 35.2867Z" fill="white" />
                        <path opacity="0.15" d="M42.5178 16.048L41.3378 16.9118C41.1678 16.8787 40.9932 16.8504 40.8138 16.8315C40.0798 16.7504 39.339 16.7504 38.6049 16.8315C38.1297 16.8744 37.657 16.9421 37.1889 17.0345C36.5509 17.1549 35.9205 17.3125 35.3009 17.5065C34.7109 17.6906 34.1162 17.9124 33.5262 18.1626L41.7909 29.264C42.0269 28.9761 42.2629 28.6882 42.4706 28.3955C42.6782 28.1029 42.8906 27.8102 43.0842 27.5129C43.3674 27.0881 43.6222 26.6586 43.8582 26.2243C44.047 25.8703 44.2217 25.521 44.3727 25.1765C44.5238 24.8319 44.6182 24.5865 44.722 24.3033C44.8258 24.0201 44.9061 23.7369 44.9769 23.4584C45.1815 22.7117 45.2706 21.9381 45.2412 21.1645C45.2412 21.0087 45.2412 20.8577 45.2034 20.6925C45.1334 20.0847 44.9458 19.4964 44.6512 18.9602C44.5627 18.7954 44.4618 18.6376 44.3491 18.4882L42.5178 16.048Z" fill="white" />
                        <path opacity="0.15" d="M45.2459 21.1786C45.2459 21.0229 45.2459 20.8718 45.2082 20.7066C45.1381 20.0989 44.9505 19.5106 44.6559 18.9744C44.5675 18.8096 44.4665 18.6518 44.3538 18.5024L42.5178 16.048L41.3378 16.9118H41.3094L41.0215 17.3507L45.1138 22.8826C45.2281 22.3222 45.2725 21.7499 45.2459 21.1786Z" fill="white" />
                        <path d="M18.6487 27.7914C18.3896 28.5331 18.2308 29.3062 18.1767 30.09C18.1014 31.0494 18.296 32.0107 18.7384 32.8654C19.0198 33.3854 19.4073 33.8405 19.8759 34.2011C19.9829 34.2861 20.0962 34.3663 20.2158 34.4418C20.8196 34.8158 21.4866 35.0765 22.184 35.2112C22.4458 35.2657 22.7106 35.3051 22.977 35.3292C23.2622 35.3613 23.549 35.3787 23.836 35.3811C24.2236 35.3859 24.6112 35.3702 24.9971 35.3339C25.4219 35.2962 25.8467 35.2348 26.281 35.1546C26.6066 35.0932 26.937 35.0224 27.2674 34.9374C32.1243 33.7197 37.3871 30.1042 40.5542 25.8278C40.7714 25.5352 40.9743 25.2426 41.1726 24.9452C41.451 24.5204 41.7106 24.0909 41.9419 23.6566C42.1354 23.3026 42.3054 22.9534 42.4564 22.6088C42.6074 22.2642 42.7018 22.0188 42.8057 21.7356C42.9095 21.4524 42.9898 21.1692 43.0653 20.8907C43.2712 20.1442 43.362 19.3707 43.3343 18.5968C43.3343 18.441 43.3343 18.29 43.2966 18.1248C43.225 17.5167 43.0359 16.9284 42.7396 16.3926C42.2899 15.5969 41.6013 14.9628 40.7714 14.5801C40.1275 14.2734 39.4366 14.0771 38.7276 13.9995C37.9942 13.9131 37.2537 13.9052 36.5186 13.9759C36.0434 14.0188 35.5708 14.0865 35.1026 14.1789C34.4726 14.3001 33.8501 14.4577 33.2382 14.6509C28.7174 16.1921 24.7094 18.9507 21.6554 22.623C21.2198 23.1474 20.8117 23.694 20.4329 24.2608C20.1214 24.7328 19.8334 25.2048 19.5786 25.6768C19.2077 26.3533 18.8966 27.0608 18.6487 27.7914Z" fill="#F0F0F0" />
                        <path opacity="0.15" d="M39.8746 26.701C40.1106 26.4131 40.3466 26.1205 40.559 25.8278C40.7714 25.5352 40.979 25.2426 41.1773 24.9452C41.4558 24.5204 41.7154 24.0909 41.9466 23.6566C42.1402 23.3026 42.3101 22.9534 42.4611 22.6088C42.6122 22.2642 42.7066 22.0188 42.8104 21.7356C42.9142 21.4524 42.9945 21.1692 43.07 20.8907C43.276 20.1442 43.3667 19.3707 43.339 18.5968C43.339 18.441 43.339 18.29 43.3013 18.1248C43.2297 17.5167 43.0406 16.9284 42.7443 16.3926C42.2946 15.5969 41.606 14.9628 40.7761 14.5801C40.1322 14.2734 39.4413 14.0771 38.7323 13.9995C37.9989 13.9131 37.2584 13.9052 36.5234 13.9759C36.108 14.0137 35.6879 14.075 35.2678 14.1506L39.8746 26.701Z" fill="white" />
                        <path opacity="0.15" d="M43.2069 20.296C43.3163 19.7446 43.3607 19.1822 43.339 18.6204C43.339 18.4646 43.339 18.3136 43.3013 18.1484C43.2297 17.5403 43.0406 16.952 42.7443 16.4162C42.3796 15.7668 41.8506 15.2248 41.2103 14.8444L43.2069 20.296Z" fill="white" />
                        <path opacity="0.15" d="M36.6697 29.9201C37.299 29.3914 37.8969 28.8392 38.4633 28.2634L33.4459 14.632L33.243 14.6934C32.594 14.9 31.956 15.1395 31.3314 15.4108L36.6697 29.9201Z" fill="white" />
                        <path opacity="0.15" d="M30.4818 33.8188C31.2181 33.4884 31.9544 33.1155 32.6766 32.7049L27.1542 17.6811C26.4509 18.1531 25.7665 18.6676 25.1057 19.2057L30.4818 33.8188Z" fill="white" />
                        <path opacity="0.15" d="M28.5135 34.5787C28.9163 34.4466 29.3175 34.3003 29.7171 34.1398L24.4354 19.7768C24.0862 20.0789 23.7463 20.3951 23.4159 20.7208L28.5135 34.5787Z" fill="white" />
                        <path opacity="0.3" d="M19.2151 26.3942C19.127 26.5862 19.0421 26.7797 18.9602 26.9748C18.9319 27.0362 18.9036 27.1022 18.88 27.1636C18.8564 27.225 18.8281 27.2958 18.8045 27.3618C18.7478 27.5034 18.6959 27.6498 18.6487 27.7914C18.3896 28.5331 18.2308 29.3062 18.1767 30.09C18.1014 31.0494 18.296 32.0107 18.7384 32.8654C19.0198 33.3854 19.4073 33.8405 19.8759 34.2011C19.9829 34.2861 20.0962 34.3663 20.2158 34.4418C20.8196 34.8158 21.4866 35.0765 22.184 35.2112L22.4719 35.2631L19.2151 26.3942Z" fill="#E0E0E0" />
                        <path d="M22.4483 25.3794C24.7422 21.1645 30.0428 17.4262 34.5221 16.7749L35.0932 16.7088C39.6858 16.317 41.4699 19.5408 39.0769 23.9493C36.6838 28.3578 31.0293 32.2235 26.432 32.6199L25.8845 32.6483C21.712 32.7474 20.1544 29.5944 22.4483 25.3794Z" fill="#E0E0E0" />
                        <path d="M22.8165 25.3464C22.892 25.2142 22.9675 25.0774 23.0525 24.9405C25.3511 21.0842 30.2174 17.7283 34.3805 17.1289H34.4938L34.9233 17.0817C35.6904 17 36.4659 17.0576 37.2125 17.2516C37.9078 17.4103 38.5307 17.7953 38.9835 18.3463C39.4363 18.8973 39.6933 19.5831 39.7141 20.296C39.7411 21.3543 39.5077 22.4029 39.0344 23.3498C38.9353 23.5622 38.8267 23.7794 38.7087 23.9965C38.5907 24.2136 38.4822 24.3977 38.3594 24.5959C38.0952 25.0215 37.8084 25.4328 37.5004 25.8278C35.2155 28.6851 32.1287 30.794 28.6362 31.8836C28.1871 32.0142 27.7298 32.1151 27.2674 32.1857C27.0503 32.2187 26.8379 32.247 26.6302 32.2612C26.4226 32.2754 26.2762 32.2895 26.1063 32.2942H25.96C24.9933 32.3378 24.0355 32.093 23.2082 31.591C22.0377 30.8122 21.561 29.4339 21.915 27.7017C22.0974 26.8763 22.4012 26.0826 22.8165 25.3464Z" fill="#E0E0E0" />
                        <path opacity="0.13" d="M22.8165 25.3464C22.892 25.2142 22.9675 25.0774 23.0525 24.9405C25.3511 21.0842 30.2174 17.7283 34.3805 17.1289H34.4938L34.9233 17.0817C35.6904 17 36.4659 17.0576 37.2125 17.2516C37.9078 17.4103 38.5307 17.7953 38.9835 18.3463C39.4363 18.8973 39.6933 19.5831 39.7141 20.296C39.7411 21.3543 39.5077 22.4029 39.0344 23.3498C38.9353 23.5622 38.8267 23.7794 38.7087 23.9965C38.5907 24.2136 38.4822 24.3977 38.3594 24.5959C38.0952 25.0215 37.8084 25.4328 37.5004 25.8278C35.2155 28.6851 32.1287 30.794 28.6362 31.8836C28.1871 32.0142 27.7298 32.1151 27.2674 32.1857C27.0503 32.2187 26.8379 32.247 26.6302 32.2612C26.4226 32.2754 26.2762 32.2895 26.1063 32.2942H25.96C24.9933 32.3378 24.0355 32.093 23.2082 31.591C22.0377 30.8122 21.561 29.4339 21.915 27.7017C22.0974 26.8763 22.4012 26.0826 22.8165 25.3464Z" fill="black" />
                        <path d="M23.7746 25.9222C23.6897 26.0591 23.6142 26.196 23.5386 26.3282C23.1245 27.0661 22.8223 27.8614 22.6418 28.6882C22.428 29.5606 22.4991 30.4787 22.8448 31.3078C22.9591 31.414 23.0823 31.5103 23.213 31.5957C24.0394 32.0952 24.9954 32.3383 25.96 32.2942H26.1016C26.2715 32.2942 26.4462 32.2942 26.6255 32.2612C26.8049 32.2282 27.0456 32.2187 27.2627 32.1857C27.7251 32.1151 28.1824 32.0142 28.6315 31.8836C32.128 30.7897 35.2169 28.6739 37.5004 25.809C37.8084 25.4139 38.0952 25.0027 38.3594 24.577C38.4822 24.3788 38.6002 24.1806 38.7087 23.9776C38.8173 23.7746 38.9353 23.5434 39.0344 23.331C39.5047 22.3897 39.738 21.3479 39.7141 20.296C39.6921 19.8573 39.5881 19.4266 39.4073 19.0263C38.9766 18.6498 38.4644 18.3783 37.911 18.2334C37.1641 18.0417 36.389 17.9841 35.6218 18.0634C35.4802 18.0634 35.3339 18.0918 35.1923 18.1154H35.079C30.9396 18.7101 26.0733 22.066 23.7746 25.9222Z" fill="#E0E0E0" />
                        <path d="M20.2299 34.456C23.7369 36.4195 28.3153 34.8478 31.7326 33.1391C36.816 30.3921 41.4086 26.1299 43.0228 20.8671C43.4193 19.3898 43.4948 17.7614 42.749 16.3973C43.5278 17.7566 43.509 19.4039 43.1219 20.9002C41.9042 25.2378 38.4774 28.8911 34.6259 31.5957C30.6186 34.2058 24.7753 36.9482 20.2299 34.456Z" fill="#F5F5F5" />
                        <path opacity="0.13" d="M27.7819 26.3612L28.6079 27.5742L25.2048 28.4191L24.3788 27.2061L27.7819 26.3612Z" fill="black" />
                        <path opacity="0.13" d="M27.291 25.5871C27.6686 26.281 28.9194 26.4556 29.9767 26.5925L30.6706 26.6774C30.7987 26.5781 30.9061 26.4546 30.9868 26.314C31.3691 25.6438 30.6706 25.5588 29.1507 25.37C28.6367 25.3172 28.1272 25.2273 27.6262 25.101C27.7819 24.7422 27.7111 24.3174 26.4698 24.3646L27.291 25.5871Z" fill="black" />
                        <path opacity="0.2" d="M35.2206 18.1956L36.0466 19.4086L35.2726 20.7727L34.4513 19.555L35.2206 18.1956Z" fill="black" />
                        <path opacity="0.13" d="M30.2599 22.4342C30.5667 21.8961 31.2653 21.6082 32.0913 21.5421C33.4082 21.4382 33.5403 22.0707 33.3704 22.5569L33.5167 22.7693C33.3159 22.747 33.1133 22.747 32.9126 22.7693C32.3554 22.7882 31.8178 22.9795 31.3738 23.3168C30.5006 23.1988 29.9154 23.0478 30.2599 22.4342Z" fill="black" />
                        <path opacity="0.13" d="M36.6178 21.1362C36.7098 21.2731 36.8416 21.3785 36.9954 21.4382C36.9954 21.5185 36.9623 21.5987 36.9387 21.6837L37.76 22.8967C37.8408 22.6865 37.8723 22.4606 37.8518 22.2363C37.8313 22.0121 37.7596 21.7955 37.642 21.6034L36.816 20.3904C36.816 20.3904 36.377 20.735 36.6178 21.1362Z" fill="black" />
                        <path d="M29.1507 25.3747C30.6706 25.5588 31.3691 25.6438 30.9868 26.3187C30.9061 26.4594 30.7987 26.5828 30.6706 26.6822C31.6995 26.8238 32.129 26.9748 31.8128 27.5365C31.5815 27.9471 31.0057 28.32 29.9578 28.4002C28.91 28.4805 28.4286 28.1595 28.6079 27.579L25.2048 28.4191V28.5182C25.0408 28.4812 24.8722 28.4685 24.7045 28.4805L25.3558 29.467L25.4172 29.5614C25.6339 29.824 25.9226 30.0176 26.2479 30.1183C26.6948 30.2638 27.161 30.3418 27.6309 30.3496L26.0497 30.4723L26.8757 31.6901L27.9282 31.624L29.3773 31.5107L30.1466 30.1514C32.4217 29.7549 34.2814 28.7354 35.1876 27.1211C35.603 26.3895 35.6313 25.8798 35.4189 25.5163L35.3575 25.4266L34.659 24.426C34.5459 24.5584 34.4796 24.7242 34.4702 24.898C34.1264 24.7916 33.7743 24.7142 33.4176 24.6667C32.0016 24.4638 30.5856 24.5251 31.0906 23.6566C31.1636 23.523 31.2613 23.4045 31.3786 23.3074C30.5054 23.2035 29.9201 23.0525 30.2646 22.4389C30.5714 21.9008 31.27 21.6129 32.096 21.5468C33.4129 21.443 33.545 22.0754 33.3798 22.5616L33.5214 22.774L34.2011 23.7746L37.76 22.8967L36.934 21.6837C36.99 21.4966 37.0217 21.303 37.0284 21.1078C36.4715 20.8792 35.8746 20.7636 35.2726 20.768L35.5888 20.2158L33.545 19.5974L32.7757 20.9568C30.4723 21.3722 28.4474 22.4247 27.5176 24.0626C27.3527 24.335 27.2406 24.636 27.1872 24.9499C27.8209 25.1748 28.4808 25.3176 29.1507 25.3747Z" fill="#E0E0E0" />
                        <path opacity="0.1" d="M29.1507 25.3747C30.6706 25.5588 31.3691 25.6438 30.9868 26.3187C30.9061 26.4594 30.7987 26.5828 30.6706 26.6822C31.6995 26.8238 32.129 26.9748 31.8128 27.5365C31.5815 27.9471 31.0057 28.32 29.9578 28.4002C28.91 28.4805 28.4286 28.1595 28.6079 27.579L25.2048 28.4191V28.5182C25.0408 28.4812 24.8722 28.4685 24.7045 28.4805L25.3558 29.467L25.4172 29.5614C25.6339 29.824 25.9226 30.0176 26.2479 30.1183C26.6948 30.2638 27.161 30.3418 27.6309 30.3496L26.0497 30.4723L26.8757 31.6901L27.9282 31.624L29.3773 31.5107L30.1466 30.1514C32.4217 29.7549 34.2814 28.7354 35.1876 27.1211C35.603 26.3895 35.6313 25.8798 35.4189 25.5163L35.3575 25.4266L34.659 24.426C34.5459 24.5584 34.4796 24.7242 34.4702 24.898C34.1264 24.7916 33.7743 24.7142 33.4176 24.6667C32.0016 24.4638 30.5856 24.5251 31.0906 23.6566C31.1636 23.523 31.2613 23.4045 31.3786 23.3074C30.5054 23.2035 29.9201 23.0525 30.2646 22.4389C30.5714 21.9008 31.27 21.6129 32.096 21.5468C33.4129 21.443 33.545 22.0754 33.3798 22.5616L33.5214 22.774L34.2011 23.7746L37.76 22.8967L36.934 21.6837C36.99 21.4966 37.0217 21.303 37.0284 21.1078C36.4715 20.8792 35.8746 20.7636 35.2726 20.768L35.5888 20.2158L33.545 19.5974L32.7757 20.9568C30.4723 21.3722 28.4474 22.4247 27.5176 24.0626C27.3527 24.335 27.2406 24.636 27.1872 24.9499C27.8209 25.1748 28.4808 25.3176 29.1507 25.3747Z" fill="black" />
                        <g opacity="0.2">
                            <path opacity="0.2" d="M30.1466 30.1702C31.4397 29.9191 32.6653 29.398 33.7433 28.641C34.258 28.263 34.6994 27.7943 35.046 27.258C35.2208 27.0032 35.3591 26.7252 35.4566 26.432C35.5081 26.2864 35.5295 26.1319 35.5198 25.9778C35.5101 25.8237 35.4693 25.6732 35.4 25.5352C35.4797 25.6688 35.5313 25.8172 35.5516 25.9714C35.5718 26.1256 35.5604 26.2824 35.518 26.432C35.4375 26.7422 35.3102 27.0382 35.1404 27.3099C34.7978 27.8738 34.3509 28.3672 33.8235 28.7637C33.283 29.158 32.6905 29.4757 32.063 29.7077C31.4426 29.9302 30.8002 30.0852 30.1466 30.1702Z" fill="black" />
                        </g>
                        <path d="M27.1872 24.9546C27.8211 25.1779 28.481 25.3191 29.1507 25.3747C30.6706 25.5588 31.3691 25.6438 30.9868 26.3187C30.9061 26.4593 30.7987 26.5828 30.6706 26.6822C30.2186 26.9984 29.6833 27.1742 29.1318 27.1872C28.0179 27.2722 27.6026 26.9418 27.7819 26.3659L24.3788 27.2108C24.2895 27.5291 24.3205 27.8691 24.4659 28.1659C24.6112 28.4628 24.8608 28.6957 25.167 28.8203C25.6834 29.0404 26.2388 29.1543 26.8002 29.1554L26.0308 30.5148L27.612 30.3921L28.556 30.3213L29.3254 28.9572C31.6004 28.5607 33.4601 27.5412 34.371 25.9317C34.5478 25.6428 34.6665 25.3221 34.7203 24.9877C34.8478 23.9634 33.7763 23.633 32.5963 23.4537C32.1715 23.3923 31.7467 23.3546 31.3786 23.3074C30.5054 23.2035 29.9201 23.0525 30.2646 22.4389C30.5714 21.9008 31.27 21.6129 32.096 21.5468C33.4129 21.443 33.545 22.0754 33.3751 22.5616L36.9434 21.6884C36.9994 21.5013 37.0312 21.3078 37.0378 21.1126C37.0378 20.3951 36.5658 19.9798 35.8531 19.7626C35.3926 19.6306 34.9162 19.5623 34.4371 19.5597L35.2065 18.2003L32.7096 18.3844L31.9355 19.7532C29.6322 20.1686 27.6073 21.2211 26.6822 22.859C26.0261 24.0342 26.432 24.629 27.1872 24.9546Z" fill="#E0E0E0" />
                        <path d="M27.1872 24.9546C27.8211 25.1779 28.481 25.3191 29.1507 25.3747C30.6706 25.5588 31.3691 25.6438 30.9868 26.3187C30.9061 26.4593 30.7987 26.5828 30.6706 26.6822C30.2186 26.9984 29.6833 27.1742 29.1318 27.1872C28.0179 27.2722 27.6026 26.9418 27.7819 26.3659L24.3788 27.2108C24.2895 27.5291 24.3205 27.8691 24.4659 28.1659C24.6112 28.4628 24.8608 28.6957 25.167 28.8203C25.6834 29.0404 26.2388 29.1543 26.8002 29.1554L26.0308 30.5148L27.612 30.3921L28.556 30.3213L29.3254 28.9572C31.6004 28.5607 33.4601 27.5412 34.371 25.9317C34.5478 25.6428 34.6665 25.3221 34.7203 24.9877C34.8478 23.9634 33.7763 23.633 32.5963 23.4537C32.1715 23.3923 31.7467 23.3546 31.3786 23.3074C30.5054 23.2035 29.9201 23.0525 30.2646 22.4389C30.5714 21.9008 31.27 21.6129 32.096 21.5468C33.4129 21.443 33.545 22.0754 33.3751 22.5616L36.9434 21.6884C36.9994 21.5013 37.0312 21.3078 37.0378 21.1126C37.0378 20.3951 36.5658 19.9798 35.8531 19.7626C35.3926 19.6306 34.9162 19.5623 34.4371 19.5597L35.2065 18.2003L32.7096 18.3844L31.9355 19.7532C29.6322 20.1686 27.6073 21.2211 26.6822 22.859C26.0261 24.0342 26.432 24.629 27.1872 24.9546Z" fill="#F0F0F0" />
                        <path opacity="0.15" d="M33.6206 22.4955L35.8106 21.9574L35.046 19.5974C34.8525 19.5974 34.6542 19.5597 34.4513 19.555L34.8242 18.88L34.607 18.2192L32.719 18.3608L32.4453 18.8328L33.6206 22.4955Z" fill="white" />
                        <path opacity="0.15" d="M27.1872 24.9546C27.8211 25.1779 28.481 25.3191 29.1507 25.3747L29.6227 25.4361L28.2539 21.1881C27.6093 21.6192 27.0704 22.1904 26.6774 22.859C26.576 23.0419 26.4938 23.2349 26.432 23.4348L26.8662 24.7847C26.9696 24.8479 27.0768 24.9047 27.1872 24.9546Z" fill="white" />
                        <path opacity="0.15" d="M30.1372 26.9701C29.8138 27.0881 29.4753 27.1597 29.1318 27.1825C28.0179 27.2674 27.6026 26.937 27.7819 26.3612L27.409 26.4556L28.6174 30.208L29.3206 28.9572C29.781 28.8792 30.2352 28.7688 30.68 28.6268L30.1372 26.9701Z" fill="white" />
                        <path opacity="0.15" d="M26.045 29.0705C26.295 29.1114 26.5471 29.1382 26.8002 29.1507L26.3282 29.972L26.4934 30.4723L27.6073 30.3874H27.8008L26.6019 26.668L25.3606 26.9748L26.045 29.0705Z" fill="white" />
                        <path opacity="0.15" d="M34.0595 23.8502L34.5834 25.488C34.6462 25.3365 34.6906 25.1781 34.7156 25.016C34.7593 24.7757 34.7176 24.5276 34.5978 24.3148C34.478 24.1019 34.2876 23.9375 34.0595 23.8502Z" fill="white" />
                        <path d="M29.3254 28.9525L30.1466 30.1702L29.3773 31.5296L28.5513 30.3166L29.3254 28.9525Z" fill="#E0E0E0" />
                        <path opacity="0.13" d="M29.3254 28.9525L30.1466 30.1702L29.3773 31.5296L28.5513 30.3166L29.3254 28.9525Z" fill="black" />
                        <path d="M31.9497 19.7485L31.6854 19.8051L31.3786 19.8806C31.2653 19.909 31.1284 19.9373 30.9868 19.9845C30.8452 20.0317 30.6894 20.0647 30.5148 20.1214L30.2646 20.2063L30.0098 20.3007L29.7502 20.4046L29.4811 20.5226C29.3065 20.6028 29.1271 20.6878 28.9572 20.7869C28.7873 20.886 28.6174 20.9804 28.4474 21.0842C28.2775 21.1881 28.1312 21.2966 27.9754 21.4099C27.8197 21.5232 27.697 21.6412 27.5695 21.7498L27.3949 21.9197L27.3146 22.0046L27.2391 22.0849C27.1447 22.1934 27.055 22.2926 26.989 22.387C26.9229 22.4814 26.8521 22.5616 26.8096 22.6324L26.668 22.8542L26.8002 22.6277C26.8426 22.5569 26.904 22.4719 26.9701 22.3775C27.0465 22.2696 27.1284 22.1656 27.2155 22.066L27.2863 21.981L27.3713 21.8961L27.5412 21.7214C27.6686 21.6129 27.7914 21.4854 27.9424 21.3769C28.0934 21.2683 28.2445 21.1503 28.4144 21.0418C28.5843 20.9332 28.7495 20.8294 28.9242 20.7397C29.0988 20.65 29.2782 20.5603 29.4575 20.4754C29.5472 20.4329 29.6369 20.3998 29.7266 20.3621C29.8127 20.3226 29.9009 20.2879 29.9909 20.2582L30.2458 20.1638L30.5006 20.0836C30.6611 20.0317 30.8169 19.9939 30.9726 19.9514C31.1284 19.909 31.2511 19.8854 31.3691 19.8618C31.4871 19.8382 31.5862 19.8146 31.6712 19.8004L31.9497 19.7485Z" fill="white" />
                        <path d="M28.5513 30.3166L26.0308 30.5101L26.8002 29.1507L26.2857 30.3496L28.5513 30.3166Z" fill="#FAFAFA" />
                        <path d="M34.4513 19.555L35.0177 18.3278L32.7238 18.3797L35.2206 18.1956L34.4513 19.555Z" fill="#FAFAFA" />
                        <path d="M18.4458 101.112L17.379 101.9L18.9933 104.076C19.1851 104.342 19.4121 104.58 19.6682 104.784C19.7588 104.855 19.8533 104.922 19.9514 104.982C20.4489 105.293 20.9993 105.509 21.5751 105.619C21.7917 105.665 22.0107 105.699 22.2312 105.719C22.4661 105.747 22.7025 105.762 22.9392 105.761C23.2507 105.761 23.5717 105.761 23.8832 105.723C24.1947 105.686 24.5865 105.643 24.9452 105.577C25.2142 105.525 25.4833 105.464 25.7618 105.398C29.7832 104.388 34.1445 101.395 36.7641 97.8456C36.9434 97.6049 37.1134 97.3736 37.2738 97.114C37.5051 96.7647 37.7458 96.406 37.911 96.0473C38.0762 95.6886 38.2131 95.4667 38.3406 95.1788C38.468 94.8909 38.5435 94.7068 38.6285 94.4567C38.7134 94.2065 38.7842 93.9847 38.8409 93.7581C39.0102 93.1437 39.085 92.507 39.0627 91.8701C39.0627 91.7427 39.0627 91.6199 39.0344 91.4972C38.9687 90.9995 38.8085 90.5188 38.5624 90.0812C38.4862 89.9492 38.4027 89.8216 38.3122 89.6989L36.816 87.5985L35.872 88.3159C35.7351 88.2876 35.5888 88.264 35.4425 88.2451C34.8338 88.1784 34.2197 88.1784 33.6111 88.2451C33.2164 88.2793 32.824 88.336 32.4358 88.4151C31.9163 88.5179 31.4026 88.6486 30.8971 88.8068C27.1533 90.0789 23.8319 92.3569 21.2966 95.3912C20.9374 95.8271 20.6003 96.2808 20.2866 96.7506C20.027 97.1329 19.8146 97.5247 19.5786 97.9164C19.273 98.4789 19.0156 99.0663 18.8092 99.6723C18.6408 100.139 18.5191 100.621 18.4458 101.112Z" fill="#E0E0E0" />
                        <path opacity="0.15" d="M35.0507 99.8516L31.7798 95.4337L30.2882 96.8025L33.5639 101.225C34.0831 100.786 34.5834 100.328 35.0507 99.8516Z" fill="white" />
                        <path opacity="0.05" d="M18.9933 104.076C19.1851 104.342 19.4121 104.58 19.6682 104.784C19.7588 104.855 19.8533 104.922 19.9514 104.982C20.4489 105.293 20.9993 105.509 21.5751 105.619C21.6506 105.619 21.7309 105.648 21.8111 105.662L18.4458 101.117L17.379 101.905L18.9933 104.076Z" fill="black" />
                        <path opacity="0.15" d="M22.8778 98.0486L21.9055 98.4639L26.8143 105.072C27.1447 104.963 27.4751 104.841 27.8055 104.713L22.8778 98.0486Z" fill="white" />
                        <path opacity="0.15" d="M30.2599 103.528L26.0119 97.789L24.4826 99.12L28.438 104.468C29.0609 104.186 29.669 103.872 30.2599 103.528Z" fill="white" />
                        <path opacity="0.15" d="M36.816 87.5985L35.872 88.3159C35.7351 88.2876 35.5888 88.264 35.4425 88.2451C34.8338 88.1784 34.2197 88.1784 33.6111 88.2451C33.2164 88.2793 32.824 88.336 32.4358 88.4151C31.9163 88.5179 31.4026 88.6486 30.8971 88.8068C30.4251 88.9626 29.9154 89.1467 29.4245 89.3543L36.2449 98.5772C36.4431 98.3381 36.6319 98.0974 36.8113 97.8551C36.9906 97.6143 37.1606 97.3831 37.321 97.1235C37.5523 96.7742 37.793 96.4155 37.9582 96.0567C38.1234 95.698 38.2603 95.4762 38.3878 95.1883C38.5152 94.9003 38.5907 94.7163 38.6757 94.4661C38.7606 94.2159 38.8314 93.9941 38.8881 93.7675C39.0574 93.1531 39.1322 92.5165 39.1099 91.8795C39.1099 91.7521 39.1099 91.6294 39.0816 91.5067C39.0159 91.0089 38.8557 90.5283 38.6096 90.0907C38.5334 89.9587 38.4499 89.831 38.3594 89.7083L36.816 87.5985Z" fill="white" />
                        <path opacity="0.15" d="M39.0863 91.8465C39.0863 91.719 39.0863 91.5963 39.058 91.4736C38.9923 90.9759 38.8321 90.4952 38.586 90.0576C38.5098 89.9256 38.4263 89.798 38.3358 89.6753L36.816 87.5985L35.872 88.3159L35.6313 88.6794L39.0202 93.2578C39.099 92.7917 39.1212 92.3179 39.0863 91.8465Z" fill="white" />
                        <path d="M17.0628 97.3217C16.8521 97.9313 16.7235 98.5662 16.6805 99.2097C16.6189 100.005 16.7824 100.802 17.1525 101.508C17.3867 101.938 17.7083 102.314 18.0965 102.613C18.187 102.684 18.2816 102.75 18.3797 102.811C18.8766 103.122 19.4272 103.339 20.0034 103.448C20.2198 103.495 20.4389 103.528 20.6594 103.547C20.8943 103.576 21.1308 103.59 21.3674 103.59C21.6742 103.59 21.9952 103.59 22.3114 103.552C22.6277 103.514 23.0147 103.472 23.3734 103.406C23.6472 103.354 23.9162 103.292 24.19 103.226C28.2162 102.216 32.5727 99.2238 35.197 95.6744C35.3764 95.4337 35.5463 95.2024 35.7068 94.9428C35.9381 94.5935 36.1505 94.2348 36.344 93.8761C36.5375 93.5174 36.6461 93.2955 36.7735 93.0076C36.901 92.7197 36.9765 92.5356 37.0614 92.2854C37.1464 92.0353 37.2125 91.8134 37.2738 91.5869C37.4431 90.9724 37.5179 90.3358 37.4957 89.6989C37.4957 89.5714 37.4957 89.4487 37.4674 89.326C37.4015 88.8283 37.2413 88.3477 36.9954 87.91C36.6326 87.2536 36.0752 86.7259 35.4 86.3996C34.8656 86.1486 34.2928 85.989 33.7055 85.9276C33.0985 85.8608 32.4859 85.8608 31.8789 85.9276C31.4845 85.9625 31.0923 86.0176 30.7036 86.0928C30.1819 86.195 29.6665 86.3274 29.1602 86.4893C25.4162 87.7584 22.0955 90.037 19.5644 93.0737C19.1982 93.4973 18.8547 93.94 18.5354 94.4C18.2806 94.787 18.0634 95.1741 17.8322 95.5658C17.5234 96.1268 17.2659 96.7145 17.0628 97.3217Z" fill="#F0F0F0" />
                        <path opacity="0.15" d="M34.6354 96.4202C34.8336 96.1842 35.0224 95.9482 35.2065 95.698C35.3906 95.4479 35.5558 95.226 35.7162 94.9664C35.9475 94.6171 36.1599 94.2584 36.3534 93.8997C36.547 93.541 36.6555 93.3191 36.783 93.0312C36.9104 92.7433 36.9859 92.5592 37.0709 92.3091C37.1558 92.0589 37.2219 91.8371 37.2833 91.6105C37.4526 90.9961 37.5274 90.3594 37.5051 89.7225C37.5051 89.595 37.5051 89.4723 37.4768 89.3496C37.411 88.8519 37.2508 88.3713 37.0048 87.9336C36.6435 87.267 36.0822 86.7305 35.4 86.3996C34.8656 86.1486 34.2928 85.989 33.7055 85.9276C33.0985 85.8608 32.4859 85.8608 31.8789 85.9276C31.5289 85.9582 31.1807 86.007 30.8358 86.0739L34.6354 96.4202Z" fill="white" />
                        <path opacity="0.15" d="M37.4013 91.096C37.4901 90.6391 37.5249 90.1734 37.5051 89.7083C37.5051 89.5809 37.5051 89.4582 37.4768 89.3355C37.411 88.8377 37.2508 88.3571 37.0048 87.9195C36.7026 87.3818 36.2649 86.9326 35.7351 86.6167L37.4013 91.096Z" fill="white" />
                        <path opacity="0.15" d="M31.9874 99.087C32.5066 98.6464 33.0007 98.1886 33.4695 97.7134L29.3159 86.3855L29.146 86.4374C28.6221 86.6026 28.0887 86.8055 27.5601 87.0321L31.9874 99.087Z" fill="white" />
                        <path opacity="0.15" d="M26.8568 102.315C27.4798 102.032 28.0879 101.717 28.6787 101.371L24.105 88.9295C23.5198 89.326 22.9549 89.7477 22.4106 90.1945L26.8568 102.315Z" fill="white" />
                        <path opacity="0.15" d="M25.2284 102.943C25.5588 102.835 25.8939 102.712 26.229 102.58L21.8536 90.6854C21.5657 90.9402 21.2825 91.1951 21.0087 91.4642L25.2284 102.943Z" fill="white" />
                        <path opacity="0.3" d="M17.5301 96.1653C17.4546 96.3305 17.3838 96.4863 17.3177 96.6373C17.3177 96.6892 17.2705 96.7459 17.2516 96.7978C17.2327 96.8497 17.2091 96.9063 17.1902 96.9583C17.1714 97.0102 17.1006 97.1943 17.0628 97.3123C16.8521 97.9218 16.7235 98.5567 16.6805 99.2003C16.6189 99.9956 16.7824 100.792 17.1525 101.499C17.3867 101.929 17.7083 102.305 18.0965 102.603C18.187 102.675 18.2816 102.741 18.3797 102.802C18.8766 103.113 19.4272 103.329 20.0034 103.439C20.0789 103.439 20.1591 103.467 20.2394 103.481L17.5301 96.1653Z" fill="#E0E0E0" />
                        <path d="M20.2063 95.344C22.0943 91.8559 26.4934 88.736 30.208 88.2215C30.3638 88.1979 30.5242 88.1791 30.68 88.1649C34.4843 87.8392 35.9617 90.5249 33.984 94.1593C32.0063 97.7937 27.3146 101.008 23.5103 101.32L23.0383 101.348C19.5927 101.428 18.3089 98.8179 20.2063 95.344Z" fill="#E0E0E0" />
                        <path d="M20.5131 95.3015C20.5745 95.1883 20.6358 95.075 20.7066 94.9617C22.5946 91.771 26.6397 88.9862 30.0664 88.4906H30.1608C30.2788 88.4906 30.4015 88.4575 30.5195 88.4481C31.1523 88.3825 31.7916 88.4305 32.4075 88.5897C32.9858 88.7158 33.5058 89.0303 33.886 89.4839C34.2661 89.9376 34.4849 90.5046 34.5079 91.096C34.5266 91.9727 34.3323 92.8409 33.9415 93.6259C33.8629 93.8021 33.7732 93.9815 33.6725 94.164C33.5828 94.3292 33.4884 94.4944 33.3846 94.6596C33.1647 95.01 32.9268 95.3487 32.6718 95.6744C30.7833 98.0487 28.2269 99.8028 25.3322 100.711C24.9587 100.818 24.5787 100.901 24.1947 100.961C24.0201 100.989 23.8454 101.008 23.6708 101.022C23.4962 101.036 23.3734 101.022 23.2366 101.046H23.128C22.3269 101.087 21.5322 100.884 20.8482 100.465C19.9042 99.8186 19.4842 98.6763 19.7768 97.2462C19.9241 96.5649 20.1723 95.9095 20.5131 95.3015Z" fill="#E0E0E0" />
                        <path opacity="0.13" d="M20.5131 95.3015C20.5745 95.1883 20.6358 95.075 20.7066 94.9617C22.5946 91.771 26.6397 88.9862 30.0664 88.4906H30.1608C30.2788 88.4906 30.4015 88.4575 30.5195 88.4481C31.1523 88.3825 31.7916 88.4305 32.4075 88.5897C32.9858 88.7158 33.5058 89.0303 33.886 89.4839C34.2661 89.9376 34.4849 90.5046 34.5079 91.096C34.5266 91.9727 34.3323 92.8409 33.9415 93.6259C33.8629 93.8021 33.7732 93.9815 33.6725 94.164C33.5828 94.3292 33.4884 94.4944 33.3846 94.6596C33.1647 95.01 32.9268 95.3487 32.6718 95.6744C30.7833 98.0487 28.2269 99.8028 25.3322 100.711C24.9587 100.818 24.5787 100.901 24.1947 100.961C24.0201 100.989 23.8454 101.008 23.6708 101.022C23.4962 101.036 23.3734 101.022 23.2366 101.046H23.128C22.3269 101.087 21.5322 100.884 20.8482 100.465C19.9042 99.8186 19.4842 98.6763 19.7768 97.2462C19.9241 96.5649 20.1723 95.9095 20.5131 95.3015Z" fill="black" />
                        <path d="M21.3061 95.7783C21.2353 95.8915 21.1739 96.0001 21.1126 96.1134C20.768 96.7223 20.5166 97.3793 20.3668 98.0627C20.1889 98.7859 20.2485 99.5472 20.5367 100.234C20.6311 100.32 20.7321 100.399 20.8388 100.47C21.5254 100.891 22.3237 101.093 23.128 101.05H23.246C23.3829 101.05 23.5292 101.05 23.6802 101.027C23.8313 101.003 24.0295 100.994 24.2042 100.966C24.5882 100.906 24.9681 100.822 25.3417 100.715C28.2364 99.8075 30.7928 98.0534 32.6813 95.6791C32.9362 95.3534 33.1741 95.0147 33.394 94.6643C33.4978 94.4991 33.5922 94.3339 33.6819 94.1687C33.7716 94.0035 33.8707 93.8053 33.951 93.6307C34.3417 92.8456 34.536 91.9774 34.5174 91.1007C34.497 90.7373 34.4106 90.3806 34.2625 90.0482C33.9051 89.7363 33.4801 89.5117 33.0211 89.3921C32.4052 89.2329 31.7655 89.1866 31.1331 89.2552L30.7791 89.293H30.6847C27.2391 89.8027 23.2082 92.5828 21.3061 95.7783Z" fill="#E0E0E0" />
                        <path d="M18.3702 102.83C21.2778 104.458 25.0679 103.156 27.8952 101.74C32.1054 99.4646 35.9192 95.9387 37.2455 91.5916C37.6462 90.3746 37.5651 89.0502 37.019 87.8911C37.6041 89.0462 37.7158 90.3838 37.3305 91.6199C36.3251 95.2118 33.4884 98.2279 30.2835 100.475C26.9748 102.636 22.1321 104.907 18.3702 102.83Z" fill="#F5F5F5" />
                        <path opacity="0.13" d="M24.6242 96.1417L25.3086 97.1471L22.4908 97.8456L21.8064 96.8403L24.6242 96.1417Z" fill="black" />
                        <path opacity="0.13" d="M24.2183 95.4951C24.5298 96.0756 25.5635 96.2172 26.4414 96.3305L27.0173 96.4013C27.1216 96.3192 27.2096 96.2183 27.2769 96.1039C27.5931 95.547 27.0173 95.4715 25.757 95.3251C25.3322 95.2792 24.9111 95.2035 24.4968 95.0986C24.6242 94.8012 24.5629 94.4519 23.5528 94.4897L24.2183 95.4951Z" fill="black" />
                        <path opacity="0.2" d="M30.7838 89.3779L31.4682 90.3833L30.8263 91.5114L30.1466 90.5013L30.7838 89.3779Z" fill="black" />
                        <path opacity="0.13" d="M26.6774 92.8896C26.8516 92.6531 27.0803 92.4622 27.3441 92.3332C27.6079 92.2042 27.899 92.1408 28.1926 92.1486C29.2829 92.0636 29.3914 92.5875 29.2546 92.9887L29.3726 93.1634C29.2062 93.1461 29.0386 93.1461 28.8722 93.1634C28.4083 93.1826 27.9623 93.3477 27.5978 93.6354C26.8757 93.5221 26.3895 93.3947 26.6774 92.8896Z" fill="black" />
                        <path opacity="0.13" d="M31.9402 91.8135C32.0192 91.9257 32.1291 92.0126 32.2565 92.0636C32.2565 92.1297 32.2282 92.1958 32.2093 92.2666L32.889 93.2719C32.9574 93.0983 32.9845 92.911 32.9681 92.7251C32.9517 92.5391 32.8923 92.3595 32.7946 92.2005L32.096 91.1951C32.096 91.1951 31.742 91.4783 31.9402 91.8135Z" fill="black" />
                        <path d="M25.757 95.344C27.0173 95.4903 27.5931 95.5658 27.2769 96.1228C27.2096 96.2372 27.1216 96.3381 27.0173 96.4202C27.8669 96.5382 28.2209 96.6656 27.9613 97.1282C27.7678 97.468 27.291 97.7748 26.4273 97.8456C25.5635 97.9164 25.1576 97.6426 25.3039 97.1659L22.4719 97.8645V97.9447C22.3373 97.9145 22.199 97.9033 22.0613 97.9117L22.5994 98.7282C22.615 98.756 22.6323 98.7828 22.6513 98.8085C22.8283 99.0315 23.0685 99.196 23.3404 99.2805C23.7109 99.4019 24.0975 99.4671 24.4874 99.474L23.1752 99.592L23.8549 100.597L24.7422 100.527L25.9458 100.432L26.583 99.3088C28.471 98.9784 30.005 98.1335 30.7602 96.7978C31.1001 96.1936 31.1237 95.7735 30.949 95.4715L30.8971 95.4006L30.3166 94.5699C30.2277 94.6786 30.1749 94.8122 30.1655 94.9522C29.8801 94.8643 29.5881 94.7996 29.2923 94.7587C28.117 94.5935 26.9559 94.6455 27.3618 93.9233C27.4245 93.8139 27.506 93.7164 27.6026 93.6354C26.8804 93.5504 26.3942 93.423 26.6822 92.9179C26.8563 92.6814 27.085 92.4906 27.3488 92.3615C27.6126 92.2325 27.9037 92.1692 28.1973 92.1769C29.2876 92.0919 29.3962 92.6159 29.2593 93.0171L29.3773 93.1917L29.939 94.0224L32.8937 93.3003L32.214 92.2949C32.2591 92.1414 32.2844 91.9828 32.2895 91.8229C31.8286 91.6334 31.3341 91.5386 30.8358 91.5444L31.0906 91.0724L29.4009 90.5627L28.7637 91.6907C26.8757 92.0306 25.1765 92.9038 24.4118 94.2584C24.2731 94.4835 24.18 94.7337 24.1381 94.9947C24.6608 95.1792 25.2048 95.2966 25.757 95.344Z" fill="#E0E0E0" />
                        <path opacity="0.1" d="M25.757 95.344C27.0173 95.4903 27.5931 95.5658 27.2769 96.1228C27.2096 96.2372 27.1216 96.3381 27.0173 96.4202C27.8669 96.5382 28.2209 96.6656 27.9613 97.1282C27.7678 97.468 27.291 97.7748 26.4273 97.8456C25.5635 97.9164 25.1576 97.6426 25.3039 97.1659L22.4719 97.8645V97.9447C22.3373 97.9145 22.199 97.9033 22.0613 97.9117L22.5994 98.7282C22.615 98.756 22.6323 98.7828 22.6513 98.8085C22.8283 99.0315 23.0685 99.196 23.3404 99.2805C23.7109 99.4019 24.0975 99.4671 24.4874 99.474L23.1752 99.592L23.8549 100.597L24.7422 100.527L25.9458 100.432L26.583 99.3088C28.471 98.9784 30.005 98.1335 30.7602 96.7978C31.1001 96.1936 31.1237 95.7735 30.949 95.4715L30.8971 95.4006L30.3166 94.5699C30.2277 94.6786 30.1749 94.8122 30.1655 94.9522C29.8801 94.8643 29.5881 94.7996 29.2923 94.7587C28.117 94.5935 26.9559 94.6455 27.3618 93.9233C27.4245 93.8139 27.506 93.7164 27.6026 93.6354C26.8804 93.5504 26.3942 93.423 26.6822 92.9179C26.8563 92.6814 27.085 92.4906 27.3488 92.3615C27.6126 92.2325 27.9037 92.1692 28.1973 92.1769C29.2876 92.0919 29.3962 92.6159 29.2593 93.0171L29.3773 93.1917L29.939 94.0224L32.8937 93.3003L32.214 92.2949C32.2591 92.1414 32.2844 91.9828 32.2895 91.8229C31.8286 91.6334 31.3341 91.5386 30.8358 91.5444L31.0906 91.0724L29.4009 90.5627L28.7637 91.6907C26.8757 92.0306 25.1765 92.9038 24.4118 94.2584C24.2731 94.4835 24.18 94.7337 24.1381 94.9947C24.6608 95.1792 25.2048 95.2966 25.757 95.344Z" fill="black" />
                        <g opacity="0.2">
                            <path opacity="0.2" d="M26.583 99.2946C27.6528 99.084 28.667 98.6533 29.5614 98.0297C29.987 97.7147 30.353 97.3263 30.6422 96.8827C30.7858 96.6682 30.8986 96.4347 30.9774 96.1889C31.0216 96.0712 31.0419 95.9459 31.037 95.8202C31.0322 95.6946 31.0022 95.5712 30.949 95.4573C31.0144 95.5679 31.0564 95.6907 31.0727 95.8181C31.0889 95.9455 31.0789 96.0749 31.0434 96.1983C30.9773 96.4551 30.8722 96.7002 30.7319 96.9252C30.4493 97.3928 30.0791 97.8015 29.6416 98.1288C29.1939 98.4536 28.703 98.7142 28.1831 98.9029C27.6663 99.0937 27.1295 99.2251 26.583 99.2946Z" fill="black" />
                        </g>
                        <path d="M24.1334 94.9758C24.6577 95.1601 25.2033 95.2775 25.757 95.3251C27.0173 95.4715 27.5931 95.547 27.2769 96.1039C27.2096 96.2183 27.1216 96.3192 27.0173 96.4013C26.6433 96.6635 26.1996 96.8082 25.7429 96.8166C24.7989 96.8922 24.4779 96.6184 24.6242 96.1417L21.7922 96.8402C21.7171 97.1058 21.7437 97.3898 21.8668 97.6368C21.9898 97.8838 22.2005 98.076 22.4578 98.176C22.8862 98.3584 23.3468 98.4531 23.8124 98.4545L23.1752 99.592L24.4826 99.4882L25.2614 99.4268L25.8986 98.2987C27.7866 97.973 29.3206 97.1235 30.0758 95.7971C30.2207 95.5561 30.3199 95.2905 30.3685 95.0136C30.4723 94.164 29.6038 93.8903 28.6079 93.7392C28.2539 93.692 27.9046 93.659 27.5978 93.6212C26.8757 93.5363 26.3895 93.4088 26.6774 92.9038C26.8516 92.6673 27.0803 92.4764 27.3441 92.3474C27.6079 92.2183 27.899 92.155 28.1926 92.1627C29.2829 92.0778 29.3914 92.6017 29.2546 93.0029L32.2093 92.2807C32.2544 92.1272 32.2797 91.9686 32.2848 91.8087C32.2848 91.214 31.893 90.8647 31.303 90.6901C30.9266 90.5832 30.5379 90.5261 30.1466 90.5202L30.7838 89.3968L28.7165 89.5478L28.0746 90.6854C26.1866 91.0252 24.4921 91.8984 23.7227 93.2531C23.1705 94.2159 23.5009 94.7021 24.1334 94.9758Z" fill="#E0E0E0" />
                        <path d="M24.1334 94.9758C24.6577 95.1601 25.2033 95.2775 25.757 95.3251C27.0173 95.4715 27.5931 95.547 27.2769 96.1039C27.2096 96.2183 27.1216 96.3192 27.0173 96.4013C26.6433 96.6635 26.1996 96.8082 25.7429 96.8166C24.7989 96.8922 24.4779 96.6184 24.6242 96.1417L21.7922 96.8402C21.7171 97.1058 21.7437 97.3898 21.8668 97.6368C21.9898 97.8838 22.2005 98.076 22.4578 98.176C22.8862 98.3584 23.3468 98.4531 23.8124 98.4545L23.1752 99.592L24.4826 99.4882L25.2614 99.4268L25.8986 98.2987C27.7866 97.973 29.3206 97.1235 30.0758 95.7971C30.2207 95.5561 30.3199 95.2905 30.3685 95.0136C30.4723 94.164 29.6038 93.8903 28.6079 93.7392C28.2539 93.692 27.9046 93.659 27.5978 93.6212C26.8757 93.5363 26.3895 93.4088 26.6774 92.9038C26.8516 92.6673 27.0803 92.4764 27.3441 92.3474C27.6079 92.2183 27.899 92.155 28.1926 92.1627C29.2829 92.0778 29.3914 92.6017 29.2546 93.0029L32.2093 92.2807C32.2544 92.1272 32.2797 91.9686 32.2848 91.8087C32.2848 91.214 31.893 90.8647 31.303 90.6901C30.9266 90.5832 30.5379 90.5261 30.1466 90.5202L30.7838 89.3968L28.7165 89.5478L28.0746 90.6854C26.1866 91.0252 24.4921 91.8984 23.7227 93.2531C23.1705 94.2159 23.5009 94.7021 24.1334 94.9758Z" fill="#F0F0F0" />
                        <path opacity="0.15" d="M29.4575 92.9368L31.27 92.4931L30.6422 90.5391C30.4818 90.5391 30.3166 90.5107 30.1466 90.5013L30.4534 89.9585L30.2788 89.4157L28.7165 89.529L28.4899 89.9349L29.4575 92.9368Z" fill="white" />
                        <path opacity="0.15" d="M24.1334 94.9759C24.6576 95.1607 25.2032 95.2781 25.757 95.3251L26.163 95.3723L25.016 91.8512C24.4819 92.2083 24.0351 92.6809 23.7086 93.2342C23.6214 93.3843 23.5487 93.5423 23.4914 93.7062L23.8549 94.8248C23.9429 94.8835 24.0361 94.9341 24.1334 94.9759Z" fill="white" />
                        <path opacity="0.15" d="M26.5736 96.6467C26.3064 96.7435 26.0266 96.8007 25.7429 96.8167C24.7989 96.8922 24.4779 96.6184 24.6242 96.1417L24.3127 96.2172L25.3134 99.323L25.8986 98.2846C26.2773 98.2211 26.6511 98.1312 27.0173 98.0155L26.5736 96.6467Z" fill="white" />
                        <path opacity="0.15" d="M23.1846 98.3837C23.3922 98.4196 23.6019 98.4417 23.8124 98.4498L23.4254 99.12L23.5575 99.5354L24.5015 99.4646H24.662L23.6708 96.3871L22.6418 96.642L23.1846 98.3837Z" fill="white" />
                        <path opacity="0.15" d="M29.821 94.0602L30.2552 95.4054C30.3074 95.2745 30.3454 95.1384 30.3685 94.9995C30.3977 94.804 30.3594 94.6043 30.2599 94.4335C30.1603 94.2628 30.0055 94.131 29.821 94.0602Z" fill="white" />
                        <path d="M25.8986 98.2846L26.583 99.2946L25.9458 100.418L25.2614 99.4126L25.8986 98.2846Z" fill="#E0E0E0" />
                        <path opacity="0.13" d="M25.8986 98.2846L26.583 99.2946L25.9458 100.418L25.2614 99.4126L25.8986 98.2846Z" fill="black" />
                        <path d="M28.0746 90.6665L27.8574 90.7137L27.6026 90.7703L27.2769 90.8553C27.1589 90.8931 27.0314 90.9261 26.8993 90.9733L26.6916 91.0394C26.6222 91.0697 26.5513 91.0965 26.4792 91.1196L26.2621 91.2093L26.045 91.3037C25.8986 91.3698 25.7523 91.4406 25.6107 91.5208C25.4691 91.601 25.3275 91.686 25.1906 91.7663C25.0538 91.8465 24.9263 91.9456 24.8083 92.04C24.6903 92.1344 24.577 92.2335 24.4732 92.3185L24.3269 92.4601L24.2608 92.5309L24.1994 92.597C24.1192 92.6914 24.0437 92.7716 23.987 92.8519C23.9304 92.9321 23.8785 92.9935 23.8407 93.0548L23.7227 93.2342L23.836 93.0501C23.869 92.9887 23.921 92.9227 23.9729 92.8424C24.0371 92.752 24.1065 92.6654 24.1806 92.5828L24.2372 92.512L24.3033 92.4412L24.4449 92.2996C24.5534 92.2052 24.6573 92.1014 24.78 92.0117C24.9027 91.9125 25.0303 91.8195 25.1623 91.7332C25.2992 91.6483 25.4408 91.5586 25.5871 91.4831C25.7334 91.4075 25.8798 91.332 26.0261 91.2659L26.2479 91.1715L26.465 91.0866L26.6822 91.0063L26.8898 90.9403C27.022 90.8978 27.1542 90.8647 27.2722 90.8317C27.3902 90.7987 27.5034 90.7798 27.6026 90.7562L27.8527 90.7042L28.0746 90.6665Z" fill="white" />
                        <path d="M25.2614 99.4126L23.1752 99.5779L23.8124 98.4498L23.3829 99.441L25.2614 99.4126Z" fill="#FAFAFA" />
                        <path d="M30.1466 90.5013L30.6186 89.4865L28.7165 89.529L30.7838 89.3779L30.1466 90.5013Z" fill="#FAFAFA" />
                        <path d="M128.294 160.102H132.292V168.268C132.295 169.249 132.163 170.226 131.9 171.171C131.806 171.506 131.693 171.836 131.57 172.167C130.921 173.812 129.976 175.324 128.78 176.627C128.335 177.125 127.862 177.597 127.364 178.043C126.838 178.531 126.287 178.991 125.712 179.421C124.94 180.011 124.137 180.557 123.305 181.059C122.399 181.611 121.455 182.131 120.473 182.612C119.728 182.98 118.963 183.325 118.17 183.646C106.592 188.437 90.624 189.017 77.88 185.133C77.0115 184.873 76.1619 184.585 75.3312 184.278C74.137 183.844 72.9712 183.367 71.862 182.862C70.951 182.433 70.0826 181.989 69.266 181.522C68.5627 181.121 67.8925 180.705 67.2647 180.28C66.637 179.856 66.0422 179.421 65.49 178.973C63.9654 177.779 62.6316 176.359 61.5346 174.763C61.3222 174.442 61.124 174.126 60.9399 173.819C60.1744 172.493 59.6758 171.031 59.472 169.514C59.4147 169.059 59.3879 168.6 59.3918 168.141V160.404H63.0498C63.3424 160.088 63.6492 159.772 63.9938 159.46C65.338 158.192 66.8144 157.072 68.3975 156.119C69.4173 155.499 70.4681 154.932 71.5458 154.42C72.995 153.73 74.4827 153.125 76.0014 152.607C86.4657 148.987 99.795 148.501 111.123 151.314C112.778 151.72 114.412 152.21 116.018 152.782C117.334 153.254 118.609 153.773 119.831 154.349C121.578 155.147 123.254 156.092 124.839 157.176C126.085 158.034 127.243 159.015 128.294 160.102Z" fill="#F10086" />
                        <path opacity="0.15" d="M85.7388 186.912V170.307L91.8182 170.944V187.563C89.7697 187.436 87.7354 187.219 85.7388 186.912Z" fill="white" />
                        <path opacity="0.1" d="M132.292 168.268C132.295 169.249 132.163 170.226 131.9 171.171C131.806 171.506 131.693 171.836 131.57 172.167C130.921 173.812 129.976 175.324 128.781 176.627C128.62 176.806 128.45 176.981 128.309 177.156V160.074L128.337 160.102H132.335L132.292 168.268Z" fill="black" />
                        <path opacity="0.15" d="M112.02 160.636L115.14 159.899V184.779C114.145 185.093 113.127 185.395 112.086 185.685L112.02 160.636Z" fill="white" />
                        <path opacity="0.15" d="M103.977 187.209V165.644L110.056 166.092V186.176C108.048 186.622 106.019 186.967 103.977 187.209Z" fill="white" />
                        <path opacity="0.15" d="M59.3965 160.367H63.0545C63.3471 160.05 63.6539 159.734 63.9985 159.423C65.3427 158.155 66.8191 157.034 68.4022 156.081C69.422 155.461 70.4728 154.894 71.5505 154.382C72.9997 153.693 74.4874 153.087 76.0062 152.569C77.4694 152.06 78.9939 151.625 80.5562 151.238L80.5846 185.883C79.683 185.656 78.791 185.411 77.9178 185.147C77.0446 184.882 76.1997 184.599 75.369 184.292C74.1748 183.858 73.009 183.381 71.8998 182.876C70.9888 182.447 70.1203 182.003 69.3038 181.536C68.6005 181.135 67.9302 180.719 67.3025 180.295C66.6747 179.87 66.08 179.436 65.5278 178.987C64.0031 177.793 62.6694 176.373 61.5724 174.777C61.36 174.456 61.1618 174.14 60.9777 173.833C60.2122 172.508 59.7135 171.045 59.5098 169.528C59.4525 169.073 59.4257 168.614 59.4295 168.155L59.3965 160.367Z" fill="white" />
                        <path opacity="0.15" d="M61.5488 174.744C61.3364 174.423 61.1382 174.107 60.9541 173.8C60.1886 172.474 59.6899 171.012 59.4862 169.495C59.4289 169.04 59.4021 168.581 59.4059 168.122V160.386H63.0639L63.1111 160.338L64.343 160.787V177.996C63.2864 177.026 62.3484 175.934 61.5488 174.744Z" fill="white" />
                        <path d="M119.812 145.541C118.59 144.97 117.316 144.451 115.999 143.979C114.393 143.407 112.759 142.917 111.104 142.511C99.7761 139.679 86.4468 140.184 75.9778 143.8C74.4616 144.322 72.9756 144.929 71.5269 145.617C70.4492 146.129 69.3984 146.696 68.3786 147.316C66.7957 148.268 65.3193 149.386 63.9749 150.653C62.6694 151.87 61.5738 153.294 60.7322 154.868C59.64 156.869 59.1994 159.161 59.472 161.424C59.6758 162.942 60.1744 164.406 60.9399 165.733C61.1193 166.054 61.3175 166.375 61.5299 166.677C62.6287 168.274 63.964 169.693 65.49 170.888C66.0407 171.331 66.6307 171.766 67.26 172.19C67.8925 172.615 68.558 173.03 69.266 173.432C70.0826 173.904 70.9463 174.343 71.8573 174.772C72.9712 175.296 74.1323 175.773 75.3265 176.188C76.1619 176.5 77.0115 176.783 77.88 177.047C90.624 180.927 106.568 180.351 118.146 175.565C118.935 175.239 119.704 174.895 120.445 174.531C121.441 174.059 122.385 173.531 123.277 172.979C124.107 172.474 124.91 171.927 125.684 171.341C126.274 170.892 126.826 170.43 127.336 169.962C127.831 169.514 128.304 169.042 128.752 168.546C129.945 167.242 130.891 165.73 131.542 164.086C131.664 163.756 131.778 163.425 131.867 163.09C132.271 161.67 132.372 160.181 132.165 158.719C131.778 156.343 130.737 154.122 129.158 152.305C127.892 150.804 126.43 149.48 124.811 148.368C123.228 147.285 121.556 146.34 119.812 145.541Z" fill="#F10086" />
                        <path opacity="0.1" d="M119.812 145.541C118.59 144.97 117.316 144.451 115.999 143.979C114.393 143.407 112.759 142.917 111.104 142.511C99.7761 139.679 86.4468 140.184 75.9778 143.8C74.4616 144.322 72.9756 144.929 71.5269 145.617C70.4492 146.129 69.3984 146.696 68.3786 147.316C66.7957 148.268 65.3193 149.386 63.9749 150.653C62.6694 151.87 61.5738 153.294 60.7322 154.868C59.64 156.869 59.1994 159.161 59.472 161.424C59.6758 162.942 60.1744 164.406 60.9399 165.733C61.1193 166.054 61.3175 166.375 61.5299 166.677C62.6287 168.274 63.964 169.693 65.49 170.888C66.0407 171.331 66.6307 171.766 67.26 172.19C67.8925 172.615 68.558 173.03 69.266 173.432C70.0826 173.904 70.9463 174.343 71.8573 174.772C72.9712 175.296 74.1323 175.773 75.3265 176.188C76.1619 176.5 77.0115 176.783 77.88 177.047C90.624 180.927 106.568 180.351 118.146 175.565C118.935 175.239 119.704 174.895 120.445 174.531C121.441 174.059 122.385 173.531 123.277 172.979C124.107 172.474 124.91 171.927 125.684 171.341C126.274 170.892 126.826 170.43 127.336 169.962C127.831 169.514 128.304 169.042 128.752 168.546C129.945 167.242 130.891 165.73 131.542 164.086C131.664 163.756 131.778 163.425 131.867 163.09C132.271 161.67 132.372 160.181 132.165 158.719C131.778 156.343 130.737 154.122 129.158 152.305C127.892 150.804 126.43 149.48 124.811 148.368C123.228 147.285 121.556 146.34 119.812 145.541Z" fill="white" />
                        <path opacity="0.2" d="M119.812 145.541C118.59 144.97 117.316 144.451 115.999 143.979C114.393 143.407 112.759 142.917 111.104 142.511C99.7761 139.679 86.4468 140.184 75.9778 143.8C74.4616 144.322 72.9756 144.929 71.5269 145.617C70.4492 146.129 69.3984 146.696 68.3786 147.316C66.7957 148.268 65.3193 149.386 63.9749 150.653C62.6694 151.87 61.5738 153.294 60.7322 154.868C59.64 156.869 59.1994 159.161 59.472 161.424C59.6758 162.942 60.1744 164.406 60.9399 165.733C61.1193 166.054 61.3175 166.375 61.5299 166.677C62.6287 168.274 63.964 169.693 65.49 170.888C66.0407 171.331 66.6307 171.766 67.26 172.19C67.8925 172.615 68.558 173.03 69.266 173.432C70.0826 173.904 70.9463 174.343 71.8573 174.772C72.9712 175.296 74.1323 175.773 75.3265 176.188C76.1619 176.5 77.0115 176.783 77.88 177.047C90.624 180.927 106.568 180.351 118.146 175.565C118.935 175.239 119.704 174.895 120.445 174.531C121.441 174.059 122.385 173.531 123.277 172.979C124.107 172.474 124.91 171.927 125.684 171.341C126.274 170.892 126.826 170.43 127.336 169.962C127.831 169.514 128.304 169.042 128.752 168.546C129.945 167.242 130.891 165.73 131.542 164.086C131.664 163.756 131.778 163.425 131.867 163.09C132.271 161.67 132.372 160.181 132.165 158.719C131.778 156.343 130.737 154.122 129.158 152.305C127.892 150.804 126.43 149.48 124.811 148.368C123.228 147.285 121.556 146.34 119.812 145.541Z" fill="white" />
                        <path opacity="0.15" d="M80.5751 177.84C79.6689 177.614 78.7721 177.368 77.8942 177.104C77.0162 176.84 76.1761 176.556 75.3406 176.245C74.1465 175.811 72.9806 175.334 71.8714 174.829C70.9605 174.399 70.0967 173.956 69.2802 173.488C68.5722 173.087 67.9066 172.672 67.2742 172.247C66.6417 171.822 66.0564 171.388 65.5042 170.944C63.9782 169.75 62.6428 168.33 61.5441 166.734C61.3317 166.418 61.1334 166.097 60.9541 165.79C60.1766 164.447 59.6729 162.963 59.472 161.424C59.1925 159.156 59.6299 156.856 60.7228 154.849C61.5643 153.275 62.6599 151.851 63.9654 150.634C65.3099 149.368 66.7862 148.249 68.3692 147.297C69.2566 146.759 70.1911 146.254 71.1587 145.782L80.5751 177.84Z" fill="white" />
                        <path opacity="0.15" d="M64.2958 169.887C63.2561 168.939 62.331 167.873 61.5394 166.71C61.327 166.394 61.1287 166.073 60.9494 165.766C60.1765 164.43 59.6745 162.954 59.472 161.424C59.2388 159.575 59.4926 157.698 60.2083 155.977L64.2958 169.887Z" fill="white" />
                        <path opacity="0.15" d="M91.7993 179.53C89.7508 179.407 87.7212 179.185 85.7294 178.874L75.4822 143.96L75.9778 143.781C77.5543 143.238 79.2016 142.771 80.8914 142.365L91.7993 179.53Z" fill="white" />
                        <path opacity="0.15" d="M110.047 178.147C108.074 178.586 106.035 178.93 103.967 179.181L92.6914 140.769C94.8106 140.675 96.9394 140.684 99.0775 140.769L110.047 178.147Z" fill="white" />
                        <path opacity="0.15" d="M115.13 176.74C114.125 177.076 113.101 177.378 112.053 177.656L101.272 140.93C102.429 141.024 103.58 141.142 104.723 141.298L115.13 176.74Z" fill="white" />
                        <path opacity="0.3" d="M121.616 146.457C122.088 146.712 122.56 146.971 122.998 147.236L123.442 147.505L123.886 147.783C124.212 147.991 124.528 148.203 124.83 148.416C126.449 149.527 127.911 150.851 129.177 152.352C130.756 154.169 131.797 156.391 132.184 158.767C132.391 160.228 132.29 161.717 131.886 163.137C131.797 163.472 131.683 163.803 131.561 164.133C130.91 165.777 129.964 167.289 128.771 168.594C128.611 168.773 128.441 168.952 128.271 169.127L121.616 146.457Z" fill="#F10086" />
                        <path d="M113.615 149.232C102.731 144.177 86.5129 144.555 76.5395 149.931C76.1194 150.157 75.6994 150.403 75.3029 150.644C65.4853 156.69 66.7219 165.856 78.0688 171.119C89.4157 176.382 106.563 175.759 116.381 169.703C116.778 169.457 117.165 169.203 117.528 168.948C126.081 162.925 124.495 154.283 113.615 149.232Z" fill="#F10086" />
                        <path opacity="0.4" d="M113.615 149.232C102.731 144.177 86.5129 144.555 76.5395 149.931C76.1194 150.157 75.6994 150.403 75.3029 150.644C65.4853 156.69 66.7219 165.856 78.0688 171.119C89.4157 176.382 106.563 175.759 116.381 169.703C116.778 169.457 117.165 169.203 117.528 168.948C126.081 162.925 124.495 154.283 113.615 149.232Z" fill="black" />
                        <path d="M112.808 149.718C112.459 149.553 112.1 149.397 111.737 149.246C101.353 144.923 86.5931 145.423 77.3844 150.389L77.139 150.521C76.818 150.695 76.5065 150.88 76.195 151.068C74.5342 152.047 73.0619 153.314 71.8478 154.811C69.384 157.974 69.233 161.5 71.3003 164.69C72.5134 166.578 74.491 168.32 77.2192 169.811C77.7337 170.099 78.2718 170.368 78.8382 170.633C79.4046 170.897 79.8907 171.105 80.4335 171.317C81.5993 171.777 82.7888 172.174 83.9971 172.507C92.5781 174.867 102.981 174.489 110.844 171.515C111.941 171.111 113.012 170.638 114.049 170.099C114.521 169.849 114.993 169.585 115.465 169.306C115.937 169.028 116.216 168.834 116.56 168.584L116.848 168.377C119.142 166.692 120.624 164.813 121.328 162.868C122.517 159.564 121.422 156.076 118.137 153.126C116.537 151.735 114.742 150.587 112.808 149.718Z" fill="#F10086" />
                        <path opacity="0.5" d="M112.808 149.718C112.459 149.553 112.1 149.397 111.737 149.246C101.353 144.923 86.5931 145.423 77.3844 150.389L77.139 150.521C76.818 150.695 76.5065 150.88 76.195 151.068C74.5342 152.047 73.0619 153.314 71.8478 154.811C69.384 157.974 69.233 161.5 71.3003 164.69C72.5134 166.578 74.491 168.32 77.2192 169.811C77.7337 170.099 78.2718 170.368 78.8382 170.633C79.4046 170.897 79.8907 171.105 80.4335 171.317C81.5993 171.777 82.7888 172.174 83.9971 172.507C92.5781 174.867 102.981 174.489 110.844 171.515C111.941 171.111 113.012 170.638 114.049 170.099C114.521 169.849 114.993 169.585 115.465 169.306C115.937 169.028 116.216 168.834 116.56 168.584L116.848 168.377C119.142 166.692 120.624 164.813 121.328 162.868C122.517 159.564 121.422 156.076 118.137 153.126C116.537 151.735 114.742 150.587 112.808 149.718Z" fill="black" />
                        <path d="M111.765 152.296C112.128 152.447 112.487 152.607 112.836 152.768C114.763 153.63 116.552 154.772 118.146 156.156C120.067 157.879 121.233 159.781 121.639 161.726C121.562 162.108 121.458 162.483 121.328 162.849C120.629 164.794 119.142 166.673 116.848 168.358L116.56 168.565C116.216 168.811 115.848 169.037 115.465 169.288C115.083 169.538 114.55 169.83 114.049 170.08C113.012 170.619 111.941 171.092 110.844 171.496C102.981 174.47 92.5781 174.852 83.9971 172.488C82.7888 172.155 81.5993 171.758 80.4335 171.298C79.8907 171.086 79.3574 170.855 78.8382 170.614C78.319 170.373 77.7337 170.08 77.2192 169.793C74.491 168.301 72.4992 166.54 71.3003 164.671C70.6967 163.757 70.2663 162.739 70.0306 161.669C70.3344 160.269 70.9597 158.959 71.8573 157.842C73.0743 156.346 74.5479 155.078 76.2091 154.099C76.5112 153.91 76.8274 153.73 77.1531 153.551L77.4033 153.419C86.6214 148.472 101.395 147.972 111.765 152.296Z" fill="#F10086" />
                        <path opacity="0.25" d="M111.765 152.296C112.128 152.447 112.487 152.607 112.836 152.768C114.763 153.63 116.552 154.772 118.146 156.156C120.067 157.879 121.233 159.781 121.639 161.726C121.562 162.108 121.458 162.483 121.328 162.849C120.629 164.794 119.142 166.673 116.848 168.358L116.56 168.565C116.216 168.811 115.848 169.037 115.465 169.288C115.083 169.538 114.55 169.83 114.049 170.08C113.012 170.619 111.941 171.092 110.844 171.496C102.981 174.47 92.5781 174.852 83.9971 172.488C82.7888 172.155 81.5993 171.758 80.4335 171.298C79.8907 171.086 79.3574 170.855 78.8382 170.614C78.319 170.373 77.7337 170.08 77.2192 169.793C74.491 168.301 72.4992 166.54 71.3003 164.671C70.6967 163.757 70.2663 162.739 70.0306 161.669C70.3344 160.269 70.9597 158.959 71.8573 157.842C73.0743 156.346 74.5479 155.078 76.2091 154.099C76.5112 153.91 76.8274 153.73 77.1531 153.551L77.4033 153.419C86.6214 148.472 101.395 147.972 111.765 152.296Z" fill="black" />
                        <path d="M131.565 164.133C127.44 173.304 115.9 176.962 106.493 178.614C92.191 180.667 76.6245 178.949 65.5655 170.807H65.5891C62.5825 168.447 60.0195 165.266 59.4814 161.424C59.9534 165.309 62.4267 168.589 65.4334 171.02C74.34 177.925 86.6734 180.162 98.4734 179.832C110.344 179.138 126.17 175.938 131.565 164.133Z" fill="#F5F5F5" />
                        <path d="M80.8489 166.937C84.8892 168.627 88.8729 167.881 92.5403 166.588L91.568 167.588V171.808C92.3894 172.003 93.2239 172.139 94.0649 172.214C95.5153 172.335 96.975 172.28 98.412 172.049C100.771 171.659 103.056 170.908 105.185 169.821L108.744 171.317L113.549 168.358V164.162L112.648 163.779L112.449 163.694C112.524 163.374 112.558 163.046 112.548 162.717C112.547 162.605 112.537 162.493 112.52 162.382C112.459 161.868 112.567 159.498 112.548 158.521C112.548 158.403 112.548 158.295 112.515 158.191C112.378 157.016 111.401 155.963 109.41 155.137C107.396 154.32 105.209 154.027 103.052 154.287L102.514 154.358H102.452L101.886 154.448C101.721 154.476 101.556 154.504 101.39 154.547C101.324 154.547 101.253 154.571 101.192 154.589C101.131 154.608 100.932 154.637 100.814 154.67C99.7779 154.915 98.7553 155.216 97.7512 155.571C97.3122 155.727 96.8733 155.892 96.439 156.043L97.7087 154.722V150.521C93.6165 149.742 89.2505 150.403 84.9647 152.503L81.3964 151.04L76.6434 154.075V158.271L77.7573 158.743C77.682 159 77.647 159.268 77.6534 159.536C77.6912 160.187 77.6251 162.963 77.6534 163.699V163.732C77.729 164.964 78.7438 166.054 80.8489 166.937Z" fill="#F10086" />
                        <path opacity="0.15" d="M112.553 158.691C112.553 159.767 112.473 161.877 112.53 162.359C112.53 162.472 112.53 162.576 112.558 162.689C112.565 163.021 112.53 163.352 112.454 163.675L109.896 162.599C109.896 162.599 112.478 161.353 112.553 158.691Z" fill="black" />
                        <path opacity="0.45" d="M86.7206 158.625C85.6538 158.181 85.4745 157.346 87.438 156.081C89.5431 154.75 91.7096 154.401 93.5504 154.863L97.7134 150.526V154.712L96.4532 156.038L96.3258 156.086C95.6414 156.35 94.9806 156.619 94.3481 156.879C94.0932 156.992 93.843 157.1 93.5976 157.195C92.2456 157.828 90.8418 158.345 89.4015 158.738C88.808 158.897 88.1891 158.939 87.5796 158.861C87.2841 158.821 86.9952 158.741 86.7206 158.625Z" fill="black" />
                        <path opacity="0.2" d="M77.7526 158.705L76.6481 158.233V154.047L80.1928 155.533C78.8759 156.633 78.031 157.7 77.7526 158.705Z" fill="black" />
                        <path opacity="0.15" d="M108.744 167.088L113.554 164.133V168.325L108.739 171.294L105.19 169.793C103.06 170.881 100.773 171.631 98.412 172.016C96.1398 172.405 93.8124 172.335 91.568 171.808V167.612C93.8096 168.154 96.1378 168.236 98.412 167.853C100.773 167.467 103.06 166.715 105.19 165.625L108.744 167.088Z" fill="black" />
                        <path opacity="0.2" d="M105.195 169.797C103.531 170.83 101.706 171.577 99.795 172.006C97.9181 172.45 95.9711 172.513 94.0696 172.19C95.9753 172.17 97.8737 171.95 99.7336 171.534C100.678 171.331 101.574 171.062 102.49 170.774C103.406 170.486 104.284 170.142 105.195 169.797Z" fill="black" />
                        <path opacity="0.35" d="M77.6582 163.727V163.704C77.6298 162.963 77.6912 160.192 77.6582 159.531C77.729 160.773 78.7343 161.863 80.8394 162.746C85.5311 164.7 90.0954 163.411 94.2726 161.74C98.1477 160.169 100.966 158.502 103.241 159.446C104.657 160.041 104.255 160.985 102.707 161.976C101.721 162.599 100.617 163.014 99.4647 163.195C98.3121 163.376 97.1347 163.319 96.0048 163.029L92.545 166.616C88.8446 167.923 84.8892 168.646 80.8583 166.956C78.739 166.059 77.7337 164.964 77.6582 163.727Z" fill="black" />
                        <path d="M93.5551 159.059L93.5504 154.863L97.7087 150.521L97.7134 154.717L93.5551 159.059Z" fill="#F10086" />
                        <path opacity="0.15" d="M93.5551 159.059L93.5504 154.863L97.7087 150.521L97.7134 154.717L93.5551 159.059Z" fill="black" />
                        <path d="M76.6434 154.051L80.2022 155.533L77.762 158.71L76.6481 158.247L76.6434 154.051Z" fill="#F10086" />
                        <path opacity="0.45" d="M76.6434 154.051L80.2022 155.533L77.762 158.71L76.6481 158.247L76.6434 154.051Z" fill="black" />
                        <path d="M112.652 163.751L109.9 162.595C109.9 162.595 112.605 161.287 112.553 158.498C112.553 158.38 112.553 158.271 112.52 158.167C112.383 156.992 111.406 155.939 109.414 155.113C107.401 154.296 105.214 154.004 103.056 154.264L102.518 154.335H102.457L101.891 154.424C101.725 154.453 101.56 154.481 101.395 154.523C101.329 154.523 101.258 154.547 101.197 154.566C101.135 154.585 100.937 154.613 100.819 154.646C99.7826 154.891 98.76 155.192 97.7559 155.548C97.2839 155.718 96.8119 155.897 96.3399 156.086C95.6602 156.355 94.99 156.629 94.3575 156.888L93.607 157.204C92.253 157.835 90.8476 158.35 89.4062 158.743C88.816 158.903 88.2005 158.946 87.5938 158.87C87.3005 158.826 87.0137 158.747 86.7394 158.634C85.6633 158.186 85.4839 157.351 87.4569 156.09C89.5573 154.745 91.7049 154.401 93.5646 154.868L97.7229 150.526C93.6306 149.747 89.2646 150.408 84.9789 152.508L81.3964 151.04L76.6434 154.075L80.2022 155.557C78.5078 156.973 77.611 158.314 77.6582 159.527V159.536C77.729 160.768 78.739 161.863 80.8489 162.741C85.5264 164.704 90.0954 163.416 94.2678 161.736C98.1477 160.164 100.966 158.502 103.236 159.451C104.652 160.046 104.241 160.985 102.688 161.976C101.702 162.598 100.6 163.012 99.4483 163.194C98.2968 163.376 97.1203 163.321 95.9906 163.034L91.568 167.588C92.3911 167.783 93.2271 167.919 94.0696 167.994C95.5198 168.117 96.9795 168.063 98.4167 167.834C100.774 167.442 103.057 166.689 105.185 165.601L108.744 167.097L113.554 164.138L112.652 163.751Z" fill="#F10086" />
                        <path opacity="0.1" d="M112.652 163.751L109.9 162.595C109.9 162.595 112.605 161.287 112.553 158.498C112.553 158.38 112.553 158.271 112.52 158.167C112.383 156.992 111.406 155.939 109.414 155.113C107.401 154.296 105.214 154.004 103.056 154.264L102.518 154.335H102.457L101.891 154.424C101.725 154.453 101.56 154.481 101.395 154.523C101.329 154.523 101.258 154.547 101.197 154.566C101.135 154.585 100.937 154.613 100.819 154.646C99.7826 154.891 98.76 155.192 97.7559 155.548C97.2839 155.718 96.8119 155.897 96.3399 156.086C95.6602 156.355 94.99 156.629 94.3575 156.888L93.607 157.204C92.253 157.835 90.8476 158.35 89.4062 158.743C88.816 158.903 88.2005 158.946 87.5938 158.87C87.3005 158.826 87.0137 158.747 86.7394 158.634C85.6633 158.186 85.4839 157.351 87.4569 156.09C89.5573 154.745 91.7049 154.401 93.5646 154.868L97.7229 150.526C93.6306 149.747 89.2646 150.408 84.9789 152.508L81.3964 151.04L76.6434 154.075L80.2022 155.557C78.5078 156.973 77.611 158.314 77.6582 159.527V159.536C77.729 160.768 78.739 161.863 80.8489 162.741C85.5264 164.704 90.0954 163.416 94.2678 161.736C98.1477 160.164 100.966 158.502 103.236 159.451C104.652 160.046 104.241 160.985 102.688 161.976C101.702 162.598 100.6 163.012 99.4483 163.194C98.2968 163.376 97.1203 163.321 95.9906 163.034L91.568 167.588C92.3911 167.783 93.2271 167.919 94.0696 167.994C95.5198 168.117 96.9795 168.063 98.4167 167.834C100.774 167.442 103.057 166.689 105.185 165.601L108.744 167.097L113.554 164.138L112.652 163.751Z" fill="white" />
                        <path d="M80.8489 162.741C82.6229 163.422 84.5214 163.718 86.4185 163.609C88.3539 163.478 90.2609 163.073 92.0825 162.406C93.9705 161.792 95.7358 160.99 97.5624 160.22C98.48 159.831 99.427 159.516 100.394 159.276C101.333 159.036 102.322 159.094 103.226 159.442C102.309 159.135 101.32 159.12 100.394 159.399C99.4502 159.663 98.5271 159.997 97.6332 160.4C95.8302 161.183 94.0318 162.005 92.158 162.646C90.3014 163.315 88.3562 163.705 86.3854 163.803C85.4191 163.835 84.4521 163.762 83.5015 163.586C82.5906 163.396 81.7016 163.113 80.8489 162.741Z" fill="white" />
                        <path d="M108.744 167.097L108.749 171.294L105.19 169.797L105.185 165.601L108.744 167.097Z" fill="#F10086" />
                        <path opacity="0.35" d="M108.744 167.097L108.749 171.294L105.19 169.797L105.185 165.601L108.744 167.097Z" fill="black" />
                        <path d="M113.554 164.138L108.763 166.625L105.185 165.601L108.744 167.097L113.554 164.138Z" fill="#FAFAFA" />
                        <path d="M84.9553 152.503L81.4153 151.455L76.6434 154.051L81.3964 151.016L84.9553 152.503Z" fill="#FAFAFA" />
                        <path opacity="0.15" d="M97.704 150.521C96.9662 150.385 96.2205 150.297 95.4714 150.256L96.052 152.244L97.704 150.521Z" fill="white" />
                        <path opacity="0.15" d="M81.0094 162.802C82.9553 163.58 85.0603 163.876 87.1454 163.666L83.7092 151.984L81.3964 151.016L78.1538 153.088L78.6918 154.906L80.2022 155.533C79.8363 155.836 79.4881 156.159 79.1591 156.501L81.0094 162.802Z" fill="white" />
                        <path opacity="0.15" d="M108.697 154.816C107.557 154.428 106.365 154.21 105.162 154.169L108.938 166.97L111.77 165.228L111.142 163.095L109.915 162.576C110.234 162.408 110.534 162.207 110.811 161.976L108.697 154.816Z" fill="white" />
                        <path opacity="0.15" d="M102.457 154.344L101.891 154.434C101.725 154.462 101.56 154.49 101.395 154.533C101.329 154.533 101.258 154.556 101.197 154.575C101.135 154.594 100.937 154.622 100.819 154.656C99.7826 154.901 98.76 155.202 97.7559 155.557L97.0998 155.807L98.3412 160.055C100.253 159.3 101.848 158.904 103.241 159.484C104.633 160.065 104.246 161.018 102.698 162.009C101.662 162.668 100.493 163.09 99.2758 163.246L100.503 167.418C102.12 166.985 103.689 166.387 105.185 165.634L106.526 166.201L103.033 154.292L102.518 154.358L102.457 154.344Z" fill="white" />
                        <path d="M123.518 150.167H127.516V158.347C127.516 159.329 127.384 160.307 127.124 161.254C127.029 161.585 126.916 161.915 126.793 162.245C126.145 163.892 125.199 165.406 124.004 166.71C123.556 167.206 123.083 167.678 122.588 168.126C122.061 168.613 121.509 169.073 120.936 169.505C120.163 170.092 119.359 170.639 118.529 171.142C117.622 171.695 116.678 172.209 115.697 172.695C114.951 173.059 114.186 173.403 113.393 173.729C101.82 178.51 85.8615 179.091 73.127 175.211C72.2585 174.947 71.4089 174.662 70.5782 174.357C69.384 173.923 68.2182 173.441 67.109 172.917C66.1933 172.489 65.3279 172.044 64.513 171.581C63.8097 171.176 63.1394 170.765 62.5117 170.335C61.8839 169.906 61.2892 169.476 60.737 169.033C59.2133 167.835 57.8797 166.414 56.7816 164.818C56.5692 164.501 56.3662 164.18 56.1869 163.874C55.4221 162.548 54.9234 161.086 54.719 159.569C54.6617 159.114 54.6349 158.655 54.6387 158.196V150.459H58.2967C58.5894 150.138 58.8962 149.822 59.2407 149.515C60.5851 148.249 62.0615 147.13 63.6445 146.178C64.6643 145.559 65.715 144.992 66.7927 144.479C68.2406 143.789 69.7267 143.183 71.2437 142.662C81.7126 139.042 95.0419 138.556 106.37 141.369C108.025 141.777 109.659 142.268 111.265 142.841C112.581 143.313 113.856 143.833 115.078 144.404C116.832 145.214 118.512 146.175 120.1 147.273C121.33 148.126 122.474 149.095 123.518 150.167Z" fill="#F10086" />
                        <path opacity="0.15" d="M80.9622 176.972V160.367L87.0415 161.004V177.628C84.993 177.5 82.9587 177.283 80.9622 176.972Z" fill="white" />
                        <path opacity="0.1" d="M127.516 158.332C127.516 159.315 127.384 160.293 127.124 161.24C127.029 161.57 126.916 161.901 126.793 162.231C126.145 163.878 125.199 165.391 124.004 166.696C123.843 166.871 123.673 167.05 123.504 167.225V150.138L123.532 150.167H127.53L127.516 158.332Z" fill="black" />
                        <path opacity="0.15" d="M107.243 150.7L110.363 149.964V174.848C109.367 175.178 108.348 175.475 107.309 175.749L107.243 150.7Z" fill="white" />
                        <path opacity="0.15" d="M99.2002 177.278V155.708L105.28 156.156V176.24C103.272 176.688 101.243 177.035 99.2002 177.278Z" fill="white" />
                        <path opacity="0.15" d="M54.6198 150.436H58.2778C58.5705 150.115 58.8773 149.799 59.2218 149.492C60.5663 148.225 62.0426 147.107 63.6256 146.155C64.6454 145.535 65.6962 144.968 66.7738 144.456C68.2217 143.766 69.7078 143.159 71.2248 142.638C72.6911 142.132 74.2094 141.69 75.7796 141.312L75.8079 175.952C74.9064 175.73 74.0143 175.48 73.1411 175.221C72.2679 174.961 71.423 174.673 70.5923 174.366C69.3982 173.932 68.2323 173.451 67.1231 172.927C66.2074 172.499 65.3421 172.053 64.5271 171.591C63.8238 171.185 63.1536 170.774 62.5258 170.345C61.8981 169.915 61.3034 169.486 60.7511 169.042C59.2274 167.845 57.8939 166.424 56.7958 164.827C56.5834 164.511 56.3804 164.19 56.201 163.883C55.4362 162.557 54.9376 161.095 54.7331 159.578C54.6758 159.123 54.649 158.664 54.6529 158.205L54.6198 150.436Z" fill="white" />
                        <path opacity="0.15" d="M56.7722 164.808C56.5598 164.492 56.3568 164.171 56.1774 163.864C55.4126 162.539 54.914 161.076 54.7095 159.56C54.6522 159.104 54.6254 158.645 54.6293 158.186V150.45H58.2873L58.3345 150.403L59.5664 150.875V168.079C58.5075 167.105 57.5693 166.006 56.7722 164.808Z" fill="white" />
                        <path d="M115.036 135.61C113.813 135.039 112.539 134.515 111.222 134.048C109.616 133.475 107.983 132.984 106.327 132.575C94.9994 129.743 81.6702 130.248 71.2012 133.869C69.6839 134.388 68.1978 134.995 66.7502 135.686C65.6726 136.198 64.6218 136.765 63.602 137.385C62.019 138.337 60.5427 139.456 59.1982 140.722C57.8917 141.938 56.7959 143.362 55.9556 144.937C54.8606 146.944 54.4231 149.243 54.7048 151.512C54.9098 153.028 55.4067 154.49 56.168 155.817C56.3521 156.138 56.5503 156.459 56.7627 156.761C57.8601 158.359 59.1955 159.781 60.7228 160.976C61.2798 161.426 61.8713 161.861 62.4975 162.283C63.13 162.708 63.7955 163.123 64.5035 163.524C65.3154 163.996 66.1838 164.435 67.0948 164.865C68.2087 165.384 69.3698 165.866 70.564 166.281C71.401 166.596 72.2522 166.88 73.1175 167.135C85.8615 171.02 101.806 170.439 113.384 165.653C114.172 165.327 114.941 164.983 115.682 164.619C116.674 164.138 117.618 163.62 118.514 163.067C119.411 162.513 120.214 161.967 120.922 161.429C121.512 160.98 122.064 160.518 122.574 160.05C123.071 159.605 123.544 159.132 123.99 158.634C125.186 157.329 126.133 155.816 126.784 154.169C126.907 153.839 127.015 153.509 127.11 153.178C127.513 151.758 127.614 150.269 127.407 148.807C127.02 146.43 125.979 144.207 124.4 142.388C123.133 140.886 121.67 139.562 120.048 138.452C118.463 137.362 116.786 136.411 115.036 135.61Z" fill="#F10086" />
                        <path opacity="0.1" d="M115.036 135.61C113.813 135.039 112.539 134.515 111.222 134.048C109.616 133.475 107.983 132.984 106.327 132.575C94.9994 129.743 81.6702 130.248 71.2012 133.869C69.6839 134.388 68.1978 134.995 66.7502 135.686C65.6726 136.198 64.6218 136.765 63.602 137.385C62.019 138.337 60.5427 139.456 59.1982 140.722C57.8917 141.938 56.7959 143.362 55.9556 144.937C54.8606 146.944 54.4231 149.243 54.7048 151.512C54.9098 153.028 55.4067 154.49 56.168 155.817C56.3521 156.138 56.5503 156.459 56.7627 156.761C57.8601 158.359 59.1955 159.781 60.7228 160.976C61.2798 161.426 61.8713 161.861 62.4975 162.283C63.13 162.708 63.7955 163.123 64.5035 163.524C65.3154 163.996 66.1838 164.435 67.0948 164.865C68.2087 165.384 69.3698 165.866 70.564 166.281C71.401 166.596 72.2522 166.88 73.1175 167.135C85.8615 171.02 101.806 170.439 113.384 165.653C114.172 165.327 114.941 164.983 115.682 164.619C116.674 164.138 117.618 163.62 118.514 163.067C119.411 162.513 120.214 161.967 120.922 161.429C121.512 160.98 122.064 160.518 122.574 160.05C123.071 159.605 123.544 159.132 123.99 158.634C125.186 157.329 126.133 155.816 126.784 154.169C126.907 153.839 127.015 153.509 127.11 153.178C127.513 151.758 127.614 150.269 127.407 148.807C127.02 146.43 125.979 144.207 124.4 142.388C123.133 140.886 121.67 139.562 120.048 138.452C118.463 137.362 116.786 136.411 115.036 135.61Z" fill="white" />
                        <path opacity="0.2" d="M115.036 135.61C113.813 135.039 112.539 134.515 111.222 134.048C109.616 133.475 107.983 132.984 106.327 132.575C94.9994 129.743 81.6702 130.248 71.2012 133.869C69.6839 134.388 68.1978 134.995 66.7502 135.686C65.6726 136.198 64.6218 136.765 63.602 137.385C62.019 138.337 60.5427 139.456 59.1982 140.722C57.8917 141.938 56.7959 143.362 55.9556 144.937C54.8606 146.944 54.4231 149.243 54.7048 151.512C54.9098 153.028 55.4067 154.49 56.168 155.817C56.3521 156.138 56.5503 156.459 56.7627 156.761C57.8601 158.359 59.1955 159.781 60.7228 160.976C61.2798 161.426 61.8713 161.861 62.4975 162.283C63.13 162.708 63.7955 163.123 64.5035 163.524C65.3154 163.996 66.1838 164.435 67.0948 164.865C68.2087 165.384 69.3698 165.866 70.564 166.281C71.401 166.596 72.2522 166.88 73.1175 167.135C85.8615 171.02 101.806 170.439 113.384 165.653C114.172 165.327 114.941 164.983 115.682 164.619C116.674 164.138 117.618 163.62 118.514 163.067C119.411 162.513 120.214 161.967 120.922 161.429C121.512 160.98 122.064 160.518 122.574 160.05C123.071 159.605 123.544 159.132 123.99 158.634C125.186 157.329 126.133 155.816 126.784 154.169C126.907 153.839 127.015 153.509 127.11 153.178C127.513 151.758 127.614 150.269 127.407 148.807C127.02 146.43 125.979 144.207 124.4 142.388C123.133 140.886 121.67 139.562 120.048 138.452C118.463 137.362 116.786 136.411 115.036 135.61Z" fill="white" />
                        <path opacity="0.15" d="M75.7985 167.909C74.8922 167.683 73.9954 167.437 73.1175 167.168C72.2396 166.899 71.3994 166.621 70.564 166.314C69.3698 165.88 68.204 165.398 67.0948 164.898C66.1838 164.468 65.3154 164.02 64.5035 163.557C63.7955 163.156 63.13 162.741 62.4975 162.316C61.865 161.891 61.2798 161.457 60.7228 161.009C59.1955 159.814 57.8601 158.392 56.7627 156.794C56.5503 156.477 56.3521 156.156 56.1727 155.85C55.4038 154.514 54.9051 153.04 54.7048 151.512C54.4231 149.243 54.8606 146.944 55.9556 144.937C56.7959 143.362 57.8917 141.938 59.1982 140.722C60.5427 139.456 62.019 138.337 63.602 137.385C64.4894 136.842 65.4239 136.342 66.3915 135.865L75.7985 167.909Z" fill="white" />
                        <path opacity="0.15" d="M59.5192 159.951C58.4784 159.005 57.5532 157.939 56.7627 156.775C56.5503 156.459 56.3521 156.138 56.1727 155.831C55.4062 154.501 54.9075 153.034 54.7048 151.512C54.4716 149.663 54.7254 147.786 55.4411 146.065L59.5192 159.951Z" fill="white" />
                        <path opacity="0.15" d="M87.0226 169.599C84.9742 169.472 82.9446 169.254 80.9527 168.943L70.7056 134.048L71.1776 133.869C72.7906 133.314 74.4305 132.842 76.0911 132.453L87.0226 169.599Z" fill="white" />
                        <path opacity="0.15" d="M105.256 168.211C103.25 168.66 101.223 169.005 99.1814 169.245L87.9147 130.838C90.034 130.744 92.1627 130.749 94.3009 130.838L105.256 168.211Z" fill="white" />
                        <path opacity="0.15" d="M110.354 166.81C109.348 167.14 108.324 167.447 107.276 167.72L96.4957 130.999C97.6521 131.093 98.8038 131.211 99.946 131.367L110.354 166.81Z" fill="white" />
                        <path opacity="0.3" d="M116.839 136.521C117.311 136.776 117.783 137.04 118.222 137.305L118.666 137.569L119.109 137.852C119.43 138.06 119.751 138.268 120.053 138.48C121.672 139.592 123.134 140.916 124.4 142.417C125.979 144.236 127.02 146.458 127.407 148.836C127.614 150.297 127.513 151.786 127.11 153.206C127.015 153.537 126.907 153.867 126.784 154.198C126.133 155.844 125.186 157.358 123.99 158.663C123.834 158.842 123.664 159.017 123.489 159.196L116.839 136.521Z" fill="#F10086" />
                        <path d="M108.834 139.297C97.9542 134.246 81.7362 134.624 71.7629 139.995C71.3428 140.226 70.9227 140.467 70.5262 140.708C60.7086 146.754 61.9453 155.92 73.2922 161.188C84.639 166.456 101.787 165.823 111.604 159.772C112.001 159.522 112.388 159.267 112.747 159.012C121.304 152.989 119.718 144.347 108.834 139.297Z" fill="#F10086" />
                        <path opacity="0.4" d="M108.834 139.297C97.9542 134.246 81.7362 134.624 71.7629 139.995C71.3428 140.226 70.9227 140.467 70.5262 140.708C60.7086 146.754 61.9453 155.92 73.2922 161.188C84.639 166.456 101.787 165.823 111.604 159.772C112.001 159.522 112.388 159.267 112.747 159.012C121.304 152.989 119.718 144.347 108.834 139.297Z" fill="black" />
                        <path d="M108.046 139.783C107.696 139.622 107.338 139.467 106.974 139.311C96.5901 134.983 81.8306 135.488 72.6219 140.448L72.3765 140.585C72.0555 140.76 71.744 140.939 71.4325 141.128C69.7728 142.108 68.3008 143.375 67.0854 144.871C64.6215 148.033 64.4705 151.559 66.5378 154.755C67.7509 156.643 69.7286 158.38 72.4567 159.876C72.9712 160.159 73.5093 160.433 74.0757 160.697C74.6421 160.961 75.1282 161.169 75.671 161.382C76.8358 161.843 78.0256 162.238 79.2346 162.566C87.8156 164.926 98.2185 164.553 106.082 161.58C107.178 161.173 108.248 160.7 109.287 160.164C109.759 159.909 110.231 159.645 110.703 159.366C111.175 159.088 111.453 158.894 111.798 158.649L112.086 158.436C114.38 156.756 115.862 154.873 116.565 152.933C117.755 149.629 116.66 146.141 113.374 143.191C111.776 141.798 109.98 140.649 108.046 139.783Z" fill="#F10086" />
                        <path opacity="0.5" d="M108.046 139.783C107.696 139.622 107.338 139.467 106.974 139.311C96.5901 134.983 81.8306 135.488 72.6219 140.448L72.3765 140.585C72.0555 140.76 71.744 140.939 71.4325 141.128C69.7728 142.108 68.3008 143.375 67.0854 144.871C64.6215 148.033 64.4705 151.559 66.5378 154.755C67.7509 156.643 69.7286 158.38 72.4567 159.876C72.9712 160.159 73.5093 160.433 74.0757 160.697C74.6421 160.961 75.1282 161.169 75.671 161.382C76.8358 161.843 78.0256 162.238 79.2346 162.566C87.8156 164.926 98.2185 164.553 106.082 161.58C107.178 161.173 108.248 160.7 109.287 160.164C109.759 159.909 110.231 159.645 110.703 159.366C111.175 159.088 111.453 158.894 111.798 158.649L112.086 158.436C114.38 156.756 115.862 154.873 116.565 152.933C117.755 149.629 116.66 146.141 113.374 143.191C111.776 141.798 109.98 140.649 108.046 139.783Z" fill="black" />
                        <path d="M106.984 142.365C107.352 142.516 107.706 142.671 108.055 142.837C109.985 143.698 111.776 144.842 113.37 146.23C115.291 147.948 116.457 149.855 116.862 151.795C116.786 152.178 116.682 152.555 116.551 152.923C115.852 154.863 114.366 156.746 112.072 158.427L111.784 158.639C111.439 158.88 111.071 159.111 110.689 159.357C110.306 159.602 109.773 159.899 109.273 160.154C108.234 160.691 107.164 161.164 106.068 161.57C98.2043 164.544 87.8014 164.926 79.2205 162.557C78.0114 162.229 76.8217 161.833 75.6569 161.372C75.1141 161.155 74.5807 160.928 74.0615 160.688C73.5423 160.447 72.957 160.15 72.4426 159.866C69.7144 158.37 67.7226 156.614 66.5237 154.745C65.9201 153.829 65.4898 152.81 65.254 151.739C65.5579 150.34 66.1833 149.031 67.0806 147.915C68.2974 146.418 69.771 145.149 71.4325 144.168C71.7346 143.979 72.0461 143.8 72.3765 143.625L72.6266 143.488C81.8448 138.541 96.6184 138.036 106.984 142.365Z" fill="#F10086" />
                        <path opacity="0.25" d="M106.984 142.365C107.352 142.516 107.706 142.671 108.055 142.837C109.985 143.698 111.776 144.842 113.37 146.23C115.291 147.948 116.457 149.855 116.862 151.795C116.786 152.178 116.682 152.555 116.551 152.923C115.852 154.863 114.366 156.746 112.072 158.427L111.784 158.639C111.439 158.88 111.071 159.111 110.689 159.357C110.306 159.602 109.773 159.899 109.273 160.154C108.234 160.691 107.164 161.164 106.068 161.57C98.2043 164.544 87.8014 164.926 79.2205 162.557C78.0114 162.229 76.8217 161.833 75.6569 161.372C75.1141 161.155 74.5807 160.928 74.0615 160.688C73.5423 160.447 72.957 160.15 72.4426 159.866C69.7144 158.37 67.7226 156.614 66.5237 154.745C65.9201 153.829 65.4898 152.81 65.254 151.739C65.5579 150.34 66.1833 149.031 67.0806 147.915C68.2974 146.418 69.771 145.149 71.4325 144.168C71.7346 143.979 72.0461 143.8 72.3765 143.625L72.6266 143.488C81.8448 138.541 96.6184 138.036 106.984 142.365Z" fill="black" />
                        <path d="M126.789 154.198C122.663 163.373 111.123 167.031 101.716 168.683C87.4144 170.737 71.8478 169.014 60.7889 160.876H60.8125C57.8058 158.516 55.2429 155.34 54.7048 151.498C55.1768 155.378 57.6501 158.663 60.6567 161.089C69.5634 167.99 81.8967 170.227 93.6967 169.896C105.568 169.203 121.394 166.007 126.789 154.198Z" fill="#F5F5F5" />
                        <path d="M76.0722 157.001C80.1126 158.696 84.0962 157.945 87.7637 156.657L86.7914 157.648V161.844C87.6128 162.039 88.4472 162.175 89.2882 162.25C90.7384 162.373 92.1982 162.319 93.6354 162.09C95.9945 161.698 98.2792 160.945 100.409 159.857L103.967 161.353L108.772 158.394V154.202L107.871 153.815L107.673 153.735C107.747 153.413 107.781 153.084 107.772 152.753C107.77 152.643 107.761 152.532 107.743 152.423C107.682 151.904 107.791 149.539 107.772 148.557C107.772 148.439 107.772 148.335 107.739 148.227C107.602 147.056 106.625 146.004 104.633 145.173C102.619 144.359 100.432 144.069 98.2751 144.328L97.7323 144.394H97.6757L97.1093 144.484C96.9441 144.512 96.7789 144.541 96.6137 144.578C96.5476 144.578 96.4768 144.602 96.4154 144.621C96.2879 144.641 96.1618 144.67 96.0378 144.706C95.0038 144.951 93.9842 145.253 92.984 145.612C92.5403 145.768 92.1014 145.933 91.6718 146.084L92.9415 144.762V140.566C88.8493 139.788 84.4833 140.448 80.1975 142.549L76.6386 141.067L71.8856 144.102V148.293L72.9995 148.765C72.9216 149.022 72.8865 149.29 72.8957 149.558V149.624C72.9287 150.271 72.8674 153.046 72.8957 153.782V153.815C72.9523 155.033 73.9671 156.123 76.0722 157.001Z" fill="#F10086" />
                        <path opacity="0.15" d="M107.772 148.76C107.772 149.832 107.696 151.942 107.748 152.423C107.748 152.536 107.772 152.645 107.781 152.758C107.788 153.088 107.753 153.418 107.677 153.74L105.119 152.664C105.119 152.664 107.701 151.418 107.772 148.76Z" fill="black" />
                        <path opacity="0.45" d="M81.9439 148.68C80.8772 148.232 80.6978 147.401 82.6614 146.136C84.7665 144.8 86.933 144.456 88.7974 144.913L92.9604 140.576V144.767L91.7049 146.089C91.6577 146.089 91.6246 146.126 91.5774 146.141C90.893 146.4 90.2322 146.669 89.5998 146.934C89.3449 147.047 89.0947 147.151 88.8493 147.245C87.4961 147.88 86.0906 148.396 84.6485 148.789C84.0568 148.949 83.4395 148.992 82.8313 148.916C82.5261 148.878 82.2276 148.799 81.9439 148.68Z" fill="black" />
                        <path opacity="0.2" d="M72.9759 148.77L71.8714 148.298V144.106L75.4162 145.593C74.104 146.698 73.2544 147.769 72.9759 148.77Z" fill="black" />
                        <path opacity="0.15" d="M103.967 157.176L108.777 154.221V158.417L103.963 161.382L100.413 159.881C98.2834 160.971 95.9967 161.722 93.6354 162.108C91.3612 162.492 89.033 162.41 86.7914 161.868V157.648C89.0339 158.184 91.3612 158.265 93.6354 157.884C95.9972 157.5 98.2843 156.748 100.413 155.656L103.967 157.176Z" fill="black" />
                        <path opacity="0.2" d="M100.418 159.862C98.754 160.894 96.9289 161.641 95.0183 162.071C93.1421 162.519 91.1946 162.583 89.2929 162.259C91.1908 162.234 93.081 162.012 94.9334 161.599C95.8774 161.396 96.7789 161.127 97.6946 160.843C98.6102 160.56 99.507 160.206 100.418 159.862Z" fill="black" />
                        <path opacity="0.35" d="M72.8815 153.792V153.768C72.8485 153.032 72.9146 150.256 72.8815 149.6C72.957 150.837 73.9624 151.932 76.0675 152.81C80.7592 154.764 85.3234 153.48 89.5006 151.805C93.3758 150.238 96.1936 148.567 98.4686 149.511C99.8846 150.11 99.4834 151.054 97.9353 152.041C96.949 152.664 95.8457 153.079 94.6932 153.261C93.5407 153.443 92.3632 153.387 91.2329 153.098L87.792 156.657C84.0915 157.964 80.1362 158.686 76.1053 157.001C73.9577 156.123 72.957 155.033 72.8815 153.792Z" fill="black" />
                        <path d="M88.7738 149.124V144.928L92.9321 140.59L92.9368 144.781L88.7738 149.124Z" fill="#F10086" />
                        <path opacity="0.15" d="M88.7738 149.124V144.928L92.9321 140.59L92.9368 144.781L88.7738 149.124Z" fill="black" />
                        <path d="M71.8667 144.12L75.4256 145.603L72.9854 148.779L71.8714 148.312L71.8667 144.12Z" fill="#F10086" />
                        <path opacity="0.45" d="M71.8667 144.12L75.4256 145.603L72.9854 148.779L71.8714 148.312L71.8667 144.12Z" fill="black" />
                        <path d="M107.876 153.82L105.124 152.664C105.124 152.664 107.828 151.352 107.776 148.562C107.776 148.444 107.776 148.34 107.743 148.232C107.607 147.061 106.63 146.008 104.638 145.178C102.624 144.364 100.436 144.074 98.2798 144.333L97.737 144.399H97.6804L97.114 144.489C96.9488 144.517 96.7836 144.545 96.6184 144.583C96.5476 144.583 96.4815 144.607 96.4202 144.626C96.2927 144.646 96.1666 144.674 96.0426 144.71C95.007 144.954 93.9859 145.255 92.984 145.612C92.512 145.782 92.04 145.961 91.568 146.15C90.8883 146.414 90.2181 146.688 89.5856 146.952L88.8351 147.269C87.4793 147.896 86.0743 148.41 84.6343 148.807C84.0419 148.964 83.4251 149.005 82.8171 148.93C82.5243 148.886 82.2376 148.809 81.9628 148.699C80.8914 148.227 80.712 147.415 82.685 146.15C84.7854 144.81 86.933 144.465 88.7926 144.928L92.951 140.585C88.8587 139.806 84.4927 140.467 80.207 142.568L76.6481 141.086L71.895 144.12L75.4539 145.603C73.7547 147.019 72.8626 148.354 72.9098 149.572V149.624C72.9806 150.861 73.9907 151.951 76.1006 152.834C80.7781 154.792 85.347 153.504 89.5195 151.828C93.3994 150.256 96.2172 148.59 98.4875 149.539C99.9035 150.138 99.4976 151.073 97.9447 152.069C96.9586 152.691 95.8562 153.105 94.7047 153.287C93.5532 153.469 92.3767 153.414 91.247 153.126L86.7914 157.648C87.6138 157.847 88.45 157.984 89.293 158.059C90.7433 158.18 92.2031 158.124 93.6401 157.893C95.9973 157.503 98.2803 156.752 100.409 155.666L103.967 157.157L108.777 154.202L107.876 153.82Z" fill="#F10086" />
                        <path opacity="0.1" d="M107.876 153.82L105.124 152.664C105.124 152.664 107.828 151.352 107.776 148.562C107.776 148.444 107.776 148.34 107.743 148.232C107.607 147.061 106.63 146.008 104.638 145.178C102.624 144.364 100.436 144.074 98.2798 144.333L97.737 144.399H97.6804L97.114 144.489C96.9488 144.517 96.7836 144.545 96.6184 144.583C96.5476 144.583 96.4815 144.607 96.4202 144.626C96.2927 144.646 96.1666 144.674 96.0426 144.71C95.007 144.954 93.9859 145.255 92.984 145.612C92.512 145.782 92.04 145.961 91.568 146.15C90.8883 146.414 90.2181 146.688 89.5856 146.952L88.8351 147.269C87.4793 147.896 86.0743 148.41 84.6343 148.807C84.0419 148.964 83.4251 149.005 82.8171 148.93C82.5243 148.886 82.2376 148.809 81.9628 148.699C80.8914 148.227 80.712 147.415 82.685 146.15C84.7854 144.81 86.933 144.465 88.7926 144.928L92.951 140.585C88.8587 139.806 84.4927 140.467 80.207 142.568L76.6481 141.086L71.895 144.12L75.4539 145.603C73.7547 147.019 72.8626 148.354 72.9098 149.572V149.624C72.9806 150.861 73.9907 151.951 76.1006 152.834C80.7781 154.792 85.347 153.504 89.5195 151.828C93.3994 150.256 96.2172 148.59 98.4875 149.539C99.9035 150.138 99.4976 151.073 97.9447 152.069C96.9586 152.691 95.8562 153.105 94.7047 153.287C93.5532 153.469 92.3767 153.414 91.247 153.126L86.7914 157.648C87.6138 157.847 88.45 157.984 89.293 158.059C90.7433 158.18 92.2031 158.124 93.6401 157.893C95.9973 157.503 98.2803 156.752 100.409 155.666L103.967 157.157L108.777 154.202L107.876 153.82Z" fill="white" />
                        <path d="M76.0722 152.81C77.8466 153.489 79.7451 153.784 81.6418 153.674C83.5831 153.542 85.4953 153.131 87.32 152.456C89.208 151.842 90.9733 151.04 92.7999 150.275C93.7168 149.885 94.6639 149.569 95.6319 149.331C96.57 149.09 97.5602 149.148 98.4639 149.497C97.5471 149.188 96.5566 149.175 95.6319 149.459C94.6871 149.719 93.7639 150.052 92.8707 150.455C91.0677 151.243 89.2694 152.064 87.3955 152.701C85.5502 153.371 83.6163 153.766 81.656 153.872C80.6897 153.902 79.723 153.829 78.7721 153.655C77.8456 153.466 76.9409 153.183 76.0722 152.81Z" fill="white" />
                        <path d="M103.967 157.167L103.972 161.358L100.413 159.862L100.409 155.67L103.967 157.167Z" fill="#F10086" />
                        <path opacity="0.35" d="M103.967 157.167L103.972 161.358L100.413 159.862L100.409 155.67L103.967 157.167Z" fill="black" />
                        <path d="M108.777 154.207L103.986 156.695L100.409 155.67L103.967 157.167L108.777 154.207Z" fill="#FAFAFA" />
                        <path d="M80.1786 142.568L76.6386 141.52L71.8667 144.12L76.615 141.086L80.1786 142.568Z" fill="#FAFAFA" />
                        <path opacity="0.15" d="M92.9321 140.59C92.1943 140.453 91.4487 140.363 90.6995 140.321L91.2801 142.313L92.9321 140.59Z" fill="white" />
                        <path opacity="0.15" d="M76.2327 152.871C78.179 153.648 80.284 153.942 82.3687 153.73L78.9326 142.072L76.6198 141.128L73.3771 143.195L73.9152 145.013L75.4256 145.645C75.0614 145.941 74.7132 146.256 74.3825 146.589L76.2327 152.871Z" fill="white" />
                        <path opacity="0.15" d="M103.92 144.904C102.78 144.518 101.588 144.301 100.385 144.262L104.161 157.058L106.993 155.316L106.365 153.183L105.138 152.668C105.458 152.5 105.758 152.297 106.035 152.064L103.92 144.904Z" fill="white" />
                        <path opacity="0.15" d="M97.704 144.399L97.1376 144.489C96.9724 144.517 96.8072 144.545 96.642 144.583C96.5759 144.583 96.5051 144.607 96.4438 144.626C96.3163 144.646 96.1902 144.674 96.0662 144.71C95.0226 144.953 93.9936 145.254 92.984 145.612C92.7622 145.688 92.545 145.777 92.3279 145.857L93.5693 150.105C95.4809 149.35 97.0762 148.949 98.4639 149.53C99.8516 150.11 99.474 151.064 97.9211 152.06C96.8869 152.717 95.7199 153.138 94.5038 153.291L95.731 157.464C97.3491 157.034 98.9182 156.438 100.413 155.684L101.754 156.246L98.261 144.342C98.091 144.342 97.9117 144.39 97.7418 144.404L97.704 144.399Z" fill="white" />
                        <path d="M125.141 140.236H129.139V148.42C129.139 149.401 129.008 150.378 128.747 151.323C128.653 151.658 128.54 151.989 128.417 152.319C127.769 153.964 126.823 155.476 125.628 156.78C125.181 157.276 124.708 157.749 124.212 158.196C123.685 158.684 123.134 159.144 122.56 159.574C121.787 160.163 120.984 160.71 120.152 161.212C119.246 161.764 118.302 162.283 117.32 162.764C116.575 163.133 115.81 163.477 115.017 163.798C103.444 168.584 87.4852 169.165 74.7506 165.28C73.8822 165.021 73.0326 164.733 72.2018 164.426C71.0077 163.992 69.8418 163.515 68.7326 163.01C67.817 162.58 66.9532 162.137 66.1366 161.669C65.4334 161.268 64.7631 160.853 64.1354 160.428C63.5076 160.003 62.9129 159.569 62.3606 159.121C60.836 157.926 59.5023 156.507 58.4053 154.91C58.1929 154.589 57.9899 154.273 57.8106 153.966C57.0451 152.641 56.5464 151.179 56.3426 149.662C56.2853 149.206 56.2585 148.747 56.2624 148.288V140.552H59.9204C60.213 140.236 60.5198 139.92 60.8644 139.608C62.2086 138.34 63.685 137.22 65.2682 136.266C66.2879 135.647 67.3387 135.08 68.4164 134.567C69.8644 133.879 71.3504 133.274 72.8674 132.755C83.3363 129.134 96.6656 128.648 107.994 131.461C109.649 131.868 111.283 132.358 112.888 132.929C114.205 133.401 115.48 133.921 116.702 134.496C118.455 135.307 120.135 136.267 121.724 137.366C122.953 138.21 124.098 139.171 125.141 140.236Z" fill="#F10086" />
                        <path opacity="0.15" d="M82.6 167.041V150.436L88.6794 151.073V167.692C86.6167 167.56 84.5824 167.352 82.6 167.041Z" fill="white" />
                        <path opacity="0.1" d="M129.139 148.402C129.139 149.382 129.008 150.359 128.747 151.304C128.653 151.639 128.54 151.97 128.417 152.3C127.768 153.945 126.823 155.457 125.628 156.761C125.467 156.94 125.297 157.115 125.127 157.289V140.208L125.156 140.236H129.153L129.139 148.402Z" fill="black" />
                        <path opacity="0.15" d="M108.867 140.769L111.987 140.033V164.912C110.992 165.227 109.974 165.529 108.933 165.818L108.867 140.769Z" fill="white" />
                        <path opacity="0.15" d="M100.824 167.343V145.777L106.903 146.226V166.309C104.895 166.756 102.866 167.101 100.824 167.343Z" fill="white" />
                        <path opacity="0.15" d="M56.2435 140.5H59.9015C60.1942 140.184 60.501 139.868 60.8455 139.556C62.1898 138.288 63.6661 137.168 65.2493 136.214C66.2691 135.595 67.3199 135.028 68.3975 134.515C69.8455 133.827 71.3316 133.222 72.8485 132.703C74.3164 132.193 75.8362 131.759 77.3986 131.372L77.4316 166.017C76.5301 165.79 75.638 165.545 74.7648 165.28C73.8916 165.016 73.0467 164.733 72.216 164.426C71.0218 163.992 69.856 163.515 68.7468 163.01C67.8311 162.58 66.9674 162.137 66.1508 161.669C65.4475 161.268 64.7773 160.853 64.1495 160.428C63.5218 160.003 62.927 159.569 62.3748 159.121C60.8502 157.926 59.5164 156.507 58.4194 154.91C58.207 154.589 58.0041 154.273 57.8247 153.966C57.0592 152.641 56.5606 151.179 56.3568 149.662C56.2995 149.206 56.2727 148.747 56.2766 148.288L56.2435 140.5Z" fill="white" />
                        <path opacity="0.15" d="M58.3958 154.877C58.1834 154.556 57.9805 154.24 57.8011 153.933C57.0356 152.608 56.537 151.146 56.3332 149.629C56.2759 149.173 56.2491 148.714 56.253 148.255V140.519H59.911L59.9582 140.472L61.1901 140.92V158.129C60.1334 157.16 59.1955 156.068 58.3958 154.877Z" fill="white" />
                        <path d="M116.66 125.675C115.437 125.104 114.163 124.584 112.846 124.112C111.24 123.541 109.606 123.051 107.951 122.644C96.6231 119.812 83.2938 120.318 72.8249 123.933C71.3083 124.455 69.8223 125.062 68.3739 125.75C67.2957 126.262 66.2449 126.829 65.2257 127.449C63.6419 128.4 62.1654 129.519 60.8219 130.786C59.5189 132.001 58.425 133.422 57.584 134.992C56.4805 137.008 56.0411 139.32 56.3285 141.6C56.5327 143.118 57.0296 144.581 57.7917 145.909C57.9758 146.23 58.174 146.551 58.3864 146.853C59.4838 148.451 60.8193 149.87 62.3465 151.064C62.9034 151.507 63.495 151.942 64.1212 152.366C64.7537 152.791 65.4192 153.206 66.1272 153.608C66.939 154.08 67.8075 154.519 68.7185 154.948C69.8324 155.472 70.9935 155.949 72.1877 156.364C73.0231 156.676 73.8727 156.959 74.7412 157.223C87.4852 161.103 103.429 160.527 115.008 155.741C115.796 155.415 116.565 155.071 117.306 154.707C118.297 154.235 119.246 153.707 120.138 153.155C120.966 152.65 121.768 152.103 122.541 151.517C123.135 151.045 123.688 150.606 124.197 150.138C124.694 149.692 125.167 149.219 125.613 148.722C126.81 147.419 127.757 145.907 128.408 144.262C128.53 143.932 128.639 143.601 128.733 143.266C129.137 141.846 129.238 140.357 129.031 138.895C128.643 136.521 127.602 134.302 126.024 132.486C124.757 130.984 123.293 129.66 121.672 128.549C120.088 127.448 118.41 126.486 116.66 125.675Z" fill="#F10086" />
                        <path opacity="0.1" d="M116.66 125.675C115.437 125.104 114.163 124.584 112.846 124.112C111.24 123.541 109.606 123.051 107.951 122.644C96.6231 119.812 83.2938 120.318 72.8249 123.933C71.3083 124.455 69.8223 125.062 68.3739 125.75C67.2957 126.262 66.2449 126.829 65.2257 127.449C63.6419 128.4 62.1654 129.519 60.8219 130.786C59.5189 132.001 58.425 133.422 57.584 134.992C56.4805 137.008 56.0411 139.32 56.3285 141.6C56.5327 143.118 57.0296 144.581 57.7917 145.909C57.9758 146.23 58.174 146.551 58.3864 146.853C59.4838 148.451 60.8193 149.87 62.3465 151.064C62.9034 151.507 63.495 151.942 64.1212 152.366C64.7537 152.791 65.4192 153.206 66.1272 153.608C66.939 154.08 67.8075 154.519 68.7185 154.948C69.8324 155.472 70.9935 155.949 72.1877 156.364C73.0231 156.676 73.8727 156.959 74.7412 157.223C87.4852 161.103 103.429 160.527 115.008 155.741C115.796 155.415 116.565 155.071 117.306 154.707C118.297 154.235 119.246 153.707 120.138 153.155C120.966 152.65 121.768 152.103 122.541 151.517C123.135 151.045 123.688 150.606 124.197 150.138C124.694 149.692 125.167 149.219 125.613 148.722C126.81 147.419 127.757 145.907 128.408 144.262C128.53 143.932 128.639 143.601 128.733 143.266C129.137 141.846 129.238 140.357 129.031 138.895C128.643 136.521 127.602 134.302 126.024 132.486C124.757 130.984 123.293 129.66 121.672 128.549C120.088 127.448 118.41 126.486 116.66 125.675Z" fill="white" />
                        <path opacity="0.2" d="M116.66 125.675C115.437 125.104 114.163 124.584 112.846 124.112C111.24 123.541 109.606 123.051 107.951 122.644C96.6231 119.812 83.2938 120.318 72.8249 123.933C71.3083 124.455 69.8223 125.062 68.3739 125.75C67.2957 126.262 66.2449 126.829 65.2257 127.449C63.6419 128.4 62.1654 129.519 60.8219 130.786C59.5189 132.001 58.425 133.422 57.584 134.992C56.4805 137.008 56.0411 139.32 56.3285 141.6C56.5327 143.118 57.0296 144.581 57.7917 145.909C57.9758 146.23 58.174 146.551 58.3864 146.853C59.4838 148.451 60.8193 149.87 62.3465 151.064C62.9034 151.507 63.495 151.942 64.1212 152.366C64.7537 152.791 65.4192 153.206 66.1272 153.608C66.939 154.08 67.8075 154.519 68.7185 154.948C69.8324 155.472 70.9935 155.949 72.1877 156.364C73.0231 156.676 73.8727 156.959 74.7412 157.223C87.4852 161.103 103.429 160.527 115.008 155.741C115.796 155.415 116.565 155.071 117.306 154.707C118.297 154.235 119.246 153.707 120.138 153.155C120.966 152.65 121.768 152.103 122.541 151.517C123.135 151.045 123.688 150.606 124.197 150.138C124.694 149.692 125.167 149.219 125.613 148.722C126.81 147.419 127.757 145.907 128.408 144.262C128.53 143.932 128.639 143.601 128.733 143.266C129.137 141.846 129.238 140.357 129.031 138.895C128.643 136.521 127.602 134.302 126.024 132.486C124.757 130.984 123.293 129.66 121.672 128.549C120.088 127.448 118.41 126.486 116.66 125.675Z" fill="white" />
                        <path opacity="0.15" d="M77.408 157.974C76.5018 157.747 75.605 157.502 74.727 157.237C73.8491 156.973 73.009 156.69 72.1735 156.383C70.9794 155.944 69.8135 155.467 68.7043 154.943C67.7934 154.514 66.9249 154.07 66.113 153.603C65.405 153.202 64.7395 152.786 64.107 152.362C63.4746 151.937 62.8893 151.503 62.3323 151.059C60.8052 149.866 59.4697 148.446 58.3722 146.849C58.1598 146.532 57.9616 146.211 57.7775 145.905C57.0154 144.576 56.5185 143.113 56.3143 141.595C56.0323 139.315 56.4766 137.005 57.584 134.992C58.4255 133.418 59.5211 131.994 60.8266 130.777C62.1701 129.509 63.6466 128.391 65.2304 127.44C66.1178 126.902 67.0523 126.397 68.0199 125.925L77.408 157.974Z" fill="white" />
                        <path opacity="0.15" d="M61.1382 150.02C60.1012 149.072 59.1777 148.006 58.3864 146.844C58.174 146.528 57.9758 146.207 57.7917 145.9C57.0296 144.572 56.5327 143.108 56.3285 141.591C56.0952 139.742 56.3491 137.864 57.0648 136.144L61.1382 150.02Z" fill="white" />
                        <path opacity="0.15" d="M88.6463 159.663C86.5978 159.541 84.5682 159.319 82.5717 159.007L72.3293 124.136L72.8013 123.957C74.3778 123.414 76.025 122.947 77.7148 122.541L88.6463 159.663Z" fill="white" />
                        <path opacity="0.15" d="M106.889 158.28C104.916 158.719 102.882 159.064 100.814 159.314L89.5384 120.903C91.6577 120.808 93.7864 120.818 95.9246 120.903L106.889 158.28Z" fill="white" />
                        <path opacity="0.15" d="M111.977 156.874C110.972 157.209 109.948 157.511 108.9 157.79L98.1194 121.063C99.2758 121.158 100.427 121.276 101.57 121.431L111.977 156.874Z" fill="white" />
                        <path opacity="0.3" d="M118.472 126.59C118.944 126.845 119.416 127.105 119.855 127.369L120.299 127.638L120.742 127.917C121.063 128.124 121.384 128.337 121.686 128.549C123.307 129.66 124.771 130.984 126.038 132.486C127.617 134.303 128.658 136.524 129.045 138.9C129.252 140.362 129.151 141.851 128.747 143.271C128.653 143.606 128.544 143.936 128.422 144.267C127.771 145.912 126.824 147.424 125.628 148.727C125.472 148.907 125.302 149.086 125.127 149.261L118.472 126.59Z" fill="#F10086" />
                        <path d="M110.448 129.366C99.5684 124.311 83.3505 124.688 73.3771 130.064C72.957 130.291 72.537 130.536 72.1358 130.777C62.3229 136.823 63.5595 145.99 74.9017 151.252C86.2438 156.515 103.401 155.892 113.219 149.836C113.615 149.591 114.002 149.336 114.361 149.081C122.928 143.058 121.342 134.416 110.448 129.366Z" fill="#F10086" />
                        <path opacity="0.4" d="M110.448 129.366C99.5684 124.311 83.3505 124.688 73.3771 130.064C72.957 130.291 72.537 130.536 72.1358 130.777C62.3229 136.823 63.5595 145.99 74.9017 151.252C86.2438 156.515 103.401 155.892 113.219 149.836C113.615 149.591 114.002 149.336 114.361 149.081C122.928 143.058 121.342 134.416 110.448 129.366Z" fill="black" />
                        <path d="M109.669 129.852C109.32 129.687 108.961 129.531 108.598 129.38C98.2138 125.056 83.4543 125.557 74.2456 130.522L73.9954 130.654C73.6792 130.829 73.363 131.013 73.0514 131.202C71.3907 132.18 69.9184 133.448 68.7043 134.945C66.2405 138.107 66.0894 141.633 68.1568 144.824C69.3698 146.712 71.3475 148.453 74.0757 149.945C74.5902 150.228 75.1282 150.502 75.6946 150.766C76.261 151.031 76.7472 151.238 77.29 151.451C78.4555 151.911 79.6451 152.308 80.8536 152.64C89.4346 155 99.8374 154.623 107.701 151.649C108.798 151.244 109.868 150.772 110.906 150.233C111.378 149.983 111.85 149.718 112.322 149.44C112.794 149.161 113.072 148.968 113.417 148.718L113.705 148.51C115.999 146.825 117.481 144.946 118.184 143.002C119.374 139.698 118.278 136.21 114.993 133.26C113.395 131.869 111.601 130.721 109.669 129.852Z" fill="#F10086" />
                        <path opacity="0.5" d="M109.669 129.852C109.32 129.687 108.961 129.531 108.598 129.38C98.2138 125.056 83.4543 125.557 74.2456 130.522L73.9954 130.654C73.6792 130.829 73.363 131.013 73.0514 131.202C71.3907 132.18 69.9184 133.448 68.7043 134.945C66.2405 138.107 66.0894 141.633 68.1568 144.824C69.3698 146.712 71.3475 148.453 74.0757 149.945C74.5902 150.228 75.1282 150.502 75.6946 150.766C76.261 151.031 76.7472 151.238 77.29 151.451C78.4555 151.911 79.6451 152.308 80.8536 152.64C89.4346 155 99.8374 154.623 107.701 151.649C108.798 151.244 109.868 150.772 110.906 150.233C111.378 149.983 111.85 149.718 112.322 149.44C112.794 149.161 113.072 148.968 113.417 148.718L113.705 148.51C115.999 146.825 117.481 144.946 118.184 143.002C119.374 139.698 118.278 136.21 114.993 133.26C113.395 131.869 111.601 130.721 109.669 129.852Z" fill="black" />
                        <path d="M108.607 132.429C108.975 132.58 109.329 132.741 109.679 132.901C111.608 133.762 113.399 134.905 114.993 136.29C116.914 138.013 118.08 139.915 118.486 141.86C118.409 142.241 118.305 142.616 118.175 142.983C117.476 144.928 115.989 146.806 113.695 148.491L113.407 148.699C113.063 148.944 112.695 149.171 112.312 149.421C111.93 149.671 111.397 149.964 110.896 150.214C109.859 150.753 108.788 151.226 107.692 151.63C99.828 154.604 89.4251 154.986 80.8442 152.621C79.6357 152.289 78.446 151.892 77.2806 151.432C76.7378 151.219 76.2044 150.988 75.6852 150.747C75.166 150.507 74.5807 150.209 74.0662 149.926C71.3381 148.435 69.3462 146.674 68.1474 144.805C67.5437 143.891 67.1134 142.873 66.8777 141.803C67.1814 140.403 67.8068 139.092 68.7043 137.975C69.9213 136.479 71.3949 135.212 73.0562 134.232C73.3582 134.043 73.6698 133.864 74.0002 133.685L74.2503 133.552C83.4685 128.606 98.2421 128.106 108.607 132.429Z" fill="#F10086" />
                        <path opacity="0.25" d="M108.607 132.429C108.975 132.58 109.329 132.741 109.679 132.901C111.608 133.762 113.399 134.905 114.993 136.29C116.914 138.013 118.08 139.915 118.486 141.86C118.409 142.241 118.305 142.616 118.175 142.983C117.476 144.928 115.989 146.806 113.695 148.491L113.407 148.699C113.063 148.944 112.695 149.171 112.312 149.421C111.93 149.671 111.397 149.964 110.896 150.214C109.859 150.753 108.788 151.226 107.692 151.63C99.828 154.604 89.4251 154.986 80.8442 152.621C79.6357 152.289 78.446 151.892 77.2806 151.432C76.7378 151.219 76.2044 150.988 75.6852 150.747C75.166 150.507 74.5807 150.209 74.0662 149.926C71.3381 148.435 69.3462 146.674 68.1474 144.805C67.5437 143.891 67.1134 142.873 66.8777 141.803C67.1814 140.403 67.8068 139.092 68.7043 137.975C69.9213 136.479 71.3949 135.212 73.0562 134.232C73.3582 134.043 73.6698 133.864 74.0002 133.685L74.2503 133.552C83.4685 128.606 98.2421 128.106 108.607 132.429Z" fill="black" />
                        <path d="M128.412 144.267C124.287 153.438 112.747 157.096 103.34 158.748C89.0381 160.801 73.4715 159.083 62.4126 150.941H62.4362C59.4295 148.581 56.8666 145.4 56.3285 141.558C56.8005 145.442 59.2738 148.722 62.2804 151.153C71.187 158.059 83.5204 160.296 95.3204 159.961C107.191 159.272 123.017 156.072 128.412 144.267Z" fill="#F5F5F5" />
                        <path d="M77.6959 147.07C81.7362 148.76 85.6963 148.014 89.3874 146.721L88.415 147.736V151.932C89.2365 152.127 90.0709 152.263 90.9119 152.338C92.3623 152.459 93.822 152.404 95.259 152.173C97.6167 151.784 99.9 151.033 102.028 149.945L105.591 151.441L110.396 148.482V144.286L109.49 143.903L109.296 143.818C109.371 143.498 109.405 143.17 109.395 142.841C109.394 142.729 109.384 142.617 109.367 142.506C109.306 141.992 109.41 139.622 109.395 138.645C109.395 138.527 109.395 138.419 109.362 138.315C109.221 137.14 108.248 136.087 106.257 135.261C104.243 134.444 102.056 134.152 99.8988 134.411L99.356 134.482H99.2994L98.733 134.572C98.5678 134.6 98.4026 134.629 98.2374 134.671C98.1666 134.671 98.1005 134.695 98.0391 134.714C97.9778 134.732 97.7795 134.761 97.6615 134.794C96.6249 135.039 95.6023 135.34 94.5982 135.695C94.1546 135.851 93.7156 136.016 93.2861 136.191L94.5558 134.869V130.673C90.4635 129.894 86.0975 130.555 81.8118 132.656L78.2482 131.169L73.4998 134.204V138.4L74.6138 138.872C74.5362 139.129 74.5011 139.397 74.5099 139.665V139.698C74.543 140.349 74.4816 143.125 74.5099 143.861V143.894C74.576 145.098 75.5908 146.188 77.6959 147.07Z" fill="#F10086" />
                        <path opacity="0.15" d="M109.395 138.825C109.395 139.901 109.32 142.011 109.372 142.492C109.372 142.605 109.395 142.709 109.405 142.822C109.412 143.154 109.377 143.486 109.301 143.809L106.743 142.733C106.743 142.733 109.325 141.487 109.395 138.825Z" fill="black" />
                        <path opacity="0.45" d="M83.5676 138.768C82.5009 138.324 82.3215 137.489 84.285 136.224C86.3902 134.893 88.5566 134.544 90.421 135.006L94.5841 130.668V134.855L93.3286 136.181L93.2011 136.229C92.5167 136.493 91.8559 136.762 91.2234 137.022C90.9686 137.135 90.7184 137.243 90.4729 137.338C89.1197 137.972 87.7142 138.489 86.2722 138.881C85.6801 139.04 85.0629 139.081 84.4549 139.004C84.1498 138.966 83.8513 138.887 83.5676 138.768Z" fill="black" />
                        <path opacity="0.2" d="M74.5996 138.839L73.4904 138.367V134.18L77.0351 135.667C75.723 136.767 74.8781 137.824 74.5996 138.839Z" fill="black" />
                        <path opacity="0.15" d="M105.591 147.226L110.401 144.272V148.463L105.586 151.432L102.037 149.931C99.9069 151.019 97.6201 151.769 95.259 152.154C92.9849 152.534 90.6575 152.454 88.415 151.918V147.736C90.6567 148.278 92.9849 148.36 95.259 147.977C97.6204 147.591 99.9071 146.839 102.037 145.749L105.591 147.226Z" fill="black" />
                        <path opacity="0.2" d="M102.042 149.931C100.378 150.963 98.5526 151.71 96.642 152.14C94.7651 152.584 92.8182 152.647 90.9166 152.324C92.8145 152.302 94.7048 152.082 96.557 151.668C97.501 151.465 98.4026 151.196 99.3182 150.908C100.234 150.62 101.131 150.275 102.042 149.931Z" fill="black" />
                        <path opacity="0.35" d="M74.5052 143.861V143.837C74.4722 143.096 74.5382 140.326 74.5052 139.665C74.5807 140.906 75.5861 141.996 77.6912 142.879C82.3829 144.833 86.9471 143.545 91.1243 141.874C94.9994 140.302 97.8173 138.636 100.092 139.58C101.508 140.175 101.107 141.119 99.559 142.11C98.5724 142.733 97.469 143.147 96.3165 143.328C95.1639 143.509 93.9865 143.453 92.8566 143.162L89.4015 146.726C85.7058 148.033 81.7504 148.756 77.7195 147.066C75.5814 146.193 74.576 145.098 74.5052 143.861Z" fill="black" />
                        <path d="M90.3974 139.188V134.997L94.5558 130.654L94.5605 134.85L90.3974 139.188Z" fill="#F10086" />
                        <path opacity="0.15" d="M90.3974 139.188V134.997L94.5558 130.654L94.5605 134.85L90.3974 139.188Z" fill="black" />
                        <path d="M73.4904 134.185L77.0493 135.672L74.609 138.844L73.4951 138.381L73.4904 134.185Z" fill="#F10086" />
                        <path opacity="0.45" d="M73.4904 134.185L77.0493 135.672L74.609 138.844L73.4951 138.381L73.4904 134.185Z" fill="black" />
                        <path d="M109.504 143.884L106.757 142.728C106.757 142.728 109.462 141.421 109.41 138.631C109.41 138.513 109.41 138.405 109.377 138.301C109.235 137.125 108.263 136.073 106.271 135.247C104.258 134.43 102.07 134.137 99.913 134.397L99.3702 134.468H99.3135L98.7471 134.558C98.5819 134.586 98.4167 134.614 98.2515 134.657C98.1807 134.657 98.1146 134.68 98.0533 134.699C97.9919 134.718 97.7937 134.747 97.6757 134.78C96.6391 135.025 95.6165 135.326 94.6124 135.681C94.1404 135.851 93.6684 136.03 93.1964 136.219C92.5167 136.488 91.8465 136.762 91.214 137.022L90.4635 137.338C89.1094 137.969 87.7041 138.484 86.2627 138.877C85.6706 139.034 85.0538 139.078 84.4455 139.004C84.1526 138.959 83.8659 138.88 83.5912 138.768C82.5198 138.32 82.3404 137.484 84.3134 136.224C86.4138 134.879 88.5614 134.534 90.421 135.001L94.5794 130.659C90.4871 129.88 86.1211 130.541 81.8354 132.641L78.2765 131.155L73.5234 134.19L77.0823 135.672C75.3831 137.088 74.491 138.428 74.5382 139.641V139.674C74.6043 140.906 75.6191 142.001 77.729 142.879C82.4065 144.843 86.9754 143.554 91.1479 141.874C95.0278 140.302 97.8456 138.641 100.116 139.589C101.532 140.184 101.126 141.123 99.5731 142.114C98.587 142.736 97.4846 143.151 96.3331 143.332C95.1816 143.514 94.0051 143.459 92.8754 143.172L88.415 147.736C89.238 147.931 90.0741 148.067 90.9166 148.142C92.3653 148.265 93.8234 148.211 95.259 147.981C97.6182 147.59 99.9029 146.837 102.032 145.749L105.591 147.245L110.401 144.286L109.504 143.884Z" fill="#F10086" />
                        <path opacity="0.1" d="M109.504 143.884L106.757 142.728C106.757 142.728 109.462 141.421 109.41 138.631C109.41 138.513 109.41 138.405 109.377 138.301C109.235 137.125 108.263 136.073 106.271 135.247C104.258 134.43 102.07 134.137 99.913 134.397L99.3702 134.468H99.3135L98.7471 134.558C98.5819 134.586 98.4167 134.614 98.2515 134.657C98.1807 134.657 98.1146 134.68 98.0533 134.699C97.9919 134.718 97.7937 134.747 97.6757 134.78C96.6391 135.025 95.6165 135.326 94.6124 135.681C94.1404 135.851 93.6684 136.03 93.1964 136.219C92.5167 136.488 91.8465 136.762 91.214 137.022L90.4635 137.338C89.1094 137.969 87.7041 138.484 86.2627 138.877C85.6706 139.034 85.0538 139.078 84.4455 139.004C84.1526 138.959 83.8659 138.88 83.5912 138.768C82.5198 138.32 82.3404 137.484 84.3134 136.224C86.4138 134.879 88.5614 134.534 90.421 135.001L94.5794 130.659C90.4871 129.88 86.1211 130.541 81.8354 132.641L78.2765 131.155L73.5234 134.19L77.0823 135.672C75.3831 137.088 74.491 138.428 74.5382 139.641V139.674C74.6043 140.906 75.6191 142.001 77.729 142.879C82.4065 144.843 86.9754 143.554 91.1479 141.874C95.0278 140.302 97.8456 138.641 100.116 139.589C101.532 140.184 101.126 141.123 99.5731 142.114C98.587 142.736 97.4846 143.151 96.3331 143.332C95.1816 143.514 94.0051 143.459 92.8754 143.172L88.415 147.736C89.238 147.931 90.0741 148.067 90.9166 148.142C92.3653 148.265 93.8234 148.211 95.259 147.981C97.6182 147.59 99.9029 146.837 102.032 145.749L105.591 147.245L110.401 144.286L109.504 143.884Z" fill="white" />
                        <path d="M77.6959 142.874C79.4699 143.555 81.3684 143.851 83.2655 143.743C85.201 143.612 87.108 143.206 88.9295 142.539C90.8175 141.926 92.5828 141.123 94.4094 140.354C95.327 139.965 96.274 139.649 97.2414 139.41C98.1796 139.168 99.1698 139.226 100.073 139.575C99.1567 139.268 98.167 139.253 97.2414 139.533C96.2972 139.796 95.3742 140.131 94.4802 140.533C92.6772 141.317 90.8789 142.138 89.005 142.78C87.1572 143.454 85.22 143.852 83.2561 143.96C82.2897 143.992 81.3228 143.92 80.3722 143.743C79.4523 143.547 78.5553 143.256 77.6959 142.874Z" fill="white" />
                        <path d="M105.591 147.231L105.596 151.427L102.037 149.931L102.032 145.735L105.591 147.231Z" fill="#F10086" />
                        <path opacity="0.35" d="M105.591 147.231L105.596 151.427L102.037 149.931L102.032 145.735L105.591 147.231Z" fill="black" />
                        <path d="M110.401 144.272L105.61 146.759L102.032 145.735L105.591 147.231L110.401 144.272Z" fill="#FAFAFA" />
                        <path d="M81.8023 132.637L78.2623 131.589L73.4904 134.185L78.2387 131.155L81.8023 132.637Z" fill="#FAFAFA" />
                        <path opacity="0.15" d="M94.5558 130.654C93.8164 130.519 93.0691 130.43 92.3185 130.39L92.9038 132.377L94.5558 130.654Z" fill="white" />
                        <path opacity="0.15" d="M77.88 142.936C79.8259 143.714 81.9309 144.01 84.016 143.8L80.5846 132.118L78.2718 131.15L75.0291 133.222L75.52 135.039L77.0351 135.667C76.6681 135.968 76.3198 136.291 75.992 136.635L77.88 142.936Z" fill="white" />
                        <path opacity="0.15" d="M105.544 134.992C104.404 134.604 103.212 134.386 102.009 134.345L105.785 147.146L108.617 145.404L107.989 143.271L106.762 142.752C107.081 142.584 107.381 142.383 107.658 142.152L105.544 134.992Z" fill="white" />
                        <path opacity="0.15" d="M99.3041 134.463L98.7377 134.553C98.5725 134.581 98.4073 134.61 98.2421 134.652C98.1713 134.652 98.1052 134.676 98.0438 134.695C97.9825 134.714 97.7842 134.742 97.6662 134.775C96.6296 135.02 95.6071 135.321 94.603 135.676L93.928 135.936L95.1694 140.184C97.081 139.429 98.6763 139.032 100.064 139.613C101.452 140.193 101.074 141.147 99.5212 142.138C98.4872 142.797 97.3202 143.219 96.1039 143.375L97.3311 147.547C98.9486 147.114 100.518 146.516 102.013 145.763L103.368 146.32L99.8752 134.411L99.356 134.478L99.3041 134.463Z" fill="white" />
                        <path d="M132.958 126.968H136.956V135.148C136.96 136.13 136.828 137.109 136.564 138.055C136.469 138.386 136.361 138.716 136.233 139.046C135.586 140.692 134.64 142.204 133.444 143.507C132.998 144.005 132.526 144.477 132.028 144.923C131.518 145.395 130.966 145.867 130.371 146.301C129.599 146.888 128.797 147.434 127.969 147.939C127.062 148.491 126.114 149.006 125.137 149.492C124.396 149.855 123.626 150.2 122.838 150.526C111.26 155.307 95.3015 155.887 82.5717 152.008C81.7001 151.743 80.8489 151.459 80.0182 151.153C78.824 150.714 77.6582 150.238 76.549 149.714C75.638 149.284 74.7695 148.841 73.9577 148.373C73.2497 147.972 72.5842 147.557 71.9517 147.132C71.3192 146.707 70.7339 146.273 70.177 145.829C68.6498 144.636 67.3143 143.216 66.2169 141.619C66.0045 141.303 65.8062 140.982 65.6222 140.675C64.8601 139.347 64.3631 137.883 64.159 136.366C64.0998 135.91 64.0714 135.451 64.074 134.992V127.256H67.7367C68.0246 126.94 68.3314 126.623 68.6807 126.312C70.0259 125.044 71.504 123.925 73.0892 122.975C74.1079 122.353 75.1588 121.786 76.2374 121.276C77.6846 120.584 79.1708 119.977 80.6884 119.458C91.1526 115.838 104.487 115.352 115.81 118.165C117.467 118.572 119.103 119.063 120.709 119.638C122.021 120.11 123.296 120.629 124.518 121.2C126.272 122.011 127.952 122.971 129.54 124.07C130.772 124.922 131.916 125.892 132.958 126.968Z" fill="#F10086" />
                        <path opacity="0.15" d="M90.4022 153.778V137.173L96.4674 137.824V154.443C94.4283 154.311 92.4066 154.089 90.4022 153.778Z" fill="white" />
                        <path opacity="0.1" d="M136.956 135.138C136.96 136.121 136.828 137.1 136.564 138.046C136.469 138.376 136.361 138.707 136.233 139.037C135.586 140.683 134.64 142.195 133.444 143.497C133.288 143.677 133.118 143.851 132.948 144.031V126.944L132.972 126.973H136.97L136.956 135.138Z" fill="black" />
                        <path opacity="0.15" d="M116.683 127.506L119.803 126.77V151.654C118.812 151.979 117.792 152.281 116.754 152.555L116.683 127.506Z" fill="white" />
                        <path opacity="0.15" d="M108.64 154.084V132.514L114.72 132.962V153.046C112.712 153.494 110.683 153.841 108.64 154.084Z" fill="white" />
                        <path opacity="0.15" d="M64.0598 127.237H67.7226C68.0105 126.921 68.3173 126.605 68.6666 126.293C70.0117 125.025 71.4898 123.907 73.075 122.956C74.0937 122.334 75.1446 121.767 76.2233 121.257C77.6704 120.565 79.1566 119.959 80.6742 119.44C82.1374 118.935 83.662 118.496 85.2243 118.113L85.2526 152.753C84.3495 152.53 83.4622 152.286 82.5906 152.022C81.7189 151.757 80.8678 151.473 80.037 151.167C78.8429 150.728 77.677 150.252 76.5678 149.728C75.6569 149.298 74.7884 148.855 73.9766 148.387C73.2686 147.986 72.603 147.571 71.9706 147.146C71.3381 146.721 70.7528 146.287 70.1958 145.843C68.6687 144.65 67.3332 143.23 66.2358 141.633C66.0234 141.317 65.8251 140.996 65.641 140.689C64.879 139.361 64.382 137.897 64.1778 136.38C64.1187 135.924 64.0903 135.465 64.0929 135.006L64.0598 127.237Z" fill="white" />
                        <path opacity="0.15" d="M66.2122 141.6C65.9998 141.284 65.8015 140.963 65.6174 140.656C64.8554 139.328 64.3584 137.864 64.1542 136.347C64.0951 135.891 64.0667 135.432 64.0693 134.973V127.237H67.732L67.7745 127.19L69.0064 127.638V144.847C67.9489 143.88 67.0108 142.79 66.2122 141.6Z" fill="white" />
                        <path d="M124.476 112.416C123.253 111.845 121.979 111.321 120.667 110.854C119.06 110.279 117.425 109.788 115.767 109.381C104.439 106.549 91.1102 107.054 80.6459 110.675C79.1279 111.195 77.6403 111.8 76.1902 112.487C75.1145 113.002 74.0653 113.571 73.0467 114.191C71.4615 115.142 69.9834 116.26 68.6382 117.528C67.3334 118.746 66.2379 120.17 65.3956 121.743C64.3042 123.751 63.8669 126.05 64.1448 128.318C64.3466 129.838 64.8437 131.303 65.608 132.632C65.7874 132.953 65.9903 133.274 66.198 133.576C67.2966 135.174 68.6319 136.595 70.1581 137.791C70.7103 138.235 71.3003 138.674 71.9281 139.098C72.5558 139.523 73.2261 139.934 73.9341 140.34C74.7506 140.812 75.6144 141.251 76.5301 141.676C77.644 142.199 78.8051 142.681 79.9993 143.115C80.83 143.43 81.6796 143.715 82.5481 143.969C95.2921 147.849 111.241 147.273 122.814 142.487C123.607 142.162 124.372 141.817 125.118 141.454C126.109 140.982 127.053 140.453 127.95 139.901C128.78 139.397 129.584 138.851 130.357 138.263C130.93 137.831 131.482 137.372 132.009 136.885C132.505 136.438 132.978 135.965 133.425 135.469C134.621 134.165 135.567 132.651 136.214 131.004C136.337 130.673 136.45 130.343 136.545 130.012C136.944 128.592 137.044 127.103 136.838 125.642C136.453 123.264 135.412 121.04 133.831 119.222C132.567 117.719 131.104 116.395 129.484 115.286C127.9 114.188 126.224 113.228 124.476 112.416Z" fill="#F10086" />
                        <path opacity="0.1" d="M124.476 112.416C123.253 111.845 121.979 111.321 120.667 110.854C119.06 110.279 117.425 109.788 115.767 109.381C104.439 106.549 91.1102 107.054 80.6459 110.675C79.1279 111.195 77.6403 111.8 76.1902 112.487C75.1145 113.002 74.0653 113.571 73.0467 114.191C71.4615 115.142 69.9834 116.26 68.6382 117.528C67.3334 118.746 66.2379 120.17 65.3956 121.743C64.3042 123.751 63.8669 126.05 64.1448 128.318C64.3466 129.838 64.8437 131.303 65.608 132.632C65.7874 132.953 65.9903 133.274 66.198 133.576C67.2966 135.174 68.6319 136.595 70.1581 137.791C70.7103 138.235 71.3003 138.674 71.9281 139.098C72.5558 139.523 73.2261 139.934 73.9341 140.34C74.7506 140.812 75.6144 141.251 76.5301 141.676C77.644 142.199 78.8051 142.681 79.9993 143.115C80.83 143.43 81.6796 143.715 82.5481 143.969C95.2921 147.849 111.241 147.273 122.814 142.487C123.607 142.162 124.372 141.817 125.118 141.454C126.109 140.982 127.053 140.453 127.95 139.901C128.78 139.397 129.584 138.851 130.357 138.263C130.93 137.831 131.482 137.372 132.009 136.885C132.505 136.438 132.978 135.965 133.425 135.469C134.621 134.165 135.567 132.651 136.214 131.004C136.337 130.673 136.45 130.343 136.545 130.012C136.944 128.592 137.044 127.103 136.838 125.642C136.453 123.264 135.412 121.04 133.831 119.222C132.567 117.719 131.104 116.395 129.484 115.286C127.9 114.188 126.224 113.228 124.476 112.416Z" fill="white" />
                        <path opacity="0.2" d="M124.476 112.416C123.253 111.845 121.979 111.321 120.667 110.854C119.06 110.279 117.425 109.788 115.767 109.381C104.439 106.549 91.1102 107.054 80.6459 110.675C79.1279 111.195 77.6403 111.8 76.1902 112.487C75.1145 113.002 74.0653 113.571 73.0467 114.191C71.4615 115.142 69.9834 116.26 68.6382 117.528C67.3334 118.746 66.2379 120.17 65.3956 121.743C64.3042 123.751 63.8669 126.05 64.1448 128.318C64.3466 129.838 64.8437 131.303 65.608 132.632C65.7874 132.953 65.9903 133.274 66.198 133.576C67.2966 135.174 68.6319 136.595 70.1581 137.791C70.7103 138.235 71.3003 138.674 71.9281 139.098C72.5558 139.523 73.2261 139.934 73.9341 140.34C74.7506 140.812 75.6144 141.251 76.5301 141.676C77.644 142.199 78.8051 142.681 79.9993 143.115C80.83 143.43 81.6796 143.715 82.5481 143.969C95.2921 147.849 111.241 147.273 122.814 142.487C123.607 142.162 124.372 141.817 125.118 141.454C126.109 140.982 127.053 140.453 127.95 139.901C128.78 139.397 129.584 138.851 130.357 138.263C130.93 137.831 131.482 137.372 132.009 136.885C132.505 136.438 132.978 135.965 133.425 135.469C134.621 134.165 135.567 132.651 136.214 131.004C136.337 130.673 136.45 130.343 136.545 130.012C136.944 128.592 137.044 127.103 136.838 125.642C136.453 123.264 135.412 121.04 133.831 119.222C132.567 117.719 131.104 116.395 129.484 115.286C127.9 114.188 126.224 113.228 124.476 112.416Z" fill="white" />
                        <path opacity="0.15" d="M85.2385 144.71C84.3322 144.489 83.4354 144.238 82.5575 143.974C81.6796 143.71 80.8394 143.427 80.0087 143.12C78.8146 142.686 77.6487 142.204 76.5395 141.68C75.6238 141.255 74.7601 140.807 73.9435 140.344C73.2355 139.939 72.57 139.528 71.9375 139.103C71.305 138.678 70.7198 138.239 70.1675 137.796C68.6414 136.6 67.3061 135.178 66.2074 133.581C65.9998 133.265 65.7968 132.944 65.6174 132.637C64.8526 131.311 64.354 129.849 64.1495 128.332C63.8717 126.064 64.3089 123.765 65.4003 121.757C66.2426 120.184 67.3382 118.76 68.643 117.542C69.9882 116.275 71.4662 115.156 73.0514 114.205C73.9341 113.662 74.8686 113.162 75.8362 112.685L85.2385 144.71Z" fill="white" />
                        <path opacity="0.15" d="M68.9592 136.757C67.9184 135.811 66.9932 134.745 66.2027 133.581C65.995 133.265 65.7921 132.944 65.6127 132.637C64.8479 131.311 64.3493 129.849 64.1448 128.332C63.914 126.483 64.1677 124.606 64.8811 122.885L68.9592 136.757Z" fill="white" />
                        <path opacity="0.15" d="M96.4626 146.4C94.4189 146.278 92.3893 146.056 90.3927 145.749L80.1456 110.854L80.6459 110.675C82.2589 110.12 83.8988 109.648 85.5594 109.259L96.4626 146.4Z" fill="white" />
                        <path opacity="0.15" d="M114.696 145.017C112.688 145.464 110.659 145.809 108.617 146.051L97.3406 107.644C99.4646 107.55 101.589 107.555 103.727 107.644L114.696 145.017Z" fill="white" />
                        <path opacity="0.15" d="M119.794 143.615C118.793 143.946 117.764 144.248 116.716 144.526L105.936 107.805C107.092 107.899 108.244 108.017 109.386 108.168L119.794 143.615Z" fill="white" />
                        <path opacity="0.3" d="M126.284 113.327C126.756 113.582 127.228 113.842 127.662 114.111C127.813 114.196 127.959 114.285 128.106 114.375L128.549 114.658C128.875 114.866 129.191 115.074 129.493 115.286C131.114 116.395 132.576 117.719 133.84 119.222C135.421 121.04 136.463 123.264 136.847 125.642C137.054 127.103 136.954 128.592 136.554 130.012C136.46 130.343 136.347 130.673 136.224 131.004C135.577 132.651 134.631 134.165 133.434 135.469C133.274 135.648 133.104 135.823 132.934 135.997L126.284 113.327Z" fill="#F10086" />
                        <path d="M118.278 116.112C107.394 111.062 91.1762 111.434 81.2076 116.811C80.7828 117.037 80.3674 117.283 79.9662 117.523C70.1486 123.57 71.3853 132.736 82.7322 138.003C94.079 143.271 111.227 142.638 121.044 136.587C121.446 136.342 121.828 136.087 122.191 135.832C130.744 129.8 129.158 121.153 118.278 116.112Z" fill="#F10086" />
                        <path opacity="0.4" d="M118.278 116.112C107.394 111.062 91.1762 111.434 81.2076 116.811C80.7828 117.037 80.3674 117.283 79.9662 117.523C70.1486 123.57 71.3853 132.736 82.7322 138.003C94.079 143.271 111.227 142.638 121.044 136.587C121.446 136.342 121.828 136.087 122.191 135.832C130.744 129.8 129.158 121.153 118.278 116.112Z" fill="black" />
                        <path d="M117.486 116.584C117.136 116.424 116.782 116.263 116.414 116.112C106.03 111.784 91.2706 112.284 82.0619 117.25L81.8165 117.382C81.4955 117.561 81.184 117.74 80.8725 117.929C79.2127 118.909 77.7406 120.176 76.5254 121.672C74.0662 124.835 73.9152 128.36 75.9778 131.551C77.1956 133.439 79.1733 135.181 81.9014 136.677C82.4112 136.96 82.9493 137.234 83.5157 137.494C84.0821 137.753 84.5682 137.966 85.1158 138.183C86.2797 138.642 87.4678 139.037 88.6746 139.367C97.2603 141.727 107.663 141.35 115.522 138.381C116.621 137.969 117.693 137.488 118.732 136.941C119.204 136.691 119.676 136.427 120.124 136.148C120.572 135.87 120.874 135.676 121.219 135.426C121.318 135.36 121.417 135.289 121.507 135.219C123.806 133.538 125.283 131.655 125.986 129.71C127.176 126.406 126.085 122.918 122.796 119.968C121.202 118.585 119.413 117.444 117.486 116.584Z" fill="#F10086" />
                        <path opacity="0.5" d="M117.486 116.584C117.136 116.424 116.782 116.263 116.414 116.112C106.03 111.784 91.2706 112.284 82.0619 117.25L81.8165 117.382C81.4955 117.561 81.184 117.74 80.8725 117.929C79.2127 118.909 77.7406 120.176 76.5254 121.672C74.0662 124.835 73.9152 128.36 75.9778 131.551C77.1956 133.439 79.1733 135.181 81.9014 136.677C82.4112 136.96 82.9493 137.234 83.5157 137.494C84.0821 137.753 84.5682 137.966 85.1158 138.183C86.2797 138.642 87.4678 139.037 88.6746 139.367C97.2603 141.727 107.663 141.35 115.522 138.381C116.621 137.969 117.693 137.488 118.732 136.941C119.204 136.691 119.676 136.427 120.124 136.148C120.572 135.87 120.874 135.676 121.219 135.426C121.318 135.36 121.417 135.289 121.507 135.219C123.806 133.538 125.283 131.655 125.986 129.71C127.176 126.406 126.085 122.918 122.796 119.968C121.202 118.585 119.413 117.444 117.486 116.584Z" fill="black" />
                        <path d="M116.428 119.171C116.792 119.317 117.15 119.477 117.5 119.643C119.429 120.506 121.219 121.65 122.814 123.036C124.731 124.754 125.897 126.657 126.302 128.601C126.228 128.983 126.124 129.359 125.991 129.725C125.297 131.669 123.81 133.552 121.512 135.233C121.422 135.304 121.323 135.374 121.224 135.44C120.879 135.686 120.511 135.912 120.129 136.163C119.746 136.413 119.218 136.705 118.736 136.956C117.698 137.502 116.626 137.983 115.527 138.395C107.668 141.364 97.265 141.746 88.6794 139.382C87.4725 139.051 86.2844 138.656 85.1205 138.197C84.573 137.98 84.0443 137.753 83.5204 137.508C82.9965 137.262 82.4159 136.974 81.9062 136.691C79.178 135.195 77.1862 133.439 75.9826 131.565C75.3796 130.651 74.9507 129.633 74.7176 128.563C75.0183 127.162 75.644 125.851 76.5442 124.735C77.7583 123.238 79.2306 121.971 80.8914 120.993C81.1934 120.804 81.5097 120.624 81.8354 120.45L82.0855 120.313C91.2848 115.343 106.063 114.842 116.428 119.171Z" fill="#F10086" />
                        <path opacity="0.25" d="M116.428 119.171C116.792 119.317 117.15 119.477 117.5 119.643C119.429 120.506 121.219 121.65 122.814 123.036C124.731 124.754 125.897 126.657 126.302 128.601C126.228 128.983 126.124 129.359 125.991 129.725C125.297 131.669 123.81 133.552 121.512 135.233C121.422 135.304 121.323 135.374 121.224 135.44C120.879 135.686 120.511 135.912 120.129 136.163C119.746 136.413 119.218 136.705 118.736 136.956C117.698 137.502 116.626 137.983 115.527 138.395C107.668 141.364 97.265 141.746 88.6794 139.382C87.4725 139.051 86.2844 138.656 85.1205 138.197C84.573 137.98 84.0443 137.753 83.5204 137.508C82.9965 137.262 82.4159 136.974 81.9062 136.691C79.178 135.195 77.1862 133.439 75.9826 131.565C75.3796 130.651 74.9507 129.633 74.7176 128.563C75.0183 127.162 75.644 125.851 76.5442 124.735C77.7583 123.238 79.2306 121.971 80.8914 120.993C81.1934 120.804 81.5097 120.624 81.8354 120.45L82.0855 120.313C91.2848 115.343 106.063 114.842 116.428 119.171Z" fill="black" />
                        <path d="M136.229 131.004C132.103 140.175 120.568 143.833 111.156 145.485C96.8544 147.543 81.2878 145.82 70.2289 137.682H70.2572C67.2458 135.322 64.6876 132.146 64.1448 128.304C64.6168 132.184 67.0901 135.464 70.1014 137.895H70.0778C78.9892 144.8 91.3178 147.037 103.118 146.707C115.008 146.009 130.838 142.808 136.229 131.004Z" fill="#F5F5F5" />
                        <path d="M85.517 133.807C89.5526 135.502 93.5126 134.751 97.2037 133.458L96.2314 134.459V138.655C97.0544 138.85 97.8905 138.985 98.733 139.061C100.183 139.181 101.643 139.127 103.08 138.9C105.438 138.508 107.721 137.755 109.849 136.668L113.407 138.164L118.217 135.204V131.013L117.316 130.626L117.122 130.541C117.192 130.22 117.226 129.892 117.221 129.564C117.221 129.446 117.221 129.337 117.188 129.234C117.127 128.714 117.235 126.345 117.216 125.368C117.216 125.25 117.216 125.146 117.188 125.038C117.047 123.867 116.07 122.814 114.082 121.984C112.069 121.168 109.881 120.877 107.725 121.139L107.182 121.205H107.144L106.582 121.299C106.417 121.328 106.252 121.356 106.11 121.394C106.04 121.394 105.969 121.417 105.907 121.436C105.846 121.455 105.652 121.483 105.534 121.516C104.496 121.763 103.472 122.066 102.466 122.423C102.028 122.578 101.589 122.744 101.154 122.895L102.424 121.573V117.377C98.3365 116.598 93.9658 117.259 89.68 119.359L86.1211 117.877L81.3681 120.908V125.104L82.482 125.576C82.4093 125.834 82.3743 126.101 82.3782 126.369V126.402C82.4159 127.053 82.3782 129.828 82.3782 130.565V130.598C82.397 131.834 83.4071 132.929 85.517 133.807Z" fill="#F10086" />
                        <path opacity="0.15" d="M117.216 125.552C117.216 126.628 117.136 128.738 117.193 129.219C117.193 129.333 117.216 129.437 117.221 129.555C117.228 129.885 117.193 130.215 117.117 130.536L114.564 129.46C114.564 129.46 117.146 128.224 117.216 125.552Z" fill="black" />
                        <path opacity="0.45" d="M91.3839 125.495C90.3172 125.052 90.1426 124.221 92.1061 122.956C94.2112 121.62 96.3541 121.271 98.2138 121.734L102.377 117.396V121.587L101.121 122.909L100.989 122.961C100.309 123.22 99.6439 123.489 99.0162 123.749C98.7613 123.867 98.5111 123.971 98.2657 124.065C96.9121 124.699 95.5067 125.215 94.0649 125.609C93.4716 125.769 92.8528 125.812 92.243 125.736C91.9468 125.696 91.6576 125.615 91.3839 125.495Z" fill="black" />
                        <path opacity="0.2" d="M82.4206 125.576L81.3114 125.104V120.912L84.8562 122.399C83.544 123.504 82.6944 124.575 82.4206 125.576Z" fill="black" />
                        <path opacity="0.15" d="M113.412 133.963L118.217 131.008V135.2L113.403 138.169L109.853 136.668C107.723 137.758 105.437 138.509 103.075 138.895C100.801 139.279 98.473 139.197 96.2314 138.655V134.459C98.473 135.001 100.801 135.082 103.075 134.699C105.437 134.316 107.724 133.564 109.853 132.472L113.412 133.963Z" fill="black" />
                        <path opacity="0.2" d="M109.858 136.668C108.194 137.7 106.369 138.447 104.458 138.877C102.582 139.322 100.635 139.386 98.733 139.065C100.639 139.041 102.537 138.819 104.397 138.405C105.341 138.202 106.242 137.933 107.153 137.649C108.064 137.366 108.947 137.012 109.858 136.668Z" fill="black" />
                        <path opacity="0.35" d="M82.3262 130.598V130.574C82.2979 129.833 82.3593 127.062 82.3262 126.402C82.4017 127.643 83.4024 128.733 85.5075 129.616C90.1992 131.57 94.7634 130.286 98.9406 128.611C102.82 127.039 105.634 125.373 107.909 126.317C109.325 126.916 108.923 127.855 107.371 128.847C106.384 129.47 105.281 129.885 104.128 130.067C102.976 130.249 101.798 130.193 100.668 129.904L97.232 133.463C93.5315 134.77 89.5809 135.492 85.5453 133.803C83.4024 132.929 82.4017 131.839 82.3262 130.598Z" fill="black" />
                        <path d="M98.2185 125.93L98.2138 121.734L102.372 117.391L102.377 121.587L98.2185 125.93Z" fill="#F10086" />
                        <path opacity="0.15" d="M98.2185 125.93L98.2138 121.734L102.372 117.391L102.377 121.587L98.2185 125.93Z" fill="black" />
                        <path d="M81.3067 120.922L84.8703 122.408L82.4254 125.58L81.3114 125.118L81.3067 120.922Z" fill="#F10086" />
                        <path opacity="0.45" d="M81.3067 120.922L84.8703 122.408L82.4254 125.58L81.3114 125.118L81.3067 120.922Z" fill="black" />
                        <path d="M117.316 130.626L114.564 129.465C114.564 129.465 117.268 128.157 117.216 125.368C117.216 125.25 117.216 125.146 117.188 125.038C117.047 123.867 116.07 122.814 114.082 121.984C112.069 121.168 109.881 120.877 107.725 121.139L107.182 121.205H107.144L106.582 121.299C106.417 121.328 106.252 121.356 106.11 121.394C106.04 121.394 105.969 121.417 105.907 121.436C105.846 121.455 105.652 121.483 105.534 121.516C104.496 121.763 103.472 122.066 102.466 122.423C101.994 122.593 101.522 122.772 101.05 122.961C100.371 123.225 99.7006 123.499 99.0681 123.758C98.8132 123.872 98.5678 123.976 98.3176 124.075C96.9638 124.704 95.5604 125.22 94.1215 125.618C93.5291 125.774 92.9123 125.816 92.3043 125.741C92.0114 125.698 91.7246 125.62 91.45 125.51C90.3786 125.038 90.1945 124.226 92.1674 122.961C94.2678 121.62 96.4154 121.271 98.2751 121.738L102.433 117.396C98.3459 116.617 93.9752 117.278 89.6894 119.378L86.1306 117.896L81.3775 120.926L84.9411 122.413C83.2419 123.829 82.3451 125.165 82.3923 126.378V126.411C82.4631 127.648 83.4732 128.738 85.583 129.621C90.2606 131.579 94.8295 130.291 99.0067 128.615C102.887 127.044 105.7 125.377 107.975 126.326C109.391 126.921 108.985 127.86 107.432 128.856C106.445 129.478 105.342 129.892 104.189 130.074C103.037 130.255 101.86 130.201 100.73 129.913L96.3022 134.463C97.1256 134.658 97.9614 134.795 98.8038 134.874C100.254 134.993 101.714 134.937 103.151 134.709C105.509 134.32 107.792 133.569 109.919 132.481L113.478 133.972L118.288 131.018L117.316 130.626Z" fill="#F10086" />
                        <path opacity="0.1" d="M117.316 130.626L114.564 129.465C114.564 129.465 117.268 128.157 117.216 125.368C117.216 125.25 117.216 125.146 117.188 125.038C117.047 123.867 116.07 122.814 114.082 121.984C112.069 121.168 109.881 120.877 107.725 121.139L107.182 121.205H107.144L106.582 121.299C106.417 121.328 106.252 121.356 106.11 121.394C106.04 121.394 105.969 121.417 105.907 121.436C105.846 121.455 105.652 121.483 105.534 121.516C104.496 121.763 103.472 122.066 102.466 122.423C101.994 122.593 101.522 122.772 101.05 122.961C100.371 123.225 99.7006 123.499 99.0681 123.758C98.8132 123.872 98.5678 123.976 98.3176 124.075C96.9638 124.704 95.5604 125.22 94.1215 125.618C93.5291 125.774 92.9123 125.816 92.3043 125.741C92.0114 125.698 91.7246 125.62 91.45 125.51C90.3786 125.038 90.1945 124.226 92.1674 122.961C94.2678 121.62 96.4154 121.271 98.2751 121.738L102.433 117.396C98.3459 116.617 93.9752 117.278 89.6894 119.378L86.1306 117.896L81.3775 120.926L84.9411 122.413C83.2419 123.829 82.3451 125.165 82.3923 126.378V126.411C82.4631 127.648 83.4732 128.738 85.583 129.621C90.2606 131.579 94.8295 130.291 99.0067 128.615C102.887 127.044 105.7 125.377 107.975 126.326C109.391 126.921 108.985 127.86 107.432 128.856C106.445 129.478 105.342 129.892 104.189 130.074C103.037 130.255 101.86 130.201 100.73 129.913L96.3022 134.463C97.1256 134.658 97.9614 134.795 98.8038 134.874C100.254 134.993 101.714 134.937 103.151 134.709C105.509 134.32 107.792 133.569 109.919 132.481L113.478 133.972L118.288 131.018L117.316 130.626Z" fill="white" />
                        <path d="M85.5122 129.616C87.2868 130.294 89.1851 130.589 91.0818 130.48C93.0223 130.352 94.9344 129.946 96.76 129.276C98.6197 128.663 100.413 127.86 102.24 127.095C103.157 126.705 104.104 126.389 105.072 126.151C106.01 125.911 107 125.969 107.904 126.317C106.987 126.009 105.997 125.996 105.072 126.279C104.129 126.539 103.207 126.872 102.315 127.275C100.512 128.058 98.7094 128.884 96.8355 129.522C94.9895 130.188 93.0558 130.581 91.096 130.687C90.1313 130.718 89.1661 130.645 88.2168 130.47C87.2883 130.279 86.3819 129.993 85.5122 129.616Z" fill="white" />
                        <path d="M113.407 133.968L113.412 138.164L109.853 136.668L109.849 132.476L113.407 133.968Z" fill="#F10086" />
                        <path opacity="0.35" d="M113.407 133.968L113.412 138.164L109.853 136.668L109.849 132.476L113.407 133.968Z" fill="black" />
                        <path d="M118.217 131.008L113.426 133.5L109.849 132.476L113.407 133.968L118.217 131.008Z" fill="#FAFAFA" />
                        <path d="M89.6186 119.374L86.0834 118.326L81.3067 120.922L86.0598 117.891L89.6186 119.374Z" fill="#FAFAFA" />
                        <path opacity="0.15" d="M102.372 117.391C101.634 117.258 100.888 117.17 100.14 117.127L100.725 119.114L102.372 117.391Z" fill="white" />
                        <path opacity="0.15" d="M85.6727 129.673C87.6181 130.452 89.7236 130.749 91.8087 130.536L88.382 118.854L86.0645 117.91L82.8265 119.978L83.3552 121.776L84.8703 122.409C84.5025 122.702 84.1526 123.017 83.8225 123.353L85.6727 129.673Z" fill="white" />
                        <path opacity="0.15" d="M113.36 121.705C112.221 121.32 111.032 121.102 109.83 121.059L113.606 133.859L116.438 132.118L115.81 129.984L114.583 129.465C114.903 129.296 115.205 129.096 115.484 128.865L113.36 121.705Z" fill="white" />
                        <path opacity="0.15" d="M107.144 121.2L106.582 121.295C106.417 121.323 106.252 121.351 106.11 121.389C106.04 121.389 105.969 121.413 105.907 121.431C105.846 121.45 105.652 121.479 105.534 121.512C104.496 121.758 103.472 122.061 102.466 122.418C102.245 122.493 102.032 122.583 101.81 122.663L103.052 126.911C104.968 126.161 106.559 125.76 107.951 126.34C109.344 126.921 108.961 127.874 107.408 128.87C106.374 129.527 105.207 129.948 103.991 130.102L105.214 134.275C106.831 133.844 108.4 133.247 109.896 132.495L111.241 133.057L107.743 121.148L107.229 121.214L107.144 121.2Z" fill="white" />
                        <path d="M118 116.99H121.998V125.174C121.998 126.155 121.866 127.132 121.606 128.077C121.512 128.408 121.398 128.743 121.276 129.068C120.628 130.716 119.683 132.229 118.486 133.534C118.039 134.03 117.567 134.503 117.07 134.95C116.544 135.438 115.993 135.898 115.418 136.328C114.646 136.917 113.842 137.464 113.011 137.966C112.105 138.518 111.161 139.037 110.179 139.518C109.433 139.882 108.669 140.231 107.876 140.552C96.3022 145.338 80.3438 145.919 67.6093 142.034C66.7408 141.775 65.8912 141.487 65.0605 141.18C63.8663 140.746 62.7005 140.264 61.5913 139.764C60.6756 139.334 59.8118 138.891 58.9953 138.423C58.292 138.022 57.6218 137.607 56.994 137.182C56.3662 136.757 55.7715 136.323 55.2193 135.875C53.6947 134.68 52.3609 133.261 51.2639 131.664C51.0515 131.343 50.8486 131.027 50.6692 130.72C49.9037 129.395 49.405 127.933 49.2013 126.416C49.144 125.96 49.1172 125.501 49.121 125.042V117.306H52.779C53.0717 116.99 53.3785 116.674 53.723 116.362C55.0675 115.096 56.5438 113.977 58.1268 113.025C59.1466 112.405 60.1974 111.838 61.275 111.326C62.7238 110.638 64.2098 110.031 65.726 109.509C76.195 105.893 89.5242 105.402 100.852 108.22C102.5 108.604 104.127 109.07 105.728 109.617C107.045 110.089 108.319 110.608 109.542 111.18C111.295 111.992 112.975 112.954 114.564 114.054C115.804 114.916 116.955 115.9 118 116.99Z" fill="#F10086" />
                        <path opacity="0.15" d="M75.4586 143.795V127.19L81.538 127.827V144.446C79.4895 144.323 77.4552 144.106 75.4586 143.795Z" fill="white" />
                        <path opacity="0.1" d="M122.012 125.156C122.012 126.136 121.88 127.113 121.62 128.058C121.526 128.389 121.413 128.724 121.29 129.05C120.642 130.697 119.697 132.21 118.5 133.515C118.34 133.694 118.17 133.869 118 134.043V116.962L118.028 116.99H122.026L122.012 125.156Z" fill="black" />
                        <path opacity="0.15" d="M101.74 117.528L104.86 116.787V141.671C103.865 141.985 102.847 142.288 101.806 142.577L101.74 117.528Z" fill="white" />
                        <path opacity="0.15" d="M93.6967 144.097V122.531L99.7761 122.98V143.063C97.7683 143.51 95.7392 143.855 93.6967 144.097Z" fill="white" />
                        <path opacity="0.15" d="M49.1163 117.254H52.7743C53.067 116.938 53.3738 116.622 53.7183 116.31C55.0627 115.044 56.5391 113.925 58.1221 112.973C59.1419 112.354 60.1926 111.786 61.2703 111.274C62.7191 110.586 64.205 109.979 65.7213 109.457C67.1908 108.95 68.709 108.508 70.2761 108.13L70.3044 142.775C69.4029 142.549 68.5108 142.303 67.6376 142.039C66.7644 141.775 65.9195 141.491 65.0888 141.185C63.8946 140.75 62.7288 140.269 61.6196 139.769C60.7039 139.339 59.8402 138.895 59.0236 138.428C58.3203 138.027 57.6501 137.612 57.0223 137.187C56.3946 136.762 55.7998 136.328 55.2476 135.879C53.723 134.685 52.3892 133.265 51.2922 131.669C51.0798 131.348 50.8769 131.032 50.6975 130.725C49.932 129.4 49.4334 127.937 49.2296 126.42C49.1723 125.965 49.1455 125.506 49.1494 125.047L49.1163 117.254Z" fill="white" />
                        <path opacity="0.15" d="M51.2686 131.631C51.0562 131.31 50.8533 130.994 50.6739 130.687C49.9084 129.362 49.4098 127.9 49.206 126.383C49.1487 125.927 49.1219 125.468 49.1258 125.009V117.273H52.7838L52.831 117.226L54.0629 117.698V134.902C53.0038 133.928 52.0657 132.83 51.2686 131.631Z" fill="white" />
                        <path d="M109.532 102.424C108.31 101.853 107.035 101.334 105.719 100.862C104.145 100.302 102.514 99.8107 100.824 99.3891C89.4959 96.5571 76.1666 97.0668 65.6977 100.682C64.1811 101.204 62.6951 101.811 61.2467 102.5C60.1691 103.012 59.1183 103.579 58.0985 104.199C56.5155 105.15 55.0391 106.269 53.6947 107.536C52.3892 108.753 51.2936 110.177 50.4521 111.751C49.3571 113.757 48.9196 116.057 49.2013 118.326C49.4055 119.843 49.9024 121.307 50.6645 122.635C50.8486 122.956 51.0468 123.272 51.2592 123.579C52.358 125.175 53.6933 126.595 55.2193 127.789C55.7762 128.233 56.3678 128.667 56.994 129.092C57.6265 129.517 58.292 129.932 59 130.333C59.8118 130.805 60.6803 131.244 61.5913 131.674C62.7052 132.198 63.8663 132.674 65.0605 133.09C65.8959 133.401 66.7455 133.685 67.614 133.949C80.358 137.829 96.3022 137.253 107.88 132.467C108.669 132.141 109.438 131.797 110.179 131.433C111.17 130.945 112.114 130.428 113.011 129.88C113.908 129.333 114.71 128.787 115.418 128.242C116.01 127.789 116.56 127.33 117.07 126.864C117.567 126.417 118.039 125.945 118.486 125.448C119.687 124.156 120.642 122.656 121.304 121.021C121.427 120.69 121.535 120.36 121.63 120.025C122.033 118.605 122.135 117.116 121.927 115.654C121.54 113.277 120.499 111.054 118.92 109.235C117.652 107.736 116.188 106.413 114.569 105.303C112.977 104.199 111.291 103.236 109.532 102.424Z" fill="#F10086" />
                        <path opacity="0.1" d="M109.532 102.424C108.31 101.853 107.035 101.334 105.719 100.862C104.145 100.302 102.514 99.8107 100.824 99.3891C89.4959 96.5571 76.1666 97.0668 65.6977 100.682C64.1811 101.204 62.6951 101.811 61.2467 102.5C60.1691 103.012 59.1183 103.579 58.0985 104.199C56.5155 105.15 55.0391 106.269 53.6947 107.536C52.3892 108.753 51.2936 110.177 50.4521 111.751C49.3571 113.757 48.9196 116.057 49.2013 118.326C49.4055 119.843 49.9024 121.307 50.6645 122.635C50.8486 122.956 51.0468 123.272 51.2592 123.579C52.358 125.175 53.6933 126.595 55.2193 127.789C55.7762 128.233 56.3678 128.667 56.994 129.092C57.6265 129.517 58.292 129.932 59 130.333C59.8118 130.805 60.6803 131.244 61.5913 131.674C62.7052 132.198 63.8663 132.674 65.0605 133.09C65.8959 133.401 66.7455 133.685 67.614 133.949C80.358 137.829 96.3022 137.253 107.88 132.467C108.669 132.141 109.438 131.797 110.179 131.433C111.17 130.945 112.114 130.428 113.011 129.88C113.908 129.333 114.71 128.787 115.418 128.242C116.01 127.789 116.56 127.33 117.07 126.864C117.567 126.417 118.039 125.945 118.486 125.448C119.687 124.156 120.642 122.656 121.304 121.021C121.427 120.69 121.535 120.36 121.63 120.025C122.033 118.605 122.135 117.116 121.927 115.654C121.54 113.277 120.499 111.054 118.92 109.235C117.652 107.736 116.188 106.413 114.569 105.303C112.977 104.199 111.291 103.236 109.532 102.424Z" fill="white" />
                        <path opacity="0.2" d="M109.532 102.424C108.31 101.853 107.035 101.334 105.719 100.862C104.145 100.302 102.514 99.8107 100.824 99.3891C89.4959 96.5571 76.1666 97.0668 65.6977 100.682C64.1811 101.204 62.6951 101.811 61.2467 102.5C60.1691 103.012 59.1183 103.579 58.0985 104.199C56.5155 105.15 55.0391 106.269 53.6947 107.536C52.3892 108.753 51.2936 110.177 50.4521 111.751C49.3571 113.757 48.9196 116.057 49.2013 118.326C49.4055 119.843 49.9024 121.307 50.6645 122.635C50.8486 122.956 51.0468 123.272 51.2592 123.579C52.358 125.175 53.6933 126.595 55.2193 127.789C55.7762 128.233 56.3678 128.667 56.994 129.092C57.6265 129.517 58.292 129.932 59 130.333C59.8118 130.805 60.6803 131.244 61.5913 131.674C62.7052 132.198 63.8663 132.674 65.0605 133.09C65.8959 133.401 66.7455 133.685 67.614 133.949C80.358 137.829 96.3022 137.253 107.88 132.467C108.669 132.141 109.438 131.797 110.179 131.433C111.17 130.945 112.114 130.428 113.011 129.88C113.908 129.333 114.71 128.787 115.418 128.242C116.01 127.789 116.56 127.33 117.07 126.864C117.567 126.417 118.039 125.945 118.486 125.448C119.687 124.156 120.642 122.656 121.304 121.021C121.427 120.69 121.535 120.36 121.63 120.025C122.033 118.605 122.135 117.116 121.927 115.654C121.54 113.277 120.499 111.054 118.92 109.235C117.652 107.736 116.188 106.413 114.569 105.303C112.977 104.199 111.291 103.236 109.532 102.424Z" fill="white" />
                        <path opacity="0.15" d="M70.295 134.728C69.3887 134.501 68.4919 134.256 67.614 133.991C66.7361 133.727 65.8959 133.444 65.0605 133.132C63.8663 132.698 62.7005 132.221 61.5913 131.716C60.6803 131.287 59.8118 130.843 59 130.376C58.292 129.975 57.6265 129.559 56.994 129.134C56.3615 128.71 55.7762 128.275 55.2193 127.832C53.6933 126.637 52.358 125.218 51.2592 123.622C51.0468 123.301 50.8486 122.984 50.6692 122.678C49.9037 121.35 49.405 119.887 49.2013 118.368C48.9196 116.1 49.3571 113.8 50.4521 111.793C51.2936 110.219 52.3892 108.795 53.6947 107.578C55.0391 106.312 56.5155 105.193 58.0985 104.241C58.9858 103.703 59.9204 103.198 60.888 102.726L70.295 134.728Z" fill="white" />
                        <path opacity="0.15" d="M54.0157 126.774C52.976 125.827 52.0509 124.761 51.2592 123.598C51.0468 123.277 50.8486 122.961 50.6692 122.654C49.9037 121.327 49.405 119.863 49.2013 118.345C48.968 116.496 49.2219 114.618 49.9376 112.898L54.0157 126.774Z" fill="white" />
                        <path opacity="0.15" d="M81.5191 136.408C79.4706 136.285 77.441 136.063 75.4492 135.752L65.2021 100.866L65.6977 100.687C67.2742 100.144 68.9214 99.677 70.6112 99.271L81.5191 136.408Z" fill="white" />
                        <path opacity="0.15" d="M99.7619 135.034C97.789 135.473 95.7546 135.818 93.6873 136.068L82.4112 97.6568C84.5305 97.5624 86.6592 97.5718 88.7974 97.6568L99.7619 135.034Z" fill="white" />
                        <path opacity="0.15" d="M104.85 133.628C103.845 133.963 102.82 134.265 101.773 134.544L90.9922 97.8173C92.1486 97.9117 93.3002 98.0297 94.4425 98.1855L104.85 133.628Z" fill="white" />
                        <path opacity="0.3" d="M111.335 103.368C111.807 103.623 112.279 103.882 112.718 104.147L113.162 104.416L113.606 104.694C113.927 104.902 114.248 105.114 114.55 105.327C116.167 106.438 117.629 107.761 118.897 109.259C120.475 111.078 121.516 113.301 121.903 115.678C122.111 117.139 122.01 118.628 121.606 120.048C121.512 120.384 121.403 120.714 121.28 121.044C120.629 122.689 119.682 124.201 118.486 125.505C118.33 125.684 118.16 125.864 117.986 126.038L111.335 103.368Z" fill="#F10086" />
                        <path d="M103.33 106.12C92.4742 101.065 76.2327 101.442 66.2594 106.818C65.8393 107.045 65.4192 107.29 65.0227 107.531C55.2051 113.577 56.4418 122.744 67.7886 128.006C79.1355 133.269 96.2833 132.646 106.101 126.59C106.497 126.345 106.884 126.09 107.243 125.835C115.8 119.812 114.224 111.17 103.33 106.12Z" fill="#F10086" />
                        <path opacity="0.4" d="M103.33 106.12C92.4742 101.065 76.2327 101.442 66.2594 106.818C65.8393 107.045 65.4192 107.29 65.0227 107.531C55.2051 113.577 56.4418 122.744 67.7886 128.006C79.1355 133.269 96.2833 132.646 106.101 126.59C106.497 126.345 106.884 126.09 107.243 125.835C115.8 119.812 114.224 111.17 103.33 106.12Z" fill="black" />
                        <path d="M102.542 106.606C102.193 106.441 101.834 106.285 101.471 106.134C91.0866 101.806 76.3271 102.311 67.1184 107.276L66.873 107.408C66.552 107.583 66.2405 107.767 65.929 107.956C64.2682 108.934 62.7959 110.202 61.5818 111.699C59.118 114.861 58.967 118.387 61.0343 121.578C62.2474 123.466 64.225 125.207 66.9532 126.699C67.4677 126.982 68.0058 127.256 68.5722 127.52C69.1386 127.785 69.6247 127.992 70.1675 128.205C71.333 128.665 72.5226 129.062 73.7311 129.394C82.3121 131.754 92.715 131.376 100.578 128.403C101.675 127.998 102.746 127.526 103.783 126.987C104.255 126.737 104.727 126.468 105.199 126.194C105.671 125.92 105.95 125.722 106.294 125.472L106.582 125.264C108.876 123.579 110.358 121.696 111.062 119.756C112.251 116.452 111.156 112.964 107.871 110.014C106.271 108.623 104.476 107.474 102.542 106.606Z" fill="#F10086" />
                        <path opacity="0.5" d="M102.542 106.606C102.193 106.441 101.834 106.285 101.471 106.134C91.0866 101.806 76.3271 102.311 67.1184 107.276L66.873 107.408C66.552 107.583 66.2405 107.767 65.929 107.956C64.2682 108.934 62.7959 110.202 61.5818 111.699C59.118 114.861 58.967 118.387 61.0343 121.578C62.2474 123.466 64.225 125.207 66.9532 126.699C67.4677 126.982 68.0058 127.256 68.5722 127.52C69.1386 127.785 69.6247 127.992 70.1675 128.205C71.333 128.665 72.5226 129.062 73.7311 129.394C82.3121 131.754 92.715 131.376 100.578 128.403C101.675 127.998 102.746 127.526 103.783 126.987C104.255 126.737 104.727 126.468 105.199 126.194C105.671 125.92 105.95 125.722 106.294 125.472L106.582 125.264C108.876 123.579 110.358 121.696 111.062 119.756C112.251 116.452 111.156 112.964 107.871 110.014C106.271 108.623 104.476 107.474 102.542 106.606Z" fill="black" />
                        <path d="M101.48 109.183C101.848 109.334 102.202 109.49 102.551 109.655C104.48 110.516 106.271 111.658 107.866 113.044C109.787 114.767 110.953 116.669 111.359 118.614C111.282 118.995 111.178 119.37 111.047 119.737C110.349 121.677 108.862 123.56 106.568 125.245L106.28 125.453C105.936 125.694 105.568 125.925 105.185 126.175C104.803 126.425 104.27 126.718 103.769 126.968C102.732 127.507 101.661 127.98 100.564 128.384C92.7008 131.358 82.2979 131.74 73.717 129.375C72.5085 129.043 71.3188 128.646 70.1534 128.186C69.6106 127.973 69.0772 127.742 68.558 127.501C68.0388 127.261 67.4535 126.963 66.939 126.68C64.2109 125.189 62.219 123.428 61.0202 121.559C60.4166 120.645 59.9862 119.627 59.7505 118.557C60.0542 117.157 60.6796 115.846 61.5771 114.729C62.7942 113.233 64.2677 111.966 65.929 110.986C66.231 110.797 66.5426 110.613 66.873 110.439L67.1231 110.306C76.3413 105.36 91.096 104.86 101.48 109.183Z" fill="#F10086" />
                        <path opacity="0.25" d="M101.48 109.183C101.848 109.334 102.202 109.49 102.551 109.655C104.48 110.516 106.271 111.658 107.866 113.044C109.787 114.767 110.953 116.669 111.359 118.614C111.282 118.995 111.178 119.37 111.047 119.737C110.349 121.677 108.862 123.56 106.568 125.245L106.28 125.453C105.936 125.694 105.568 125.925 105.185 126.175C104.803 126.425 104.27 126.718 103.769 126.968C102.732 127.507 101.661 127.98 100.564 128.384C92.7008 131.358 82.2979 131.74 73.717 129.375C72.5085 129.043 71.3188 128.646 70.1534 128.186C69.6106 127.973 69.0772 127.742 68.558 127.501C68.0388 127.261 67.4535 126.963 66.939 126.68C64.2109 125.189 62.219 123.428 61.0202 121.559C60.4166 120.645 59.9862 119.627 59.7505 118.557C60.0542 117.157 60.6796 115.846 61.5771 114.729C62.7942 113.233 64.2677 111.966 65.929 110.986C66.231 110.797 66.5426 110.613 66.873 110.439L67.1231 110.306C76.3413 105.36 91.096 104.86 101.48 109.183Z" fill="black" />
                        <path d="M121.304 121.021C117.179 130.192 105.638 133.85 96.2314 135.502C81.9298 137.555 66.3632 135.832 55.3042 127.695H55.3278C52.3212 125.335 49.7582 122.154 49.2202 118.312C49.6922 122.196 52.1654 125.476 55.1721 127.907C64.0787 134.813 76.4121 137.045 88.2121 136.715C100.064 136.026 115.89 132.826 121.304 121.021Z" fill="#F5F5F5" />
                        <path d="M70.5687 123.824C74.609 125.514 78.5927 124.768 82.2602 123.475L81.2878 124.476V128.672C82.1093 128.867 82.9437 129.003 83.7847 129.078C85.2351 129.199 86.6948 129.143 88.1318 128.913C90.4908 128.523 92.7754 127.772 94.905 126.685L98.4639 128.181L103.269 125.222V121.026L102.367 120.638L102.169 120.558C102.244 120.238 102.277 119.91 102.268 119.581C102.266 119.469 102.257 119.357 102.24 119.246C102.179 118.732 102.287 116.362 102.268 115.385C102.268 115.267 102.268 115.159 102.235 115.055C102.098 113.879 101.121 112.827 99.1294 112.001C97.1163 111.184 94.9286 110.891 92.7716 111.151L92.2288 111.222H92.1722L91.6058 111.312C91.4406 111.34 91.2754 111.368 91.1102 111.406L90.9119 111.449C90.7845 111.477 90.6523 111.501 90.5343 111.534C89.4977 111.779 88.4751 112.08 87.471 112.435C87.0274 112.591 86.5884 112.756 86.1589 112.931L87.4286 111.609V107.413C83.3363 106.634 78.9703 107.295 74.6846 109.395L71.1257 107.909L66.3632 110.92V115.116L67.4771 115.588C67.3992 115.845 67.3641 116.113 67.3733 116.381V116.414C67.4063 117.065 67.345 119.841 67.3733 120.577V120.61C67.4488 121.852 68.4636 122.942 70.5687 123.824Z" fill="#F10086" />
                        <path opacity="0.15" d="M102.268 115.579C102.268 116.655 102.193 118.765 102.268 119.246C102.268 119.359 102.268 119.463 102.301 119.576C102.308 119.908 102.274 120.24 102.197 120.563L99.6392 119.487C99.6392 119.487 102.197 118.236 102.268 115.579Z" fill="black" />
                        <path opacity="0.45" d="M76.464 115.513C75.3973 115.064 75.2179 114.233 77.1814 112.968C79.2866 111.637 81.453 111.288 83.2938 111.751L87.4569 107.413V111.6L86.2014 112.926L86.0739 112.973C85.3895 113.238 84.7287 113.507 84.0962 113.766C83.8414 113.879 83.5912 113.988 83.3458 114.082C81.9934 114.718 80.5877 115.233 79.145 115.621C78.5536 115.784 77.936 115.827 77.3278 115.749C77.0306 115.709 76.7402 115.629 76.464 115.513Z" fill="black" />
                        <path opacity="0.2" d="M67.496 115.593L66.3868 115.121V110.934L69.9315 112.421C68.5958 113.516 67.7509 114.587 67.496 115.593Z" fill="black" />
                        <path opacity="0.15" d="M98.4639 123.98L103.274 121.021V125.217L98.4592 128.181L94.9098 126.68C92.7808 127.772 90.4937 128.524 88.1318 128.908C86.695 129.14 85.2353 129.197 83.7847 129.078C82.9441 129 82.1098 128.865 81.2878 128.672V124.476C83.5295 125.018 85.8577 125.1 88.1318 124.717C90.4932 124.331 92.7799 123.579 94.9098 122.489L98.4639 123.98Z" fill="black" />
                        <path opacity="0.2" d="M94.9145 126.685C93.2505 127.717 91.4254 128.464 89.5148 128.894C87.6379 129.338 85.691 129.401 83.7894 129.078C85.6876 129.057 87.5783 128.836 89.4298 128.417C90.3738 128.219 91.2754 127.945 92.191 127.662C93.1067 127.379 94.0035 127.029 94.9145 126.685Z" fill="black" />
                        <path opacity="0.35" d="M67.378 120.615C67.345 119.874 67.411 117.098 67.378 116.442C67.4535 117.684 68.4589 118.774 70.564 119.657C75.2557 121.611 79.8199 120.322 83.9971 118.647C87.8722 117.08 90.6901 115.413 92.9651 116.357C94.3811 116.952 93.9799 117.896 92.4318 118.887C91.4448 119.509 90.3414 119.923 89.189 120.104C88.0366 120.285 86.8594 120.229 85.7294 119.94L82.2743 123.504C78.5738 124.811 74.6185 125.533 70.5876 123.843C68.44 122.947 67.4535 121.852 67.378 120.615Z" fill="black" />
                        <path d="M83.2702 115.942V111.751L87.4286 107.408L87.4333 111.6L83.2702 115.942Z" fill="#F10086" />
                        <path opacity="0.15" d="M83.2702 115.942V111.751L87.4286 107.408L87.4333 111.6L83.2702 115.942Z" fill="black" />
                        <path d="M66.3632 110.939L69.9221 112.421L67.4818 115.598L66.3679 115.135L66.3632 110.939Z" fill="#F10086" />
                        <path opacity="0.45" d="M66.3632 110.939L69.9221 112.421L67.4818 115.598L66.3679 115.135L66.3632 110.939Z" fill="black" />
                        <path d="M102.372 120.638L99.6203 119.482C99.6203 119.482 102.325 118.175 102.273 115.385C102.273 115.267 102.273 115.159 102.24 115.055C102.103 113.879 101.126 112.827 99.1342 112.001C97.1211 111.184 94.9334 110.891 92.7763 111.151L92.2335 111.222H92.1769L91.6105 111.312C91.4453 111.34 91.2801 111.368 91.1149 111.406L90.9166 111.449C90.7892 111.477 90.657 111.501 90.539 111.534C89.5025 111.779 88.4799 112.08 87.4758 112.435C87.0038 112.605 86.5318 112.784 86.0598 112.973C85.3801 113.242 84.7098 113.516 84.0774 113.776L83.3269 114.092C81.9728 114.723 80.5675 115.238 79.1261 115.631C78.534 115.788 77.9172 115.832 77.3089 115.758C77.0159 115.713 76.7292 115.634 76.4546 115.522C75.3831 115.05 75.2038 114.238 77.1767 112.978C79.2771 111.633 81.4247 111.288 83.2844 111.755L87.4427 107.413C83.3505 106.63 78.9845 107.295 74.6987 109.395L71.1398 107.909L66.3632 110.92L69.9221 112.402C68.2229 113.818 67.3308 115.159 67.378 116.372V116.405C67.4488 117.637 68.4589 118.732 70.5687 119.61C75.2462 121.568 79.8152 120.284 83.9877 118.604C87.8675 117.032 90.6854 115.371 92.9557 116.32C94.3717 116.914 93.9658 117.854 92.4129 118.845C91.4267 119.467 90.3243 119.881 89.1728 120.063C88.0213 120.244 86.8449 120.19 85.7152 119.902L81.2878 124.457C82.1109 124.652 82.9469 124.788 83.7894 124.863C85.2397 124.983 86.6991 124.929 88.1366 124.702C90.4941 124.311 92.777 123.557 94.905 122.47L98.4639 123.966L103.274 121.007L102.372 120.638Z" fill="#F10086" />
                        <path opacity="0.1" d="M102.372 120.638L99.6203 119.482C99.6203 119.482 102.325 118.175 102.273 115.385C102.273 115.267 102.273 115.159 102.24 115.055C102.103 113.879 101.126 112.827 99.1342 112.001C97.1211 111.184 94.9334 110.891 92.7763 111.151L92.2335 111.222H92.1769L91.6105 111.312C91.4453 111.34 91.2801 111.368 91.1149 111.406L90.9166 111.449C90.7892 111.477 90.657 111.501 90.539 111.534C89.5025 111.779 88.4799 112.08 87.4758 112.435C87.0038 112.605 86.5318 112.784 86.0598 112.973C85.3801 113.242 84.7098 113.516 84.0774 113.776L83.3269 114.092C81.9728 114.723 80.5675 115.238 79.1261 115.631C78.534 115.788 77.9172 115.832 77.3089 115.758C77.0159 115.713 76.7292 115.634 76.4546 115.522C75.3831 115.05 75.2038 114.238 77.1767 112.978C79.2771 111.633 81.4247 111.288 83.2844 111.755L87.4427 107.413C83.3505 106.63 78.9845 107.295 74.6987 109.395L71.1398 107.909L66.3632 110.92L69.9221 112.402C68.2229 113.818 67.3308 115.159 67.378 116.372V116.405C67.4488 117.637 68.4589 118.732 70.5687 119.61C75.2462 121.568 79.8152 120.284 83.9877 118.604C87.8675 117.032 90.6854 115.371 92.9557 116.32C94.3717 116.914 93.9658 117.854 92.4129 118.845C91.4267 119.467 90.3243 119.881 89.1728 120.063C88.0213 120.244 86.8449 120.19 85.7152 119.902L81.2878 124.457C82.1109 124.652 82.9469 124.788 83.7894 124.863C85.2397 124.983 86.6991 124.929 88.1366 124.702C90.4941 124.311 92.777 123.557 94.905 122.47L98.4639 123.966L103.274 121.007L102.372 120.638Z" fill="white" />
                        <path d="M70.5687 119.628C72.3427 120.309 74.2412 120.605 76.1383 120.497C78.0737 120.366 79.9808 119.96 81.8023 119.293C83.6903 118.68 85.4556 117.877 87.2822 117.108C88.2007 116.721 89.1475 116.406 90.1142 116.164C91.0531 115.926 92.0425 115.986 92.9462 116.334C92.0295 116.026 91.0398 116.012 90.1142 116.291C89.1693 116.553 88.2462 116.887 87.353 117.292C85.55 118.076 83.7517 118.897 81.8778 119.539C80.0213 120.207 78.076 120.597 76.1053 120.695C75.1389 120.727 74.172 120.655 73.2214 120.478C72.3108 120.285 71.4221 120 70.5687 119.628Z" fill="white" />
                        <path d="M98.4639 123.985L98.4686 128.176L94.905 126.685V122.489L98.4639 123.985Z" fill="#F10086" />
                        <path opacity="0.35" d="M98.4639 123.985L98.4686 128.176L94.905 126.685V122.489L98.4639 123.985Z" fill="black" />
                        <path d="M103.269 121.026L98.4828 123.513L94.905 122.489L98.4639 123.985L103.269 121.026Z" fill="#FAFAFA" />
                        <path d="M74.6751 109.391L71.1351 108.338L66.3632 110.939L71.1115 107.904L74.6751 109.391Z" fill="#FAFAFA" />
                        <path opacity="0.15" d="M87.4286 107.408C86.6907 107.273 85.945 107.185 85.196 107.144L85.7766 109.131L87.4286 107.408Z" fill="white" />
                        <path opacity="0.15" d="M70.7292 119.69C72.6752 120.468 74.7802 120.764 76.8652 120.554L73.4338 108.872L71.121 107.928L67.8736 109.976L68.4117 111.793L69.9221 112.421C69.5568 112.716 69.2085 113.031 68.879 113.365L70.7292 119.69Z" fill="white" />
                        <path opacity="0.15" d="M98.4167 111.718C97.2756 111.333 96.0848 111.117 94.8814 111.076L98.6574 123.876L101.489 122.135L100.862 120.001L99.6345 119.482C99.9534 119.314 100.254 119.113 100.531 118.883L98.4167 111.718Z" fill="white" />
                        <path opacity="0.15" d="M92.1769 111.217L91.6105 111.307C91.4453 111.335 91.2801 111.364 91.1149 111.401L90.9166 111.444C90.7892 111.472 90.657 111.496 90.539 111.529C89.5025 111.774 88.4799 112.075 87.4758 112.43L86.8197 112.681L88.061 116.929C89.9726 116.173 91.568 115.777 92.9557 116.357C94.3434 116.938 93.9658 117.891 92.4129 118.883C91.3779 119.54 90.2114 119.962 88.9956 120.119L90.2228 124.287C91.8409 123.857 93.41 123.261 94.905 122.508L96.2455 123.074L92.7527 111.165L92.2335 111.232L92.1769 111.217Z" fill="white" />
                        <path d="M131.863 103.17H135.856V111.35C135.856 112.321 135.724 113.288 135.464 114.224C135.374 114.559 135.261 114.89 135.138 115.22C134.487 116.865 133.54 118.377 132.344 119.68C131.897 120.177 131.425 120.65 130.928 121.096C130.418 121.565 129.868 122.025 129.276 122.475C128.502 123.061 127.699 123.608 126.869 124.112C125.963 124.66 125.019 125.178 124.037 125.665C123.296 126.029 122.526 126.373 121.738 126.699C110.16 131.48 94.2018 132.061 81.4719 128.181C80.6034 127.917 79.7538 127.634 78.9184 127.322C77.7242 126.888 76.5584 126.411 75.4492 125.906C74.5382 125.476 73.6698 125.033 72.8579 124.566C72.1499 124.164 71.4844 123.749 70.8519 123.324C70.2194 122.899 69.6342 122.465 69.0772 122.021C67.5579 120.826 66.229 119.406 65.136 117.811C64.9236 117.49 64.7254 117.174 64.546 116.867C63.7805 115.54 63.2818 114.076 63.0781 112.558C63.0191 112.102 62.9907 111.644 62.9931 111.184V103.434H66.6511C66.939 103.118 67.2458 102.802 67.5715 102.49C68.9167 101.222 70.3948 100.104 71.98 99.153C72.9996 98.5317 74.0504 97.963 75.1282 97.4491C76.5767 96.762 78.0627 96.1569 79.5792 95.6366C90.0482 92.0164 103.377 91.5302 114.701 94.3434C116.349 94.7506 117.974 95.2421 119.572 95.816C120.884 96.288 122.163 96.8072 123.381 97.3783C125.128 98.1834 126.802 99.1389 128.384 100.234C129.637 101.096 130.802 102.079 131.863 103.17Z" fill="#F10086" />
                        <path opacity="0.15" d="M89.3024 129.975V113.37L95.3818 114.007V130.626C93.338 130.503 91.3037 130.272 89.3024 129.975Z" fill="white" />
                        <path opacity="0.1" d="M135.86 111.335C135.86 112.312 135.727 113.284 135.464 114.224C135.374 114.559 135.261 114.89 135.138 115.22C134.487 116.865 133.54 118.377 132.344 119.68C132.188 119.86 132.018 120.034 131.848 120.214V103.127L131.877 103.156H135.87L135.86 111.335Z" fill="black" />
                        <path opacity="0.15" d="M115.583 103.703L118.708 102.967V127.846C117.717 128.176 116.697 128.478 115.659 128.752L115.583 103.703Z" fill="white" />
                        <path opacity="0.15" d="M107.54 130.272V108.706L113.62 109.178V129.262C111.612 129.703 109.583 130.04 107.54 130.272Z" fill="white" />
                        <path opacity="0.15" d="M62.9648 103.434H66.6228C66.9107 103.118 67.2175 102.802 67.5432 102.49C68.8884 101.222 70.3664 100.104 71.9517 99.1531C72.9713 98.5317 74.0221 97.963 75.0999 97.4491C76.5484 96.762 78.0344 96.1569 79.5509 95.6367C81.0141 95.1316 82.5386 94.6927 84.101 94.3103L84.1293 128.95C83.2262 128.727 82.3388 128.483 81.4672 128.219C80.5987 127.954 79.7491 127.671 78.9137 127.36C77.7195 126.926 76.5537 126.449 75.4445 125.944C74.5335 125.514 73.665 125.071 72.8532 124.603C72.1452 124.202 71.4797 123.787 70.8472 123.362C70.2147 122.937 69.6294 122.503 69.0725 122.059C67.552 120.852 66.2246 119.419 65.136 117.811C64.9236 117.49 64.7254 117.174 64.546 116.867C63.7805 115.54 63.2818 114.076 63.0781 112.558C63.0191 112.102 62.9907 111.644 62.9931 111.184L62.9648 103.434Z" fill="white" />
                        <path opacity="0.15" d="M65.136 117.811C64.9236 117.49 64.7254 117.174 64.546 116.867C63.7805 115.54 63.2818 114.076 63.0781 112.558C63.0191 112.102 62.9907 111.644 62.9931 111.184V103.434H66.6511L66.6936 103.387L67.9255 103.859V121.068C66.8691 120.098 65.9326 119.004 65.136 117.811Z" fill="white" />
                        <path d="M123.376 88.6133C122.154 88.0375 120.879 87.5182 119.567 87.051C117.96 86.4762 116.325 85.9847 114.668 85.5783C103.34 82.7463 90.0104 83.2514 79.5462 86.8716C78.0297 87.3919 76.5437 87.997 75.0952 88.6841C73.9907 89.2127 72.9413 89.7791 71.947 90.3833C70.3615 91.3354 68.8835 92.4558 67.5385 93.7251C66.2349 94.9419 65.1395 96.3642 64.2958 97.9353C63.2047 99.945 62.7674 102.245 63.045 104.515C63.2495 106.032 63.7481 107.494 64.513 108.82C64.697 109.141 64.8953 109.462 65.1077 109.764C66.2058 111.36 67.5394 112.781 69.063 113.979C69.6153 114.422 70.21 114.861 70.8378 115.281C71.4655 115.701 72.1358 116.121 72.839 116.527C73.6556 116.999 74.5241 117.434 75.435 117.863C76.549 118.387 77.7101 118.868 78.9042 119.279C79.735 119.594 80.5846 119.879 81.453 120.133C94.197 124.013 110.146 123.437 121.719 118.651C122.512 118.326 123.277 117.981 124.023 117.618C125.014 117.146 125.958 116.617 126.855 116.065C127.685 115.561 128.489 115.015 129.262 114.427C129.835 113.995 130.387 113.535 130.914 113.049C131.409 112.601 131.882 112.128 132.33 111.633C133.525 110.328 134.471 108.814 135.119 107.168C135.242 106.837 135.355 106.507 135.45 106.176C135.849 104.756 135.949 103.267 135.742 101.806C135.361 99.4267 134.319 97.2025 132.736 95.3865C131.472 93.8835 130.009 92.5592 128.389 91.45C126.802 90.363 125.125 89.4139 123.376 88.6133Z" fill="#F10086" />
                        <path opacity="0.1" d="M123.376 88.6133C122.154 88.0375 120.879 87.5182 119.567 87.051C117.96 86.4762 116.325 85.9847 114.668 85.5783C103.34 82.7463 90.0104 83.2514 79.5462 86.8716C78.0297 87.3919 76.5437 87.997 75.0952 88.6841C73.9907 89.2127 72.9413 89.7791 71.947 90.3833C70.3615 91.3354 68.8835 92.4558 67.5385 93.7251C66.2349 94.9419 65.1395 96.3642 64.2958 97.9353C63.2047 99.945 62.7674 102.245 63.045 104.515C63.2495 106.032 63.7481 107.494 64.513 108.82C64.697 109.141 64.8953 109.462 65.1077 109.764C66.2058 111.36 67.5394 112.781 69.063 113.979C69.6153 114.422 70.21 114.861 70.8378 115.281C71.4655 115.701 72.1358 116.121 72.839 116.527C73.6556 116.999 74.5241 117.434 75.435 117.863C76.549 118.387 77.7101 118.868 78.9042 119.279C79.735 119.594 80.5846 119.879 81.453 120.133C94.197 124.013 110.146 123.437 121.719 118.651C122.512 118.326 123.277 117.981 124.023 117.618C125.014 117.146 125.958 116.617 126.855 116.065C127.685 115.561 128.489 115.015 129.262 114.427C129.835 113.995 130.387 113.535 130.914 113.049C131.409 112.601 131.882 112.128 132.33 111.633C133.525 110.328 134.471 108.814 135.119 107.168C135.242 106.837 135.355 106.507 135.45 106.176C135.849 104.756 135.949 103.267 135.742 101.806C135.361 99.4267 134.319 97.2025 132.736 95.3865C131.472 93.8835 130.009 92.5592 128.389 91.45C126.802 90.363 125.125 89.4139 123.376 88.6133Z" fill="white" />
                        <path opacity="0.2" d="M123.376 88.6133C122.154 88.0375 120.879 87.5182 119.567 87.051C117.96 86.4762 116.325 85.9847 114.668 85.5783C103.34 82.7463 90.0104 83.2514 79.5462 86.8716C78.0297 87.3919 76.5437 87.997 75.0952 88.6841C73.9907 89.2127 72.9413 89.7791 71.947 90.3833C70.3615 91.3354 68.8835 92.4558 67.5385 93.7251C66.2349 94.9419 65.1395 96.3642 64.2958 97.9353C63.2047 99.945 62.7674 102.245 63.045 104.515C63.2495 106.032 63.7481 107.494 64.513 108.82C64.697 109.141 64.8953 109.462 65.1077 109.764C66.2058 111.36 67.5394 112.781 69.063 113.979C69.6153 114.422 70.21 114.861 70.8378 115.281C71.4655 115.701 72.1358 116.121 72.839 116.527C73.6556 116.999 74.5241 117.434 75.435 117.863C76.549 118.387 77.7101 118.868 78.9042 119.279C79.735 119.594 80.5846 119.879 81.453 120.133C94.197 124.013 110.146 123.437 121.719 118.651C122.512 118.326 123.277 117.981 124.023 117.618C125.014 117.146 125.958 116.617 126.855 116.065C127.685 115.561 128.489 115.015 129.262 114.427C129.835 113.995 130.387 113.535 130.914 113.049C131.409 112.601 131.882 112.128 132.33 111.633C133.525 110.328 134.471 108.814 135.119 107.168C135.242 106.837 135.355 106.507 135.45 106.176C135.849 104.756 135.949 103.267 135.742 101.806C135.361 99.4267 134.319 97.2025 132.736 95.3865C131.472 93.8835 130.009 92.5592 128.389 91.45C126.802 90.363 125.125 89.4139 123.376 88.6133Z" fill="white" />
                        <path opacity="0.15" d="M84.1387 120.908C83.2325 120.681 82.3404 120.436 81.4578 120.171C80.5751 119.907 79.7397 119.624 78.909 119.317C77.7148 118.883 76.549 118.401 75.4398 117.901C74.5288 117.471 73.6603 117.028 72.8438 116.565C72.1405 116.159 71.4702 115.749 70.8425 115.319C70.2147 114.89 69.62 114.46 69.0678 114.016C67.5441 112.819 66.2105 111.398 65.1124 109.801C64.9 109.485 64.7018 109.164 64.5177 108.857C63.7529 107.532 63.2542 106.069 63.0498 104.553C62.7722 102.283 63.2094 99.9828 64.3006 97.9731C65.1442 96.4019 66.2396 94.9797 67.5432 93.7628C68.8882 92.4936 70.3663 91.3732 71.9517 90.4211C72.839 89.883 73.7689 89.3826 74.7365 88.9059L84.1387 120.908Z" fill="white" />
                        <path opacity="0.15" d="M67.8594 112.954C66.8201 112.008 65.8964 110.941 65.1077 109.778C64.8953 109.462 64.697 109.141 64.513 108.834C63.7481 107.508 63.2495 106.046 63.045 104.529C62.8138 102.68 63.0675 100.803 63.7814 99.0822L67.8594 112.954Z" fill="white" />
                        <path opacity="0.15" d="M95.344 122.597C93.3002 122.475 91.2706 122.253 89.2741 121.941L79.0317 87.051C79.1969 86.9896 79.3574 86.9283 79.5273 86.8716C81.1038 86.3241 82.751 85.8615 84.4408 85.4556L95.344 122.597Z" fill="white" />
                        <path opacity="0.15" d="M113.61 121.214C111.603 121.661 109.574 122.006 107.531 122.248L96.255 83.8414C98.379 83.747 100.503 83.7517 102.641 83.8414L113.61 121.214Z" fill="white" />
                        <path opacity="0.15" d="M118.694 119.812C117.693 120.143 116.664 120.445 115.616 120.723L104.836 84.016C105.992 84.1104 107.149 84.2284 108.291 84.3794L118.694 119.812Z" fill="white" />
                        <path opacity="0.3" d="M125.184 89.5242C125.656 89.7791 126.128 90.0387 126.562 90.3078C126.713 90.3927 126.864 90.4824 127.006 90.5721L127.454 90.8553C127.775 91.063 128.096 91.2707 128.398 91.4831C130.019 92.5923 131.481 93.9165 132.745 95.4195C134.329 97.2355 135.37 99.4597 135.752 101.839C135.959 103.297 135.861 104.782 135.464 106.2C135.37 106.53 135.256 106.861 135.134 107.191C134.485 108.838 133.54 110.351 132.344 111.656C132.184 111.831 132.014 112.01 131.844 112.185L125.184 89.5242Z" fill="#F10086" />
                        <path d="M117.179 92.2996C106.323 87.2492 90.0765 87.6221 80.1078 92.9982C79.683 93.2247 79.2677 93.4702 78.8665 93.7109C69.0489 99.7572 70.2902 108.923 81.6324 114.191C92.9745 119.458 110.127 118.826 119.945 112.775C120.346 112.53 120.728 112.275 121.092 112.02C129.644 105.992 128.058 97.35 117.179 92.2996Z" fill="#F10086" />
                        <path opacity="0.4" d="M117.179 92.2996C106.323 87.2492 90.0765 87.6221 80.1078 92.9982C79.683 93.2247 79.2677 93.4702 78.8665 93.7109C69.0489 99.7572 70.2902 108.923 81.6324 114.191C92.9745 119.458 110.127 118.826 119.945 112.775C120.346 112.53 120.728 112.275 121.092 112.02C129.644 105.992 128.058 97.35 117.179 92.2996Z" fill="black" />
                        <path d="M116.386 92.7858C116.036 92.6253 115.682 92.4648 115.314 92.3138C104.93 87.9855 90.1709 88.4858 80.9669 93.4513L80.7167 93.5834C80.3958 93.7628 80.0842 93.9422 79.7727 94.131C78.1138 95.1118 76.642 96.3791 75.4256 97.8739C72.9665 101.036 72.8154 104.562 74.8828 107.753C76.0958 109.641 78.0735 111.383 80.8017 112.879C81.3114 113.162 81.8495 113.436 82.4159 113.695C82.9823 113.955 83.4685 114.167 84.016 114.384C85.1802 114.843 86.3682 115.238 87.5749 115.569C96.1606 117.929 106.563 117.552 114.427 114.578C115.524 114.175 116.595 113.702 117.632 113.162C118.104 112.912 118.576 112.648 119.048 112.369C119.52 112.091 119.798 111.897 120.143 111.647L120.436 111.439C122.729 109.754 124.212 107.876 124.91 105.931C126.104 102.627 125.009 99.1389 121.719 96.1889C120.12 94.7968 118.322 93.6499 116.386 92.7858Z" fill="#F10086" />
                        <path opacity="0.5" d="M116.386 92.7858C116.036 92.6253 115.682 92.4648 115.314 92.3138C104.93 87.9855 90.1709 88.4858 80.9669 93.4513L80.7167 93.5834C80.3958 93.7628 80.0842 93.9422 79.7727 94.131C78.1138 95.1118 76.642 96.3791 75.4256 97.8739C72.9665 101.036 72.8154 104.562 74.8828 107.753C76.0958 109.641 78.0735 111.383 80.8017 112.879C81.3114 113.162 81.8495 113.436 82.4159 113.695C82.9823 113.955 83.4685 114.167 84.016 114.384C85.1802 114.843 86.3682 115.238 87.5749 115.569C96.1606 117.929 106.563 117.552 114.427 114.578C115.524 114.175 116.595 113.702 117.632 113.162C118.104 112.912 118.576 112.648 119.048 112.369C119.52 112.091 119.798 111.897 120.143 111.647L120.436 111.439C122.729 109.754 124.212 107.876 124.91 105.931C126.104 102.627 125.009 99.1389 121.719 96.1889C120.12 94.7968 118.322 93.6499 116.386 92.7858Z" fill="black" />
                        <path d="M115.328 95.3676C115.692 95.5139 116.051 95.6744 116.4 95.8396C118.328 96.7034 120.118 97.8452 121.715 99.2286C123.631 100.951 124.797 102.854 125.203 104.798C125.128 105.18 125.024 105.556 124.891 105.922C124.197 107.866 122.711 109.745 120.417 111.43L120.124 111.637C119.779 111.883 119.411 112.109 119.029 112.36C118.647 112.61 118.118 112.902 117.613 113.153C116.576 113.692 115.505 114.165 114.408 114.569C106.545 117.542 96.1417 117.924 87.556 115.56C86.3493 115.229 85.1613 114.833 83.9971 114.375C83.4496 114.158 82.921 113.931 82.397 113.686C81.8731 113.44 81.2926 113.153 80.7828 112.869C78.0546 111.373 76.0628 109.613 74.8639 107.743C74.2584 106.83 73.8279 105.812 73.5942 104.742C73.895 103.34 74.5206 102.029 75.4209 100.914C76.635 99.4166 78.1072 98.1489 79.768 97.1706C80.0748 96.9818 80.3863 96.8025 80.712 96.6231L80.9574 96.491C90.185 91.5397 104.963 91.0394 115.328 95.3676Z" fill="#F10086" />
                        <path opacity="0.25" d="M115.328 95.3676C115.692 95.5139 116.051 95.6744 116.4 95.8396C118.328 96.7034 120.118 97.8452 121.715 99.2286C123.631 100.951 124.797 102.854 125.203 104.798C125.128 105.18 125.024 105.556 124.891 105.922C124.197 107.866 122.711 109.745 120.417 111.43L120.124 111.637C119.779 111.883 119.411 112.109 119.029 112.36C118.647 112.61 118.118 112.902 117.613 113.153C116.576 113.692 115.505 114.165 114.408 114.569C106.545 117.542 96.1417 117.924 87.556 115.56C86.3493 115.229 85.1613 114.833 83.9971 114.375C83.4496 114.158 82.921 113.931 82.397 113.686C81.8731 113.44 81.2926 113.153 80.7828 112.869C78.0546 111.373 76.0628 109.613 74.8639 107.743C74.2584 106.83 73.8279 105.812 73.5942 104.742C73.895 103.34 74.5206 102.029 75.4209 100.914C76.635 99.4166 78.1072 98.1489 79.768 97.1706C80.0748 96.9818 80.3863 96.8025 80.712 96.6231L80.9574 96.491C90.185 91.5397 104.963 91.0394 115.328 95.3676Z" fill="black" />
                        <path d="M135.129 107.201C131.004 116.372 119.468 120.03 110.056 121.682C95.7546 123.735 80.1881 122.017 69.1338 113.879H69.1574C66.1508 111.519 63.5878 108.343 63.0214 104.501C63.4934 108.381 65.9714 111.661 68.9781 114.092H68.9545C77.8658 120.997 90.1945 123.234 101.994 122.904C113.908 122.206 129.739 119.005 135.129 107.201Z" fill="#F5F5F5" />
                        <path d="M84.4172 110.004C88.4575 111.699 92.4129 110.948 96.1039 109.655L95.1316 110.656V114.852C95.9547 115.047 96.7907 115.182 97.6332 115.258C99.0836 115.379 100.543 115.323 101.98 115.092C104.338 114.705 106.622 113.953 108.749 112.865L112.308 114.361L117.122 111.392V107.196L116.221 106.814L116.027 106.729C116.097 106.408 116.131 106.08 116.126 105.752C116.126 105.634 116.126 105.525 116.093 105.416C116.032 104.902 116.14 102.533 116.121 101.556C116.12 101.445 116.11 101.334 116.093 101.225C115.952 100.055 114.974 98.9973 112.987 98.1713C110.974 97.3557 108.786 97.065 106.63 97.3264L106.087 97.3925H106.025L105.464 97.4869C105.298 97.5152 105.133 97.5388 104.968 97.5813C104.897 97.5813 104.826 97.6049 104.77 97.6238C104.638 97.6238 104.51 97.671 104.392 97.704C103.353 97.9485 102.329 98.251 101.324 98.6102C100.885 98.7613 100.446 98.9312 100.017 99.0822L101.282 97.7606V93.5646C97.1942 92.7858 92.8235 93.4466 88.5378 95.547L84.96 94.0885L80.2117 97.1187V101.315L81.3256 101.787C81.2526 102.045 81.2176 102.312 81.2218 102.58V102.613C81.2595 103.264 81.1982 106.04 81.2218 106.776V106.809C81.2973 108.031 82.3074 109.126 84.4172 110.004Z" fill="#F10086" />
                        <path opacity="0.15" d="M116.112 101.758C116.112 102.835 116.032 104.944 116.112 105.426C116.112 105.539 116.112 105.643 116.14 105.756C116.147 106.088 116.113 106.419 116.041 106.743L113.483 105.667C113.483 105.667 116.046 104.421 116.112 101.758Z" fill="black" />
                        <path opacity="0.45" d="M90.2842 101.692C89.2174 101.249 89.0428 100.418 91.0063 99.1531C93.1114 97.8173 95.2543 97.468 97.114 97.9306L101.277 93.5929V97.7842L100.017 99.12L99.8846 99.1672C99.2049 99.4315 98.5441 99.7006 97.9117 99.9602C97.6568 100.078 97.4066 100.182 97.1612 100.276C95.8076 100.91 94.4022 101.426 92.9604 101.82C92.3669 101.979 91.748 102.021 91.1385 101.943C90.8429 101.902 90.5551 101.817 90.2842 101.692Z" fill="black" />
                        <path opacity="0.2" d="M81.3209 101.773L80.2117 101.301V97.1093L83.7611 98.5961C82.4395 99.7006 81.5946 100.772 81.3209 101.773Z" fill="black" />
                        <path opacity="0.15" d="M112.336 110.16L117.141 107.205V111.392L112.327 114.361L108.777 112.86C106.647 113.949 104.36 114.699 101.999 115.083C100.563 115.317 99.1028 115.376 97.6521 115.258C96.8113 115.178 95.977 115.041 95.1552 114.847V110.651C97.3969 111.192 99.725 111.274 101.999 110.892C104.361 110.508 106.648 109.756 108.777 108.664L112.336 110.16Z" fill="black" />
                        <path opacity="0.2" d="M108.758 112.865C107.097 113.896 105.275 114.643 103.368 115.074C101.491 115.517 99.5446 115.581 97.6426 115.262C99.5484 115.238 101.446 115.017 103.307 114.602C104.251 114.399 105.152 114.13 106.063 113.846C106.974 113.563 107.847 113.209 108.758 112.865Z" fill="black" />
                        <path opacity="0.35" d="M81.2265 106.795C81.1981 106.054 81.2595 103.283 81.2265 102.622C81.302 103.864 82.3026 104.954 84.4125 105.837C89.0994 107.791 93.6637 106.507 97.8409 104.831C101.721 103.259 104.539 101.593 106.809 102.537C108.225 103.137 107.824 104.076 106.271 105.067C105.284 105.691 104.181 106.106 103.029 106.288C101.876 106.47 100.699 106.414 99.5684 106.124L96.1134 109.683C92.4176 110.991 88.4622 111.713 84.4314 110.023C82.3026 109.126 81.302 108.036 81.2265 106.795Z" fill="black" />
                        <path d="M97.1187 102.127L97.114 97.9306L101.272 93.5882L101.277 97.7842L97.1187 102.127Z" fill="#F10086" />
                        <path opacity="0.15" d="M97.1187 102.127L97.114 97.9306L101.272 93.5882L101.277 97.7842L97.1187 102.127Z" fill="black" />
                        <path d="M80.2117 97.1187L83.7706 98.6055L81.3256 101.777L80.2117 101.315V97.1187Z" fill="#F10086" />
                        <path opacity="0.45" d="M80.2117 97.1187L83.7706 98.6055L81.3256 101.777L80.2117 101.315V97.1187Z" fill="black" />
                        <path d="M116.216 106.823L113.464 105.662C113.464 105.662 116.169 104.354 116.117 101.565C116.115 101.454 116.105 101.344 116.088 101.235C115.947 100.059 114.97 99.0067 112.983 98.1807C110.97 97.3629 108.781 97.0721 106.625 97.3358L106.082 97.4019H106.021L105.459 97.4963C105.294 97.5246 105.129 97.5482 104.963 97.5907C104.893 97.5907 104.822 97.6143 104.765 97.6332C104.633 97.6332 104.506 97.6804 104.388 97.7134C103.349 97.9579 102.324 98.2605 101.32 98.6197C100.848 98.7849 100.376 98.969 99.9035 99.1578C99.2238 99.4221 98.5583 99.6958 97.9211 99.9554C97.6662 100.069 97.4208 100.173 97.1754 100.272C95.8197 100.9 94.4147 101.417 92.9746 101.815C92.3821 101.971 91.7654 102.013 91.1574 101.938C90.8638 101.895 90.5768 101.816 90.303 101.702C89.2316 101.23 89.0522 100.423 91.0205 99.1578C93.1256 97.8173 95.2921 97.468 97.1282 97.9353L101.286 93.5929C97.199 92.8141 92.8282 93.4749 88.5425 95.5753L84.96 94.0885L80.2117 97.1187L83.7706 98.6055C82.0714 100.022 81.1746 101.357 81.2218 102.57V102.603C81.2926 103.84 82.3074 104.93 84.4125 105.808C89.09 107.772 93.6637 106.483 97.8362 104.808C101.716 103.236 104.529 101.57 106.804 102.518C108.22 103.113 107.814 104.052 106.261 105.048C105.274 105.67 104.171 106.084 103.019 106.266C101.867 106.448 100.689 106.393 99.559 106.106L95.1316 110.656C95.955 110.85 96.7909 110.987 97.6332 111.066C99.0836 111.185 100.543 111.13 101.98 110.901C104.338 110.513 106.621 109.761 108.749 108.673L112.308 110.165L117.117 107.205L116.216 106.823Z" fill="#F10086" />
                        <path opacity="0.1" d="M116.216 106.823L113.464 105.662C113.464 105.662 116.169 104.354 116.117 101.565C116.115 101.454 116.105 101.344 116.088 101.235C115.947 100.059 114.97 99.0067 112.983 98.1807C110.97 97.3629 108.781 97.0721 106.625 97.3358L106.082 97.4019H106.021L105.459 97.4963C105.294 97.5246 105.129 97.5482 104.963 97.5907C104.893 97.5907 104.822 97.6143 104.765 97.6332C104.633 97.6332 104.506 97.6804 104.388 97.7134C103.349 97.9579 102.324 98.2605 101.32 98.6197C100.848 98.7849 100.376 98.969 99.9035 99.1578C99.2238 99.4221 98.5583 99.6958 97.9211 99.9554C97.6662 100.069 97.4208 100.173 97.1754 100.272C95.8197 100.9 94.4147 101.417 92.9746 101.815C92.3821 101.971 91.7654 102.013 91.1574 101.938C90.8638 101.895 90.5768 101.816 90.303 101.702C89.2316 101.23 89.0522 100.423 91.0205 99.1578C93.1256 97.8173 95.2921 97.468 97.1282 97.9353L101.286 93.5929C97.199 92.8141 92.8282 93.4749 88.5425 95.5753L84.96 94.0885L80.2117 97.1187L83.7706 98.6055C82.0714 100.022 81.1746 101.357 81.2218 102.57V102.603C81.2926 103.84 82.3074 104.93 84.4125 105.808C89.09 107.772 93.6637 106.483 97.8362 104.808C101.716 103.236 104.529 101.57 106.804 102.518C108.22 103.113 107.814 104.052 106.261 105.048C105.274 105.67 104.171 106.084 103.019 106.266C101.867 106.448 100.689 106.393 99.559 106.106L95.1316 110.656C95.955 110.85 96.7909 110.987 97.6332 111.066C99.0836 111.185 100.543 111.13 101.98 110.901C104.338 110.513 106.621 109.761 108.749 108.673L112.308 110.165L117.117 107.205L116.216 106.823Z" fill="white" />
                        <path d="M84.4125 105.808C86.1867 106.488 88.085 106.784 89.9821 106.677C91.9257 106.55 93.8411 106.144 95.6697 105.473C97.5294 104.86 99.323 104.057 101.15 103.292C102.059 102.903 102.998 102.588 103.958 102.348C104.896 102.108 105.886 102.166 106.79 102.514C105.873 102.206 104.883 102.193 103.958 102.476C103.015 102.736 102.093 103.069 101.202 103.472C99.3985 104.255 97.5954 105.081 95.7216 105.719C93.8652 106.387 91.9198 106.777 89.949 106.875C88.9844 106.909 88.019 106.838 87.0698 106.663C86.1574 106.468 85.2671 106.182 84.4125 105.808Z" fill="white" />
                        <path d="M112.308 110.165L112.312 114.361L108.754 112.865L108.749 108.673L112.308 110.165Z" fill="#F10086" />
                        <path opacity="0.35" d="M112.308 110.165L112.312 114.361L108.754 112.865L108.749 108.673L112.308 110.165Z" fill="black" />
                        <path d="M117.117 107.205L112.327 109.698L108.749 108.673L112.308 110.165L117.117 107.205Z" fill="#FAFAFA" />
                        <path d="M88.5189 95.5706L84.9836 94.5227L80.2117 97.1187L84.96 94.0885L88.5189 95.5706Z" fill="#FAFAFA" />
                        <path opacity="0.15" d="M101.272 93.5882C100.535 93.4525 99.7888 93.3642 99.0398 93.3239L99.625 95.311L101.272 93.5882Z" fill="white" />
                        <path opacity="0.15" d="M84.5729 105.87C86.5185 106.649 88.6239 106.945 90.709 106.733L87.2822 95.0514L84.9647 94.1074L81.7268 96.1747L82.2602 97.9919L83.7753 98.6244C83.4064 98.9166 83.0564 99.2319 82.7274 99.5684L84.5729 105.87Z" fill="white" />
                        <path opacity="0.15" d="M112.26 97.9023C111.121 97.517 109.932 97.2992 108.73 97.2556L112.506 110.056L115.338 108.315L114.696 106.2L113.469 105.681C113.789 105.512 114.091 105.311 114.37 105.081L112.26 97.9023Z" fill="white" />
                        <path opacity="0.15" d="M106.021 97.3972L105.459 97.4916C105.294 97.5199 105.129 97.5435 104.963 97.586C104.893 97.586 104.822 97.6096 104.765 97.6285C104.633 97.6285 104.506 97.6757 104.388 97.7087C103.349 97.9532 102.324 98.2557 101.32 98.615C101.102 98.6905 100.885 98.7802 100.663 98.8604L101.91 103.108C103.821 102.353 105.416 101.957 106.804 102.537C108.192 103.118 107.814 104.071 106.261 105.067C105.227 105.725 104.06 106.146 102.844 106.299L104.067 110.472C105.684 110.041 107.254 109.445 108.749 108.692L110.094 109.254L106.596 97.3453L106.082 97.4114L106.021 97.3972Z" fill="white" />
                        <path d="M65.7826 104.435L66.3632 100.036L66.9438 104.435L74.5618 104.77L66.9438 105.105L66.3632 109.504L65.7826 105.105L58.1646 104.77L65.7826 104.435Z" fill="white" />
                        <path d="M72.0886 107.743L72.367 105.61L72.6502 107.743L76.3413 107.904L72.6502 108.069L72.367 110.203L72.0886 108.069L68.3928 107.904L72.0886 107.743Z" fill="white" />
                        <path d="M55.6582 129.493L56.2388 125.094L56.8194 129.493L64.4422 129.828L56.8194 130.163L56.2388 134.562L55.6582 130.163L48.0402 129.828L55.6582 129.493Z" fill="white" />
                        <path d="M59.7788 132.906L60.0573 130.772L60.3405 132.906L64.0315 133.066L60.3405 133.227L60.0573 135.36L59.7788 133.227L56.083 133.066L59.7788 132.906Z" fill="white" />
                        <path d="M54.4971 64.664C54.4903 66.2576 54.5865 67.85 54.785 69.4312C55.6837 76.462 58.6207 83.076 63.2338 88.4573C67.8468 93.8387 73.9342 97.752 80.745 99.7147C83.9685 100.639 87.3038 101.115 90.6571 101.131C93.2065 101.143 95.7503 100.888 98.2468 100.371C103.005 99.369 107.515 97.4245 111.51 94.6521C115.504 91.8797 118.904 88.3355 121.507 84.2284C121.842 83.6998 122.163 83.157 122.475 82.6142C123.916 80.0556 125.039 77.331 125.821 74.5005C126.085 73.5565 126.293 72.5653 126.501 71.5741C126.708 70.5829 126.836 69.5775 126.94 68.5533C127.062 67.3733 127.125 66.1791 127.128 64.9708C127.137 63.5151 127.056 62.0602 126.888 60.6142C126.774 59.6136 126.617 58.6271 126.416 57.6548C125.001 50.6404 121.548 44.1996 116.488 39.1399C111.428 34.0801 104.987 30.6265 97.9731 29.2121C97.0007 29.0138 96.0095 28.8581 95.0089 28.7401C93.6646 28.5832 92.3125 28.5029 90.9591 28.4994C89.6618 28.4908 88.365 28.5538 87.0746 28.6882C86.055 28.7873 85.045 28.9383 84.0538 29.1271C83.0626 29.3159 82.0855 29.5378 81.1226 29.8021C78.2913 30.5833 75.5664 31.7088 73.009 33.1533C72.4614 33.4601 71.9186 33.781 71.39 34.0973C65.5678 37.7943 60.9106 43.0634 57.9569 49.2957C56.7386 51.8677 55.8307 54.5756 55.2523 57.3622C54.7565 59.7647 54.5035 62.2109 54.4971 64.664Z" fill="#F10086" />
                        <path opacity="0.1" d="M54.4971 64.664C54.4903 66.2576 54.5865 67.85 54.785 69.4312C55.6837 76.462 58.6207 83.076 63.2338 88.4573C67.8468 93.8387 73.9342 97.752 80.745 99.7147C83.9685 100.639 87.3038 101.115 90.6571 101.131C93.2065 101.143 95.7503 100.888 98.2468 100.371C103.005 99.369 107.515 97.4245 111.51 94.6521C115.504 91.8797 118.904 88.3355 121.507 84.2284C121.842 83.6998 122.163 83.157 122.475 82.6142C123.916 80.0556 125.039 77.331 125.821 74.5005C126.085 73.5565 126.293 72.5653 126.501 71.5741C126.708 70.5829 126.836 69.5775 126.94 68.5533C127.062 67.3733 127.125 66.1791 127.128 64.9708C127.137 63.5151 127.056 62.0602 126.888 60.6142C126.774 59.6136 126.617 58.6271 126.416 57.6548C125.001 50.6404 121.548 44.1996 116.488 39.1399C111.428 34.0801 104.987 30.6265 97.9731 29.2121C97.0007 29.0138 96.0095 28.8581 95.0089 28.7401C93.6646 28.5832 92.3125 28.5029 90.9591 28.4994C89.6618 28.4908 88.365 28.5538 87.0746 28.6882C86.055 28.7873 85.045 28.9383 84.0538 29.1271C83.0626 29.3159 82.0855 29.5378 81.1226 29.8021C78.2913 30.5833 75.5664 31.7088 73.009 33.1533C72.4614 33.4601 71.9186 33.781 71.39 34.0973C65.5678 37.7943 60.9106 43.0634 57.9569 49.2957C56.7386 51.8677 55.8307 54.5756 55.2523 57.3622C54.7565 59.7647 54.5035 62.2109 54.4971 64.664Z" fill="white" />
                        <path opacity="0.05" d="M125.708 54.752C125.982 55.696 126.222 56.6778 126.42 57.669C126.619 58.6602 126.774 59.6278 126.892 60.6284C127.061 62.0744 127.141 63.5292 127.133 64.985C127.133 66.1933 127.07 67.3875 126.944 68.5675C126.841 69.5933 126.694 70.6002 126.505 71.5883C126.317 72.5795 126.09 73.5518 125.826 74.5147C125.04 77.3356 123.915 80.0507 122.475 82.6C122.163 83.1428 121.842 83.6856 121.507 84.2143C118.224 89.4199 113.668 93.7034 108.271 96.6607C102.873 99.6179 96.8115 101.151 90.657 101.117C89.3321 101.108 88.0085 101.028 86.6922 100.876L125.708 54.752Z" fill="black" />
                        <path opacity="0.05" d="M125.146 76.6717C124.437 78.7247 123.543 80.709 122.475 82.6C122.163 83.1428 121.842 83.6856 121.507 84.2143C118.208 89.4183 113.643 93.7007 108.239 96.6609L125.146 76.6717Z" fill="black" />
                        <path opacity="0.15" d="M120.742 44.2406C121.912 45.9366 122.932 47.7306 123.791 49.6025L81.3114 99.8658L80.7356 99.71C78.9567 99.1889 77.2209 98.5308 75.5436 97.7418L120.742 44.2406Z" fill="white" />
                        <path opacity="0.15" d="M106.851 32.2234C108.756 33.1656 110.575 34.2711 112.289 35.5274L65.5183 90.8553C63.9943 89.3732 62.6029 87.7607 61.36 86.0362L106.851 32.2234Z" fill="white" />
                        <path opacity="0.15" d="M101.546 30.1136C102.669 30.4629 103.771 30.8625 104.85 31.3125L60.1281 84.2142C59.505 83.2278 58.915 82.2177 58.3864 81.1698L101.546 30.1136Z" fill="white" />
                        <path opacity="0.15" d="M54.601 62.1907C54.6482 61.5205 54.719 60.855 54.7992 60.1989C54.8275 59.9771 54.8511 59.7552 54.8842 59.5381C54.9172 59.321 54.9502 59.0661 54.988 58.8631C55.073 58.3911 55.1579 57.8719 55.257 57.3811C56.6918 50.5101 60.0909 44.2037 65.0416 39.2279C66.9616 37.2919 69.0926 35.5773 71.3947 34.1162C71.9234 33.7811 72.4662 33.4601 73.0137 33.1722C75.5711 31.7277 78.296 30.6022 81.1274 29.821C81.4861 29.7219 81.8448 29.6369 82.2082 29.5472L54.601 62.1907Z" fill="white" />
                        <path d="M63.753 64.815C63.7521 71.6782 66.3595 78.2852 71.0472 83.2981C75.7349 88.3109 82.1525 91.3549 89.0003 91.8134C89.595 91.8512 90.2086 91.8748 90.8175 91.8748C97.9329 91.7808 104.725 88.8883 109.724 83.8234C114.722 78.7585 117.525 71.9287 117.525 64.8127C117.525 57.6966 114.722 50.8669 109.724 45.802C104.725 40.7371 97.9329 37.8445 90.8175 37.7506C90.2086 37.7506 89.595 37.7506 89.0003 37.8119C82.1525 38.2728 75.7355 41.318 71.0481 46.3314C66.3607 51.3447 63.7531 57.9517 63.753 64.815Z" fill="#F10086" />
                        <path opacity="0.4" d="M63.753 64.815C63.7521 71.6782 66.3595 78.2852 71.0472 83.2981C75.7349 88.3109 82.1525 91.3549 89.0003 91.8134C89.595 91.8512 90.2086 91.8748 90.8175 91.8748C97.9329 91.7808 104.725 88.8883 109.724 83.8234C114.722 78.7585 117.525 71.9287 117.525 64.8127C117.525 57.6966 114.722 50.8669 109.724 45.802C104.725 40.7371 97.9329 37.8445 90.8175 37.7506C90.2086 37.7506 89.595 37.7506 89.0003 37.8119C82.1525 38.2728 75.7355 41.318 71.0481 46.3314C66.3607 51.3447 63.7531 57.9517 63.753 64.815Z" fill="black" />
                        <path d="M64.9566 64.8151C64.9566 65.2871 64.9566 65.7591 64.9944 66.1933C65.3307 72.5014 67.965 78.4681 72.4 82.9666C76.835 87.4651 82.7637 90.184 89.0664 90.6098L89.4204 90.6334C89.8924 90.6334 90.3361 90.6712 90.7986 90.6712C97.2797 90.6837 103.529 88.2591 108.305 83.8786C113.082 79.4981 116.037 73.4819 116.584 67.024C116.65 66.2924 116.678 65.5561 116.678 64.8056C116.678 64.0551 116.655 63.4415 116.603 62.7713C116.497 61.3818 116.276 60.0035 115.942 58.6507C114.811 54.0602 112.445 49.8669 109.1 46.5255C105.755 43.184 101.559 40.8219 96.9677 39.6952C95.6149 39.3609 94.2366 39.1399 92.8471 39.0344C92.1769 38.9825 91.4972 38.9589 90.8128 38.9589C90.2322 38.9589 89.6517 38.9589 89.0806 39.0155C88.9201 39.0155 88.7596 39.0155 88.6086 39.0533C84.8414 39.3683 81.1906 40.5127 77.9178 42.4045C73.9783 44.6701 70.7067 47.9353 68.4333 51.8703C66.16 55.8053 64.9656 60.2706 64.9708 64.8151H64.9566Z" fill="#F10086" />
                        <path opacity="0.5" d="M64.9566 64.8151C64.9566 65.2871 64.9566 65.7591 64.9944 66.1933C65.3307 72.5014 67.965 78.4681 72.4 82.9666C76.835 87.4651 82.7637 90.184 89.0664 90.6098L89.4204 90.6334C89.8924 90.6334 90.3361 90.6712 90.7986 90.6712C97.2797 90.6837 103.529 88.2591 108.305 83.8786C113.082 79.4981 116.037 73.4819 116.584 67.024C116.65 66.2924 116.678 65.5561 116.678 64.8056C116.678 64.0551 116.655 63.4415 116.603 62.7713C116.497 61.3818 116.276 60.0035 115.942 58.6507C114.811 54.0602 112.445 49.8669 109.1 46.5255C105.755 43.184 101.559 40.8219 96.9677 39.6952C95.6149 39.3609 94.2366 39.1399 92.8471 39.0344C92.1769 38.9825 91.4972 38.9589 90.8128 38.9589C90.2322 38.9589 89.6517 38.9589 89.0806 39.0155C88.9201 39.0155 88.7596 39.0155 88.6086 39.0533C84.8414 39.3683 81.1906 40.5127 77.9178 42.4045C73.9783 44.6701 70.7067 47.9353 68.4333 51.8703C66.16 55.8053 64.9656 60.2706 64.9708 64.8151H64.9566Z" fill="black" />
                        <path d="M109.032 83.1617C113.394 78.856 116.073 73.1321 116.584 67.024C116.65 66.2924 116.683 65.5561 116.683 64.8056C116.683 64.0551 116.655 63.4415 116.603 62.7713C116.497 61.3818 116.276 60.0035 115.942 58.6507C114.811 54.0602 112.445 49.8669 109.1 46.5255C105.755 43.184 101.559 40.8219 96.9677 39.6952C95.6149 39.3609 94.2366 39.1399 92.8471 39.0344C92.1769 38.9825 91.4972 38.9589 90.8128 38.9589C90.2322 38.9589 89.6517 38.9589 89.0806 39.0155C88.9201 39.0155 88.7596 39.0155 88.6086 39.0533C84.8414 39.3683 81.1906 40.5127 77.9178 42.4045C76.7833 43.0689 75.7009 43.8184 74.6798 44.6465C72.2632 47.042 70.3463 49.8935 69.0402 53.0357C67.734 56.1778 67.0648 59.5479 67.0712 62.9507C67.0712 63.4227 67.0712 63.8711 67.1137 64.3289C67.4479 70.6377 70.0816 76.6055 74.5171 81.1044C78.9525 85.6033 84.8823 88.3216 91.1857 88.7455H91.535C92.007 88.7691 92.4554 88.7832 92.9179 88.7832C98.7768 88.7891 104.461 86.7905 109.027 83.1192L109.032 83.1617Z" fill="#F10086" />
                        <path opacity="0.25" d="M109.032 83.1617C113.394 78.856 116.073 73.1321 116.584 67.024C116.65 66.2924 116.683 65.5561 116.683 64.8056C116.683 64.0551 116.655 63.4415 116.603 62.7713C116.497 61.3818 116.276 60.0035 115.942 58.6507C114.811 54.0602 112.445 49.8669 109.1 46.5255C105.755 43.184 101.559 40.8219 96.9677 39.6952C95.6149 39.3609 94.2366 39.1399 92.8471 39.0344C92.1769 38.9825 91.4972 38.9589 90.8128 38.9589C90.2322 38.9589 89.6517 38.9589 89.0806 39.0155C88.9201 39.0155 88.7596 39.0155 88.6086 39.0533C84.8414 39.3683 81.1906 40.5127 77.9178 42.4045C76.7833 43.0689 75.7009 43.8184 74.6798 44.6465C72.2632 47.042 70.3463 49.8935 69.0402 53.0357C67.734 56.1778 67.0648 59.5479 67.0712 62.9507C67.0712 63.4227 67.0712 63.8711 67.1137 64.3289C67.4479 70.6377 70.0816 76.6055 74.5171 81.1044C78.9525 85.6033 84.8823 88.3216 91.1857 88.7455H91.535C92.007 88.7691 92.4554 88.7832 92.9179 88.7832C98.7768 88.7891 104.461 86.7905 109.027 83.1192L109.032 83.1617Z" fill="black" />
                        <path d="M73.0137 33.1533C88.6416 25.0585 101.334 29.2687 109.83 34.3191C122.102 42.5555 129.842 56.3521 125.609 74.5855V74.5477C123.992 80.6122 120.812 86.1461 116.386 90.5957C120.956 86.1325 124.279 80.5511 126.024 74.4061V74.4391C129.927 59.3351 125.019 47.3416 116.829 38.8787C108.055 30.7886 93.187 22.7976 73.0137 33.1533Z" fill="#F5F5F5" />
                        <path d="M86.9235 45.7038L86.8952 49.1871C86.8952 49.1871 83.3127 48.5546 79.2299 51.7784C79.06 51.9153 78.909 52.0474 78.7579 52.1796C77.9118 52.9122 77.2392 53.8237 76.7886 54.8482C76.338 55.8727 76.1207 56.9845 76.1525 58.1032C76.119 59.232 76.339 60.354 76.7964 61.3865C77.2539 62.419 77.9371 63.3357 78.7957 64.0693C78.9326 64.1873 79.093 64.2958 79.2299 64.4138L79.2724 64.4563L79.7444 64.7962C79.887 64.8972 80.0351 64.9902 80.1881 65.0746C80.2475 65.114 80.309 65.1502 80.3722 65.1832C80.4843 65.2606 80.6027 65.3285 80.7262 65.3862C81.7552 65.9353 82.8289 66.3964 83.9358 66.7644C84.4786 66.9532 85.0402 67.1231 85.6019 67.2789C86.4138 67.5102 87.2256 67.7226 87.9997 67.9208L88.9437 68.1521C91.1526 68.6854 92.7622 69.1197 93.7817 69.6955C94.3536 69.955 94.8139 70.4104 95.0797 70.9794C95.1995 71.2796 95.2604 71.6001 95.2591 71.9234C95.2591 73.278 94.131 74.373 91.063 74.2834C87.7967 74.1606 85.9606 72.8674 85.5217 70.8755L76.5537 72.5558C77.9083 76.6622 81.5569 79.3432 87.2822 80.325V84.8137L94.6643 85.0874V80.5987C97.7654 80.3391 100.328 79.4942 102.075 78.0735L102.122 78.0358C103.029 77.2919 103.751 76.3484 104.232 75.279C104.714 74.2096 104.941 73.0434 104.897 71.8714C104.926 65.9573 100.229 63.8475 95.2024 62.4692C90.506 61.209 86.3288 60.8974 86.3382 58.023C86.3382 56.2294 87.9808 55.4836 90.3974 55.5686C94.2537 55.7054 95.7546 57.9805 96.0614 59.6325L105.501 57.9191C105.291 57.0722 104.974 56.2554 104.557 55.4883C103.844 54.2071 102.852 53.1023 101.655 52.2551C99.559 50.7447 96.8969 49.8951 94.2254 49.4798V44.9769L86.9188 44.571L86.9235 45.7038Z" fill="#F10086" />
                        <path opacity="0.1" d="M86.9235 45.7038L86.8952 49.1871C86.8952 49.1871 83.3127 48.5546 79.2299 51.7784C79.06 51.9153 78.909 52.0474 78.7579 52.1796C77.9118 52.9122 77.2392 53.8237 76.7886 54.8482C76.338 55.8727 76.1207 56.9845 76.1525 58.1032C76.119 59.232 76.339 60.354 76.7964 61.3865C77.2539 62.419 77.9371 63.3357 78.7957 64.0693C78.9326 64.1873 79.093 64.2958 79.2299 64.4138L79.2724 64.4563L79.7444 64.7962C79.887 64.8972 80.0351 64.9902 80.1881 65.0746C80.2475 65.114 80.309 65.1502 80.3722 65.1832C80.4843 65.2606 80.6027 65.3285 80.7262 65.3862C81.7552 65.9353 82.8289 66.3964 83.9358 66.7644C84.4786 66.9532 85.0402 67.1231 85.6019 67.2789C86.4138 67.5102 87.2256 67.7226 87.9997 67.9208L88.9437 68.1521C91.1526 68.6854 92.7622 69.1197 93.7817 69.6955C94.3536 69.955 94.8139 70.4104 95.0797 70.9794C95.1995 71.2796 95.2604 71.6001 95.2591 71.9234C95.2591 73.278 94.131 74.373 91.063 74.2834C87.7967 74.1606 85.9606 72.8674 85.5217 70.8755L76.5537 72.5558C77.9083 76.6622 81.5569 79.3432 87.2822 80.325V84.8137L94.6643 85.0874V80.5987C97.7654 80.3391 100.328 79.4942 102.075 78.0735L102.122 78.0358C103.029 77.2919 103.751 76.3484 104.232 75.279C104.714 74.2096 104.941 73.0434 104.897 71.8714C104.926 65.9573 100.229 63.8475 95.2024 62.4692C90.506 61.209 86.3288 60.8974 86.3382 58.023C86.3382 56.2294 87.9808 55.4836 90.3974 55.5686C94.2537 55.7054 95.7546 57.9805 96.0614 59.6325L105.501 57.9191C105.291 57.0722 104.974 56.2554 104.557 55.4883C103.844 54.2071 102.852 53.1023 101.655 52.2551C99.559 50.7447 96.8969 49.8951 94.2254 49.4798V44.9769L86.9188 44.571L86.9235 45.7038Z" fill="white" />
                        <path d="M104.897 71.8573C104.888 69.8644 104.128 67.9482 102.769 66.4906C101.456 65.0746 99.5873 64.1306 97.5105 63.3943C95.4337 62.658 93.1634 62.1577 90.9355 61.5771C89.8605 61.3198 88.8196 60.9364 87.8345 60.4349C87.3797 60.2153 86.9972 59.8702 86.7321 59.4403C86.467 59.0105 86.3303 58.5138 86.3382 58.0088C86.3713 59.0897 87.0746 59.8166 88.0044 60.2744C89.0162 60.7359 90.0771 61.0811 91.1668 61.3034C93.4188 61.7605 95.6437 62.3419 97.8314 63.045C99.8988 63.805 101.806 64.782 103.075 66.2641C103.718 66.9972 104.209 67.8507 104.52 68.7751C104.807 69.7763 104.934 70.8164 104.897 71.8573Z" fill="white" />
                        <path d="M86.9377 44.5568L93.541 45.4819L94.2206 49.4656L94.2442 44.9627L86.9377 44.5568Z" fill="#FAFAFA" />
                        <path d="M87.2822 80.3108L87.9006 84.285L94.6549 85.0733L87.2775 84.7995L87.2822 80.3108Z" fill="#FAFAFA" />
                        <path opacity="0.15" d="M76.5537 72.5417C76.7992 73.2629 77.127 73.9535 77.5307 74.5996L80.1078 71.8762L76.5537 72.5417Z" fill="white" />
                        <path opacity="0.15" d="M104.888 71.6638C104.913 70.5752 104.705 69.4939 104.278 68.4924C103.851 67.491 103.214 66.5928 102.41 65.8582L87.2822 81.8778V84.7995L92.3185 84.9883L94.6785 82.4914V80.6035C95.3703 80.5452 96.0587 80.4523 96.7411 80.325L104.888 71.6638Z" fill="white" />
                        <path opacity="0.15" d="M76.1855 59C76.2853 60.2545 76.7028 61.463 77.3986 62.5117L93.9752 44.9439L89.6706 44.7079L86.9094 47.6343V49.1871C86.431 49.1325 85.9481 49.1325 85.4698 49.1871L76.1855 59Z" fill="white" />
                        <path opacity="0.15" d="M79.263 64.4658L79.735 64.8056C79.8775 64.9066 80.0257 64.9996 80.1786 65.0841C80.2381 65.1234 80.2995 65.1597 80.3627 65.1926C80.4748 65.2701 80.5932 65.338 80.7167 65.3956C81.7458 65.9448 82.8195 66.4058 83.9263 66.7738C84.1765 66.8588 84.4361 66.9296 84.691 67.0098L90.1709 61.209C87.8864 60.5623 86.3241 59.7646 86.3288 58.0088C86.3335 56.253 87.9714 55.4694 90.388 55.5544C91.7959 55.5489 93.1681 55.9975 94.3009 56.8335L99.7006 51.1129C97.9612 50.2971 96.1118 49.7406 94.2112 49.4609V47.7664L78.824 64.0929C78.9562 64.2062 79.1072 64.3147 79.2346 64.4233L79.263 64.4658Z" fill="white" />
                        <path d="M67.5857 43.0606L68.1851 35.1687L68.7846 43.0606L76.6764 43.66L68.7846 44.2642L68.1851 52.1513L67.5857 44.2642L59.6938 43.66L67.5857 43.0606Z" fill="white" />
                        <path d="M73.7736 49.5364L74.0662 45.7085L74.3542 49.5364L78.1821 49.8243L74.3542 50.117L74.0662 53.9402L73.7736 50.117L69.9504 49.8243L73.7736 49.5364Z" fill="white" />
                        <path d="M203.692 172.53H207.689V180.71C207.69 181.692 207.558 182.67 207.298 183.617C207.203 183.948 207.09 184.278 206.967 184.609C206.32 186.254 205.374 187.767 204.178 189.069C203.731 189.566 203.258 190.038 202.762 190.485C202.234 190.972 201.683 191.432 201.11 191.863C200.34 192.457 199.54 193.01 198.712 193.52C197.806 194.072 196.862 194.587 195.88 195.073C195.134 195.436 194.37 195.781 193.577 196.107C182.003 200.888 166.045 201.469 153.31 197.589C152.442 197.324 151.592 197.04 150.762 196.734C149.567 196.295 148.402 195.819 147.292 195.295C146.377 194.865 145.513 194.422 144.696 193.954C143.993 193.553 143.323 193.138 142.695 192.713C142.067 192.288 141.473 191.854 140.92 191.41C139.396 190.216 138.062 188.796 136.965 187.2C136.753 186.884 136.55 186.563 136.37 186.256C135.605 184.929 135.106 183.465 134.902 181.947C134.845 181.491 134.818 181.032 134.822 180.573V172.837H138.48C138.773 172.521 139.08 172.204 139.424 171.893C140.769 170.626 142.245 169.508 143.828 168.556C144.848 167.936 145.898 167.369 146.976 166.857C148.424 166.167 149.91 165.56 151.427 165.04C161.896 161.419 175.225 160.933 186.553 163.746C188.209 164.154 189.842 164.646 191.448 165.219C192.765 165.691 194.039 166.21 195.262 166.781C197.015 167.592 198.695 168.552 200.284 169.651C201.51 170.498 202.651 171.463 203.692 172.53Z" fill="#F10086" />
                        <path opacity="0.15" d="M161.136 199.335V182.73L167.215 183.367V199.986C165.167 199.864 163.133 199.656 161.136 199.335Z" fill="white" />
                        <path opacity="0.1" d="M207.68 180.696C207.68 181.678 207.548 182.656 207.288 183.603C207.194 183.934 207.081 184.264 206.958 184.594C206.31 186.24 205.365 187.752 204.168 189.055C204.008 189.234 203.838 189.409 203.668 189.588V172.502L203.696 172.53H207.694L207.68 180.696Z" fill="black" />
                        <path opacity="0.15" d="M187.417 173.064L190.537 172.327V197.211C189.543 197.526 188.525 197.826 187.483 198.113L187.417 173.064Z" fill="white" />
                        <path opacity="0.15" d="M179.36 199.656V178.086L185.439 178.558V198.641C183.431 199.081 181.402 199.42 179.36 199.656Z" fill="white" />
                        <path opacity="0.15" d="M134.794 172.794H138.452C138.744 172.478 139.051 172.162 139.396 171.85C140.74 170.584 142.217 169.465 143.8 168.513C144.819 167.894 145.87 167.327 146.948 166.814C148.396 166.124 149.882 165.518 151.399 164.997C152.865 164.494 154.383 164.051 155.954 163.671L155.982 198.311C155.08 198.089 154.188 197.839 153.315 197.579C152.442 197.32 151.597 197.032 150.766 196.725C149.572 196.286 148.406 195.809 147.297 195.285C146.381 194.856 145.518 194.412 144.701 193.945C143.998 193.544 143.328 193.128 142.7 192.703C142.072 192.279 141.477 191.844 140.925 191.401C139.4 190.206 138.067 188.787 136.97 187.19C136.757 186.874 136.554 186.553 136.375 186.246C135.609 184.919 135.111 183.456 134.907 181.937C134.849 181.482 134.823 181.023 134.827 180.564L134.794 172.794Z" fill="white" />
                        <path opacity="0.15" d="M136.946 187.172C136.734 186.855 136.531 186.534 136.351 186.228C135.586 184.901 135.087 183.437 134.883 181.918C134.826 181.463 134.799 181.004 134.803 180.545V172.809H138.461L138.508 172.761L139.74 173.233V190.443C138.681 189.468 137.743 188.37 136.946 187.172Z" fill="white" />
                        <path d="M195.21 157.974C193.987 157.403 192.713 156.879 191.396 156.411C189.791 155.837 188.157 155.345 186.501 154.939C175.173 152.107 161.844 152.612 151.375 156.232C149.858 156.752 148.372 157.359 146.924 158.049C145.846 158.562 144.796 159.129 143.776 159.748C142.193 160.7 140.717 161.819 139.372 163.085C138.064 164.3 136.968 165.725 136.13 167.3C135.034 169.307 134.596 171.607 134.879 173.875C135.084 175.391 135.581 176.853 136.342 178.18C136.526 178.501 136.724 178.822 136.937 179.124C138.035 180.722 139.371 182.143 140.897 183.339C141.454 183.783 142.044 184.222 142.671 184.646C143.299 185.071 143.969 185.482 144.677 185.888C145.489 186.36 146.358 186.799 147.269 187.224C148.383 187.747 149.544 188.229 150.738 188.663C151.572 188.978 152.423 189.263 153.291 189.517C166.035 193.397 181.98 192.821 193.558 188.035C194.346 187.71 195.115 187.365 195.856 187.002C196.848 186.53 197.796 186.001 198.688 185.449C199.58 184.897 200.355 184.373 201.096 183.811C201.686 183.363 202.238 182.9 202.748 182.433C203.245 181.987 203.718 181.514 204.164 181.017C205.36 179.711 206.307 178.198 206.958 176.552C207.081 176.221 207.189 175.891 207.283 175.56C207.687 174.14 207.788 172.651 207.581 171.19C207.194 168.812 206.153 166.589 204.574 164.77C203.308 163.27 201.846 161.946 200.227 160.834C198.64 159.739 196.961 158.782 195.21 157.974Z" fill="#F10086" />
                        <path opacity="0.1" d="M195.21 157.974C193.987 157.403 192.713 156.879 191.396 156.411C189.791 155.837 188.157 155.345 186.501 154.939C175.173 152.107 161.844 152.612 151.375 156.232C149.858 156.752 148.372 157.359 146.924 158.049C145.846 158.562 144.796 159.129 143.776 159.748C142.193 160.7 140.717 161.819 139.372 163.085C138.064 164.3 136.968 165.725 136.13 167.3C135.034 169.307 134.596 171.607 134.879 173.875C135.084 175.391 135.581 176.853 136.342 178.18C136.526 178.501 136.724 178.822 136.937 179.124C138.035 180.722 139.371 182.143 140.897 183.339C141.454 183.783 142.044 184.222 142.671 184.646C143.299 185.071 143.969 185.482 144.677 185.888C145.489 186.36 146.358 186.799 147.269 187.224C148.383 187.747 149.544 188.229 150.738 188.663C151.572 188.978 152.423 189.263 153.291 189.517C166.035 193.397 181.98 192.821 193.558 188.035C194.346 187.71 195.115 187.365 195.856 187.002C196.848 186.53 197.796 186.001 198.688 185.449C199.58 184.897 200.355 184.373 201.096 183.811C201.686 183.363 202.238 182.9 202.748 182.433C203.245 181.987 203.718 181.514 204.164 181.017C205.36 179.711 206.307 178.198 206.958 176.552C207.081 176.221 207.189 175.891 207.283 175.56C207.687 174.14 207.788 172.651 207.581 171.19C207.194 168.812 206.153 166.589 204.574 164.77C203.308 163.27 201.846 161.946 200.227 160.834C198.64 159.739 196.961 158.782 195.21 157.974Z" fill="white" />
                        <path opacity="0.2" d="M195.21 157.974C193.987 157.403 192.713 156.879 191.396 156.411C189.791 155.837 188.157 155.345 186.501 154.939C175.173 152.107 161.844 152.612 151.375 156.232C149.858 156.752 148.372 157.359 146.924 158.049C145.846 158.562 144.796 159.129 143.776 159.748C142.193 160.7 140.717 161.819 139.372 163.085C138.064 164.3 136.968 165.725 136.13 167.3C135.034 169.307 134.596 171.607 134.879 173.875C135.084 175.391 135.581 176.853 136.342 178.18C136.526 178.501 136.724 178.822 136.937 179.124C138.035 180.722 139.371 182.143 140.897 183.339C141.454 183.783 142.044 184.222 142.671 184.646C143.299 185.071 143.969 185.482 144.677 185.888C145.489 186.36 146.358 186.799 147.269 187.224C148.383 187.747 149.544 188.229 150.738 188.663C151.572 188.978 152.423 189.263 153.291 189.517C166.035 193.397 181.98 192.821 193.558 188.035C194.346 187.71 195.115 187.365 195.856 187.002C196.848 186.53 197.796 186.001 198.688 185.449C199.58 184.897 200.355 184.373 201.096 183.811C201.686 183.363 202.238 182.9 202.748 182.433C203.245 181.987 203.718 181.514 204.164 181.017C205.36 179.711 206.307 178.198 206.958 176.552C207.081 176.221 207.189 175.891 207.283 175.56C207.687 174.14 207.788 172.651 207.581 171.19C207.194 168.812 206.153 166.589 204.574 164.77C203.308 163.27 201.846 161.946 200.227 160.834C198.64 159.739 196.961 158.782 195.21 157.974Z" fill="white" />
                        <path opacity="0.15" d="M155.972 190.268C155.066 190.046 154.169 189.796 153.291 189.532C152.413 189.267 151.573 188.984 150.738 188.677C149.544 188.243 148.378 187.762 147.269 187.238C146.358 186.813 145.489 186.364 144.677 185.902C143.969 185.496 143.304 185.085 142.671 184.661C142.039 184.236 141.454 183.797 140.897 183.353C139.371 182.157 138.035 180.736 136.937 179.138C136.724 178.822 136.526 178.501 136.342 178.194C135.581 176.867 135.084 175.405 134.879 173.89C134.596 171.621 135.034 169.321 136.13 167.315C136.968 165.739 138.064 164.314 139.372 163.1C140.717 161.833 142.193 160.714 143.776 159.763C144.663 159.22 145.598 158.719 146.565 158.243L155.972 190.268Z" fill="white" />
                        <path opacity="0.15" d="M139.712 182.315C138.671 181.368 137.746 180.302 136.956 179.138C136.743 178.822 136.545 178.501 136.361 178.194C135.6 176.867 135.103 175.405 134.898 173.89C134.664 172.041 134.918 170.163 135.634 168.443L139.712 182.315Z" fill="white" />
                        <path opacity="0.15" d="M167.197 191.958C165.148 191.835 163.118 191.613 161.127 191.306L150.88 156.411L151.352 156.232C152.964 155.678 154.604 155.205 156.265 154.816L167.197 191.958Z" fill="white" />
                        <path opacity="0.15" d="M185.439 190.575C183.432 191.029 181.403 191.382 179.36 191.632L168.084 153.225C170.203 153.131 172.332 153.136 174.47 153.225L185.439 190.575Z" fill="white" />
                        <path opacity="0.15" d="M190.528 189.173C189.521 189.503 188.495 189.807 187.45 190.084L176.67 153.362C177.826 153.457 178.978 153.575 180.12 153.73L190.528 189.173Z" fill="white" />
                        <path opacity="0.3" d="M197.013 158.885C197.485 159.14 197.957 159.399 198.396 159.668L198.839 159.932L199.283 160.216C199.604 160.423 199.925 160.631 200.227 160.843C201.846 161.955 203.308 163.279 204.574 164.78C206.153 166.599 207.194 168.822 207.581 171.199C207.788 172.661 207.687 174.15 207.284 175.57C207.189 175.885 207.081 176.215 206.958 176.561C206.307 178.208 205.36 179.721 204.164 181.026C204.008 181.206 203.838 181.38 203.663 181.56L197.013 158.885Z" fill="#F10086" />
                        <path d="M189.008 161.66C178.128 156.61 161.896 156.982 151.937 162.368C151.517 162.595 151.097 162.84 150.7 163.081C140.883 169.127 142.119 178.293 153.466 183.561C164.813 188.828 181.961 188.196 191.778 182.145C192.175 181.899 192.562 181.645 192.921 181.39C201.478 175.353 199.892 166.71 189.008 161.66Z" fill="#F10086" />
                        <path opacity="0.4" d="M189.008 161.66C178.128 156.61 161.896 156.982 151.937 162.368C151.517 162.595 151.097 162.84 150.7 163.081C140.883 169.127 142.119 178.293 153.466 183.561C164.813 188.828 181.961 188.196 191.778 182.145C192.175 181.899 192.562 181.645 192.921 181.39C201.478 175.353 199.892 166.71 189.008 161.66Z" fill="black" />
                        <path d="M188.219 162.146C187.87 161.986 187.511 161.825 187.148 161.674C176.764 157.346 162.005 157.846 152.796 162.812L152.546 162.944C152.229 163.123 151.918 163.303 151.602 163.491C149.941 164.47 148.469 165.737 147.255 167.234C144.791 170.397 144.64 173.923 146.707 177.113C147.92 179.001 149.898 180.743 152.626 182.239C153.14 182.522 153.678 182.796 154.245 183.056C154.811 183.315 155.297 183.528 155.84 183.745C157.006 184.204 158.195 184.599 159.404 184.93C167.985 187.29 178.388 186.912 186.251 183.943C187.347 183.537 188.418 183.064 189.456 182.527C189.928 182.272 190.4 182.008 190.872 181.729C191.344 181.451 191.623 181.257 191.967 181.007C192.066 180.941 192.161 180.87 192.255 180.8C194.549 179.119 196.031 177.236 196.734 175.291C197.924 171.987 196.829 168.499 193.544 165.549C191.945 164.16 190.151 163.013 188.219 162.146Z" fill="#F10086" />
                        <path opacity="0.5" d="M188.219 162.146C187.87 161.986 187.511 161.825 187.148 161.674C176.764 157.346 162.005 157.846 152.796 162.812L152.546 162.944C152.229 163.123 151.918 163.303 151.602 163.491C149.941 164.47 148.469 165.737 147.255 167.234C144.791 170.397 144.64 173.923 146.707 177.113C147.92 179.001 149.898 180.743 152.626 182.239C153.14 182.522 153.678 182.796 154.245 183.056C154.811 183.315 155.297 183.528 155.84 183.745C157.006 184.204 158.195 184.599 159.404 184.93C167.985 187.29 178.388 186.912 186.251 183.943C187.347 183.537 188.418 183.064 189.456 182.527C189.928 182.272 190.4 182.008 190.872 181.729C191.344 181.451 191.623 181.257 191.967 181.007C192.066 180.941 192.161 180.87 192.255 180.8C194.549 179.119 196.031 177.236 196.734 175.291C197.924 171.987 196.829 168.499 193.544 165.549C191.945 164.16 190.151 163.013 188.219 162.146Z" fill="black" />
                        <path d="M187.157 164.728C187.526 164.874 187.88 165.035 188.229 165.2C190.159 166.061 191.95 167.205 193.544 168.594C195.465 170.312 196.63 172.214 197.036 174.159C196.96 174.54 196.855 174.915 196.725 175.282C196.026 177.227 194.54 179.11 192.246 180.79C192.151 180.861 192.057 180.932 191.958 180.998C191.613 181.243 191.245 181.47 190.863 181.72C190.48 181.97 189.947 182.263 189.447 182.518C188.408 183.054 187.338 183.527 186.242 183.934C178.378 186.903 167.975 187.285 159.394 184.92C158.186 184.59 156.996 184.195 155.831 183.735C155.288 183.518 154.755 183.292 154.235 183.046C153.716 182.801 153.131 182.513 152.616 182.23C149.888 180.734 147.896 178.978 146.698 177.104C146.093 176.19 145.663 175.172 145.428 174.102C145.733 172.702 146.358 171.392 147.255 170.274C148.472 168.778 149.945 167.511 151.606 166.531C151.909 166.342 152.225 166.163 152.55 165.988L152.801 165.851C162.019 160.9 176.792 160.4 187.157 164.728Z" fill="#F10086" />
                        <path opacity="0.25" d="M187.157 164.728C187.526 164.874 187.88 165.035 188.229 165.2C190.159 166.061 191.95 167.205 193.544 168.594C195.465 170.312 196.63 172.214 197.036 174.159C196.96 174.54 196.855 174.915 196.725 175.282C196.026 177.227 194.54 179.11 192.246 180.79C192.151 180.861 192.057 180.932 191.958 180.998C191.613 181.243 191.245 181.47 190.863 181.72C190.48 181.97 189.947 182.263 189.447 182.518C188.408 183.054 187.338 183.527 186.242 183.934C178.378 186.903 167.975 187.285 159.394 184.92C158.186 184.59 156.996 184.195 155.831 183.735C155.288 183.518 154.755 183.292 154.235 183.046C153.716 182.801 153.131 182.513 152.616 182.23C149.888 180.734 147.896 178.978 146.698 177.104C146.093 176.19 145.663 175.172 145.428 174.102C145.733 172.702 146.358 171.392 147.255 170.274C148.472 168.778 149.945 167.511 151.606 166.531C151.909 166.342 152.225 166.163 152.55 165.988L152.801 165.851C162.019 160.9 176.792 160.4 187.157 164.728Z" fill="black" />
                        <path d="M206.963 176.561C202.837 185.732 191.297 189.39 181.89 191.042C167.588 193.1 152.022 191.377 140.963 183.24H140.986C137.98 180.88 135.417 177.703 134.879 173.861C135.351 177.741 137.824 181.021 140.831 183.452C149.737 190.358 162.071 192.595 173.871 192.264C185.741 191.566 201.568 188.37 206.963 176.561Z" fill="#F5F5F5" />
                        <path d="M156.232 179.36C160.272 181.055 164.256 180.304 167.923 179.011L166.951 180.011V184.207C167.773 184.403 168.607 184.538 169.448 184.613C170.898 184.734 172.358 184.68 173.795 184.453C176.154 184.062 178.439 183.309 180.568 182.22L184.127 183.717L188.951 180.776V176.585L188.05 176.198L187.851 176.113C187.926 175.793 187.96 175.464 187.95 175.136C187.949 175.025 187.939 174.915 187.922 174.805C187.861 174.286 187.969 171.917 187.95 170.94C187.95 170.822 187.95 170.718 187.917 170.609C187.78 169.439 186.803 168.386 184.812 167.555C182.798 166.742 180.61 166.451 178.454 166.71L177.911 166.776H177.854L177.288 166.871C177.123 166.899 176.958 166.928 176.792 166.965C176.726 166.965 176.655 166.989 176.594 167.008C176.533 167.027 176.334 167.055 176.216 167.088C175.18 167.335 174.157 167.637 173.153 167.994C172.71 168.15 172.271 168.315 171.841 168.466L173.111 167.145V162.949C169.018 162.17 164.652 162.831 160.367 164.931L156.808 163.449L152.055 166.479V170.675L153.169 171.147C153.091 171.404 153.056 171.672 153.065 171.94V171.973C153.098 172.625 153.037 175.4 153.065 176.136V176.169C153.126 177.392 154.141 178.487 156.232 179.36Z" fill="#F10086" />
                        <path opacity="0.15" d="M187.946 171.119C187.946 172.195 187.87 174.305 187.946 174.786C187.946 174.9 187.946 175.003 187.974 175.121C187.981 175.452 187.946 175.782 187.87 176.103L185.312 175.027C185.312 175.027 187.856 173.781 187.946 171.119Z" fill="black" />
                        <path opacity="0.45" d="M162.118 171.058C161.051 170.586 160.872 169.778 162.835 168.513C164.94 167.178 167.107 166.828 168.948 167.291L173.111 162.953V167.145L171.855 168.466C171.808 168.466 171.775 168.499 171.728 168.518C171.043 168.778 170.383 169.047 169.75 169.306C169.495 169.424 169.245 169.528 169 169.623C167.648 170.256 166.244 170.772 164.804 171.166C164.21 171.326 163.591 171.369 162.982 171.294C162.684 171.254 162.394 171.174 162.118 171.058Z" fill="black" />
                        <path opacity="0.2" d="M153.15 171.133L152.045 170.661V166.47L155.59 167.956C154.273 169.061 153.428 170.132 153.15 171.133Z" fill="black" />
                        <path opacity="0.15" d="M184.141 179.52L188.951 176.566V180.776L184.137 183.745L180.587 182.244C178.457 183.334 176.171 184.086 173.809 184.472C171.535 184.855 169.207 184.773 166.965 184.231V180.035C169.207 180.577 171.535 180.659 173.809 180.276C176.171 179.892 178.458 179.14 180.587 178.048L184.141 179.52Z" fill="black" />
                        <path opacity="0.2" d="M180.592 182.225C178.928 183.258 177.103 184.004 175.192 184.434C173.316 184.88 171.369 184.944 169.467 184.623C171.365 184.597 173.255 184.376 175.107 183.962C176.051 183.759 176.953 183.49 177.868 183.207C178.784 182.924 179.681 182.57 180.592 182.225Z" fill="black" />
                        <path opacity="0.35" d="M153.055 176.155C153.022 175.414 153.088 172.643 153.055 171.983C153.131 173.224 154.136 174.314 156.241 175.197C160.933 177.151 165.497 175.867 169.675 174.192C173.55 172.62 176.368 170.954 178.643 171.898C180.059 172.497 179.657 173.436 178.109 174.428C177.123 175.051 176.02 175.466 174.867 175.648C173.715 175.83 172.537 175.774 171.407 175.485L167.952 179.044C164.251 180.351 160.296 181.073 156.265 179.388C154.136 178.487 153.131 177.396 153.055 176.155Z" fill="black" />
                        <path d="M168.948 171.487V167.291L173.106 162.949V167.145L168.948 171.487Z" fill="#F10086" />
                        <path opacity="0.15" d="M168.948 171.487V167.291L173.106 162.949V167.145L168.948 171.487Z" fill="black" />
                        <path d="M152.041 166.479L155.6 167.966L153.159 171.138L152.045 170.675L152.041 166.479Z" fill="#F10086" />
                        <path opacity="0.45" d="M152.041 166.479L155.6 167.966L153.159 171.138L152.045 170.675L152.041 166.479Z" fill="black" />
                        <path d="M188.05 176.183L185.298 175.022C185.298 175.022 188.002 173.715 187.95 170.925C187.95 170.807 187.95 170.704 187.917 170.595C187.78 169.424 186.803 168.372 184.812 167.541C182.798 166.728 180.61 166.437 178.454 166.696L177.911 166.762H177.854L177.288 166.857C177.123 166.885 176.958 166.913 176.792 166.951C176.722 166.951 176.655 166.975 176.594 166.994C176.533 167.012 176.334 167.041 176.216 167.074C175.18 167.321 174.157 167.623 173.153 167.98C172.681 168.15 172.209 168.329 171.737 168.518C171.058 168.782 170.387 169.056 169.755 169.316L169.004 169.632C167.649 170.262 166.244 170.779 164.804 171.176C164.211 171.332 163.594 171.374 162.986 171.298C162.694 171.255 162.407 171.177 162.132 171.067C161.061 170.595 160.881 169.783 162.854 168.518C164.955 167.178 167.102 166.828 168.962 167.296L173.12 162.953C169.028 162.174 164.662 162.835 160.376 164.936L156.817 163.454L152.064 166.484L155.623 167.971C153.924 169.387 153.032 170.722 153.079 171.935V171.968C153.15 173.205 154.16 174.295 156.27 175.178C160.947 177.137 165.516 175.848 169.689 174.173C173.569 172.601 176.386 170.935 178.657 171.884C180.073 172.478 179.667 173.418 178.114 174.413C177.128 175.035 176.025 175.45 174.874 175.631C173.722 175.813 172.546 175.758 171.416 175.471L166.989 180.021C169.232 180.561 171.561 180.645 173.838 180.266C176.195 179.876 178.478 179.124 180.606 178.038L184.165 179.53L188.975 176.575L188.05 176.183Z" fill="#F10086" />
                        <path opacity="0.1" d="M188.05 176.183L185.298 175.022C185.298 175.022 188.002 173.715 187.95 170.925C187.95 170.807 187.95 170.704 187.917 170.595C187.78 169.424 186.803 168.372 184.812 167.541C182.798 166.728 180.61 166.437 178.454 166.696L177.911 166.762H177.854L177.288 166.857C177.123 166.885 176.958 166.913 176.792 166.951C176.722 166.951 176.655 166.975 176.594 166.994C176.533 167.012 176.334 167.041 176.216 167.074C175.18 167.321 174.157 167.623 173.153 167.98C172.681 168.15 172.209 168.329 171.737 168.518C171.058 168.782 170.387 169.056 169.755 169.316L169.004 169.632C167.649 170.262 166.244 170.779 164.804 171.176C164.211 171.332 163.594 171.374 162.986 171.298C162.694 171.255 162.407 171.177 162.132 171.067C161.061 170.595 160.881 169.783 162.854 168.518C164.955 167.178 167.102 166.828 168.962 167.296L173.12 162.953C169.028 162.174 164.662 162.835 160.376 164.936L156.817 163.454L152.064 166.484L155.623 167.971C153.924 169.387 153.032 170.722 153.079 171.935V171.968C153.15 173.205 154.16 174.295 156.27 175.178C160.947 177.137 165.516 175.848 169.689 174.173C173.569 172.601 176.386 170.935 178.657 171.884C180.073 172.478 179.667 173.418 178.114 174.413C177.128 175.035 176.025 175.45 174.874 175.631C173.722 175.813 172.546 175.758 171.416 175.471L166.989 180.021C169.232 180.561 171.561 180.645 173.838 180.266C176.195 179.876 178.478 179.124 180.606 178.038L184.165 179.53L188.975 176.575L188.05 176.183Z" fill="white" />
                        <path d="M156.232 175.173C158.006 175.853 159.905 176.147 161.802 176.037C163.737 175.908 165.645 175.503 167.466 174.834C169.33 174.22 171.119 173.418 172.946 172.653C173.862 172.262 174.81 171.946 175.778 171.709C176.716 171.467 177.706 171.525 178.61 171.874C177.693 171.566 176.702 171.553 175.778 171.836C174.833 172.096 173.91 172.429 173.016 172.832C171.213 173.616 169.415 174.442 167.541 175.079C165.685 175.749 163.74 176.14 161.769 176.24C160.802 176.27 159.836 176.197 158.885 176.023C157.974 175.83 157.085 175.545 156.232 175.173Z" fill="white" />
                        <path d="M184.141 179.525L184.146 183.721L180.587 182.225L180.582 178.034L184.141 179.525Z" fill="#F10086" />
                        <path opacity="0.35" d="M184.141 179.525L184.146 183.721L180.587 182.225L180.582 178.034L184.141 179.525Z" fill="black" />
                        <path d="M188.951 176.566L184.16 179.058L180.582 178.034L184.141 179.525L188.951 176.566Z" fill="#FAFAFA" />
                        <path d="M160.353 164.931L156.813 163.883L152.041 166.479L156.794 163.449L160.353 164.931Z" fill="#FAFAFA" />
                        <path opacity="0.15" d="M173.106 162.949C172.368 162.816 171.622 162.727 170.873 162.684L171.454 164.671L173.106 162.949Z" fill="white" />
                        <path opacity="0.15" d="M156.407 175.23C158.352 176.01 160.458 176.306 162.543 176.094L159.111 164.412L156.798 163.468L153.556 165.535L154.094 167.352L155.604 167.985C155.239 168.279 154.891 168.595 154.561 168.929L156.407 175.23Z" fill="white" />
                        <path opacity="0.15" d="M184.08 167.263C182.939 166.877 181.748 166.659 180.545 166.616L184.321 179.417L187.153 177.675L186.525 175.542L185.298 175.022C185.617 174.854 185.917 174.653 186.195 174.423L184.08 167.263Z" fill="white" />
                        <path opacity="0.15" d="M177.854 166.758L177.288 166.852C177.123 166.88 176.958 166.909 176.792 166.946C176.726 166.946 176.655 166.97 176.594 166.989C176.533 167.008 176.334 167.036 176.216 167.069C175.18 167.316 174.157 167.618 173.153 167.975C172.931 168.051 172.714 168.141 172.497 168.221L173.738 172.469C175.65 171.718 177.245 171.317 178.633 171.898C180.021 172.478 179.643 173.432 178.09 174.428C177.056 175.085 175.889 175.506 174.673 175.66L175.9 179.832C177.518 179.4 179.086 178.804 180.582 178.053L181.923 178.614L178.416 166.687L177.897 166.753L177.854 166.758Z" fill="white" />
                        <path d="M198.915 162.595H202.913V170.779C202.913 171.76 202.781 172.736 202.521 173.682C202.427 174.012 202.313 174.343 202.191 174.673C201.543 176.32 200.598 177.834 199.401 179.138C198.954 179.635 198.482 180.107 197.985 180.554C197.459 181.042 196.908 181.502 196.333 181.932C195.56 182.52 194.756 183.067 193.926 183.57C193.02 184.122 192.076 184.642 191.094 185.123C190.348 185.487 189.584 185.836 188.791 186.157C177.217 190.938 161.259 191.523 148.524 187.639C147.656 187.375 146.806 187.09 145.975 186.785C144.781 186.35 143.615 185.869 142.506 185.369C141.591 184.937 140.725 184.491 139.91 184.028C139.202 183.627 138.537 183.212 137.904 182.787C137.272 182.362 136.687 181.928 136.134 181.479C134.601 180.276 133.261 178.845 132.16 177.236C131.952 176.92 131.749 176.599 131.57 176.292C130.805 174.966 130.307 173.504 130.102 171.987C130.046 171.532 130.017 171.073 130.017 170.614V162.864H133.675C133.968 162.543 134.275 162.226 134.619 161.92C135.964 160.653 137.44 159.534 139.023 158.583C140.043 157.963 141.093 157.396 142.171 156.883C143.62 156.195 145.106 155.588 146.622 155.066C157.091 151.451 170.42 150.96 181.748 153.773C183.435 154.191 185.066 154.682 186.643 155.246C187.96 155.718 189.234 156.237 190.457 156.808C192.21 157.62 193.89 158.582 195.479 159.682C196.716 160.54 197.866 161.515 198.915 162.595Z" fill="#F10086" />
                        <path opacity="0.15" d="M156.359 189.399V172.799L162.439 173.432V190.056C160.39 189.933 158.356 189.711 156.359 189.399Z" fill="white" />
                        <path opacity="0.1" d="M202.913 170.765C202.913 171.746 202.781 172.722 202.521 173.668C202.427 173.998 202.313 174.329 202.191 174.659C201.543 176.306 200.598 177.82 199.401 179.124C199.241 179.303 199.071 179.478 198.901 179.653V162.571L198.929 162.595H202.927L202.913 170.765Z" fill="black" />
                        <path opacity="0.15" d="M182.664 163.133L185.784 162.392V187.275C184.79 187.606 183.772 187.908 182.73 188.182L182.664 163.133Z" fill="white" />
                        <path opacity="0.15" d="M174.598 189.706V168.141L180.677 168.584V188.673C178.699 189.112 176.665 189.456 174.598 189.706Z" fill="white" />
                        <path opacity="0.15" d="M130.017 162.864H133.675C133.968 162.543 134.275 162.226 134.619 161.92C135.964 160.653 137.44 159.534 139.023 158.583C140.043 157.963 141.093 157.396 142.171 156.883C143.62 156.195 145.106 155.588 146.622 155.066C148.085 154.561 149.61 154.122 151.172 153.74L151.2 188.385C150.304 188.158 149.412 187.913 148.538 187.648C147.665 187.384 146.82 187.101 145.99 186.794C144.795 186.36 143.63 185.878 142.52 185.378C141.605 184.947 140.739 184.5 139.924 184.038C139.216 183.636 138.551 183.221 137.918 182.796C137.286 182.371 136.701 181.937 136.148 181.489C134.609 180.283 133.264 178.849 132.16 177.236C131.952 176.92 131.749 176.599 131.57 176.292C130.805 174.966 130.307 173.504 130.102 171.987C130.046 171.532 130.017 171.073 130.017 170.614V162.864Z" fill="white" />
                        <path opacity="0.15" d="M132.16 177.236C131.952 176.92 131.749 176.599 131.57 176.292C130.805 174.966 130.307 173.504 130.102 171.987C130.046 171.532 130.017 171.073 130.017 170.614V162.864H133.675C133.69 162.847 133.705 162.831 133.722 162.816L134.954 163.265V180.469C133.898 179.506 132.96 178.421 132.16 177.236Z" fill="white" />
                        <path d="M190.433 148.038C189.211 147.467 187.936 146.948 186.619 146.476C185.043 145.914 183.405 145.423 181.725 145.003C170.397 142.171 157.067 142.681 146.598 146.296C145.081 146.816 143.595 147.423 142.148 148.114C141.069 148.625 140.019 149.192 138.999 149.813C137.415 150.763 135.939 151.882 134.596 153.15C133.29 154.367 132.194 155.791 131.353 157.365C130.258 159.371 129.82 161.671 130.102 163.94C130.306 165.457 130.803 166.921 131.565 168.249C131.749 168.57 131.948 168.886 132.16 169.193C133.257 170.79 134.593 172.21 136.12 173.403C136.677 173.853 137.269 174.289 137.895 174.711C138.527 175.136 139.193 175.551 139.901 175.952C140.713 176.424 141.581 176.863 142.492 177.293C143.606 177.817 144.767 178.293 145.961 178.709C146.792 179.023 147.643 179.31 148.515 179.568C161.259 183.448 177.203 182.872 188.781 178.086C189.569 177.76 190.339 177.415 191.08 177.047C192.071 176.566 193.015 176.048 193.912 175.494C194.742 174.993 195.544 174.446 196.314 173.856C196.889 173.425 197.442 172.965 197.971 172.478C198.469 172.033 198.941 171.56 199.387 171.062C200.582 169.759 201.528 168.247 202.176 166.602C202.304 166.271 202.412 165.941 202.507 165.606C202.908 164.185 203.01 162.697 202.804 161.235C202.417 158.858 201.376 156.635 199.798 154.816C198.53 153.316 197.067 151.993 195.446 150.884C193.86 149.793 192.183 148.84 190.433 148.038Z" fill="#F10086" />
                        <path opacity="0.1" d="M190.433 148.038C189.211 147.467 187.936 146.948 186.619 146.476C185.043 145.914 183.405 145.423 181.725 145.003C170.397 142.171 157.067 142.681 146.598 146.296C145.081 146.816 143.595 147.423 142.148 148.114C141.069 148.625 140.019 149.192 138.999 149.813C137.415 150.763 135.939 151.882 134.596 153.15C133.29 154.367 132.194 155.791 131.353 157.365C130.258 159.371 129.82 161.671 130.102 163.94C130.306 165.457 130.803 166.921 131.565 168.249C131.749 168.57 131.948 168.886 132.16 169.193C133.257 170.79 134.593 172.21 136.12 173.403C136.677 173.853 137.269 174.289 137.895 174.711C138.527 175.136 139.193 175.551 139.901 175.952C140.713 176.424 141.581 176.863 142.492 177.293C143.606 177.817 144.767 178.293 145.961 178.709C146.792 179.023 147.643 179.31 148.515 179.568C161.259 183.448 177.203 182.872 188.781 178.086C189.569 177.76 190.339 177.415 191.08 177.047C192.071 176.566 193.015 176.048 193.912 175.494C194.742 174.993 195.544 174.446 196.314 173.856C196.889 173.425 197.442 172.965 197.971 172.478C198.469 172.033 198.941 171.56 199.387 171.062C200.582 169.759 201.528 168.247 202.176 166.602C202.304 166.271 202.412 165.941 202.507 165.606C202.908 164.185 203.01 162.697 202.804 161.235C202.417 158.858 201.376 156.635 199.798 154.816C198.53 153.316 197.067 151.993 195.446 150.884C193.86 149.793 192.183 148.84 190.433 148.038Z" fill="white" />
                        <path opacity="0.2" d="M190.433 148.038C189.211 147.467 187.936 146.948 186.619 146.476C185.043 145.914 183.405 145.423 181.725 145.003C170.397 142.171 157.067 142.681 146.598 146.296C145.081 146.816 143.595 147.423 142.148 148.114C141.069 148.625 140.019 149.192 138.999 149.813C137.415 150.763 135.939 151.882 134.596 153.15C133.29 154.367 132.194 155.791 131.353 157.365C130.258 159.371 129.82 161.671 130.102 163.94C130.306 165.457 130.803 166.921 131.565 168.249C131.749 168.57 131.948 168.886 132.16 169.193C133.257 170.79 134.593 172.21 136.12 173.403C136.677 173.853 137.269 174.289 137.895 174.711C138.527 175.136 139.193 175.551 139.901 175.952C140.713 176.424 141.581 176.863 142.492 177.293C143.606 177.817 144.767 178.293 145.961 178.709C146.792 179.023 147.643 179.31 148.515 179.568C161.259 183.448 177.203 182.872 188.781 178.086C189.569 177.76 190.339 177.415 191.08 177.047C192.071 176.566 193.015 176.048 193.912 175.494C194.742 174.993 195.544 174.446 196.314 173.856C196.889 173.425 197.442 172.965 197.971 172.478C198.469 172.033 198.941 171.56 199.387 171.062C200.582 169.759 201.528 168.247 202.176 166.602C202.304 166.271 202.412 165.941 202.507 165.606C202.908 164.185 203.01 162.697 202.804 161.235C202.417 158.858 201.376 156.635 199.798 154.816C198.53 153.316 197.067 151.993 195.446 150.884C193.86 149.793 192.183 148.84 190.433 148.038Z" fill="white" />
                        <path opacity="0.15" d="M151.196 180.337C150.29 180.11 149.393 179.865 148.515 179.601C147.637 179.336 146.792 179.048 145.961 178.742C144.767 178.307 143.601 177.831 142.492 177.326C141.581 176.896 140.713 176.452 139.901 175.985C139.193 175.584 138.527 175.169 137.895 174.744C137.262 174.319 136.677 173.885 136.12 173.436C134.593 172.243 133.257 170.823 132.16 169.226C131.948 168.905 131.749 168.589 131.565 168.282C130.803 166.956 130.306 165.494 130.102 163.978C129.82 161.709 130.258 159.409 131.353 157.403C132.194 155.829 133.29 154.405 134.596 153.188C135.939 151.92 137.415 150.801 138.999 149.851C139.887 149.312 140.821 148.807 141.789 148.335L151.196 180.337Z" fill="white" />
                        <path opacity="0.15" d="M134.912 172.379C133.875 171.432 132.951 170.367 132.16 169.207C131.948 168.886 131.749 168.57 131.565 168.263C130.803 166.937 130.306 165.475 130.102 163.959C129.869 162.11 130.123 160.232 130.838 158.512L134.912 172.379Z" fill="white" />
                        <path opacity="0.15" d="M162.42 182.027C160.371 181.899 158.342 181.682 156.345 181.371L146.103 146.476L146.575 146.296C148.151 145.754 149.799 145.286 151.488 144.88L162.42 182.027Z" fill="white" />
                        <path opacity="0.15" d="M180.663 180.644C178.69 181.083 176.655 181.427 174.588 181.678L163.312 143.266C165.431 143.172 167.56 143.181 169.698 143.266L180.663 180.644Z" fill="white" />
                        <path opacity="0.15" d="M185.751 179.237C184.747 179.571 183.721 179.875 182.673 180.148L171.893 143.427C173.049 143.521 174.201 143.639 175.343 143.795L185.751 179.237Z" fill="white" />
                        <path opacity="0.3" d="M192.236 148.949C192.708 149.209 193.18 149.468 193.619 149.733L194.063 150.002C194.214 150.091 194.36 150.186 194.506 150.28C194.827 150.488 195.148 150.7 195.45 150.913C197.071 152.021 198.535 153.344 199.802 154.844C201.38 156.664 202.421 158.886 202.809 161.264C203.014 162.725 202.913 164.214 202.512 165.634C202.417 165.969 202.309 166.3 202.181 166.63C201.533 168.275 200.587 169.787 199.392 171.091C199.236 171.27 199.066 171.449 198.891 171.624L192.236 148.949Z" fill="#F10086" />
                        <path d="M184.231 151.724C173.351 146.674 157.129 147.052 147.16 152.428C146.74 152.654 146.32 152.9 145.919 153.14C136.106 159.182 137.343 168.348 148.685 173.616C160.027 178.883 177.184 178.256 187.002 172.2C187.398 171.954 187.78 171.699 188.144 171.445C196.701 165.422 195.115 156.78 184.231 151.724Z" fill="#F10086" />
                        <path opacity="0.4" d="M184.231 151.724C173.351 146.674 157.129 147.052 147.16 152.428C146.74 152.654 146.32 152.9 145.919 153.14C136.106 159.182 137.343 168.348 148.685 173.616C160.027 178.883 177.184 178.256 187.002 172.2C187.398 171.954 187.78 171.699 188.144 171.445C196.701 165.422 195.115 156.78 184.231 151.724Z" fill="black" />
                        <path d="M183.443 152.215C183.094 152.05 182.735 151.894 182.371 151.743C171.987 147.415 157.228 147.92 148.019 152.881L147.769 153.018C147.453 153.192 147.137 153.376 146.825 153.56C145.165 154.542 143.693 155.811 142.478 157.308C140.014 160.466 139.863 163.996 141.93 167.187C143.143 169.075 145.121 170.812 147.849 172.308C148.359 172.592 148.902 172.865 149.468 173.13C150.035 173.394 150.521 173.602 151.064 173.814C152.228 174.275 153.418 174.671 154.627 174.999C163.208 177.359 173.611 176.986 181.475 174.012C182.571 173.608 183.642 173.135 184.679 172.596C185.151 172.346 185.623 172.077 186.095 171.803C186.567 171.53 186.841 171.331 187.19 171.081C187.29 171.01 187.384 170.944 187.478 170.869C189.772 169.188 191.254 167.305 191.958 165.365C193.147 162.061 192.052 158.573 188.767 155.623C187.17 154.231 185.376 153.082 183.443 152.215Z" fill="#F10086" />
                        <path opacity="0.5" d="M183.443 152.215C183.094 152.05 182.735 151.894 182.371 151.743C171.987 147.415 157.228 147.92 148.019 152.881L147.769 153.018C147.453 153.192 147.137 153.376 146.825 153.56C145.165 154.542 143.693 155.811 142.478 157.308C140.014 160.466 139.863 163.996 141.93 167.187C143.143 169.075 145.121 170.812 147.849 172.308C148.359 172.592 148.902 172.865 149.468 173.13C150.035 173.394 150.521 173.602 151.064 173.814C152.228 174.275 153.418 174.671 154.627 174.999C163.208 177.359 173.611 176.986 181.475 174.012C182.571 173.608 183.642 173.135 184.679 172.596C185.151 172.346 185.623 172.077 186.095 171.803C186.567 171.53 186.841 171.331 187.19 171.081C187.29 171.01 187.384 170.944 187.478 170.869C189.772 169.188 191.254 167.305 191.958 165.365C193.147 162.061 192.052 158.573 188.767 155.623C187.17 154.231 185.376 153.082 183.443 152.215Z" fill="black" />
                        <path d="M182.381 154.816C182.749 154.967 183.103 155.123 183.452 155.288C185.381 156.149 187.172 157.291 188.767 158.677C190.688 160.4 191.854 162.302 192.26 164.247C192.182 164.628 192.078 165.003 191.948 165.37C191.25 167.31 189.763 169.193 187.469 170.873C187.375 170.949 187.28 171.015 187.181 171.086C186.832 171.327 186.468 171.558 186.086 171.808C185.704 172.058 185.17 172.351 184.67 172.601C183.632 173.14 182.562 173.613 181.465 174.017C173.602 176.991 163.199 177.373 154.618 175.003C153.409 174.675 152.219 174.28 151.054 173.819C150.511 173.606 149.978 173.375 149.459 173.134C148.94 172.894 148.35 172.596 147.84 172.313C145.112 170.817 143.12 169.061 141.921 167.192C141.317 166.277 140.887 165.26 140.651 164.19C140.955 162.79 141.58 161.479 142.478 160.362C143.695 158.867 145.167 157.598 146.825 156.614C147.132 156.43 147.443 156.246 147.769 156.072L148.019 155.935C157.237 150.969 172.016 150.464 182.381 154.816Z" fill="#F10086" />
                        <path opacity="0.25" d="M182.381 154.816C182.749 154.967 183.103 155.123 183.452 155.288C185.381 156.149 187.172 157.291 188.767 158.677C190.688 160.4 191.854 162.302 192.26 164.247C192.182 164.628 192.078 165.003 191.948 165.37C191.25 167.31 189.763 169.193 187.469 170.873C187.375 170.949 187.28 171.015 187.181 171.086C186.832 171.327 186.468 171.558 186.086 171.808C185.704 172.058 185.17 172.351 184.67 172.601C183.632 173.14 182.562 173.613 181.465 174.017C173.602 176.991 163.199 177.373 154.618 175.003C153.409 174.675 152.219 174.28 151.054 173.819C150.511 173.606 149.978 173.375 149.459 173.134C148.94 172.894 148.35 172.596 147.84 172.313C145.112 170.817 143.12 169.061 141.921 167.192C141.317 166.277 140.887 165.26 140.651 164.19C140.955 162.79 141.58 161.479 142.478 160.362C143.695 158.867 145.167 157.598 146.825 156.614C147.132 156.43 147.443 156.246 147.769 156.072L148.019 155.935C157.237 150.969 172.016 150.464 182.381 154.816Z" fill="black" />
                        <path d="M202.181 166.616C198.061 175.787 186.52 179.445 177.113 181.097C162.812 183.15 147.245 181.427 136.186 173.29H136.21C133.203 170.93 130.64 167.754 130.102 163.911C130.574 167.796 133.047 171.076 136.054 173.507C144.961 180.413 157.294 182.645 169.094 182.315C180.96 181.635 196.791 178.416 202.181 166.616Z" fill="#F5F5F5" />
                        <path d="M151.47 169.448C155.51 171.138 159.47 170.392 163.161 169.099L162.189 170.099V174.291C163.009 174.49 163.844 174.627 164.686 174.701C166.136 174.82 167.595 174.765 169.033 174.536C171.394 174.139 173.678 173.378 175.806 172.28L179.369 173.772L184.174 170.817V166.616L183.268 166.229L183.075 166.149C183.15 165.827 183.183 165.497 183.174 165.167C183.172 165.056 183.162 164.946 183.145 164.837C183.084 164.317 183.188 161.953 183.174 160.976C183.174 160.858 183.174 160.749 183.141 160.64C182.999 159.47 182.027 158.417 180.035 157.591C178.026 156.783 175.846 156.494 173.696 156.751L173.153 156.822H173.097L172.53 156.912C172.365 156.94 172.2 156.968 172.035 157.006L171.836 157.049C171.709 157.072 171.577 157.1 171.459 157.134C170.422 157.379 169.4 157.68 168.395 158.035C167.952 158.191 167.513 158.356 167.083 158.531L168.348 157.204V153.013C164.256 152.229 159.89 152.895 155.604 154.995L152.041 153.509L147.264 156.548V160.744L148.378 161.216C148.301 161.473 148.264 161.741 148.269 162.009V162.042C148.302 162.694 148.241 165.469 148.269 166.205V166.234C148.35 167.461 149.364 168.551 151.47 169.448Z" fill="#F10086" />
                        <path opacity="0.15" d="M183.169 161.188C183.169 162.259 183.094 164.374 183.145 164.851C183.145 164.969 183.169 165.073 183.178 165.186C183.186 165.518 183.151 165.849 183.075 166.172L180.516 165.096C180.516 165.096 183.098 163.845 183.169 161.188Z" fill="black" />
                        <path opacity="0.45" d="M157.341 161.122C156.274 160.674 156.095 159.843 158.059 158.578C160.164 157.247 162.33 156.898 164.171 157.36L168.334 153.022V157.209L167.079 158.531C167.031 158.531 166.998 158.568 166.951 158.583C166.267 158.842 165.606 159.116 164.973 159.376C164.719 159.489 164.468 159.593 164.223 159.692C162.869 160.324 161.464 160.839 160.022 161.231C159.431 161.393 158.813 161.436 158.205 161.358C157.908 161.318 157.617 161.239 157.341 161.122Z" fill="black" />
                        <path opacity="0.2" d="M148.373 161.202L147.264 160.73V156.539L150.809 158.026C149.497 159.125 148.652 160.197 148.373 161.202Z" fill="black" />
                        <path opacity="0.15" d="M179.36 169.59L184.174 166.616V170.812L179.36 173.79L175.815 172.28C173.686 173.372 171.399 174.124 169.037 174.508C166.763 174.891 164.435 174.809 162.193 174.267V170.076C164.435 170.618 166.764 170.698 169.037 170.312C171.398 169.927 173.685 169.177 175.815 168.089L179.36 169.59Z" fill="black" />
                        <path opacity="0.2" d="M175.815 172.28C174.153 173.316 172.327 174.064 170.416 174.494C168.539 174.938 166.592 175 164.69 174.678C166.588 174.657 168.479 174.435 170.331 174.017C171.275 173.814 172.176 173.545 173.092 173.262C174.008 172.979 174.904 172.639 175.815 172.28Z" fill="black" />
                        <path opacity="0.35" d="M148.279 166.224V166.201C148.246 165.46 148.312 162.684 148.279 162.028C148.354 163.27 149.355 164.36 151.465 165.238C156.156 167.197 160.721 165.908 164.898 164.232C168.773 162.665 171.591 160.999 173.866 161.943C175.282 162.538 174.881 163.482 173.333 164.473C172.345 165.095 171.242 165.509 170.09 165.69C168.937 165.871 167.76 165.815 166.63 165.526L163.175 169.089C159.479 170.397 155.524 171.114 151.493 169.429C149.355 168.556 148.354 167.461 148.279 166.224Z" fill="black" />
                        <path d="M164.171 161.551V157.36L168.329 153.018V157.209L164.171 161.551Z" fill="#F10086" />
                        <path opacity="0.15" d="M164.171 161.551V157.36L168.329 153.018V157.209L164.171 161.551Z" fill="black" />
                        <path d="M147.264 156.548L150.823 158.03L148.383 161.207L147.269 160.744L147.264 156.548Z" fill="#F10086" />
                        <path opacity="0.45" d="M147.264 156.548L150.823 158.03L148.383 161.207L147.269 160.744L147.264 156.548Z" fill="black" />
                        <path d="M183.268 166.248L180.521 165.091C180.521 165.091 183.226 163.784 183.174 160.994C183.174 160.876 183.174 160.768 183.141 160.659C182.999 159.489 182.027 158.436 180.035 157.61C178.028 156.795 175.848 156.5 173.696 156.751L173.153 156.822H173.097L172.53 156.912C172.365 156.94 172.2 156.968 172.035 157.006L171.836 157.049C171.709 157.072 171.577 157.1 171.459 157.134C170.422 157.379 169.4 157.68 168.395 158.035C167.923 158.205 167.451 158.384 166.979 158.573C166.295 158.837 165.63 159.116 164.997 159.376L164.247 159.692C162.892 160.323 161.487 160.838 160.046 161.23C159.454 161.388 158.837 161.431 158.229 161.358C157.936 161.313 157.649 161.233 157.374 161.122C156.303 160.674 156.123 159.838 158.096 158.573C160.197 157.233 162.344 156.888 164.204 157.355L168.362 153.013C164.27 152.229 159.904 152.895 155.618 154.995L152.055 153.509L147.264 156.548L150.823 158.03C149.124 159.446 148.232 160.787 148.274 162V162.033C148.34 163.265 149.355 164.36 151.46 165.238C156.142 167.197 160.711 165.913 164.884 164.232C168.764 162.661 171.581 160.994 173.852 161.948C175.268 162.543 174.862 163.482 173.309 164.473C172.323 165.094 171.222 165.508 170.071 165.69C168.921 165.872 167.745 165.817 166.616 165.53L162.189 170.085C163.012 170.281 163.848 170.416 164.69 170.491C166.139 170.612 167.597 170.557 169.033 170.326C171.392 169.938 173.677 169.187 175.806 168.098L179.365 169.594L184.17 166.616L183.268 166.248Z" fill="#F10086" />
                        <path opacity="0.1" d="M183.268 166.248L180.521 165.091C180.521 165.091 183.226 163.784 183.174 160.994C183.174 160.876 183.174 160.768 183.141 160.659C182.999 159.489 182.027 158.436 180.035 157.61C178.028 156.795 175.848 156.5 173.696 156.751L173.153 156.822H173.097L172.53 156.912C172.365 156.94 172.2 156.968 172.035 157.006L171.836 157.049C171.709 157.072 171.577 157.1 171.459 157.134C170.422 157.379 169.4 157.68 168.395 158.035C167.923 158.205 167.451 158.384 166.979 158.573C166.295 158.837 165.63 159.116 164.997 159.376L164.247 159.692C162.892 160.323 161.487 160.838 160.046 161.23C159.454 161.388 158.837 161.431 158.229 161.358C157.936 161.313 157.649 161.233 157.374 161.122C156.303 160.674 156.123 159.838 158.096 158.573C160.197 157.233 162.344 156.888 164.204 157.355L168.362 153.013C164.27 152.229 159.904 152.895 155.618 154.995L152.055 153.509L147.264 156.548L150.823 158.03C149.124 159.446 148.232 160.787 148.274 162V162.033C148.34 163.265 149.355 164.36 151.46 165.238C156.142 167.197 160.711 165.913 164.884 164.232C168.764 162.661 171.581 160.994 173.852 161.948C175.268 162.543 174.862 163.482 173.309 164.473C172.323 165.094 171.222 165.508 170.071 165.69C168.921 165.872 167.745 165.817 166.616 165.53L162.189 170.085C163.012 170.281 163.848 170.416 164.69 170.491C166.139 170.612 167.597 170.557 169.033 170.326C171.392 169.938 173.677 169.187 175.806 168.098L179.365 169.594L184.17 166.616L183.268 166.248Z" fill="white" />
                        <path d="M151.465 165.238C153.241 165.918 155.141 166.214 157.039 166.106C158.974 165.973 160.881 165.567 162.703 164.903C164.568 164.289 166.356 163.487 168.183 162.717C169.101 162.329 170.048 162.013 171.015 161.773C171.954 161.536 172.943 161.595 173.847 161.943C172.931 161.633 171.94 161.618 171.015 161.901C170.069 162.16 169.146 162.495 168.254 162.901C166.451 163.685 164.652 164.506 162.779 165.148C160.922 165.816 158.977 166.206 157.006 166.304C156.04 166.334 155.073 166.262 154.122 166.087C153.21 165.893 152.32 165.609 151.465 165.238Z" fill="white" />
                        <path d="M179.365 169.594L179.369 173.786L175.806 172.294V168.098L179.365 169.594Z" fill="#F10086" />
                        <path opacity="0.35" d="M179.365 169.594L179.369 173.786L175.806 172.294V168.098L179.365 169.594Z" fill="black" />
                        <path d="M184.17 166.635L179.384 169.122L175.806 168.098L179.365 169.594L184.17 166.635Z" fill="#FAFAFA" />
                        <path d="M155.576 155L152.036 153.948L147.264 156.548L152.012 153.513L155.576 155Z" fill="#FAFAFA" />
                        <path opacity="0.15" d="M168.329 153.018C167.59 152.882 166.843 152.794 166.092 152.753L166.677 154.74L168.329 153.018Z" fill="white" />
                        <path opacity="0.15" d="M151.63 165.299C153.576 166.077 155.681 166.373 157.766 166.163L154.344 154.481L152.026 153.513L148.789 155.581L149.322 157.403L150.837 158.03C150.47 158.331 150.122 158.655 149.794 158.998L151.63 165.299Z" fill="white" />
                        <path opacity="0.15" d="M179.318 157.327C178.176 156.943 176.986 156.727 175.782 156.685L179.558 169.486L182.39 167.739L181.767 165.606L180.54 165.091C180.859 164.923 181.159 164.722 181.437 164.492L179.318 157.327Z" fill="white" />
                        <path opacity="0.15" d="M173.078 156.827L172.511 156.916C172.346 156.945 172.181 156.973 172.016 157.011L171.817 157.053C171.69 157.077 171.558 157.105 171.44 157.138C170.403 157.383 169.381 157.684 168.377 158.04L167.72 158.285L168.962 162.533C170.85 161.778 172.469 161.382 173.856 161.962C175.244 162.543 174.867 163.496 173.314 164.487C172.279 165.144 171.112 165.567 169.896 165.724L171.124 169.892C172.742 169.462 174.311 168.866 175.806 168.112L177.146 168.679L173.654 156.77L173.134 156.836L173.078 156.827Z" fill="white" />
                        <path d="M195.304 152.192H199.302V160.371C199.307 161.354 199.175 162.333 198.91 163.279C198.816 163.609 198.707 163.94 198.58 164.27C197.931 165.917 196.986 167.43 195.79 168.735C195.342 169.231 194.87 169.703 194.374 170.151C193.865 170.623 193.312 171.095 192.718 171.53C191.944 172.117 191.141 172.664 190.31 173.167C189.409 173.72 188.46 174.234 187.478 174.72C186.733 175.085 185.966 175.43 185.18 175.754C173.602 180.526 157.648 181.106 144.904 177.227C144.036 176.962 143.186 176.677 142.355 176.372C141.161 175.938 139.995 175.457 138.886 174.933C137.975 174.503 137.107 174.059 136.29 173.597C135.587 173.191 134.916 172.78 134.289 172.351C133.661 171.921 133.071 171.492 132.514 171.048C130.988 169.852 129.652 168.431 128.554 166.833C128.342 166.517 128.143 166.196 127.959 165.889C127.196 164.563 126.698 163.101 126.491 161.584C126.436 161.129 126.41 160.67 126.411 160.211V152.475H130.069C130.362 152.154 130.668 151.838 131.013 151.531C132.357 150.264 133.834 149.146 135.417 148.194C136.437 147.574 137.487 147.007 138.565 146.495C140.014 145.804 141.502 145.197 143.021 144.677C153.485 141.057 166.819 140.571 178.142 143.384C179.799 143.793 181.434 144.284 183.042 144.857C184.354 145.329 185.628 145.848 186.851 146.419C188.604 147.23 190.284 148.19 191.873 149.289C193.109 150.141 194.259 151.114 195.304 152.192Z" fill="#F10086" />
                        <path opacity="0.15" d="M152.749 178.997V162.392L158.828 163.029V179.653C156.78 179.525 154.745 179.308 152.749 178.997Z" fill="white" />
                        <path opacity="0.1" d="M199.302 160.357C199.307 161.34 199.175 162.318 198.91 163.265C198.816 163.595 198.707 163.926 198.58 164.256C197.931 165.903 196.986 167.416 195.79 168.721C195.635 168.896 195.465 169.075 195.295 169.25V152.163L195.323 152.192H199.321L199.302 160.357Z" fill="black" />
                        <path opacity="0.15" d="M179.03 152.725L182.15 151.989V176.873C181.154 177.203 180.134 177.5 179.096 177.774L179.03 152.725Z" fill="white" />
                        <path opacity="0.15" d="M170.987 179.303V157.733L177.066 158.181V178.265C175.058 178.713 173.029 179.059 170.987 179.303Z" fill="white" />
                        <path opacity="0.15" d="M126.406 152.456H130.064C130.357 152.135 130.664 151.819 131.008 151.512C132.353 150.245 133.829 149.127 135.412 148.175C136.432 147.555 137.483 146.988 138.56 146.476C140.009 145.785 141.497 145.178 143.016 144.659C144.479 144.154 146.004 143.715 147.566 143.332L147.594 177.972C146.693 177.75 145.801 177.5 144.928 177.241C144.054 176.981 143.21 176.693 142.379 176.386C141.185 175.952 140.019 175.471 138.91 174.947C137.999 174.517 137.13 174.074 136.314 173.611C135.61 173.205 134.94 172.794 134.312 172.365C133.685 171.935 133.095 171.506 132.538 171.062C131.011 169.866 129.676 168.445 128.578 166.847C128.365 166.531 128.167 166.21 127.983 165.903C127.22 164.577 126.722 163.115 126.515 161.599C126.46 161.143 126.433 160.684 126.435 160.225L126.406 152.456Z" fill="white" />
                        <path opacity="0.15" d="M128.559 166.833C128.346 166.517 128.148 166.196 127.964 165.889C127.201 164.563 126.703 163.101 126.496 161.584C126.441 161.129 126.414 160.67 126.416 160.211V152.475H130.074C130.088 152.458 130.104 152.442 130.121 152.428L131.353 152.871V170.08C130.295 169.113 129.357 168.023 128.559 166.833Z" fill="white" />
                        <path d="M186.822 137.635C185.6 137.064 184.325 136.54 183.009 136.073C181.403 135.5 179.769 135.008 178.114 134.6C166.786 131.768 153.457 132.273 142.992 135.894C141.474 136.413 139.986 137.02 138.537 137.711C137.459 138.223 136.408 138.79 135.388 139.41C133.806 140.362 132.329 141.48 130.985 142.747C129.68 143.964 128.584 145.388 127.742 146.962C126.652 148.97 126.217 151.269 126.496 153.537C126.7 155.054 127.199 156.516 127.964 157.842C128.143 158.163 128.342 158.483 128.554 158.786C129.651 160.384 130.987 161.806 132.514 163C133.065 163.447 133.655 163.883 134.284 164.308C134.916 164.733 135.582 165.148 136.29 165.549C137.107 166.021 137.97 166.46 138.881 166.885C140.001 167.407 141.158 167.879 142.35 168.301C143.188 168.616 144.039 168.901 144.904 169.155C157.648 173.04 173.592 172.459 185.17 167.673C185.96 167.359 186.728 167.014 187.474 166.64C188.465 166.168 189.409 165.639 190.306 165.087C191.135 164.582 191.939 164.035 192.713 163.449C193.286 163.017 193.838 162.557 194.365 162.071C194.861 161.624 195.334 161.151 195.781 160.655C196.976 159.349 197.921 157.836 198.57 156.19C198.693 155.859 198.806 155.529 198.896 155.198C199.3 153.778 199.401 152.289 199.193 150.828C198.807 148.45 197.766 146.227 196.187 144.408C194.922 142.907 193.459 141.582 191.84 140.472C190.251 139.385 188.573 138.436 186.822 137.635Z" fill="#F10086" />
                        <path opacity="0.1" d="M186.822 137.635C185.6 137.064 184.325 136.54 183.009 136.073C181.403 135.5 179.769 135.008 178.114 134.6C166.786 131.768 153.457 132.273 142.992 135.894C141.474 136.413 139.986 137.02 138.537 137.711C137.459 138.223 136.408 138.79 135.388 139.41C133.806 140.362 132.329 141.48 130.985 142.747C129.68 143.964 128.584 145.388 127.742 146.962C126.652 148.97 126.217 151.269 126.496 153.537C126.7 155.054 127.199 156.516 127.964 157.842C128.143 158.163 128.342 158.483 128.554 158.786C129.651 160.384 130.987 161.806 132.514 163C133.065 163.447 133.655 163.883 134.284 164.308C134.916 164.733 135.582 165.148 136.29 165.549C137.107 166.021 137.97 166.46 138.881 166.885C140.001 167.407 141.158 167.879 142.35 168.301C143.188 168.616 144.039 168.901 144.904 169.155C157.648 173.04 173.592 172.459 185.17 167.673C185.96 167.359 186.728 167.014 187.474 166.64C188.465 166.168 189.409 165.639 190.306 165.087C191.135 164.582 191.939 164.035 192.713 163.449C193.286 163.017 193.838 162.557 194.365 162.071C194.861 161.624 195.334 161.151 195.781 160.655C196.976 159.349 197.921 157.836 198.57 156.19C198.693 155.859 198.806 155.529 198.896 155.198C199.3 153.778 199.401 152.289 199.193 150.828C198.807 148.45 197.766 146.227 196.187 144.408C194.922 142.907 193.459 141.582 191.84 140.472C190.251 139.385 188.573 138.436 186.822 137.635Z" fill="white" />
                        <path opacity="0.2" d="M186.822 137.635C185.6 137.064 184.325 136.54 183.009 136.073C181.403 135.5 179.769 135.008 178.114 134.6C166.786 131.768 153.457 132.273 142.992 135.894C141.474 136.413 139.986 137.02 138.537 137.711C137.459 138.223 136.408 138.79 135.388 139.41C133.806 140.362 132.329 141.48 130.985 142.747C129.68 143.964 128.584 145.388 127.742 146.962C126.652 148.97 126.217 151.269 126.496 153.537C126.7 155.054 127.199 156.516 127.964 157.842C128.143 158.163 128.342 158.483 128.554 158.786C129.651 160.384 130.987 161.806 132.514 163C133.065 163.447 133.655 163.883 134.284 164.308C134.916 164.733 135.582 165.148 136.29 165.549C137.107 166.021 137.97 166.46 138.881 166.885C140.001 167.407 141.158 167.879 142.35 168.301C143.188 168.616 144.039 168.901 144.904 169.155C157.648 173.04 173.592 172.459 185.17 167.673C185.96 167.359 186.728 167.014 187.474 166.64C188.465 166.168 189.409 165.639 190.306 165.087C191.135 164.582 191.939 164.035 192.713 163.449C193.286 163.017 193.838 162.557 194.365 162.071C194.861 161.624 195.334 161.151 195.781 160.655C196.976 159.349 197.921 157.836 198.57 156.19C198.693 155.859 198.806 155.529 198.896 155.198C199.3 153.778 199.401 152.289 199.193 150.828C198.807 148.45 197.766 146.227 196.187 144.408C194.922 142.907 193.459 141.582 191.84 140.472C190.251 139.385 188.573 138.436 186.822 137.635Z" fill="white" />
                        <path opacity="0.15" d="M147.585 169.92C146.679 169.693 145.782 169.448 144.904 169.179C144.026 168.91 143.186 168.631 142.35 168.325C141.158 167.89 140.001 167.418 138.881 166.909C137.97 166.484 137.107 166.035 136.29 165.573C135.582 165.172 134.916 164.756 134.284 164.332C133.652 163.907 133.066 163.473 132.514 163.024C130.987 161.829 129.651 160.408 128.554 158.809C128.342 158.493 128.143 158.172 127.964 157.865C127.199 156.539 126.7 155.077 126.496 153.561C126.218 151.292 126.655 148.993 127.747 146.986C128.589 145.412 129.684 143.988 130.989 142.771C132.334 141.504 133.81 140.385 135.393 139.434C136.281 138.891 137.215 138.39 138.183 137.914L147.585 169.92Z" fill="white" />
                        <path opacity="0.15" d="M131.306 161.976C130.265 161.03 129.34 159.963 128.549 158.8C128.337 158.483 128.139 158.162 127.959 157.856C127.194 156.53 126.696 155.068 126.491 153.551C126.258 151.702 126.512 149.825 127.228 148.104L131.306 161.976Z" fill="white" />
                        <path opacity="0.15" d="M158.809 171.619C156.765 171.496 154.731 171.279 152.739 170.968L142.492 136.073L142.992 135.894C144.605 135.339 146.245 134.867 147.906 134.478L158.809 171.619Z" fill="white" />
                        <path opacity="0.15" d="M177.057 170.236C175.049 170.685 173.02 171.03 170.977 171.27L159.701 132.863C161.825 132.769 163.949 132.774 166.087 132.863L177.057 170.236Z" fill="white" />
                        <path opacity="0.15" d="M182.14 168.834C181.139 169.165 180.11 169.472 179.063 169.745L168.282 133.024C169.439 133.118 170.59 133.236 171.732 133.392L182.14 168.834Z" fill="white" />
                        <path opacity="0.3" d="M188.625 138.546C189.097 138.801 189.569 139.065 190.008 139.33L190.452 139.594L190.896 139.877C191.221 140.085 191.538 140.293 191.84 140.505C193.459 141.615 194.922 142.94 196.187 144.441C197.766 146.26 198.807 148.483 199.193 150.861C199.401 152.322 199.3 153.811 198.896 155.231C198.806 155.562 198.693 155.892 198.57 156.223C197.921 157.869 196.976 159.382 195.781 160.688C195.62 160.867 195.451 161.042 195.281 161.221L188.625 138.546Z" fill="#F10086" />
                        <path d="M180.625 141.322C169.741 136.271 153.523 136.649 143.549 142.02C143.129 142.251 142.709 142.492 142.313 142.733C132.495 148.779 133.732 157.945 145.079 163.213C156.426 168.48 173.573 167.848 183.391 161.797C183.792 161.551 184.174 161.297 184.538 161.042C193.09 155.014 191.505 146.372 180.625 141.322Z" fill="#F10086" />
                        <path opacity="0.4" d="M180.625 141.322C169.741 136.271 153.523 136.649 143.549 142.02C143.129 142.251 142.709 142.492 142.313 142.733C132.495 148.779 133.732 157.945 145.079 163.213C156.426 168.48 173.573 167.848 183.391 161.797C183.792 161.551 184.174 161.297 184.538 161.042C193.09 155.014 191.505 146.372 180.625 141.322Z" fill="black" />
                        <path d="M179.832 141.808C179.483 141.647 179.129 141.487 178.761 141.336C168.377 137.007 153.617 137.513 144.408 142.473L144.163 142.61C143.842 142.785 143.53 142.964 143.219 143.153C141.558 144.131 140.086 145.399 138.872 146.896C136.408 150.058 136.262 153.584 138.324 156.78C139.542 158.668 141.515 160.405 144.243 161.901C144.758 162.184 145.296 162.458 145.862 162.722C146.429 162.986 146.915 163.194 147.458 163.406C148.623 163.865 149.813 164.26 151.021 164.591C159.602 166.951 170.005 166.578 177.868 163.605C178.966 163.198 180.038 162.725 181.078 162.189C181.55 161.934 182.022 161.669 182.494 161.391C182.966 161.113 183.245 160.919 183.589 160.674L183.877 160.461C186.176 158.781 187.653 156.898 188.356 154.958C189.546 151.654 188.451 148.161 185.166 145.216C183.566 143.822 181.768 142.674 179.832 141.808Z" fill="#F10086" />
                        <path opacity="0.5" d="M179.832 141.808C179.483 141.647 179.129 141.487 178.761 141.336C168.377 137.007 153.617 137.513 144.408 142.473L144.163 142.61C143.842 142.785 143.53 142.964 143.219 143.153C141.558 144.131 140.086 145.399 138.872 146.896C136.408 150.058 136.262 153.584 138.324 156.78C139.542 158.668 141.515 160.405 144.243 161.901C144.758 162.184 145.296 162.458 145.862 162.722C146.429 162.986 146.915 163.194 147.458 163.406C148.623 163.865 149.813 164.26 151.021 164.591C159.602 166.951 170.005 166.578 177.868 163.605C178.966 163.198 180.038 162.725 181.078 162.189C181.55 161.934 182.022 161.669 182.494 161.391C182.966 161.113 183.245 160.919 183.589 160.674L183.877 160.461C186.176 158.781 187.653 156.898 188.356 154.958C189.546 151.654 188.451 148.161 185.166 145.216C183.566 143.822 181.768 142.674 179.832 141.808Z" fill="black" />
                        <path d="M178.775 144.39C179.138 144.541 179.497 144.696 179.846 144.862C181.775 145.725 183.566 146.869 185.161 148.255C187.077 149.973 188.243 151.875 188.649 153.82C188.572 154.203 188.468 154.58 188.337 154.948C187.639 156.888 186.157 158.771 183.858 160.452L183.57 160.664C183.226 160.905 182.858 161.136 182.475 161.382C182.093 161.627 181.56 161.924 181.059 162.179C180.019 162.716 178.947 163.189 177.85 163.595C169.986 166.569 159.583 166.951 151.002 164.582C149.794 164.251 148.604 163.855 147.439 163.397C146.896 163.18 146.367 162.953 145.843 162.713C145.319 162.472 144.739 162.174 144.224 161.891C141.496 160.395 139.504 158.639 138.305 156.77C137.701 155.855 137.272 154.835 137.04 153.763C137.341 152.362 137.967 151.051 138.867 149.936C140.083 148.44 141.555 147.172 143.214 146.193C143.516 146.004 143.833 145.824 144.158 145.65L144.408 145.513C153.631 140.566 168.405 140.061 178.775 144.39Z" fill="#F10086" />
                        <path opacity="0.25" d="M178.775 144.39C179.138 144.541 179.497 144.696 179.846 144.862C181.775 145.725 183.566 146.869 185.161 148.255C187.077 149.973 188.243 151.875 188.649 153.82C188.572 154.203 188.468 154.58 188.337 154.948C187.639 156.888 186.157 158.771 183.858 160.452L183.57 160.664C183.226 160.905 182.858 161.136 182.475 161.382C182.093 161.627 181.56 161.924 181.059 162.179C180.019 162.716 178.947 163.189 177.85 163.595C169.986 166.569 159.583 166.951 151.002 164.582C149.794 164.251 148.604 163.855 147.439 163.397C146.896 163.18 146.367 162.953 145.843 162.713C145.319 162.472 144.739 162.174 144.224 161.891C141.496 160.395 139.504 158.639 138.305 156.77C137.701 155.855 137.272 154.835 137.04 153.763C137.341 152.362 137.967 151.051 138.867 149.936C140.083 148.44 141.555 147.172 143.214 146.193C143.516 146.004 143.833 145.824 144.158 145.65L144.408 145.513C153.631 140.566 168.405 140.061 178.775 144.39Z" fill="black" />
                        <path d="M198.575 156.232C194.45 165.403 182.909 169.061 173.502 170.718C159.201 172.771 143.634 171.048 132.575 162.911H132.599C129.592 160.551 127.034 157.374 126.491 153.532C126.963 157.412 129.437 160.697 132.443 163.123C141.35 170.024 153.683 172.261 165.483 171.931C177.354 171.227 193.185 168.032 198.575 156.232Z" fill="#F5F5F5" />
                        <path d="M147.859 159.026C151.899 160.721 155.859 159.97 159.55 158.682L158.592 159.678V163.874C159.413 164.069 160.248 164.205 161.089 164.28C162.539 164.403 163.999 164.349 165.436 164.119C167.791 163.73 170.073 162.98 172.2 161.896L175.759 163.392L180.564 160.433V156.232L179.662 155.845L179.464 155.765C179.539 155.443 179.572 155.113 179.563 154.783C179.561 154.672 179.552 154.562 179.535 154.453C179.473 153.933 179.582 151.564 179.563 150.587C179.563 150.469 179.563 150.365 179.53 150.256C179.393 149.086 178.416 148.033 176.429 147.203C174.413 146.389 172.224 146.099 170.066 146.358L169.528 146.424H169.448L168.882 146.518C168.716 146.547 168.556 146.575 168.41 146.613C168.339 146.613 168.268 146.636 168.207 146.655C168.145 146.674 167.947 146.702 167.834 146.735C166.795 146.981 165.771 147.284 164.766 147.642C164.327 147.797 163.888 147.963 163.454 148.114L164.728 146.792V142.596C160.64 141.817 156.27 142.478 151.984 144.578L148.425 143.096L143.672 146.131V150.323L144.786 150.795C144.71 151.052 144.675 151.319 144.682 151.588V151.616C144.72 152.267 144.654 155.043 144.682 155.779V155.812C144.739 157.053 145.754 158.148 147.859 159.026Z" fill="#F10086" />
                        <path opacity="0.15" d="M179.563 150.785C179.563 151.857 179.483 153.966 179.539 154.448C179.539 154.561 179.539 154.665 179.568 154.783C179.575 155.113 179.54 155.443 179.464 155.765L176.906 154.689C176.906 154.689 179.487 153.442 179.563 150.785Z" fill="black" />
                        <path opacity="0.45" d="M153.73 150.719C152.664 150.271 152.489 149.44 154.453 148.175C156.553 146.839 158.701 146.495 160.56 146.952L164.723 142.615V146.792L163.463 148.114L163.336 148.166C162.651 148.425 161.99 148.694 161.363 148.958C161.103 149.072 160.853 149.176 160.607 149.27C159.256 149.904 157.852 150.421 156.411 150.813C155.818 150.973 155.199 151.017 154.589 150.941C154.295 150.905 154.006 150.83 153.73 150.719Z" fill="black" />
                        <path opacity="0.2" d="M144.762 150.795L143.658 150.323V146.131L147.203 147.618C145.886 148.722 145.041 149.794 144.762 150.795Z" fill="black" />
                        <path opacity="0.15" d="M175.754 159.182L180.564 156.232V160.428L175.749 163.392L172.204 161.896C170.075 162.986 167.788 163.738 165.427 164.124C163.152 164.507 160.824 164.425 158.583 163.883V159.678C160.824 160.22 163.152 160.301 165.427 159.918C167.788 159.535 170.076 158.783 172.204 157.69L175.754 159.182Z" fill="black" />
                        <path opacity="0.2" d="M172.204 161.896C170.541 162.929 168.715 163.675 166.805 164.105C164.929 164.553 162.981 164.617 161.079 164.294C162.985 164.269 164.883 164.048 166.743 163.633C167.687 163.43 168.584 163.161 169.5 162.878C170.416 162.595 171.294 162.231 172.204 161.896Z" fill="black" />
                        <path opacity="0.35" d="M144.673 155.817V155.793C144.644 155.057 144.706 152.281 144.673 151.625C144.744 152.862 145.749 153.957 147.854 154.835C152.546 156.789 157.11 155.505 161.287 153.83C165.162 152.263 167.98 150.592 170.255 151.536C171.671 152.135 171.27 153.079 169.722 154.066C168.735 154.689 167.632 155.104 166.48 155.286C165.327 155.468 164.15 155.412 163.019 155.123L159.536 158.682C155.836 159.989 151.88 160.711 147.849 159.026C145.749 158.148 144.744 157.058 144.673 155.817Z" fill="black" />
                        <path d="M160.565 151.149L160.56 146.952L164.719 142.61L164.723 146.806L160.565 151.149Z" fill="#F10086" />
                        <path opacity="0.15" d="M160.565 151.149L160.56 146.952L164.719 142.61L164.723 146.806L160.565 151.149Z" fill="black" />
                        <path d="M143.653 146.145L147.212 147.627L144.772 150.804L143.658 150.337L143.653 146.145Z" fill="#F10086" />
                        <path opacity="0.45" d="M143.653 146.145L147.212 147.627L144.772 150.804L143.658 150.337L143.653 146.145Z" fill="black" />
                        <path d="M179.662 155.845L176.91 154.689C176.91 154.689 179.615 153.376 179.563 150.587C179.563 150.469 179.563 150.365 179.53 150.256C179.393 149.086 178.416 148.033 176.429 147.203C174.413 146.389 172.224 146.099 170.066 146.358L169.528 146.424H169.448L168.882 146.518C168.716 146.547 168.556 146.575 168.41 146.613C168.339 146.613 168.268 146.636 168.207 146.655C168.145 146.674 167.947 146.702 167.834 146.735C166.795 146.981 165.771 147.284 164.766 147.642C164.294 147.812 163.822 147.991 163.35 148.18C162.67 148.444 162 148.718 161.367 148.982C161.112 149.095 160.862 149.194 160.617 149.294C159.263 149.923 157.86 150.439 156.421 150.837C155.828 150.993 155.212 151.035 154.604 150.96C154.311 150.917 154.024 150.839 153.749 150.728C152.678 150.28 152.494 149.445 154.467 148.18C156.567 146.839 158.715 146.495 160.574 146.957L164.733 142.615C160.645 141.836 156.274 142.497 151.989 144.597L148.43 143.115L143.677 146.15L147.236 147.632C145.541 149.048 144.644 150.384 144.692 151.602V151.63C144.762 152.867 145.772 153.957 147.882 154.84C152.56 156.798 157.129 155.51 161.301 153.834C165.186 152.263 167.999 150.596 170.269 151.545C171.685 152.145 171.275 153.079 169.726 154.075C168.739 154.697 167.636 155.111 166.484 155.293C165.332 155.474 164.155 155.42 163.024 155.132L158.592 159.678C159.415 159.876 160.251 160.014 161.094 160.088C162.544 160.207 164.003 160.152 165.441 159.923C167.798 159.533 170.081 158.782 172.209 157.695L175.768 159.187L180.564 156.232L179.662 155.845Z" fill="#F10086" />
                        <path opacity="0.1" d="M179.662 155.845L176.91 154.689C176.91 154.689 179.615 153.376 179.563 150.587C179.563 150.469 179.563 150.365 179.53 150.256C179.393 149.086 178.416 148.033 176.429 147.203C174.413 146.389 172.224 146.099 170.066 146.358L169.528 146.424H169.448L168.882 146.518C168.716 146.547 168.556 146.575 168.41 146.613C168.339 146.613 168.268 146.636 168.207 146.655C168.145 146.674 167.947 146.702 167.834 146.735C166.795 146.981 165.771 147.284 164.766 147.642C164.294 147.812 163.822 147.991 163.35 148.18C162.67 148.444 162 148.718 161.367 148.982C161.112 149.095 160.862 149.194 160.617 149.294C159.263 149.923 157.86 150.439 156.421 150.837C155.828 150.993 155.212 151.035 154.604 150.96C154.311 150.917 154.024 150.839 153.749 150.728C152.678 150.28 152.494 149.445 154.467 148.18C156.567 146.839 158.715 146.495 160.574 146.957L164.733 142.615C160.645 141.836 156.274 142.497 151.989 144.597L148.43 143.115L143.677 146.15L147.236 147.632C145.541 149.048 144.644 150.384 144.692 151.602V151.63C144.762 152.867 145.772 153.957 147.882 154.84C152.56 156.798 157.129 155.51 161.301 153.834C165.186 152.263 167.999 150.596 170.269 151.545C171.685 152.145 171.275 153.079 169.726 154.075C168.739 154.697 167.636 155.111 166.484 155.293C165.332 155.474 164.155 155.42 163.024 155.132L158.592 159.678C159.415 159.876 160.251 160.014 161.094 160.088C162.544 160.207 164.003 160.152 165.441 159.923C167.798 159.533 170.081 158.782 172.209 157.695L175.768 159.187L180.564 156.232L179.662 155.845Z" fill="white" />
                        <path d="M147.859 154.816C149.633 155.495 151.532 155.789 153.428 155.68C155.372 155.552 157.287 155.147 159.116 154.476C160.976 153.863 162.764 153.06 164.591 152.296C165.508 151.905 166.455 151.59 167.423 151.352C168.361 151.111 169.351 151.169 170.255 151.517C169.338 151.21 168.348 151.196 167.423 151.479C166.479 151.74 165.555 152.073 164.662 152.475C162.864 153.263 161.061 154.084 159.187 154.722C157.327 155.401 155.377 155.8 153.4 155.906C152.435 155.936 151.47 155.864 150.521 155.689C149.606 155.489 148.714 155.197 147.859 154.816Z" fill="white" />
                        <path d="M175.754 159.187L175.759 163.383L172.2 161.887L172.195 157.695L175.754 159.187Z" fill="#F10086" />
                        <path opacity="0.35" d="M175.754 159.187L175.759 163.383L172.2 161.887L172.195 157.695L175.754 159.187Z" fill="black" />
                        <path d="M180.564 156.232L175.773 158.719L172.195 157.695L175.754 159.187L180.564 156.232Z" fill="#FAFAFA" />
                        <path d="M151.965 144.592L148.425 143.545L143.653 146.145L148.406 143.11L151.965 144.592Z" fill="#FAFAFA" />
                        <path opacity="0.15" d="M164.728 142.61C163.99 142.477 163.244 142.389 162.495 142.346L163.076 144.338L164.728 142.61Z" fill="white" />
                        <path opacity="0.15" d="M148.019 154.896C149.966 155.673 152.071 155.967 154.155 155.755L150.724 144.073L148.411 143.129L145.173 145.197L145.706 147.014L147.217 147.646C146.853 147.942 146.504 148.257 146.174 148.59L148.019 154.896Z" fill="white" />
                        <path opacity="0.15" d="M175.707 146.924C174.566 146.538 173.375 146.321 172.171 146.278L175.947 159.078L178.779 157.336L178.152 155.203L176.924 154.689C177.244 154.517 177.546 154.315 177.826 154.084L175.707 146.924Z" fill="white" />
                        <path opacity="0.15" d="M169.448 146.419L168.882 146.514C168.716 146.542 168.551 146.57 168.41 146.608C168.339 146.608 168.268 146.632 168.207 146.65C168.145 146.669 167.947 146.698 167.834 146.731C166.795 146.977 165.771 147.279 164.766 147.637C164.544 147.712 164.327 147.802 164.11 147.882L165.351 152.13C167.267 151.375 168.858 150.974 170.25 151.554C171.643 152.135 171.256 153.088 169.708 154.084C168.673 154.741 167.506 155.161 166.29 155.316L167.513 159.489C169.13 159.058 170.699 158.461 172.195 157.709L173.54 158.271L170.043 146.367L169.528 146.429L169.448 146.419Z" fill="white" />
                        <path d="M204.437 142.865H208.435V151.04C208.435 152.022 208.304 153 208.043 153.948C207.949 154.278 207.836 154.608 207.713 154.939C207.065 156.585 206.119 158.099 204.924 159.404C204.475 159.899 204.003 160.372 203.508 160.82C202.98 161.307 202.429 161.767 201.856 162.198C201.082 162.786 200.279 163.332 199.448 163.836C198.542 164.388 197.598 164.903 196.616 165.389C195.871 165.752 195.106 166.097 194.313 166.422C182.74 171.204 166.781 171.784 154.047 167.905C153.178 167.64 152.329 167.355 151.498 167.05C150.304 166.616 149.138 166.135 148.029 165.611C147.113 165.18 146.248 164.734 145.433 164.275C144.725 163.869 144.059 163.458 143.427 163.029C142.794 162.599 142.209 162.17 141.657 161.726C140.132 160.529 138.796 159.108 137.697 157.511C137.489 157.195 137.286 156.874 137.107 156.567C136.342 155.241 135.843 153.779 135.639 152.262C135.582 151.807 135.554 151.348 135.554 150.889V143.134H139.212C139.504 142.813 139.811 142.497 140.156 142.19C141.499 140.922 142.976 139.803 144.559 138.853C145.579 138.232 146.63 137.665 147.708 137.154C149.155 136.463 150.641 135.856 152.159 135.337C162.628 131.716 175.957 131.23 187.285 134.043C188.94 134.452 190.574 134.943 192.18 135.516C193.496 135.988 194.771 136.507 195.993 137.078C197.746 137.888 199.424 138.848 201.011 139.948C202.245 140.807 203.393 141.784 204.437 142.865Z" fill="#F10086" />
                        <path opacity="0.15" d="M161.896 169.67V153.065L167.975 153.702V170.326C165.913 170.198 163.878 169.981 161.896 169.67Z" fill="white" />
                        <path opacity="0.1" d="M208.435 151.04C208.435 152.022 208.304 153 208.043 153.948C207.949 154.278 207.836 154.608 207.713 154.939C207.065 156.585 206.119 158.099 204.924 159.404C204.763 159.578 204.593 159.758 204.423 159.932V142.846L204.452 142.874H208.449L208.435 151.04Z" fill="black" />
                        <path opacity="0.15" d="M188.163 143.398L191.283 142.662V167.546C190.287 167.876 189.267 168.174 188.229 168.447L188.163 143.398Z" fill="white" />
                        <path opacity="0.15" d="M180.115 169.977V148.406L186.19 148.855V168.938C184.184 169.386 182.156 169.733 180.115 169.977Z" fill="white" />
                        <path opacity="0.15" d="M135.54 143.134H139.198C139.49 142.813 139.797 142.497 140.142 142.19C141.485 140.922 142.961 139.804 144.545 138.853C145.565 138.232 146.615 137.665 147.694 137.154C149.141 136.463 150.627 135.856 152.144 135.337C153.608 134.832 155.132 134.393 156.695 134.01L156.723 168.65C155.826 168.428 154.934 168.178 154.061 167.919C153.188 167.659 152.343 167.371 151.512 167.064C150.318 166.63 149.152 166.149 148.043 165.625C147.127 165.194 146.262 164.748 145.447 164.289C144.739 163.883 144.073 163.472 143.441 163.043C142.808 162.613 142.223 162.184 141.671 161.74C140.146 160.543 138.811 159.122 137.711 157.525C137.503 157.209 137.3 156.888 137.121 156.581C136.356 155.256 135.857 153.793 135.653 152.277C135.596 151.821 135.568 151.362 135.568 150.903L135.54 143.134Z" fill="white" />
                        <path opacity="0.15" d="M137.687 157.506C137.479 157.19 137.276 156.869 137.097 156.562C136.332 155.237 135.834 153.775 135.629 152.258C135.573 151.802 135.544 151.343 135.544 150.884V143.134H139.202L139.245 143.087L140.481 143.53V160.74C139.426 159.775 138.488 158.69 137.687 157.506Z" fill="white" />
                        <path d="M195.956 128.308C194.733 127.737 193.459 127.213 192.142 126.746C190.565 126.184 188.927 125.689 187.247 125.274C175.919 122.442 162.59 122.947 152.121 126.567C150.604 127.087 149.117 127.693 147.67 128.384C146.592 128.895 145.541 129.463 144.522 130.083C142.938 131.034 141.461 132.153 140.118 133.42C138.813 134.637 137.719 136.061 136.88 137.635C135.784 139.642 135.347 141.942 135.629 144.21C135.834 145.726 136.331 147.188 137.092 148.515C137.276 148.836 137.475 149.157 137.687 149.459C138.783 151.059 140.119 152.481 141.647 153.674C142.204 154.121 142.796 154.556 143.422 154.981C144.054 155.406 144.72 155.821 145.428 156.223C146.24 156.695 147.108 157.134 148.019 157.563C149.133 158.082 150.294 158.564 151.488 158.979C152.319 159.294 153.17 159.579 154.042 159.833C166.786 163.718 182.73 163.137 194.308 158.351C195.096 158.026 195.866 157.681 196.607 157.318C197.598 156.836 198.542 156.319 199.439 155.765C200.267 155.26 201.069 154.713 201.841 154.127C202.436 153.679 202.988 153.216 203.498 152.749C203.996 152.303 204.469 151.83 204.914 151.333C206.111 150.028 207.056 148.515 207.704 146.868C207.831 146.537 207.94 146.207 208.034 145.876C208.433 144.455 208.534 142.967 208.331 141.506C207.945 139.128 206.904 136.905 205.325 135.086C204.058 133.585 202.594 132.26 200.973 131.15C199.386 130.06 197.707 129.109 195.956 128.308Z" fill="#F10086" />
                        <path opacity="0.1" d="M195.956 128.308C194.733 127.737 193.459 127.213 192.142 126.746C190.565 126.184 188.927 125.689 187.247 125.274C175.919 122.442 162.59 122.947 152.121 126.567C150.604 127.087 149.117 127.693 147.67 128.384C146.592 128.895 145.541 129.463 144.522 130.083C142.938 131.034 141.461 132.153 140.118 133.42C138.813 134.637 137.719 136.061 136.88 137.635C135.784 139.642 135.347 141.942 135.629 144.21C135.834 145.726 136.331 147.188 137.092 148.515C137.276 148.836 137.475 149.157 137.687 149.459C138.783 151.059 140.119 152.481 141.647 153.674C142.204 154.121 142.796 154.556 143.422 154.981C144.054 155.406 144.72 155.821 145.428 156.223C146.24 156.695 147.108 157.134 148.019 157.563C149.133 158.082 150.294 158.564 151.488 158.979C152.319 159.294 153.17 159.579 154.042 159.833C166.786 163.718 182.73 163.137 194.308 158.351C195.096 158.026 195.866 157.681 196.607 157.318C197.598 156.836 198.542 156.319 199.439 155.765C200.267 155.26 201.069 154.713 201.841 154.127C202.436 153.679 202.988 153.216 203.498 152.749C203.996 152.303 204.469 151.83 204.914 151.333C206.111 150.028 207.056 148.515 207.704 146.868C207.831 146.537 207.94 146.207 208.034 145.876C208.433 144.455 208.534 142.967 208.331 141.506C207.945 139.128 206.904 136.905 205.325 135.086C204.058 133.585 202.594 132.26 200.973 131.15C199.386 130.06 197.707 129.109 195.956 128.308Z" fill="white" />
                        <path opacity="0.2" d="M195.956 128.308C194.733 127.737 193.459 127.213 192.142 126.746C190.565 126.184 188.927 125.689 187.247 125.274C175.919 122.442 162.59 122.947 152.121 126.567C150.604 127.087 149.117 127.693 147.67 128.384C146.592 128.895 145.541 129.463 144.522 130.083C142.938 131.034 141.461 132.153 140.118 133.42C138.813 134.637 137.719 136.061 136.88 137.635C135.784 139.642 135.347 141.942 135.629 144.21C135.834 145.726 136.331 147.188 137.092 148.515C137.276 148.836 137.475 149.157 137.687 149.459C138.783 151.059 140.119 152.481 141.647 153.674C142.204 154.121 142.796 154.556 143.422 154.981C144.054 155.406 144.72 155.821 145.428 156.223C146.24 156.695 147.108 157.134 148.019 157.563C149.133 158.082 150.294 158.564 151.488 158.979C152.319 159.294 153.17 159.579 154.042 159.833C166.786 163.718 182.73 163.137 194.308 158.351C195.096 158.026 195.866 157.681 196.607 157.318C197.598 156.836 198.542 156.319 199.439 155.765C200.267 155.26 201.069 154.713 201.841 154.127C202.436 153.679 202.988 153.216 203.498 152.749C203.996 152.303 204.469 151.83 204.914 151.333C206.111 150.028 207.056 148.515 207.704 146.868C207.831 146.537 207.94 146.207 208.034 145.876C208.433 144.455 208.534 142.967 208.331 141.506C207.945 139.128 206.904 136.905 205.325 135.086C204.058 133.585 202.594 132.26 200.973 131.15C199.386 130.06 197.707 129.109 195.956 128.308Z" fill="white" />
                        <path opacity="0.15" d="M156.704 160.607C155.798 160.381 154.901 160.135 154.023 159.866C153.145 159.597 152.3 159.319 151.47 159.012C150.275 158.578 149.11 158.096 148 157.596C147.089 157.167 146.221 156.718 145.409 156.256C144.701 155.854 144.036 155.439 143.403 155.014C142.771 154.589 142.185 154.155 141.628 153.707C140.1 152.514 138.764 151.092 137.668 149.492C137.456 149.176 137.258 148.855 137.074 148.548C136.312 147.221 135.815 145.759 135.61 144.243C135.326 141.962 135.771 139.649 136.88 137.635C137.72 136.061 138.816 134.636 140.123 133.42C141.466 132.153 142.943 131.034 144.526 130.083C145.414 129.54 146.348 129.04 147.316 128.563L156.704 160.607Z" fill="white" />
                        <path opacity="0.15" d="M140.434 152.65C139.396 151.702 138.473 150.636 137.682 149.473C137.47 149.157 137.272 148.836 137.088 148.529C136.326 147.202 135.83 145.74 135.624 144.224C135.39 142.372 135.643 140.491 136.361 138.768L140.434 152.65Z" fill="white" />
                        <path opacity="0.15" d="M167.938 162.292C165.894 162.17 163.864 161.953 161.868 161.641L151.625 126.746L152.121 126.567C153.734 126.012 155.374 125.54 157.034 125.151L167.938 162.292Z" fill="white" />
                        <path opacity="0.15" d="M186.185 160.91C184.179 161.358 182.152 161.703 180.11 161.943L168.83 123.537C170.954 123.442 173.078 123.447 175.221 123.537L186.185 160.91Z" fill="white" />
                        <path opacity="0.15" d="M191.269 159.508C190.268 159.838 189.244 160.145 188.196 160.419L177.411 123.697C178.572 123.791 179.723 123.909 180.866 124.065L191.269 159.508Z" fill="white" />
                        <path opacity="0.3" d="M197.768 129.219C198.24 129.474 198.712 129.739 199.151 130.003L199.595 130.267L200.038 130.55C200.359 130.758 200.68 130.966 200.982 131.178C202.603 132.289 204.067 133.613 205.334 135.115C206.913 136.934 207.954 139.156 208.341 141.534C208.544 142.996 208.442 144.484 208.043 145.905C207.949 146.235 207.841 146.565 207.713 146.896C207.066 148.543 206.12 150.057 204.924 151.361C204.768 151.54 204.593 151.715 204.423 151.894L197.768 129.219Z" fill="#F10086" />
                        <path d="M189.744 131.995C178.888 126.944 162.642 127.322 152.673 132.693C152.253 132.925 151.833 133.165 151.432 133.406C141.619 139.452 142.856 148.619 154.198 153.886C165.54 159.154 182.697 158.521 192.51 152.47C192.911 152.225 193.293 151.97 193.657 151.715C202.224 145.688 200.638 137.045 189.744 131.995Z" fill="#F10086" />
                        <path opacity="0.4" d="M189.744 131.995C178.888 126.944 162.642 127.322 152.673 132.693C152.253 132.925 151.833 133.165 151.432 133.406C141.619 139.452 142.856 148.619 154.198 153.886C165.54 159.154 182.697 158.521 192.51 152.47C192.911 152.225 193.293 151.97 193.657 151.715C202.224 145.688 200.638 137.045 189.744 131.995Z" fill="black" />
                        <path d="M188.965 132.481C188.616 132.321 188.257 132.16 187.894 132.009C177.51 127.681 162.75 128.186 153.542 133.147L153.291 133.283C152.975 133.458 152.659 133.637 152.347 133.826C150.687 134.804 149.214 136.072 148 137.569C145.536 140.732 145.385 144.257 147.453 147.453C148.666 149.341 150.644 151.078 153.372 152.574C153.881 152.857 154.42 153.131 154.991 153.395C155.562 153.66 156.038 153.867 156.586 154.08C157.75 154.538 158.938 154.934 160.145 155.264C168.731 157.624 179.133 157.252 186.997 154.278C188.093 153.872 189.163 153.399 190.202 152.862C190.674 152.607 191.146 152.343 191.618 152.064C192.09 151.786 192.364 151.592 192.713 151.347L193.001 151.134C195.295 149.454 196.777 147.571 197.48 145.631C198.67 142.327 197.574 138.834 194.289 135.889C192.692 134.496 190.898 133.348 188.965 132.481Z" fill="#F10086" />
                        <path opacity="0.5" d="M188.965 132.481C188.616 132.321 188.257 132.16 187.894 132.009C177.51 127.681 162.75 128.186 153.542 133.147L153.291 133.283C152.975 133.458 152.659 133.637 152.347 133.826C150.687 134.804 149.214 136.072 148 137.569C145.536 140.732 145.385 144.257 147.453 147.453C148.666 149.341 150.644 151.078 153.372 152.574C153.881 152.857 154.42 153.131 154.991 153.395C155.562 153.66 156.038 153.867 156.586 154.08C157.75 154.538 158.938 154.934 160.145 155.264C168.731 157.624 179.133 157.252 186.997 154.278C188.093 153.872 189.163 153.399 190.202 152.862C190.674 152.607 191.146 152.343 191.618 152.064C192.09 151.786 192.364 151.592 192.713 151.347L193.001 151.134C195.295 149.454 196.777 147.571 197.48 145.631C198.67 142.327 197.574 138.834 194.289 135.889C192.692 134.496 190.898 133.348 188.965 132.481Z" fill="black" />
                        <path d="M187.903 135.063C188.271 135.214 188.625 135.37 188.975 135.535C190.905 136.396 192.696 137.54 194.289 138.929C196.21 140.647 197.376 142.549 197.782 144.493C197.705 144.876 197.601 145.253 197.471 145.621C196.772 147.561 195.285 149.445 192.991 151.125L192.703 151.337C192.354 151.578 191.991 151.809 191.608 152.055C191.226 152.3 190.693 152.598 190.192 152.852C189.154 153.389 188.084 153.862 186.988 154.268C179.124 157.242 168.721 157.624 160.135 155.255C158.929 154.924 157.741 154.529 156.577 154.07C156.029 153.853 155.5 153.627 154.981 153.386C154.462 153.145 153.872 152.848 153.362 152.565C150.634 151.068 148.642 149.312 147.443 147.443C146.84 146.527 146.409 145.508 146.174 144.437C146.478 143.037 147.104 141.726 148 140.609C149.218 139.115 150.69 137.848 152.347 136.866C152.654 136.677 152.966 136.498 153.291 136.323L153.542 136.186C162.76 131.24 177.538 130.744 187.903 135.063Z" fill="#F10086" />
                        <path opacity="0.25" d="M187.903 135.063C188.271 135.214 188.625 135.37 188.975 135.535C190.905 136.396 192.696 137.54 194.289 138.929C196.21 140.647 197.376 142.549 197.782 144.493C197.705 144.876 197.601 145.253 197.471 145.621C196.772 147.561 195.285 149.445 192.991 151.125L192.703 151.337C192.354 151.578 191.991 151.809 191.608 152.055C191.226 152.3 190.693 152.598 190.192 152.852C189.154 153.389 188.084 153.862 186.988 154.268C179.124 157.242 168.721 157.624 160.135 155.255C158.929 154.924 157.741 154.529 156.577 154.07C156.029 153.853 155.5 153.627 154.981 153.386C154.462 153.145 153.872 152.848 153.362 152.565C150.634 151.068 148.642 149.312 147.443 147.443C146.84 146.527 146.409 145.508 146.174 144.437C146.478 143.037 147.104 141.726 148 140.609C149.218 139.115 150.69 137.848 152.347 136.866C152.654 136.677 152.966 136.498 153.291 136.323L153.542 136.186C162.76 131.24 177.538 130.744 187.903 135.063Z" fill="black" />
                        <path d="M207.704 146.896C203.583 156.072 192.043 159.725 182.636 161.382C168.334 163.435 152.768 161.712 141.709 153.575C138.702 151.215 136.139 148.038 135.601 144.196C136.073 148.076 138.546 151.361 141.553 153.787C150.459 160.688 162.793 162.925 174.593 162.595C186.482 161.896 202.313 158.705 207.704 146.896Z" fill="#F5F5F5" />
                        <path d="M156.992 149.7C161.032 151.394 164.992 150.644 168.683 149.355L167.711 150.351V154.547C168.532 154.742 169.367 154.878 170.208 154.953C171.658 155.076 173.118 155.022 174.555 154.792C176.913 154.402 179.196 153.649 181.324 152.56L184.887 154.056L189.692 151.097V146.905L188.791 146.518L188.597 146.438C188.672 146.116 188.705 145.786 188.696 145.456C188.694 145.346 188.685 145.235 188.668 145.126C188.606 144.607 188.71 142.237 188.668 141.26C188.666 141.15 188.656 141.039 188.64 140.93C188.498 139.759 187.526 138.707 185.534 137.876C183.52 137.063 181.333 136.772 179.176 137.031L178.633 137.097H178.576L178.01 137.187C177.845 137.215 177.68 137.243 177.514 137.281C177.444 137.281 177.373 137.281 177.316 137.324C177.189 137.347 177.057 137.371 176.939 137.404C175.902 137.651 174.879 137.953 173.875 138.31C173.432 138.466 172.993 138.631 172.563 138.782L173.828 137.461V133.265C169.736 132.486 165.37 133.147 161.084 135.247L157.521 133.765L152.772 136.8V140.991L153.886 141.463C153.809 141.72 153.772 141.988 153.778 142.256V142.284C153.815 142.936 153.778 145.711 153.778 146.447V146.48C153.872 147.736 154.887 148.822 156.992 149.7Z" fill="#F10086" />
                        <path opacity="0.15" d="M188.691 141.458C188.691 142.53 188.616 144.64 188.691 145.121C188.691 145.234 188.691 145.343 188.724 145.456C188.731 145.787 188.696 146.116 188.621 146.438L186.039 145.376C186.039 145.376 188.621 144.116 188.691 141.458Z" fill="black" />
                        <path opacity="0.45" d="M162.864 141.392C161.792 140.944 161.618 140.113 163.581 138.848C165.686 137.512 167.853 137.168 169.693 137.626L173.856 133.288V137.479L172.601 138.801C172.554 138.801 172.521 138.834 172.474 138.853C171.789 139.113 171.128 139.382 170.496 139.646C170.241 139.759 169.991 139.863 169.745 139.957C168.392 140.591 166.986 141.107 165.545 141.501C164.953 141.661 164.336 141.704 163.727 141.628C163.43 141.589 163.139 141.51 162.864 141.392Z" fill="black" />
                        <path opacity="0.2" d="M153.896 141.468L152.786 140.996V136.804L156.331 138.291C155.019 139.396 154.174 140.467 153.896 141.468Z" fill="black" />
                        <path opacity="0.15" d="M184.887 149.855L189.697 146.901V151.097L184.882 154.061L181.333 152.56C179.203 153.65 176.916 154.402 174.555 154.788C172.281 155.171 169.953 155.089 167.711 154.547V150.351C169.953 150.893 172.281 150.975 174.555 150.592C176.917 150.208 179.204 149.456 181.333 148.364L184.887 149.855Z" fill="black" />
                        <path opacity="0.2" d="M181.338 152.56C179.674 153.592 177.849 154.339 175.938 154.769C174.062 155.217 172.114 155.281 170.213 154.958C172.11 154.932 174.001 154.71 175.853 154.297C176.797 154.094 177.699 153.825 178.614 153.542C179.53 153.258 180.427 152.928 181.338 152.56Z" fill="black" />
                        <path opacity="0.35" d="M153.801 146.49V146.466C153.768 145.73 153.834 142.955 153.801 142.299C153.877 143.535 154.877 144.63 156.987 145.508C161.679 147.462 166.243 146.178 170.42 144.503C174.295 142.936 177.113 141.265 179.388 142.209C180.804 142.808 180.403 143.752 178.855 144.739C177.868 145.362 176.764 145.778 175.61 145.959C174.457 146.141 173.279 146.086 172.148 145.796L168.698 149.355C165.002 150.662 161.046 151.385 157.016 149.7C154.877 148.822 153.872 147.736 153.801 146.49Z" fill="black" />
                        <path d="M169.693 141.822V137.626L173.852 133.283V137.479L169.693 141.822Z" fill="#F10086" />
                        <path opacity="0.15" d="M169.693 141.822V137.626L173.852 133.283V137.479L169.693 141.822Z" fill="black" />
                        <path d="M152.786 136.819L156.345 138.301L153.905 141.477L152.791 141.01L152.786 136.819Z" fill="#F10086" />
                        <path opacity="0.45" d="M152.786 136.819L156.345 138.301L153.905 141.477L152.791 141.01L152.786 136.819Z" fill="black" />
                        <path d="M188.8 146.518L186.044 145.376C186.044 145.376 188.748 144.064 188.691 141.274C188.689 141.164 188.68 141.053 188.663 140.944C188.522 139.773 187.549 138.721 185.557 137.89C183.543 137.077 181.356 136.786 179.2 137.045L178.657 137.111H178.6L178.034 137.201C177.868 137.229 177.703 137.258 177.538 137.295C177.467 137.295 177.396 137.295 177.34 137.338C177.212 137.361 177.08 137.385 176.962 137.418C175.926 137.665 174.903 137.967 173.899 138.324C173.427 138.494 172.955 138.674 172.483 138.862C171.799 139.127 171.133 139.4 170.501 139.665C170.241 139.778 169.996 139.877 169.75 139.976C168.395 140.606 166.99 141.122 165.549 141.52C164.957 141.676 164.34 141.718 163.732 141.642C163.439 141.599 163.153 141.521 162.878 141.411C161.806 140.963 161.627 140.127 163.6 138.862C165.7 137.522 167.848 137.177 169.708 137.64L173.866 133.298C169.774 132.519 165.408 133.18 161.122 135.28L157.558 133.798L152.81 136.833L156.369 138.315C154.67 139.731 153.778 141.067 153.82 142.284V142.313C153.891 143.549 154.906 144.64 157.011 145.522C161.693 147.481 166.262 146.193 170.434 144.517C174.314 142.945 177.132 141.279 179.402 142.228C180.818 142.827 180.413 143.762 178.86 144.758C177.874 145.379 176.771 145.794 175.62 145.976C174.468 146.157 173.292 146.103 172.162 145.815L167.735 150.365C168.556 150.564 169.39 150.701 170.232 150.776C171.682 150.895 173.141 150.839 174.579 150.61C176.938 150.221 179.222 149.469 181.352 148.383L184.911 149.874L189.716 146.919L188.8 146.518Z" fill="#F10086" />
                        <path opacity="0.1" d="M188.8 146.518L186.044 145.376C186.044 145.376 188.748 144.064 188.691 141.274C188.689 141.164 188.68 141.053 188.663 140.944C188.522 139.773 187.549 138.721 185.557 137.89C183.543 137.077 181.356 136.786 179.2 137.045L178.657 137.111H178.6L178.034 137.201C177.868 137.229 177.703 137.258 177.538 137.295C177.467 137.295 177.396 137.295 177.34 137.338C177.212 137.361 177.08 137.385 176.962 137.418C175.926 137.665 174.903 137.967 173.899 138.324C173.427 138.494 172.955 138.674 172.483 138.862C171.799 139.127 171.133 139.4 170.501 139.665C170.241 139.778 169.996 139.877 169.75 139.976C168.395 140.606 166.99 141.122 165.549 141.52C164.957 141.676 164.34 141.718 163.732 141.642C163.439 141.599 163.153 141.521 162.878 141.411C161.806 140.963 161.627 140.127 163.6 138.862C165.7 137.522 167.848 137.177 169.708 137.64L173.866 133.298C169.774 132.519 165.408 133.18 161.122 135.28L157.558 133.798L152.81 136.833L156.369 138.315C154.67 139.731 153.778 141.067 153.82 142.284V142.313C153.891 143.549 154.906 144.64 157.011 145.522C161.693 147.481 166.262 146.193 170.434 144.517C174.314 142.945 177.132 141.279 179.402 142.228C180.818 142.827 180.413 143.762 178.86 144.758C177.874 145.379 176.771 145.794 175.62 145.976C174.468 146.157 173.292 146.103 172.162 145.815L167.735 150.365C168.556 150.564 169.39 150.701 170.232 150.776C171.682 150.895 173.141 150.839 174.579 150.61C176.938 150.221 179.222 149.469 181.352 148.383L184.911 149.874L189.716 146.919L188.8 146.518Z" fill="white" />
                        <path d="M156.987 145.508C158.763 146.187 160.663 146.482 162.562 146.372C164.497 146.243 166.405 145.838 168.226 145.168C170.09 144.555 171.879 143.752 173.705 142.988C174.622 142.597 175.569 142.281 176.537 142.044C177.476 141.802 178.466 141.86 179.369 142.209C178.453 141.901 177.462 141.887 176.537 142.171C175.593 142.431 174.669 142.764 173.776 143.167C171.973 143.955 170.17 144.777 168.301 145.414C166.445 146.083 164.499 146.474 162.528 146.575C161.562 146.605 160.595 146.532 159.645 146.358C158.732 146.165 157.842 145.88 156.987 145.508Z" fill="white" />
                        <path d="M184.887 149.86V154.056L181.328 152.56V148.368L184.887 149.86Z" fill="#F10086" />
                        <path opacity="0.35" d="M184.887 149.86V154.056L181.328 152.56V148.368L184.887 149.86Z" fill="black" />
                        <path d="M189.692 146.905L184.906 149.393L181.328 148.368L184.887 149.86L189.692 146.905Z" fill="#FAFAFA" />
                        <path d="M161.098 135.266L157.558 134.218L152.786 136.819L157.535 133.784L161.098 135.266Z" fill="#FAFAFA" />
                        <path opacity="0.15" d="M173.852 133.283C173.112 133.15 172.365 133.062 171.614 133.019L172.2 135.011L173.852 133.283Z" fill="white" />
                        <path opacity="0.15" d="M157.176 145.57C159.122 146.346 161.227 146.641 163.312 146.429L159.881 134.747L157.563 133.803L154.325 135.87L154.858 137.687L156.374 138.32C156.008 138.614 155.66 138.93 155.33 139.264L157.176 145.57Z" fill="white" />
                        <path opacity="0.15" d="M184.84 137.597C183.699 137.212 182.508 136.994 181.305 136.951L185.081 149.751L187.913 148.01L187.29 145.876L186.062 145.362C186.382 145.193 186.683 144.991 186.959 144.758L184.84 137.597Z" fill="white" />
                        <path opacity="0.15" d="M178.6 137.097L178.034 137.187C177.868 137.215 177.703 137.243 177.538 137.281C177.467 137.281 177.396 137.281 177.34 137.324C177.212 137.347 177.08 137.371 176.962 137.404C175.926 137.651 174.903 137.953 173.899 138.31C173.677 138.386 173.46 138.475 173.243 138.556L174.484 142.804C176.396 142.048 177.991 141.647 179.379 142.228C180.767 142.808 180.389 143.762 178.836 144.758C177.802 145.416 176.635 145.836 175.419 145.99L176.646 150.162C178.263 149.73 179.832 149.134 181.328 148.383L182.669 148.944L179.176 137.04L178.657 137.102L178.6 137.097Z" fill="white" />
                        <path d="M140.094 179.643L140.675 175.244L141.255 179.643L148.874 179.978L141.255 180.313L140.675 184.712L140.094 180.313L132.476 179.978L140.094 179.643Z" fill="white" />
                        <path d="M145.777 183.414L146.06 181.286L146.339 183.414L150.035 183.58L146.339 183.74L146.06 185.874L145.777 183.74L142.086 183.58L145.777 183.414Z" fill="white" />
                        <path d="M132.863 137.626L133.444 133.227L134.02 137.626L141.642 137.961L134.02 138.296L133.444 142.695L132.863 138.296L125.24 137.961L132.863 137.626Z" fill="white" />
                        <path d="M136.979 141.038L137.262 138.905L137.541 141.038L141.237 141.199L137.541 141.364L137.262 143.493L136.979 141.364L133.288 141.199L136.979 141.038Z" fill="white" />
                        <path d="M195.073 156.6L195.653 152.201L196.234 156.6L203.852 156.935L196.234 157.27L195.653 161.669L195.073 157.27L187.455 156.935L195.073 156.6Z" fill="white" />
                        <path d="M199.193 160.013L199.472 157.879L199.755 160.013L203.446 160.173L199.755 160.334L199.472 162.467L199.193 160.334L195.498 160.173L199.193 160.013Z" fill="white" />
                        <path d="M101.48 185.024H105.478V193.208C105.482 194.19 105.35 195.167 105.086 196.111C104.992 196.442 104.883 196.772 104.756 197.102C104.1 198.742 103.15 200.247 101.952 201.544C101.504 202.039 101.031 202.512 100.536 202.96C100.026 203.432 99.474 203.904 98.8793 204.338C98.106 204.926 97.3025 205.473 96.4721 205.976C95.5705 206.528 94.6218 207.043 93.6401 207.529C92.899 207.892 92.1297 208.237 91.3414 208.563C79.768 213.344 63.8238 213.915 51.094 210.04C50.2224 209.776 49.3712 209.491 48.5405 209.186C47.3463 208.751 46.1805 208.27 45.0713 207.746C44.1603 207.321 43.2918 206.873 42.48 206.41C41.772 206.004 41.1018 205.594 40.474 205.164C39.8462 204.735 39.2562 204.305 38.6993 203.862C37.1727 202.67 35.8372 201.251 34.7392 199.656C34.5268 199.34 34.3286 199.019 34.1445 198.712C33.3818 197.385 32.8833 195.924 32.6766 194.407C32.6216 193.952 32.5948 193.493 32.5963 193.034V185.298H36.2543C36.547 184.977 36.8538 184.661 37.1983 184.354C38.5435 183.086 40.0216 181.967 41.6068 181.017C42.6244 180.396 43.6737 179.829 44.7503 179.318C46.1995 178.627 47.6871 178.02 49.206 177.5C59.6702 173.88 73.0042 173.394 84.3275 176.207C85.9771 176.601 87.6059 177.076 89.208 177.632C90.5201 178.104 91.7946 178.624 93.017 179.195C94.7714 180.005 96.4518 180.967 98.0391 182.069C99.2814 182.937 100.434 183.927 101.48 185.024Z" fill="#F10086" />
                        <path opacity="0.15" d="M58.9292 211.805V195.205L65.0086 195.838V212.461C62.9601 212.334 60.9258 212.117 58.9292 211.805Z" fill="white" />
                        <path opacity="0.1" d="M105.483 193.171C105.487 194.152 105.355 195.129 105.091 196.074C104.996 196.404 104.888 196.734 104.76 197.065C104.108 198.718 103.156 200.236 101.952 201.544C101.796 201.719 101.626 201.898 101.48 202.073V184.986L101.508 185.015H105.506L105.483 193.171Z" fill="black" />
                        <path opacity="0.15" d="M85.2102 185.534L88.3301 184.797V209.681C87.3389 210.012 86.3194 210.309 85.2762 210.583L85.2102 185.534Z" fill="white" />
                        <path opacity="0.15" d="M77.1673 212.112V190.542L83.2466 190.99V211.074C81.2389 211.521 79.2099 211.868 77.1673 212.112Z" fill="white" />
                        <path opacity="0.15" d="M32.568 185.269H36.226C36.5186 184.948 36.8254 184.632 37.17 184.325C38.5152 183.058 39.9932 181.939 41.5785 180.988C42.5961 180.368 43.6454 179.801 44.722 179.289C46.1711 178.599 47.6588 177.992 49.1777 177.472C50.6409 176.967 52.1654 176.528 53.7278 176.146L53.7561 210.786C52.8546 210.564 51.9625 210.314 51.094 210.054C50.2255 209.795 49.3712 209.507 48.5405 209.2C47.3463 208.766 46.1805 208.284 45.0713 207.76C44.1603 207.335 43.2918 206.887 42.48 206.424C41.772 206.019 41.1018 205.608 40.474 205.178C39.8462 204.749 39.2562 204.319 38.6993 203.876C37.1716 202.68 35.8361 201.256 34.7392 199.656C34.5268 199.34 34.3286 199.019 34.1445 198.712C33.3818 197.385 32.8833 195.924 32.6766 194.407C32.6216 193.952 32.5948 193.493 32.5963 193.034L32.568 185.269Z" fill="white" />
                        <path opacity="0.15" d="M34.7392 199.656C34.5268 199.34 34.3286 199.019 34.1445 198.712C33.3818 197.385 32.8833 195.924 32.6766 194.407C32.6216 193.952 32.5948 193.493 32.5963 193.034V185.298H36.2543C36.2688 185.281 36.2846 185.265 36.3015 185.251L37.5334 185.699V202.903C36.476 201.936 35.5379 200.846 34.7392 199.656Z" fill="white" />
                        <path d="M92.984 170.444C91.7615 169.873 90.4871 169.354 89.1702 168.882C87.5646 168.308 85.931 167.817 84.2756 167.409C72.9476 164.577 59.6183 165.082 49.1541 168.702C47.6352 169.222 46.1475 169.829 44.6984 170.519C43.6207 171.032 42.5699 171.599 41.5502 172.219C39.9672 173.17 38.4908 174.289 37.1464 175.556C35.8412 176.773 34.7456 178.197 33.9038 179.771C32.8133 181.779 32.376 184.077 32.653 186.346C32.8574 187.862 33.3561 189.325 34.1209 190.65C34.3002 190.971 34.4985 191.292 34.7109 191.594C35.8171 193.2 37.1639 194.627 38.704 195.823C39.2578 196.27 39.8478 196.706 40.474 197.131C41.1065 197.556 41.772 197.971 42.48 198.372C43.295 198.835 44.1603 199.282 45.076 199.713C46.1899 200.232 47.3463 200.713 48.5405 201.129C49.3775 201.443 50.2287 201.728 51.094 201.983C63.838 205.868 79.7822 205.287 91.3603 200.501C92.1533 200.175 92.9179 199.831 93.6637 199.467C94.6549 198.995 95.5989 198.467 96.4957 197.914C97.3254 197.41 98.1289 196.863 98.9029 196.276C99.4689 195.857 100.014 195.409 100.536 194.936C101.033 194.489 101.505 194.017 101.952 193.52C103.147 192.215 104.092 190.701 104.742 189.055C104.864 188.725 104.978 188.394 105.067 188.064C105.471 186.644 105.572 185.155 105.365 183.693C104.978 181.316 103.937 179.093 102.358 177.274C101.093 175.772 99.6305 174.448 98.0108 173.337C96.4216 172.23 94.7397 171.262 92.984 170.444Z" fill="#F10086" />
                        <path opacity="0.1" d="M92.984 170.444C91.7615 169.873 90.4871 169.354 89.1702 168.882C87.5646 168.308 85.931 167.817 84.2756 167.409C72.9476 164.577 59.6183 165.082 49.1541 168.702C47.6352 169.222 46.1475 169.829 44.6984 170.519C43.6207 171.032 42.5699 171.599 41.5502 172.219C39.9672 173.17 38.4908 174.289 37.1464 175.556C35.8412 176.773 34.7456 178.197 33.9038 179.771C32.8133 181.779 32.376 184.077 32.653 186.346C32.8574 187.862 33.3561 189.325 34.1209 190.65C34.3002 190.971 34.4985 191.292 34.7109 191.594C35.8171 193.2 37.1639 194.627 38.704 195.823C39.2578 196.27 39.8478 196.706 40.474 197.131C41.1065 197.556 41.772 197.971 42.48 198.372C43.295 198.835 44.1603 199.282 45.076 199.713C46.1899 200.232 47.3463 200.713 48.5405 201.129C49.3775 201.443 50.2287 201.728 51.094 201.983C63.838 205.868 79.7822 205.287 91.3603 200.501C92.1533 200.175 92.9179 199.831 93.6637 199.467C94.6549 198.995 95.5989 198.467 96.4957 197.914C97.3254 197.41 98.1289 196.863 98.9029 196.276C99.4689 195.857 100.014 195.409 100.536 194.936C101.033 194.489 101.505 194.017 101.952 193.52C103.147 192.215 104.092 190.701 104.742 189.055C104.864 188.725 104.978 188.394 105.067 188.064C105.471 186.644 105.572 185.155 105.365 183.693C104.978 181.316 103.937 179.093 102.358 177.274C101.093 175.772 99.6305 174.448 98.0108 173.337C96.4216 172.23 94.7397 171.262 92.984 170.444Z" fill="white" />
                        <path opacity="0.2" d="M92.984 170.444C91.7615 169.873 90.4871 169.354 89.1702 168.882C87.5646 168.308 85.931 167.817 84.2756 167.409C72.9476 164.577 59.6183 165.082 49.1541 168.702C47.6352 169.222 46.1475 169.829 44.6984 170.519C43.6207 171.032 42.5699 171.599 41.5502 172.219C39.9672 173.17 38.4908 174.289 37.1464 175.556C35.8412 176.773 34.7456 178.197 33.9038 179.771C32.8133 181.779 32.376 184.077 32.653 186.346C32.8574 187.862 33.3561 189.325 34.1209 190.65C34.3002 190.971 34.4985 191.292 34.7109 191.594C35.8171 193.2 37.1639 194.627 38.704 195.823C39.2578 196.27 39.8478 196.706 40.474 197.131C41.1065 197.556 41.772 197.971 42.48 198.372C43.295 198.835 44.1603 199.282 45.076 199.713C46.1899 200.232 47.3463 200.713 48.5405 201.129C49.3775 201.443 50.2287 201.728 51.094 201.983C63.838 205.868 79.7822 205.287 91.3603 200.501C92.1533 200.175 92.9179 199.831 93.6637 199.467C94.6549 198.995 95.5989 198.467 96.4957 197.914C97.3254 197.41 98.1289 196.863 98.9029 196.276C99.4689 195.857 100.014 195.409 100.536 194.936C101.033 194.489 101.505 194.017 101.952 193.52C103.147 192.215 104.092 190.701 104.742 189.055C104.864 188.725 104.978 188.394 105.067 188.064C105.471 186.644 105.572 185.155 105.365 183.693C104.978 181.316 103.937 179.093 102.358 177.274C101.093 175.772 99.6305 174.448 98.0108 173.337C96.4216 172.23 94.7397 171.262 92.984 170.444Z" fill="white" />
                        <path opacity="0.15" d="M53.7655 202.743C52.8593 202.516 51.9625 202.271 51.0846 202.002C50.2066 201.733 49.3665 201.454 48.5358 201.148C47.3369 200.713 46.1758 200.232 45.0666 199.732C44.1509 199.3 43.2855 198.854 42.4706 198.391C41.7626 197.99 41.097 197.574 40.4646 197.15C39.8321 196.725 39.2468 196.291 38.6946 195.842C37.166 194.649 35.8289 193.229 34.7298 191.632C34.5174 191.316 34.3191 190.995 34.1398 190.688C33.375 189.362 32.8763 187.9 32.6718 186.383C32.3949 184.115 32.8321 181.817 33.9226 179.808C34.7645 178.235 35.8601 176.811 37.1653 175.593C38.5097 174.327 39.9861 173.208 41.569 172.256C42.4564 171.714 43.391 171.213 44.3586 170.737L53.7655 202.743Z" fill="white" />
                        <path opacity="0.15" d="M37.4862 194.785C36.4469 193.846 35.5217 192.788 34.7298 191.632C34.5174 191.316 34.3191 190.995 34.1398 190.688C33.3749 189.362 32.8763 187.9 32.6718 186.383C32.441 184.535 32.6947 182.658 33.4082 180.936L37.4862 194.785Z" fill="white" />
                        <path opacity="0.15" d="M64.9897 204.433C62.9459 204.305 60.9116 204.088 58.9198 203.777L48.6726 168.882L49.173 168.702C50.7494 168.159 52.3967 167.692 54.0865 167.286L64.9897 204.433Z" fill="white" />
                        <path opacity="0.15" d="M83.2372 203.045C81.2299 203.494 79.2007 203.839 77.1578 204.079L65.8818 165.672C68.0058 165.578 70.1298 165.582 72.2679 165.672L83.2372 203.045Z" fill="white" />
                        <path opacity="0.15" d="M88.3206 201.643C87.32 201.974 86.291 202.28 85.2432 202.554L74.4627 165.832C75.6191 165.927 76.7708 166.045 77.913 166.201L88.3206 201.643Z" fill="white" />
                        <path opacity="0.3" d="M94.8059 171.336C95.2779 171.591 95.7499 171.855 96.1889 172.12L96.6325 172.384L97.0762 172.667C97.4019 172.875 97.7181 173.082 98.0202 173.295C99.6399 174.405 101.102 175.729 102.367 177.231C103.946 179.05 104.987 181.273 105.374 183.65C105.582 185.112 105.48 186.601 105.077 188.021C104.987 188.352 104.874 188.682 104.751 189.012C104.104 190.675 103.155 192.203 101.952 193.52C101.792 193.699 101.622 193.874 101.452 194.053L94.8059 171.336Z" fill="#F10086" />
                        <path d="M86.8055 174.13C75.9212 169.08 59.7033 169.457 49.7299 174.829C49.3098 175.06 48.8945 175.301 48.4933 175.542C38.6757 181.588 39.9123 190.754 51.2592 196.022C62.6061 201.289 79.7538 200.657 89.5714 194.606C89.9726 194.355 90.355 194.101 90.7184 193.846C99.271 187.823 97.704 179.181 86.8055 174.13Z" fill="#F10086" />
                        <path opacity="0.4" d="M86.8055 174.13C75.9212 169.08 59.7033 169.457 49.7299 174.829C49.3098 175.06 48.8945 175.301 48.4933 175.542C38.6757 181.588 39.9123 190.754 51.2592 196.022C62.6061 201.289 79.7538 200.657 89.5714 194.606C89.9726 194.355 90.355 194.101 90.7184 193.846C99.271 187.823 97.704 179.181 86.8055 174.13Z" fill="black" />
                        <path d="M86.0126 174.64C85.6633 174.48 85.3093 174.324 84.9411 174.168C74.5571 169.84 59.7977 170.345 50.589 175.306L50.3435 175.442C50.0226 175.617 49.711 175.796 49.3995 175.985C47.7398 176.965 46.2678 178.232 45.0524 179.728C42.5886 182.891 42.4422 186.416 44.5049 189.612C45.7226 191.5 47.7003 193.237 50.4238 194.733C50.9382 195.016 51.4763 195.29 52.0427 195.554C52.6091 195.819 53.0953 196.026 53.6381 196.239C54.8031 196.699 55.9928 197.095 57.2017 197.423C65.7826 199.783 76.1855 199.411 84.049 196.437C85.1466 196.031 86.2185 195.558 87.2586 195.021C87.7306 194.766 88.2026 194.502 88.6746 194.228C89.1466 193.954 89.4251 193.756 89.7697 193.506L90.0576 193.293C92.3562 191.613 93.8336 189.73 94.5369 187.79C95.7263 184.486 94.6313 180.998 91.3462 178.048C89.7464 176.655 87.949 175.506 86.0126 174.64Z" fill="#F10086" />
                        <path opacity="0.5" d="M86.0126 174.64C85.6633 174.48 85.3093 174.324 84.9411 174.168C74.5571 169.84 59.7977 170.345 50.589 175.306L50.3435 175.442C50.0226 175.617 49.711 175.796 49.3995 175.985C47.7398 176.965 46.2678 178.232 45.0524 179.728C42.5886 182.891 42.4422 186.416 44.5049 189.612C45.7226 191.5 47.7003 193.237 50.4238 194.733C50.9382 195.016 51.4763 195.29 52.0427 195.554C52.6091 195.819 53.0953 196.026 53.6381 196.239C54.8031 196.699 55.9928 197.095 57.2017 197.423C65.7826 199.783 76.1855 199.411 84.049 196.437C85.1466 196.031 86.2185 195.558 87.2586 195.021C87.7306 194.766 88.2026 194.502 88.6746 194.228C89.1466 193.954 89.4251 193.756 89.7697 193.506L90.0576 193.293C92.3562 191.613 93.8336 189.73 94.5369 187.79C95.7263 184.486 94.6313 180.998 91.3462 178.048C89.7464 176.655 87.949 175.506 86.0126 174.64Z" fill="black" />
                        <path d="M84.96 177.198C85.3234 177.349 85.6822 177.505 86.0314 177.67C87.9604 178.534 89.7513 179.677 91.3462 181.064C93.2625 182.782 94.4283 184.689 94.8342 186.629C94.7597 187.012 94.6556 187.389 94.5227 187.757C93.8242 189.697 92.3421 191.58 90.0434 193.26L89.7555 193.473C89.411 193.714 89.0428 193.945 88.6605 194.195C88.2782 194.445 87.7448 194.733 87.2445 194.988C86.2043 195.525 85.1325 195.997 84.0349 196.404C76.1714 199.378 65.7685 199.76 57.1875 197.39C55.9787 197.062 54.789 196.666 53.6239 196.206C53.0811 195.989 52.5525 195.762 52.0286 195.521C51.5046 195.281 50.9241 194.983 50.4096 194.7C47.6862 193.204 45.6896 191.448 44.4907 189.579C43.8865 188.664 43.4576 187.644 43.2258 186.572C43.5255 185.172 44.1513 183.862 45.0524 182.749C46.2662 181.25 47.7385 179.981 49.3995 179.001C49.7016 178.812 50.0178 178.633 50.3435 178.458L50.5937 178.322C59.8118 173.375 74.576 172.87 84.96 177.198Z" fill="#F10086" />
                        <path opacity="0.25" d="M84.96 177.198C85.3234 177.349 85.6822 177.505 86.0314 177.67C87.9604 178.534 89.7513 179.677 91.3462 181.064C93.2625 182.782 94.4283 184.689 94.8342 186.629C94.7597 187.012 94.6556 187.389 94.5227 187.757C93.8242 189.697 92.3421 191.58 90.0434 193.26L89.7555 193.473C89.411 193.714 89.0428 193.945 88.6605 194.195C88.2782 194.445 87.7448 194.733 87.2445 194.988C86.2043 195.525 85.1325 195.997 84.0349 196.404C76.1714 199.378 65.7685 199.76 57.1875 197.39C55.9787 197.062 54.789 196.666 53.6239 196.206C53.0811 195.989 52.5525 195.762 52.0286 195.521C51.5046 195.281 50.9241 194.983 50.4096 194.7C47.6862 193.204 45.6896 191.448 44.4907 189.579C43.8865 188.664 43.4576 187.644 43.2258 186.572C43.5255 185.172 44.1513 183.862 45.0524 182.749C46.2662 181.25 47.7385 179.981 49.3995 179.001C49.7016 178.812 50.0178 178.633 50.3435 178.458L50.5937 178.322C59.8118 173.375 74.576 172.87 84.96 177.198Z" fill="black" />
                        <path d="M104.756 189.031C100.63 198.207 89.09 201.865 79.683 203.517C65.3814 205.57 49.8149 203.847 38.7559 195.71C35.7493 193.35 33.191 190.174 32.6482 186.331C33.1202 190.211 35.5935 193.496 38.6049 195.922C47.5115 202.823 59.8449 205.06 71.6449 204.73C83.544 204.041 99.3654 200.841 104.756 189.031Z" fill="#F5F5F5" />
                        <path d="M54.0393 191.835C58.0796 193.529 62.0633 192.779 65.7307 191.49L64.7584 192.491V196.682C65.5814 196.878 66.4174 197.013 67.26 197.088C68.7086 197.211 70.1668 197.157 71.6024 196.928C73.9616 196.537 76.2462 195.784 78.3756 194.695L81.9345 196.192L86.7394 193.232V189.041L85.8379 188.654L85.6397 188.573C85.7145 188.252 85.7478 187.922 85.7388 187.592C85.737 187.481 85.7275 187.371 85.7105 187.261C85.6491 186.742 85.7577 184.377 85.7388 183.396C85.7388 183.278 85.7388 183.174 85.7058 183.065C85.5689 181.895 84.5918 180.842 82.6047 180.011C80.589 179.198 78.4003 178.907 76.2422 179.166L75.7041 179.233H75.6427L75.0763 179.322C74.9111 179.351 74.7506 179.379 74.6043 179.417C74.5335 179.417 74.4627 179.44 74.4014 179.459C74.2753 179.479 74.1507 179.508 74.0285 179.544C72.9896 179.786 71.9653 180.087 70.9605 180.446C70.5215 180.601 70.0826 180.767 69.6483 180.918L70.918 179.596V175.4C66.8305 174.621 62.4598 175.282 58.174 177.382L54.6151 175.9L49.8621 178.935V183.136L50.976 183.608C50.9028 183.866 50.8678 184.133 50.8722 184.401V184.434C50.9099 185.081 50.8438 187.861 50.8722 188.592V188.625C50.9194 189.867 51.92 190.957 54.0393 191.835Z" fill="#F10086" />
                        <path opacity="0.15" d="M85.7435 183.608C85.7435 184.679 85.6633 186.789 85.7435 187.271C85.7435 187.384 85.7435 187.493 85.7718 187.606C85.779 187.936 85.7441 188.266 85.668 188.588L83.072 187.497C83.072 187.497 85.6727 186.251 85.7435 183.608Z" fill="black" />
                        <path opacity="0.45" d="M59.911 183.528C58.8442 183.079 58.6696 182.249 60.6331 180.984C62.7382 179.648 64.8811 179.303 66.7408 179.761L70.9038 175.424V179.615L69.6483 180.936L69.5162 180.988C68.8365 181.248 68.171 181.517 67.5432 181.781C67.282 181.895 67.0319 181.998 66.7927 182.093C65.4391 182.727 64.0337 183.243 62.5919 183.636C61.9986 183.796 61.3798 183.839 60.77 183.764C60.4745 183.723 60.1856 183.644 59.911 183.528Z" fill="black" />
                        <path opacity="0.2" d="M50.943 183.608L49.8385 183.136V178.945L53.3832 180.431C52.0663 181.531 51.2214 182.603 50.943 183.608Z" fill="black" />
                        <path opacity="0.15" d="M81.9345 191.991L86.7442 189.036V193.232L81.9298 196.196L78.3803 194.695C76.2505 195.785 73.9637 196.537 71.6024 196.923C69.3282 197.306 67 197.224 64.7584 196.682V192.491C67.0009 193.027 69.3283 193.108 71.6024 192.727C73.9642 192.343 76.2513 191.591 78.3803 190.499L81.9345 191.991Z" fill="black" />
                        <path opacity="0.2" d="M78.3851 194.695C76.7211 195.728 74.896 196.475 72.9854 196.904C71.1091 197.352 69.1617 197.417 67.26 197.093C69.1657 197.069 71.0638 196.847 72.924 196.432C73.868 196.229 74.7648 195.96 75.6805 195.677C76.5962 195.394 77.4741 195.04 78.3851 194.695Z" fill="black" />
                        <path opacity="0.35" d="M50.8533 188.625V188.602C50.825 187.865 50.8863 185.09 50.8533 184.434C50.9241 185.671 51.9294 186.766 54.0346 187.644C58.7262 189.598 63.2905 188.314 67.4677 186.638C71.3428 185.071 74.1606 183.4 76.4357 184.344C77.8517 184.944 77.4505 185.888 75.9023 186.874C74.916 187.498 73.8128 187.913 72.6603 188.095C71.5077 188.277 70.3302 188.221 69.1999 187.932L65.7449 191.49C62.0444 192.798 58.089 193.52 54.0582 191.835C51.92 190.957 50.9241 189.867 50.8533 188.625Z" fill="black" />
                        <path d="M66.7455 183.957L66.7408 179.761L70.8991 175.419L70.9039 179.615L66.7455 183.957Z" fill="#F10086" />
                        <path opacity="0.15" d="M66.7455 183.957L66.7408 179.761L70.8991 175.419L70.9039 179.615L66.7455 183.957Z" fill="black" />
                        <path d="M49.8338 178.954L53.3974 180.436L50.9524 183.613L49.8385 183.145L49.8338 178.954Z" fill="#F10086" />
                        <path opacity="0.45" d="M49.8338 178.954L53.3974 180.436L50.9524 183.613L49.8385 183.145L49.8338 178.954Z" fill="black" />
                        <path d="M85.8426 188.654L83.072 187.497C83.072 187.497 85.7766 186.185 85.7246 183.396C85.7246 183.278 85.7246 183.174 85.6916 183.065C85.5547 181.895 84.5777 180.842 82.5906 180.011C80.5749 179.198 78.3862 178.907 76.228 179.166L75.6899 179.233H75.6286L75.0622 179.322C74.9017 179.351 74.7365 179.379 74.5902 179.417C74.5194 179.417 74.4486 179.44 74.3872 179.459C74.2612 179.479 74.1366 179.508 74.0143 179.544C72.9754 179.786 71.9511 180.087 70.9463 180.446C70.4743 180.616 70.0023 180.795 69.5303 180.984C68.8506 181.248 68.1804 181.522 67.5479 181.786C67.293 181.899 67.0429 181.998 66.7974 182.102C65.4429 182.728 64.0395 183.243 62.6014 183.641C62.0089 183.797 61.3922 183.839 60.7842 183.764C60.4912 183.721 60.2045 183.643 59.9298 183.532C58.8584 183.084 58.6743 182.249 60.6473 180.984C62.7477 179.643 64.8953 179.299 66.755 179.761L70.9133 175.424C66.8258 174.64 62.455 175.301 58.1693 177.401L54.6104 175.919L49.8574 178.954L53.421 180.436C51.7218 181.852 50.825 183.188 50.8722 184.406V184.439C50.943 185.671 51.953 186.761 54.0629 187.644C58.7404 189.602 63.3094 188.314 67.4866 186.638C71.3664 185.066 74.1795 183.4 76.4546 184.349C77.8706 184.948 77.4646 185.883 75.9118 186.879C74.9247 187.501 73.8215 187.915 72.6692 188.097C71.517 188.278 70.3398 188.224 69.2094 187.936L64.782 192.491C65.6051 192.686 66.4411 192.822 67.2836 192.897C68.734 193.018 70.1937 192.963 71.6307 192.732C73.9881 192.342 76.2712 191.591 78.3992 190.504L81.9581 191.995L86.7678 189.041L85.8426 188.654Z" fill="#F10086" />
                        <path opacity="0.1" d="M85.8426 188.654L83.072 187.497C83.072 187.497 85.7766 186.185 85.7246 183.396C85.7246 183.278 85.7246 183.174 85.6916 183.065C85.5547 181.895 84.5777 180.842 82.5906 180.011C80.5749 179.198 78.3862 178.907 76.228 179.166L75.6899 179.233H75.6286L75.0622 179.322C74.9017 179.351 74.7365 179.379 74.5902 179.417C74.5194 179.417 74.4486 179.44 74.3872 179.459C74.2612 179.479 74.1366 179.508 74.0143 179.544C72.9754 179.786 71.9511 180.087 70.9463 180.446C70.4743 180.616 70.0023 180.795 69.5303 180.984C68.8506 181.248 68.1804 181.522 67.5479 181.786C67.293 181.899 67.0429 181.998 66.7974 182.102C65.4429 182.728 64.0395 183.243 62.6014 183.641C62.0089 183.797 61.3922 183.839 60.7842 183.764C60.4912 183.721 60.2045 183.643 59.9298 183.532C58.8584 183.084 58.6743 182.249 60.6473 180.984C62.7477 179.643 64.8953 179.299 66.755 179.761L70.9133 175.424C66.8258 174.64 62.455 175.301 58.1693 177.401L54.6104 175.919L49.8574 178.954L53.421 180.436C51.7218 181.852 50.825 183.188 50.8722 184.406V184.439C50.943 185.671 51.953 186.761 54.0629 187.644C58.7404 189.602 63.3094 188.314 67.4866 186.638C71.3664 185.066 74.1795 183.4 76.4546 184.349C77.8706 184.948 77.4646 185.883 75.9118 186.879C74.9247 187.501 73.8215 187.915 72.6692 188.097C71.517 188.278 70.3398 188.224 69.2094 187.936L64.782 192.491C65.6051 192.686 66.4411 192.822 67.2836 192.897C68.734 193.018 70.1937 192.963 71.6307 192.732C73.9881 192.342 76.2712 191.591 78.3992 190.504L81.9581 191.995L86.7678 189.041L85.8426 188.654Z" fill="white" />
                        <path d="M54.0393 187.644C55.814 188.322 57.7119 188.618 59.6089 188.512C61.5444 188.378 63.4513 187.971 65.2729 187.304C67.1326 186.69 68.9214 185.888 70.7481 185.123C71.6652 184.733 72.6123 184.417 73.5801 184.179C74.5183 183.939 75.5081 183.997 76.4121 184.344C75.4951 184.037 74.5049 184.024 73.5801 184.307C72.6355 184.567 71.7124 184.9 70.8189 185.302C69.0206 186.091 67.2175 186.912 65.3437 187.549C63.4874 188.219 61.5421 188.61 59.5711 188.71C58.6064 188.74 57.6412 188.668 56.6919 188.493C55.7813 188.3 54.8925 188.016 54.0393 187.644Z" fill="white" />
                        <path d="M81.9345 191.995L81.9392 196.192L78.3803 194.7L78.3756 190.504L81.9345 191.995Z" fill="#F10086" />
                        <path opacity="0.35" d="M81.9345 191.995L81.9392 196.192L78.3803 194.7L78.3756 190.504L81.9345 191.995Z" fill="black" />
                        <path d="M86.7442 189.041L81.9534 191.528L78.3756 190.504L81.9345 191.995L86.7442 189.041Z" fill="#FAFAFA" />
                        <path d="M58.1457 177.401L54.6057 176.353L49.8338 178.954L54.5868 175.919L58.1457 177.401Z" fill="#FAFAFA" />
                        <path opacity="0.15" d="M70.8991 175.424C70.1614 175.287 69.4157 175.197 68.6665 175.154L69.2471 177.146L70.8991 175.424Z" fill="white" />
                        <path opacity="0.15" d="M54.1998 187.705C56.1461 188.481 58.2511 188.776 60.3358 188.564L56.9043 176.887L54.5915 175.919L51.3536 177.986L51.887 179.804L53.4021 180.436C53.0354 180.731 52.6856 181.046 52.3542 181.38L54.1998 187.705Z" fill="white" />
                        <path opacity="0.15" d="M81.8873 179.733C80.7467 179.347 79.5555 179.13 78.352 179.091L82.128 191.887L84.96 190.145L84.3322 188.012L83.105 187.497C83.4283 187.334 83.7336 187.138 84.016 186.912L81.8873 179.733Z" fill="white" />
                        <path opacity="0.15" d="M75.6474 179.233L75.081 179.322C74.9206 179.351 74.7554 179.379 74.609 179.417C74.5382 179.417 74.4674 179.44 74.4061 179.459C74.28 179.479 74.1555 179.508 74.0332 179.544C72.9943 179.786 71.97 180.087 70.9652 180.446C70.7434 180.521 70.531 180.611 70.3091 180.691L71.5505 184.939C73.4668 184.184 75.0574 183.783 76.4498 184.363C77.8422 184.944 77.4599 185.897 75.907 186.893C74.8721 187.549 73.7054 187.97 72.4898 188.125L73.7122 192.298C75.3305 191.869 76.8998 191.272 78.3945 190.518L79.7397 191.08L76.2422 179.176C76.0722 179.176 75.8929 179.223 75.7277 179.237L75.6474 179.233Z" fill="white" />
                        <path d="M34.4843 184.382L35.0649 179.983L35.6454 184.382L43.2635 184.717L35.6454 185.052L35.0649 189.451L34.4843 185.052L26.8615 184.717L34.4843 184.382Z" fill="white" />
                        <path d="M38.6002 187.795L38.8834 185.661L39.1618 187.795L42.8576 187.955L39.1618 188.116L38.8834 190.249L38.6002 188.116L34.9091 187.955L38.6002 187.795Z" fill="white" />
                    </svg>
)

export {
    RightArrow,
    FollowUS,
    Signout,
    HistorySvg,
    Transaction,
    Rupee,
    ReferBanner
}